import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { provideLegalName } from "../OwnershipTree/utils";
import { ProfileActionOverview } from "../ProfileActionOverview";
import { JointLandingScreenProps } from "./types";

export const ProfileActionOverviewScreen: React.FC<JointLandingScreenProps> = ({
  caseResult,
  basePath,
}) => {
  const { profileId } = useParams<{ profileId: string }>();
  const history = useHistory();
  const investingProfileId = caseResult?.complianceCasesByObjectId[0].investingProfile?.id;
  const investingProfileAccountType =
    caseResult?.complianceCasesByObjectId[0].investingProfile?.accountType || "";

  if (
    investingProfileId === parseInt(profileId) &&
    ["individual", "joint", "joint_other", "tic"].includes(investingProfileAccountType)
  ) {
    // This shouldn't normally happen, but if user changes the account type and clicks on a cached URL,
    // redirect to the correct page.
    history.push(basePath);
  }

  const allProfiles = caseResult?.complianceCasesByObjectId[0].profiles.concat([
    caseResult?.complianceCasesByObjectId[0].investingProfile,
  ]);
  const profile = allProfiles?.filter((p) => p?.id === parseInt(profileId))[0];
  if (!profile) {
    throw new Error("Profile not found");
  }

  // All fields are required, so checking just one is sufficient
  const areDetailsCompleted =
    profile.complianceObjectType === "individual"
      ? !!profile.dateOfBirth
      : !!profile.dateOfOrganization;
  const areDocumentsCompleted = !!profile.documents.length;
  const areOwnersCompleted = !!profile.caseDataEntryStatus?.ownersCompleted;
  const areControllersCompleted = !!profile.caseDataEntryStatus?.controllersCompleted;
  return (
    <div>
      <ProfileActionOverview
        profileName={provideLegalName(profile)}
        profileType={profile.complianceObjectType}
        detailsCompleted={areDetailsCompleted}
        documentsCompleted={areDocumentsCompleted}
        ownersCompleted={areOwnersCompleted}
        controllersCompleted={areControllersCompleted}
        onDetailsClick={() => {
          history.push(`${basePath}/edit-institution/${profileId}`);
        }}
        onDocumentsClick={() => {
          history.push(`${basePath}/documents/${profileId}`);
        }}
        onOwnersClick={() => {
          history.push(`${basePath}/owners/${profileId}`);
        }}
        onControllersClick={() => {
          history.push(`${basePath}/controllers/${profileId}`);
        }}
      />
    </div>
  );
};
