import { DocumentCategoryInfo, IdentificationCategory } from "./types";

export const documentCategories: Record<IdentificationCategory, DocumentCategoryInfo[]> = {
  [IdentificationCategory.Category1]: [
    {
      title: "Formation document",
      description:
        "Legal document issued by a government entity confirming the establishment and existence of an entity. Examples include: Certificate of Incorporation or Certificate of Formation.",
    },
    {
      title: "AML reliance letter",
      description:
        "Letter confirming reliance on another institution's anti-money laundering procedures. Examples include: AML Reliance Letter, Reliance Confirmation Letter, or AML Comfort Letter.",
    },
    {
      title: "W series tax form",
      description:
        "Standard IRS tax form for reporting purposes. Examples include: W-8 or W-9 forms.",
    },
    {
      title: "Confirmation of beneficial owners",
      description:
        "Formal declaration identifying individuals with significant ownership or control. Examples include: Declaration of Beneficial Ownership, Ownership Confirmation Form, or Juniper Square's Appendix A.",
    },
    {
      title: "Organization chart",
      description:
        "Diagrammatic representation of an entity's governance and reporting structuren",
    },
  ],
  [IdentificationCategory.Category2]: [
    {
      title: "Share register",
      description:
        "Official register detailing all shareholders of an entity. Examples include: Shareholder Register, Register of Owners, or Register of Members.",
    },
    {
      title: "W series tax form",
      description:
        "Standard IRS tax form for reporting purposes. Examples include: W-8 or W-9 forms.",
    },
  ],
  [IdentificationCategory.Category3]: [
    {
      title: "Share register",
      description:
        "Official register detailing all shareholders of an entity. Examples include: Shareholder Register, Register of Owners, or Register of Members.",
    },
    {
      title: "W series tax form",
      description:
        "Standard IRS tax form for reporting purposes. Examples include: W-8 or W-9 forms.",
    },
  ],
  [IdentificationCategory.Category4]: [
    {
      title: "Share register",
      description:
        "Official register detailing all shareholders of an entity. Examples include: Shareholder Register, Register of Owners, or Register of Members.",
    },
    {
      title: "Share register",
      description:
        "Official register detailing all shareholders of an entity. Examples include: Shareholder Register, Register of Owners, or Register of Members.",
    },
  ],
};
