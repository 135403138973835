export enum IdentificationCategory {
  Category1 = "Category1",
  Category2 = "Category2",
  Category3 = "Category3",
  Category4 = "Category4",
}

export interface DocumentCategoryInfo {
  title: string;
  description: string;
}

export interface DocumentCollectorProps {
  /**
   * Identification document category
   */
  identificationCategory: IdentificationCategory;

  /**
   * Handler for back button click
   */
  onNavigateBack?: () => void;

  /**
   * Handler for complete button click
   */
  onComplete: () => void;
}
