import {
  ComplianceObjectType,
  ProfileRelationshipEnum as ProfileRelationship,
} from "../../graphql/supergraph.index";
import {
  ComplianceObjectType as ComplianceObjectTypeEnum,
  ProfileRelationshipEnum,
} from "../../graphql/supergraphOperations";
import { MISSING_VALUE_PLACEHOLDER } from "../common/constants";
import { HierarchicalProfileNode, InvestingProfile, ProfileType } from "./types";

/**
 * Extracts profiles that have parent relationships of specific types.
 *
 * This function filters profiles based on their relationship types, keeping only those
 * that have at least one parent relationship marked as "owner", "controller", or "payment_beneficiary".
 *
 * @param profiles - Array of profiles to filter
 * @returns Array of filtered profiles with relevant parent relationships
 */
export const extractChildProfiles = (profiles: ProfileType[]): ProfileType[] => {
  if (!profiles || !profiles.length) return [];

  return profiles.filter((p) =>
    p?.parentRelationships?.some((pr) =>
      Object.values(ProfileRelationshipEnum).includes(
        pr.relationshipType as ProfileRelationshipEnum,
      ),
    ),
  );
};

/**
 * Builds a hierarchical tree structure from flat profiles.
 *
 * This function transforms a flat list of profiles into a tree structure using a two-pass approach:
 * 1. First pass: Create all nodes and store them in a map for efficient lookup
 * 2. Second pass: Establish parent-child relationships between nodes
 *
 * The investing profile serves as the root of the tree, and other profiles
 * are connected based on their parent relationships.
 *
 * @param profiles - Array of profiles to organize into a hierarchy
 * @param investingProfile - The root profile for the hierarchy
 * @returns A hierarchical tree structure with nested children
 */
export const buildHierarchy = (
  profiles: ProfileType[],
  investingProfile: InvestingProfile,
): HierarchicalProfileNode => {
  // Initialize the root node using the investing profile
  const rootNode: HierarchicalProfileNode = {
    id: investingProfile.id,
    displayName: provideLegalName(investingProfile),
    complianceObjectType: investingProfile.complianceObjectType,
    children: [],
  };

  // Create a map for fast node lookup by ID
  // This avoids O(n) searches when establishing relationships
  const nodeMap = new Map<number, HierarchicalProfileNode>();
  nodeMap.set(rootNode.id, rootNode);

  // FIRST PASS: Pre-process all profiles and create nodes
  // Create profile nodes with all necessary data and add them to the map
  profiles.forEach((profile) => {
    // Extract ownership percentage from parent relationships
    const percentageOwnership = profile.parentRelationships?.find(
      (pr) => pr.relationshipType == ProfileRelationshipEnum.Owner,
    )?.percentOwnership;

    // Create the node and add it to the map
    nodeMap.set(profile.id, {
      id: profile.id,
      parentId: profile.parentRelationships[0]?.parentProfileId,
      displayName: provideLegalName(profile),
      complianceObjectType: profile.complianceObjectType,
      relationship: profile.parentRelationships?.map((pr) => pr.relationshipType),
      percentageOwnership: percentageOwnership,
      children: [],
    });
  });

  // SECOND PASS: Establish parent-child relationships
  // Connect each node to its parent by adding it to the parent's children array
  [...nodeMap.values()].forEach((profile) => {
    if (profile.parentId) {
      const parentNode = nodeMap.get(profile.parentId);
      if (parentNode) {
        parentNode.children.push(profile);
      }
    }
  });

  // THIRD PASS: Calculate ownership shares for all nodes
  // Call our utility function to calculate root percentage ownership for all nodes
  calculateOwnershipShares(rootNode.children);

  // Return the fully constructed hierarchy with the root node at the top
  return rootNode;
};

/**
 * Calculates the root percentage ownership for all nodes in a tree.
 *
 * Traverses the hierarchy and calculates the absolute percentage ownership
 * for each node relative to the root. The percentage is accumulated through
 * the hierarchy by multiplying the parent's accumulated share by the node's
 * direct percentage ownership.
 *
 * @param nodes - Array of hierarchical nodes to process
 * @param accumulatedShare - Starting share percentage (defaults to 100%)
 */
export const calculateOwnershipShares = (
  nodes: HierarchicalProfileNode[],
  accumulatedShare: number = 100,
): void => {
  // Function to traverse the tree and calculate accumulated shares
  const traverse = (node: HierarchicalProfileNode, currentAccumulatedShare: number): void => {
    // Skip nodes without valid percentageOwnership
    if (node.percentageOwnership === null || node.percentageOwnership === undefined) {
      return;
    }

    // Calculate the current share
    const currentShare = currentAccumulatedShare * (node.percentageOwnership / 100);

    // Set rootPercentageOwnership for every node
    node.rootPercentageOwnership = currentShare;

    // Continue traversal with children
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        traverse(child, currentShare);
      });
    }
  };

  // Process each node in the array
  nodes.forEach((node) => {
    traverse(node, accumulatedShare);
  });
};

/**
 * Formats a person's full name from individual name components.
 *
 * Combines firstName, middleName, and lastName into a properly formatted full name.
 * If firstName or lastName is missing or empty, returns a placeholder value instead.
 * Handles null/undefined values and trims whitespace from all name parts.
 *
 * @param profile - Object containing name components (firstName, lastName, middleName)
 * @param missingValuePlaceholder - Value to return if required name parts are missing (default: "--")
 * @returns Formatted full name or placeholder if required fields are missing
 */
const provideFullName = (
  profile: Pick<ProfileType, "firstName" | "lastName" | "middleName"> | null | undefined,
): string => {
  if (!profile) return MISSING_VALUE_PLACEHOLDER;

  const { firstName, lastName, middleName } = profile;
  // Check for missing required fields
  if (!firstName?.trim() || !lastName?.trim()) {
    return MISSING_VALUE_PLACEHOLDER;
  }

  // Build the full name with proper spacing
  const parts = [firstName.trim(), middleName?.trim(), lastName.trim()]
    // filter(Boolean) removes any falsy values (null, undefined, empty strings)
    // This automatically handles missing middle names without extra conditionals
    .filter(Boolean);

  return parts.join(" ");
};

/**
 * Provides the legal name for a profile based on its compliance object type.
 *
 * For institutions, returns the legal name.
 * For individuals, returns the formatted full name.
 * Handles null/undefined values appropriately.
 *
 * @param profile - The profile object containing name information
 * @returns The legal name or a placeholder if information is missing
 */
export const provideLegalName = (
  profile?: Pick<
    ProfileType,
    "complianceObjectType" | "firstName" | "lastName" | "middleName" | "legalName"
  > | null,
): string => {
  if (!profile) return MISSING_VALUE_PLACEHOLDER;
  const { legalName, complianceObjectType } = profile;
  return complianceObjectType === ComplianceObjectTypeEnum.Institution
    ? legalName?.trim() ?? MISSING_VALUE_PLACEHOLDER
    : provideFullName(profile);
};

/**
 * Gets a human-readable label for a compliance object type.
 *
 * @param complianceObjectType - The compliance object type enum value
 * @returns The human-readable label or undefined for unknown types
 */
export const getComplianceLabel = (
  complianceObjectType?: ComplianceObjectType | null,
): string | undefined => {
  if (!complianceObjectType) return undefined;

  switch (complianceObjectType) {
    case ComplianceObjectTypeEnum.Institution:
      return "Institution";
    case ComplianceObjectTypeEnum.Individual:
      return "Individual";
    default:
      return undefined;
  }
};

/**
 * Gets a human-readable label for a relationship type.
 *
 * @param relationship - The relationship enum value
 * @returns The human-readable label or undefined for unknown types
 */
export const getRelationshipLabel = (
  relationship?: ProfileRelationship | null,
): string | undefined => {
  if (!relationship) return undefined;

  switch (relationship) {
    case ProfileRelationshipEnum.Owner:
      return "Owner";
    case ProfileRelationshipEnum.Controller:
      return "Controller";
    default:
      return undefined;
  }
};
