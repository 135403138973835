import React from "react";
import { FileObjectType, FileUpload, Flex, Label } from "@jsq/ds/core";
import { fileUploadTitle } from "./style_vanilla.css";
import { SingleFileUploadProps } from "./types";

export const SingleFileUpload: React.FC<SingleFileUploadProps> = ({
  title,
  description,
  acceptedFileFormats,
  initialFile,
  onFileSelected,
  onFileDeleted,
}: SingleFileUploadProps) => {
  const handleFileSelected = ([selectedFile]: FileObjectType[]) => {
    if (!selectedFile) return;
    onFileSelected(selectedFile);
  };

  const handleOnDelete = (deletedFile?: FileObjectType) => {
    if (deletedFile) {
      onFileDeleted(deletedFile);
    }
  };

  return (
    <Flex flexDirection="column">
      <Label className={fileUploadTitle}>{title}</Label>
      <FileUpload
        acceptedFileTypes={acceptedFileFormats}
        size="small"
        canAllowMultiple={false}
        isFullWidth
        guidanceMessage={description}
        alwaysShowGuidance
        onDelete={handleOnDelete}
        onFileSelected={handleFileSelected}
        initialFiles={initialFile ? [initialFile] : []}
        data-testid="file-attach-button"
      />
    </Flex>
  );
};
