export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (isoformat) */
  Date: any;
  /** Date with time (isoformat) */
  DateTime: any;
  /** Decimal (fixed-point) */
  Decimal: any;
  /**
   * Graphene type that will handle converting to camel case when generating strings for FE components and converting
   * back to snake case for string inputs.
   */
  FieldName: any;
  GlobalID: any;
  /**
   * HexColorCode Scalar type
   *
   * Expects hex color code. Can optionally start with `#`.
   */
  HexColorCode: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404_2nd_edition_december_2017.pdf). */
  JSON: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Scalar to allow structured boolean logic operations to be passed between the frontend
   * and backend, as per the jsonLogic spec: https://jsonlogic.com/
   *
   * Examples:
   *
   * ALWAYS:
   * true
   *
   * OR:
   * {
   * "or": [
   * {"==": [{"var": "subscription.accountType"}, "Individual"]},
   * {"==": [{"var": "subscription.accountType"}, "LLC"]},
   * ]
   * }
   *
   * AND:
   * {
   * "and": [
   * {
   * "or": [
   * {"==": [{"var": "subscription.accountType"}, "Individual"]},
   * {"==": [{"var": "subscription.accountType"}, "501c3"]},
   * ]
   * },
   * {
   * "==": [
   * {"var": "65e79858d200a7cf20a7202f.65e79858d200a7cf20a72030"},
   * "65e79858d200a7cf20a72031",
   * ]
   * },
   * ]
   * }
   */
  JsonLogicRule: any;
  /** Money scalar type */
  Money: { amount: number, currency: CurrencyCode, precision: number };
  Period: any;
  StrawberryObjectId: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /** Represents NULL values */
  Void: any;
  join__DirectiveArguments: any;
  join__FieldSet: any;
  link__Import: any;
};

export type AchDebitLimitDto = {
  __typename?: 'ACHDebitLimitDto';
  consumed: Scalars['Money'];
  limit: Scalars['Money'];
  limitType: PaymentsAchDebitLimitType;
};

export type AcceptMobileTouInput = {
  /** Determine the platform type for the TOU. */
  platformType: MobilePlatformType;
};

export type AcceptMobileTouResponse = MutationErrorResponse | MutationSuccessResponse;

export type AcceptPortalInvitationInput = {
  acceptedArenaId: Scalars['String'];
};

export type AcceptPortalInvitationResponse = MutationErrorResponse | MutationSuccessResponse;

export type AccountConnection = {
  __typename?: 'AccountConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AccountEdge>;
  pageInfo: PageInfo;
};

export type AccountContactLabelObjectType = {
  __typename?: 'AccountContactLabelObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  labelText: Scalars['String'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
};

export type AccountContactMapObjectType = PermissionCheckedNode & {
  __typename?: 'AccountContactMapObjectType';
  accountId?: Maybe<Scalars['ID']>;
  accountName: Scalars['String'];
  arenaId: Scalars['ID'];
  arenaName: Scalars['String'];
  communicationPrefs?: Maybe<Array<Maybe<CommunicationPrefObjectType>>>;
  contact?: Maybe<ContactObjectType>;
  contactDetails?: Maybe<ContactObjectType>;
  contactId?: Maybe<Scalars['ID']>;
  hasPortalAccount?: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isAdminContact: Scalars['Boolean'];
  isMainContact: Scalars['Boolean'];
  label?: Maybe<AccountContactLabelObjectType>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum AccountContactMapObjectTypeSortEnum {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsAdminContactAsc = 'IS_ADMIN_CONTACT_ASC',
  IsAdminContactDesc = 'IS_ADMIN_CONTACT_DESC',
  IsMainContactAsc = 'IS_MAIN_CONTACT_ASC',
  IsMainContactDesc = 'IS_MAIN_CONTACT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC'
}

export type AccountContactsConnection = {
  __typename?: 'AccountContactsConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AccountContactsEdge>;
  pageInfo: PageInfo;
};

export type AccountContactsEdge = {
  __typename?: 'AccountContactsEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AccountContactMapObjectType;
};

export type AccountContactsFilters = {
  accountId?: InputMaybe<Array<Scalars['ID']>>;
  arena?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** When True, returns only results where the user is an admin on the account. */
  fetchContactsWithAccountAdmin?: InputMaybe<Scalars['Boolean']>;
  nameOrEmail?: InputMaybe<Scalars['String']>;
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AccountObjectType;
};

export type AccountFilterInput = {
  arenaIds: Array<InputMaybe<Scalars['ID']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectType = NodeWithProfitLoss & NodeWithTimeSeries & ObjectDocuments & ObjectLevelEditPermissions & PermissionCheckedNode & TaggableObjectType & {
  __typename?: 'AccountObjectType';
  accountContactMaps?: Maybe<Array<AccountContactMapObjectType>>;
  allAssetsInGraph: Array<AssetObjectType>;
  /** Whether all diligences are confirmed for this entity; returns false for accounts. */
  allDiligencesConfirmed: Scalars['Boolean'];
  allEntitiesInGraph: Array<AccountObjectType>;
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  assets?: Maybe<AssetObjectTypeConnection>;
  assetsIndirect?: Maybe<AssetConnection>;
  /** Whether automated payments is enabled on the account */
  automatedPaymentsEnabled: Scalars['Boolean'];
  bankAccount?: Maybe<BankAccountObjectType>;
  /** Determines if the LPCM is enabled for the arena */
  canAccessLpcm: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  /** Determines if the authenticated user can edit account mailing address and payment preferences. */
  canEditAccountDetails: Scalars['Boolean'];
  canLimitedEdit?: Maybe<Scalars['Boolean']>;
  /** Determines if the authenticated user can edit contacts communication preferences or remove contact. */
  canLpcmManageContacts: Scalars['Boolean'];
  /** Determines if the authenticated user can view account payment preferences and contacts. */
  canViewAccountDetails: Scalars['Boolean'];
  capitalOut: Wallet;
  committed: Wallet;
  complianceSettings?: Maybe<ComplianceSettingsObjectType>;
  contacts?: Maybe<ContactObjectTypeConnection>;
  contributed: Wallet;
  contributionStoredTotals?: Maybe<Array<EntityStoredTotalsObjectType>>;
  contributionTypeTotals?: Maybe<Array<CapitalActivityTypeTotalsObjectType>>;
  createdAt: Scalars['DateTime'];
  currency: CurrencyObjectType;
  currentEquityBalance?: Maybe<Wallet>;
  deltas: Array<PositionDeltaObjectType>;
  description?: Maybe<Scalars['String']>;
  directlyOwnedEntities?: Maybe<Array<Maybe<AccountObjectType>>>;
  disregardedEntity?: Maybe<Scalars['Boolean']>;
  disregardedEntityUltimateBeneficialOwner?: Maybe<Scalars['String']>;
  disregardedEntityUltimateBeneficialOwnerTaxId?: Maybe<Scalars['String']>;
  disregardedEntityUltimateBeneficialOwnerTaxIdType?: Maybe<Scalars['Int']>;
  distributed: Wallet;
  distributionStoredTotals?: Maybe<Array<EntityStoredTotalsObjectType>>;
  domicileAddress?: Maybe<AddressObjectType>;
  endOfExtensionPeriods?: Maybe<Scalars['Date']>;
  endOfInvestingPeriod?: Maybe<Scalars['Date']>;
  endOfInvestmentLife?: Maybe<Scalars['Date']>;
  entityAccessGroups?: Maybe<Array<ObjectGroupObjectType>>;
  entityCollection?: Maybe<EntityCollectionObjectType>;
  entityTransactionTotals?: Maybe<EntityTransactionTotalsObjectType>;
  finalEquityBalance?: Maybe<Wallet>;
  gpDisplayName?: Maybe<Scalars['String']>;
  gpTitle: Scalars['String'];
  hasInvestorPositions: Scalars['Boolean'];
  hideCapitalCallDetails: Scalars['Boolean'];
  hideCommitment: Scalars['Boolean'];
  hideOwnershipPct: Scalars['Boolean'];
  highRiskPositions: Array<PositionObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  inceptionDate?: Maybe<Scalars['Date']>;
  inheritedDescription?: Maybe<EntityInheritedDescription>;
  initialEquityBalance?: Maybe<Wallet>;
  investorCapitalOut: Wallet;
  investorCommitted: Wallet;
  investorContributed: Wallet;
  investorDiligenceWatchlist?: Maybe<InvestorDiligenceObjectType>;
  investorPositionCount?: Maybe<Scalars['Int']>;
  isCompleted: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  /** Whether the investment entity is managed by fund admin */
  isFundAdminEntity?: Maybe<Scalars['Boolean']>;
  isInvestmentEntity: Scalars['Boolean'];
  isInvestorDiligenceEnabled?: Maybe<Scalars['Boolean']>;
  isOperatingCompany: Scalars['Boolean'];
  isPartial: Scalars['Boolean'];
  isPerfMetricsAsOfDateOverridden: Scalars['Boolean'];
  isRoot?: Maybe<Scalars['Boolean']>;
  isSpv: Scalars['Boolean'];
  latestTimeSeries?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesByPeriod?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesProjections?: Maybe<TimeSeriesDateToListTuple>;
  legalEntityName: Scalars['String'];
  loans?: Maybe<LoanObjectTypeConnection>;
  lpDisplayName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<AddressObjectType>;
  name: Scalars['String'];
  netIncomeStoredTotals?: Maybe<Array<EntityStoredTotalsObjectType>>;
  objectDocuments?: Maybe<DocumentConnection>;
  opCoDetails?: Maybe<OperatingCompanyDetailsObjectType>;
  opCosIndirect?: Maybe<Array<OperatingCompanyParentObjectType>>;
  overviewAndFinancialsPublished?: Maybe<Scalars['DateTime']>;
  overviewAndFinancialsVisible: Scalars['Boolean'];
  ownedEntities?: Maybe<Array<Maybe<AccountObjectType>>>;
  owningInvestorPositions?: Maybe<PositionConnection>;
  /** @deprecated Do not use this field because it is not paginated. Use owning_investor_positions instead. */
  owningInvestorPositionsDeprecated: Array<PositionObjectType>;
  owningPositions?: Maybe<PositionObjectTypeConnection>;
  paymentBatches?: Maybe<Array<PaymentBatchDto>>;
  /** Counts of positions that support each payment preference type on this account. */
  paymentMethodSupportedCounts: PaymentMethodSupportedCountsObjectType;
  paymentPrefs: Array<PaymentPrefObjectType>;
  /** Payments application tied to this account. */
  paymentsApplication?: Maybe<PaymentsApplicationDto>;
  perfMetricsAsOfDate?: Maybe<Scalars['Date']>;
  perfMetricsPublished?: Maybe<Scalars['Boolean']>;
  perfWarnings?: Maybe<PerfMetricsWarningFields>;
  performanceMetrics?: Maybe<PerformanceMetricsObjectType>;
  photos?: Maybe<PhotoObjectTypeConnection>;
  portalStatus?: Maybe<Array<Maybe<PortalStatus>>>;
  positionCount?: Maybe<Scalars['Int']>;
  positions?: Maybe<PositionConnection>;
  primaryPhoto?: Maybe<PhotoObjectType>;
  primaryPhotoId?: Maybe<Scalars['ID']>;
  profitLossPeriods: Array<ProfitLossPeriodObjectType>;
  published?: Maybe<Scalars['DateTime']>;
  publishedAssetsIndirect?: Maybe<AssetConnection>;
  redemptionStoredTotals?: Maybe<Array<EntityStoredTotalsObjectType>>;
  redemptionTypeTotals?: Maybe<Array<CapitalActivityTypeTotalsObjectType>>;
  reportingPeriod?: Maybe<Scalars['Period']>;
  showAccountValue: Scalars['Boolean'];
  showAccountValueBalance: Scalars['Boolean'];
  showCapitalAccountBalance: Scalars['Boolean'];
  showDpi: Scalars['Boolean'];
  showEntityTotalsOnNp?: Maybe<Scalars['Boolean']>;
  showInvestedEquityBalance: Scalars['Boolean'];
  showIrr: Scalars['Boolean'];
  showNavBalance: Scalars['Boolean'];
  showOwnershipAsCommitments: Scalars['Boolean'];
  showPerfMetricsOnAsset: Scalars['Boolean'];
  showRvpi: Scalars['Boolean'];
  showTimeWeightedReturns: Scalars['Boolean'];
  showTwrNetworkPortalOnly: Scalars['Boolean'];
  showTwrOnAsset: Scalars['Boolean'];
  showYield: Scalars['Boolean'];
  status?: Maybe<EntityStatus>;
  subEntities?: Maybe<Array<AccountObjectType>>;
  subscriptionSettings: EntitySubscriptionSettings;
  supportedPaymentMethods: Array<PaymentMethodEnum>;
  tags?: Maybe<TagConnection>;
  targetIrr?: Maybe<PercentageRange>;
  targetStrategy?: Maybe<Strategy>;
  taxId?: Maybe<Scalars['String']>;
  /** Tax ID, redacted to show only the last 4 digits. */
  taxIdRedacted?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<PhotoObjectType>;
  timeSeries: Array<TimeSeriesDateToListTuple>;
  timeSeriesProjections: Array<TimeSeriesDateToListTuple>;
  totalGrossSqft: Scalars['Int'];
  totalNetSqft: Scalars['Int'];
  transferAccountingEnabled?: Maybe<Scalars['Boolean']>;
  twrCompoundingPeriod?: Maybe<Scalars['String']>;
  twrEndDate?: Maybe<Scalars['Date']>;
  twrPeriodMetrics: TwrPeriodMetricsTableObjectType;
  twrStartDate?: Maybe<Scalars['Date']>;
  twrSummaryMetrics?: Maybe<TwrSummaryMetricsObjectType>;
  type?: Maybe<EntitySubTypeObjectType>;
  updatedInReportingPeriod: Scalars['Boolean'];
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeAllAssetsInGraphArgs = {
  editableOnly?: InputMaybe<Scalars['Boolean']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeAllEntitiesInGraphArgs = {
  editableOnly?: InputMaybe<Scalars['Boolean']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeAssetsIndirectArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AssetObjectTypeSortEnum>>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeContributedArgs = {
  maxCapitalCallBatchId?: InputMaybe<Scalars['ID']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeContributionStoredTotalsArgs = {
  period?: InputMaybe<Scalars['Period']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeDeltasArgs = {
  transferOnly?: InputMaybe<Scalars['Boolean']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeDistributedArgs = {
  maxDistributionBatchId?: InputMaybe<Scalars['ID']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeDistributionStoredTotalsArgs = {
  period?: InputMaybe<Scalars['Period']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeEntityTransactionTotalsArgs = {
  includePublishStatus?: InputMaybe<Scalars['Boolean']>;
  maxDate?: InputMaybe<Scalars['Date']>;
  period?: InputMaybe<Scalars['Period']>;
  periodOptions?: InputMaybe<EntityTotalsPeriodOptions>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeLatestTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeLatestTimeSeriesByPeriodArgs = {
  period: Scalars['Period'];
  timeSeriesTypeNames: Array<Scalars['FieldName']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeLatestTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeLoansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeNetIncomeStoredTotalsArgs = {
  period?: InputMaybe<Scalars['Period']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeObjectDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  manualShareOnly?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<DocumentObjectTypeSortEnum>>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeOpCosIndirectArgs = {
  filterDate?: InputMaybe<Scalars['Date']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeOwningInvestorPositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeOwningPositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypePhotosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PositionArgumentFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeProfitLossPeriodsArgs = {
  period?: InputMaybe<Scalars['Period']>;
  plCategories: Array<InputMaybe<Scalars['FieldName']>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypePublishedAssetsIndirectArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AssetObjectTypeSortEnum>>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeRedemptionStoredTotalsArgs = {
  period?: InputMaybe<Scalars['Period']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeSubscriptionSettingsArgs = {
  arenaId: Scalars['Int'];
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<TagObjectTypeSortEnum>>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeTwrPeriodMetricsArgs = {
  period?: InputMaybe<Scalars['Period']>;
};


/** Corresponds to the AccountObjectType in the main subgraph. */
export type AccountObjectTypeUpdatedInReportingPeriodArgs = {
  period: Scalars['Period'];
};

export type AccountObjectTypeConnection = {
  __typename?: 'AccountObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AccountObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type AccountObjectTypeEdge = {
  __typename?: 'AccountObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AccountObjectType;
};

/** An enumeration. */
export enum AccountObjectTypeSortEnum {
  ArenaIdAsc = 'ARENA_ID_ASC',
  ArenaIdDesc = 'ARENA_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DisregardedEntityAsc = 'DISREGARDED_ENTITY_ASC',
  DisregardedEntityDesc = 'DISREGARDED_ENTITY_DESC',
  DisregardedEntityUltimateBeneficialOwnerAsc = 'DISREGARDED_ENTITY_ULTIMATE_BENEFICIAL_OWNER_ASC',
  DisregardedEntityUltimateBeneficialOwnerDesc = 'DISREGARDED_ENTITY_ULTIMATE_BENEFICIAL_OWNER_DESC',
  DisregardedEntityUltimateBeneficialOwnerTaxIdAsc = 'DISREGARDED_ENTITY_ULTIMATE_BENEFICIAL_OWNER_TAX_ID_ASC',
  DisregardedEntityUltimateBeneficialOwnerTaxIdDesc = 'DISREGARDED_ENTITY_ULTIMATE_BENEFICIAL_OWNER_TAX_ID_DESC',
  DisregardedEntityUltimateBeneficialOwnerTaxIdTypeAsc = 'DISREGARDED_ENTITY_ULTIMATE_BENEFICIAL_OWNER_TAX_ID_TYPE_ASC',
  DisregardedEntityUltimateBeneficialOwnerTaxIdTypeDesc = 'DISREGARDED_ENTITY_ULTIMATE_BENEFICIAL_OWNER_TAX_ID_TYPE_DESC',
  EndOfExtensionPeriodsAsc = 'END_OF_EXTENSION_PERIODS_ASC',
  EndOfExtensionPeriodsDesc = 'END_OF_EXTENSION_PERIODS_DESC',
  EndOfInvestingPeriodAsc = 'END_OF_INVESTING_PERIOD_ASC',
  EndOfInvestingPeriodDesc = 'END_OF_INVESTING_PERIOD_DESC',
  EndOfInvestmentLifeAsc = 'END_OF_INVESTMENT_LIFE_ASC',
  EndOfInvestmentLifeDesc = 'END_OF_INVESTMENT_LIFE_DESC',
  GpDisplayNameAsc = 'GP_DISPLAY_NAME_ASC',
  GpDisplayNameDesc = 'GP_DISPLAY_NAME_DESC',
  InceptionDateAsc = 'INCEPTION_DATE_ASC',
  InceptionDateDesc = 'INCEPTION_DATE_DESC',
  IsInvestmentEntityAsc = 'IS_INVESTMENT_ENTITY_ASC',
  IsInvestmentEntityDesc = 'IS_INVESTMENT_ENTITY_DESC',
  LpDisplayNameAsc = 'LP_DISPLAY_NAME_ASC',
  LpDisplayNameDesc = 'LP_DISPLAY_NAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OverviewAndFinancialsPublishedAsc = 'OVERVIEW_AND_FINANCIALS_PUBLISHED_ASC',
  OverviewAndFinancialsPublishedDesc = 'OVERVIEW_AND_FINANCIALS_PUBLISHED_DESC',
  PrimaryPhotoIdAsc = 'PRIMARY_PHOTO_ID_ASC',
  PrimaryPhotoIdDesc = 'PRIMARY_PHOTO_ID_DESC',
  PublishedAsc = 'PUBLISHED_ASC',
  PublishedDesc = 'PUBLISHED_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TargetStrategyAsc = 'TARGET_STRATEGY_ASC',
  TargetStrategyDesc = 'TARGET_STRATEGY_DESC',
  TaxIdAsc = 'TAX_ID_ASC',
  TaxIdDesc = 'TAX_ID_DESC',
  TransferAccountingEnabledAsc = 'TRANSFER_ACCOUNTING_ENABLED_ASC',
  TransferAccountingEnabledDesc = 'TRANSFER_ACCOUNTING_ENABLED_DESC'
}

export enum AccountTypeEnum {
  Ag = 'ag',
  Corporation = 'corporation',
  Custodian = 'custodian',
  Dac = 'dac',
  EmployeePension = 'employee_pension',
  Field_501c3 = 'field_501c3',
  Foundation = 'foundation',
  Gmbh = 'gmbh',
  Individual = 'individual',
  Ira = 'ira',
  IraOrNonUs = 'ira_or_non_us',
  IrrevocableTrust = 'irrevocable_trust',
  Joint = 'joint',
  JointOther = 'joint_other',
  Llc = 'llc',
  Lllp = 'lllp',
  Llp = 'llp',
  Ltd = 'ltd',
  NonProfit = 'non_profit',
  Other = 'other',
  Partnership = 'partnership',
  PensionPlan = 'pension_plan',
  Plc = 'plc',
  Ptc = 'ptc',
  RevocableTrust = 'revocable_trust',
  Sa = 'sa',
  Sarl = 'sarl',
  SelfDirectedIra = 'self_directed_ira',
  Sovereign = 'sovereign',
  Spc = 'spc',
  Tic = 'tic',
  Trust = 'trust'
}

/** An enumeration. */
export enum AchAccountOwnershipType {
  Business = 'business',
  Personal = 'personal'
}

export type AchPaymentPrefObjectType = {
  __typename?: 'AchPaymentPrefObjectType';
  accountHolderType?: Maybe<PaymentPersonType>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountOwnership?: Maybe<AchAccountOwnershipType>;
  accountType?: Maybe<BankAccountType>;
  additionalInstructions?: Maybe<Scalars['String']>;
  address?: Maybe<SubscriptionAddressObjectType>;
  bankInfoRedacted?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  /** May be None if the payment pref is in draft state via subscriptions workflows. In this case, they may return a CommonPaymentPrefObjectType with the ID set to None. */
  id?: Maybe<Scalars['ID']>;
  naturalPersonDateOfBirth?: Maybe<Scalars['Date']>;
  naturalPersonEmailAddress?: Maybe<Scalars['String']>;
  naturalPersonFirstName?: Maybe<Scalars['String']>;
  naturalPersonLastName?: Maybe<Scalars['String']>;
  naturalPersonMiddleName?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentPrefId?: Maybe<Scalars['Int']>;
  positions?: Maybe<Array<PositionObjectType>>;
  routingNumber?: Maybe<Scalars['String']>;
  signatureFullName?: Maybe<Scalars['String']>;
};

export type ActiveEmbeddedDocusignEnvelopes = {
  __typename?: 'ActiveEmbeddedDocusignEnvelopes';
  accessibleEnvelopeDetails: Array<EnvelopeDetails>;
  allEnvelopesCount: Scalars['Int'];
  nonAccessibleEnvelopesCount: Scalars['Int'];
};

export type ActivityAttachmentObjectType = {
  __typename?: 'ActivityAttachmentObjectType';
  documentId?: Maybe<Scalars['ID']>;
  documentName?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersionObjectType>;
};

export type ActivityFeedObjectType = CapitalCallObjectType | DistributionObjectType | EmailObjectType | NetIncomeObjectType | NoteObjectType | TransactionObjectType;

export type AddAssigneesInput = {
  assigneeId?: InputMaybe<Scalars['String']>;
  contactIds: Array<Scalars['String']>;
};

export type AddContactRequest = {
  __typename?: 'AddContactRequest';
  account: AccountObjectType;
  accountContactMap: ChangeRequestAccountContactMap;
  addContactUnderReview: ChangeRequestContact;
  changeRequester: UserObjectType;
};

export type AddTag = {
  __typename?: 'AddTag';
  /** The newly created tag. */
  tag?: Maybe<TagObjectType>;
  /** Objects to whom the new tag was assigned. */
  updatedObjects?: Maybe<Array<Maybe<TaggableObjectType>>>;
};

export type AddTagInput = {
  tagText: Scalars['String'];
};

export type Address = {
  addressType?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
  subprovince?: InputMaybe<Scalars['String']>;
};

export type AddressDto = {
  __typename?: 'AddressDto';
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type AddressObjectType = AddressObjectTypeBase & {
  __typename?: 'AddressObjectType';
  addressType?: Maybe<AddressType>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<ObjectRefObjectType>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  state?: Maybe<ObjectRefObjectType>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
};

export type AddressObjectTypeBase = {
  addressType?: Maybe<AddressType>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<ObjectRefObjectType>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  state?: Maybe<ObjectRefObjectType>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
};

/** Enumeration that defines the types of addresses. */
export enum AddressType {
  Home = 'home',
  Unknown = 'unknown',
  Work = 'work'
}

/** An enumeration. */
export enum AllocationChoice {
  AccruedReturn = 'accrued_return',
  OwnershipPercentage = 'ownership_percentage'
}

export type AmountPaidObjectType = {
  __typename?: 'AmountPaidObjectType';
  /** Payments made via ACH that were before automated payments being enabled. */
  ach: Wallet;
  /** Payments made via check. */
  check: Wallet;
  /** Manual payments, identified by having a manual settlement date. */
  manual: Wallet;
};

export enum AnnouncementArenaGroup {
  FaOnly = 'FA_ONLY',
  NonFaOnly = 'NON_FA_ONLY',
  TestArenaOnly = 'TEST_ARENA_ONLY'
}

export enum AnnouncementFilterType {
  Read = 'READ',
  Unread = 'UNREAD'
}

export type AnnouncementNotificationDto = {
  __typename?: 'AnnouncementNotificationDto';
  arenaGroups: Array<AnnouncementArenaGroup>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Float'];
  createdByUser: UserObjectType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt: Scalars['DateTime'];
  header: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  roles: Array<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type ApiDiligencePaymentBankObjectType = {
  __typename?: 'ApiDiligencePaymentBankObjectType';
  country: Scalars['String'];
  isFincenSpecialMeasure: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ApiFirstWatchlistDiligenceObjectType = {
  __typename?: 'ApiFirstWatchlistDiligenceObjectType';
  arenaId: Scalars['Int'];
  diligenceData: DiligenceDataObjectType;
  id: Scalars['String'];
  investorDiligenceType: InvestorDiligenceType;
  keyIndividuals?: Maybe<Array<WatchlistKeyIndividualObjectType>>;
  keyInstitutions?: Maybe<Array<WatchlistKeyInstitutionObjectType>>;
  name: Scalars['String'];
  paymentBanks: Array<ApiDiligencePaymentBankObjectType>;
  status: InvestorDiligenceStatus;
};

/** An enumeration. */
export enum ApplicationContext {
  AdminFundAccounting = 'admin_fund_accounting'
}

export type ApprovalRequest = {
  __typename?: 'ApprovalRequest';
  account: AccountObjectType;
  changeRequest: ChangeRequest;
  changeRequestArena?: Maybe<ChangeRequestArena>;
  changeRequester: UserObjectType;
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status: ChangeRequestStatus;
  statusUpdatedAt: Scalars['DateTime'];
  statusUpdatedBy?: Maybe<UserObjectType>;
};

export type ApproveCanopyPaymentBatchInput = {
  paymentBatchId: Scalars['ID'];
};

export type ApprovePaymentBatchInput = {
  isFinal?: InputMaybe<Scalars['Boolean']>;
  paymentBatchId: Scalars['ID'];
  updatedProcessDate?: InputMaybe<Scalars['DateTime']>;
};

export type ArenaConnection = {
  __typename?: 'ArenaConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ArenaEdge>;
  pageInfo: PageInfo;
};

export type ArenaDeletePromptInput = {
  arenaId: Scalars['Int'];
  promptId: Scalars['StrawberryObjectId'];
  sectionId: Scalars['StrawberryObjectId'];
};

export type ArenaEdge = {
  __typename?: 'ArenaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ArenaObjectType;
};

/** An enumeration. */
export enum ArenaEdition {
  Full = 'full',
  GpLiteAssisted = 'gp_lite_assisted',
  GpLiteSelfServe = 'gp_lite_self_serve',
  Institutional = 'institutional',
  Professional = 'professional',
  Sponsor = 'sponsor'
}

/**
 * A graphene object for arena features assigned to their on/off state as a boolean
 * i.e. has_portal_invites = graphene.Boolean()
 * has_transfer_accounting = graphene.Boolean()
 * ...
 */
export type ArenaFeatures = {
  __typename?: 'ArenaFeatures';
  disableLexisNexisBridgerAndRdp: Scalars['Boolean'];
  hasAccountValuesSettings: Scalars['Boolean'];
  hasAdvancedPermissions: Scalars['Boolean'];
  hasAnalytics: Scalars['Boolean'];
  hasAssetReportTemplates: Scalars['Boolean'];
  hasAutoSubscribeLeads: Scalars['Boolean'];
  hasAutomatedPayments: Scalars['Boolean'];
  hasBankAccountShowSwiftUi: Scalars['Boolean'];
  hasBulkCountersign: Scalars['Boolean'];
  hasContactLastUpdateOptimization: Scalars['Boolean'];
  hasCrowdstreetIdInExports: Scalars['Boolean'];
  hasCrowdstreetIntegration: Scalars['Boolean'];
  hasCurrencyConversion: Scalars['Boolean'];
  hasCustomerEmailServer: Scalars['Boolean'];
  hasDataAccessRoleUse: Scalars['Boolean'];
  hasDocumentCategoryAdvancedPermissions: Scalars['Boolean'];
  hasDynamicSubscriptions: Scalars['Boolean'];
  hasDynamicSubscriptionsCustomSections: Scalars['Boolean'];
  hasEditAndDeleteActivity: Scalars['Boolean'];
  hasEditableLpPaymentPreferences: Scalars['Boolean'];
  hasEmailServiceProviderMailgun: Scalars['Boolean'];
  hasExternalAuthentication: Scalars['Boolean'];
  hasFundAdmin: Scalars['Boolean'];
  hasGlDataForSoi: Scalars['Boolean'];
  hasGpxIntegration: Scalars['Boolean'];
  hasGpxIntegrationExport: Scalars['Boolean'];
  hasHiddenAccountContactDownload: Scalars['Boolean'];
  hasHubspotIntegration: Scalars['Boolean'];
  hasInsightEtl: Scalars['Boolean'];
  hasLegacyAutomatedPayments: Scalars['Boolean'];
  hasLegacyEntityEquityFields: Scalars['Boolean'];
  hasLpcmAutomaticUpdateAndRemoval: Scalars['Boolean'];
  hasManagementLedgerAndFees: Scalars['Boolean'];
  hasNetAssetValueSettings: Scalars['Boolean'];
  hasNetIncomeSettings: Scalars['Boolean'];
  hasNewDocusignOauth: Scalars['Boolean'];
  hasNewOwnershipTypes: Scalars['Boolean'];
  hasOpenEndFundSettings: Scalars['Boolean'];
  hasOperatingCompanies: Scalars['Boolean'];
  hasOutlookIntegration: Scalars['Boolean'];
  hasOwnershipCache: Scalars['Boolean'];
  hasParallelFunds: Scalars['Boolean'];
  hasPortalInvites: Scalars['Boolean'];
  hasPositionPaymentPrefStaffApprovals: Scalars['Boolean'];
  hasPreqinIntegration: Scalars['Boolean'];
  hasProspectOptimization: Scalars['Boolean'];
  hasReportDashboardM1: Scalars['Boolean'];
  hasSso: Scalars['Boolean'];
  hasSubsV3GpFlow: Scalars['Boolean'];
  hasSubsV3Ui: Scalars['Boolean'];
  hasSubscriptions: Scalars['Boolean'];
  hasSwiftPayments: Scalars['Boolean'];
  hasTransferAccounting: Scalars['Boolean'];
  hasUnifiedLpSubscription: Scalars['Boolean'];
  hasUnifiedPortal: Scalars['Boolean'];
  hasUnifiedPortalPreview: Scalars['Boolean'];
  hasYardiImImport: Scalars['Boolean'];
  hasYardiVoyagerIntegration: Scalars['Boolean'];
  hasZapierIntegration: Scalars['Boolean'];
  hasZendeskMessagingWidget: Scalars['Boolean'];
  hideRecallableAmount: Scalars['Boolean'];
  mappingTabAdminSettings: Scalars['Boolean'];
  requireLpAccreditationAndPreferences: Scalars['Boolean'];
  showFaAccountingTab: Scalars['Boolean'];
  showFundAdminDeliverablesModule: Scalars['Boolean'];
  showOwnershipTabWithNewLogic: Scalars['Boolean'];
  supportsComprehensiveAuditLog: Scalars['Boolean'];
  supportsCustomDataFields: Scalars['Boolean'];
  supportsDynamicSubscriptionQuestions: Scalars['Boolean'];
  supportsEntityLevelContacts: Scalars['Boolean'];
  supportsIlpaNotices: Scalars['Boolean'];
  supportsMultipleNda: Scalars['Boolean'];
  supportsMultipleStationery: Scalars['Boolean'];
  supportsProspectTableCustomization: Scalars['Boolean'];
  supportsTimeWeightedReturn: Scalars['Boolean'];
  supportsWatermarking: Scalars['Boolean'];
};

/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectType = PermissionCheckedNode & {
  __typename?: 'ArenaObjectType';
  aboutText?: Maybe<Scalars['String']>;
  /** Portal 2.0 Field */
  accentBackgroundColor?: Maybe<Scalars['HexColorCode']>;
  activeEmbeddedDocusignEnvelopes: ActiveEmbeddedDocusignEnvelopes;
  activeOfferings?: Maybe<OfferingConnection>;
  /** Whether all diligences are confirmed for this arena. */
  allDiligencesConfirmed: Scalars['Boolean'];
  allowPortalInvestorReport: Scalars['Boolean'];
  archivedSoboEmailAddresses: Array<Scalars['String']>;
  assetDisclosureText?: Maybe<Scalars['String']>;
  assets?: Maybe<AssetConnection>;
  canAddNewNda: Scalars['Boolean'];
  capitalActivityDocumentCategory?: Maybe<DocumentCategoryObjectType>;
  capitalActivityDocumentCategoryId?: Maybe<Scalars['ID']>;
  capitalActivityEmailDistributionList?: Maybe<EmailDistributionListObjectType>;
  capitalActivityEmailDistributionListId?: Maybe<Scalars['ID']>;
  capitalCallDocumentCategory?: Maybe<DocumentCategoryObjectType>;
  capitalCallDocumentCategoryId?: Maybe<Scalars['ID']>;
  capitalCallEmailDistributionList?: Maybe<EmailDistributionListObjectType>;
  capitalCallEmailDistributionListId?: Maybe<Scalars['ID']>;
  clientPrivacyPolicy?: Maybe<Scalars['String']>;
  clientTos?: Maybe<Scalars['String']>;
  contactAccessGroups?: Maybe<ObjectGroupConnection>;
  contactAddress?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  distributionDocumentCategory?: Maybe<DocumentCategoryObjectType>;
  distributionDocumentCategoryId?: Maybe<Scalars['ID']>;
  distributionEmailDistributionList?: Maybe<EmailDistributionListObjectType>;
  distributionEmailDistributionListId?: Maybe<Scalars['ID']>;
  docusignSoboEmailAddress?: Maybe<Scalars['String']>;
  docusignSoboUserConsentUrl: Scalars['String'];
  docusignSoboUserStatus?: Maybe<SoboUserState>;
  docusignUserId?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
  electronicDeliveryConsentUrl?: Maybe<Scalars['String']>;
  emailDomain?: Maybe<Scalars['String']>;
  faviconUrl16px?: Maybe<Scalars['String']>;
  faviconUrl32px?: Maybe<Scalars['String']>;
  features?: Maybe<ArenaFeatures>;
  hasCurrencyConversion: Scalars['Boolean'];
  /** Whether the arena has external SSO configured. */
  hasExternalSsoConfigured: Scalars['Boolean'];
  hasPortalInvites: Scalars['Boolean'];
  hasUnifiedPortal: Scalars['Boolean'];
  hasUnifiedPortalPreview?: Maybe<Scalars['Boolean']>;
  headerLogoUrl?: Maybe<Scalars['String']>;
  headerLogoUrl2x?: Maybe<Scalars['String']>;
  hideCashFlowBarChartInPortal?: Maybe<Scalars['Boolean']>;
  hostname: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  idsTermsOfServiceSignedDate?: Maybe<Scalars['DateTime']>;
  importTemplateConfig?: Maybe<ImportTemplateConfig>;
  /** Indicates whether the arena is activated. */
  isActivated?: Maybe<Scalars['Boolean']>;
  isAdjustMetricsProvisioned?: Maybe<Scalars['Boolean']>;
  isGpLite: Scalars['Boolean'];
  isIdsTermsOfServiceSigned?: Maybe<Scalars['Boolean']>;
  isOnlineSubscriptionProvisioned?: Maybe<Scalars['Boolean']>;
  isPartial: Scalars['Boolean'];
  /** Returns a [client].junipersquare.com hostname */
  jsqHostname: Scalars['String'];
  loginMessage?: Maybe<Scalars['String']>;
  logoBackgroundColor?: Maybe<Scalars['HexColorCode']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoWideUrl?: Maybe<Scalars['String']>;
  lookerAcmeRecordValue?: Maybe<Scalars['String']>;
  lookerApiKeyIdValue?: Maybe<Scalars['String']>;
  lookerApiKeySecretValue?: Maybe<Scalars['String']>;
  lookerInstanceId?: Maybe<Scalars['Int']>;
  lookerVerificationRecordValue?: Maybe<Scalars['String']>;
  managementFeeDistributionList?: Maybe<EmailDistributionListObjectType>;
  managementFeeEmailDistributionList?: Maybe<EmailDistributionListObjectType>;
  managementFeeEmailDistributionListId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  /** Staff field returning collection of all NDAs for a given arena. Only returns id and name fields */
  ndaSelectList: Array<NdaSelectObjectType>;
  netIncomeDistributionList?: Maybe<EmailDistributionListObjectType>;
  netIncomeDocumentCategory?: Maybe<DocumentCategoryObjectType>;
  netIncomeDocumentCategoryId?: Maybe<Scalars['ID']>;
  netIncomeEmailDistributionList?: Maybe<EmailDistributionListObjectType>;
  netIncomeEmailDistributionListId?: Maybe<Scalars['ID']>;
  netNoticeDocumentCategory?: Maybe<DocumentCategoryObjectType>;
  netNoticeDocumentCategoryId?: Maybe<Scalars['ID']>;
  networkActivityFeedWrapper?: Maybe<NetworkActivityFeedWrapperObjectType>;
  originalLogoUrl?: Maybe<Scalars['String']>;
  ownershipDisplayPrecision?: Maybe<Scalars['Int']>;
  packagingEdition: EditionObjectType;
  packagingEditionId: Scalars['Int'];
  perfMetricsAsOfDate?: Maybe<Scalars['String']>;
  positions?: Maybe<PositionConnection>;
  reportEmailDistributionList?: Maybe<EmailDistributionListObjectType>;
  reportEmailDistributionListId?: Maybe<Scalars['ID']>;
  secondLevelDomain: Scalars['String'];
  settings?: Maybe<ArenaSettingsObjectType>;
  showDisregardedEntityQuestionnaire?: Maybe<Scalars['Boolean']>;
  showEntityTotalsOnNp?: Maybe<Scalars['Boolean']>;
  /** Portal 2.0 Field */
  shrinkIosIconCorners?: Maybe<Scalars['Boolean']>;
  /** Portal 2.0 Field */
  signInAccessMessage?: Maybe<Scalars['String']>;
  /** Portal 2.0 Field */
  signInButtonBackgroundColor?: Maybe<Scalars['HexColorCode']>;
  /** Portal 2.0 Field */
  signInButtonTextColor?: Maybe<Scalars['HexColorCode']>;
  signInPhotoUrl?: Maybe<Scalars['String']>;
  /** Portal 1.0 Field */
  signinButtonBackgroundColor?: Maybe<Scalars['String']>;
  /** Portal 1.0 Field */
  signinButtonTextColor?: Maybe<Scalars['String']>;
  smtpHost?: Maybe<Scalars['String']>;
  statementDocumentCategory?: Maybe<DocumentCategoryObjectType>;
  statementDocumentCategoryId?: Maybe<Scalars['ID']>;
  subscriptionDocumentCategory?: Maybe<DocumentCategoryObjectType>;
  subscriptionSettings: ArenaSubscriptionSettings;
  supportEmail: Scalars['String'];
  supportPhone: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  transactionTotals?: Maybe<ContactTransactionTotalsObjectType>;
  transactionTotalsByCurrency: Array<ContactTransactionTotalsByCurrencyObjectType>;
  twoFaRequiredStaff: Scalars['Boolean'];
  /** Portal 2.0 Field */
  useBgColorForAndroidIcons?: Maybe<Scalars['Boolean']>;
  /** Portal 2.0 Field */
  useBgColorForIosIcons?: Maybe<Scalars['Boolean']>;
  /** Portal 2.0 Field */
  useBgColorForPortalIcons?: Maybe<Scalars['Boolean']>;
  /** Portal 2.0 Field */
  useBgColorForWebIcons?: Maybe<Scalars['Boolean']>;
  websiteUrl?: Maybe<Scalars['String']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};


/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectTypeActiveOfferingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<OfferingObjectTypeSortEnum>>>;
};


/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectTypeAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AssetObjectTypeSortEnum>>>;
};


/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectTypeContactAccessGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ObjectGroupObjectTypeSortEnum>>>;
};


/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectTypeNetworkActivityFeedWrapperArgs = {
  quantity?: InputMaybe<Scalars['Int']>;
  startTimestamp?: InputMaybe<Scalars['String']>;
};


/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectTypePositionsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  calculateSummaryTransactions?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};


/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectTypeTransactionTotalsArgs = {
  status?: InputMaybe<PositionEntityStatus>;
};


/** Corresponds to the ArenaObjectType in the main subgraph. */
export type ArenaObjectTypeTransactionTotalsByCurrencyArgs = {
  normalizeTo?: InputMaybe<CurrencyCode>;
  status?: InputMaybe<PositionEntityStatus>;
};

/** An enumeration. */
export enum ArenaObjectTypeSortEnum {
  AllowPortalInvestorReportAsc = 'ALLOW_PORTAL_INVESTOR_REPORT_ASC',
  AllowPortalInvestorReportDesc = 'ALLOW_PORTAL_INVESTOR_REPORT_DESC',
  AssetDisclosureTextAsc = 'ASSET_DISCLOSURE_TEXT_ASC',
  AssetDisclosureTextDesc = 'ASSET_DISCLOSURE_TEXT_DESC',
  ClientPrivacyPolicyAsc = 'CLIENT_PRIVACY_POLICY_ASC',
  ClientPrivacyPolicyDesc = 'CLIENT_PRIVACY_POLICY_DESC',
  ClientTosAsc = 'CLIENT_TOS_ASC',
  ClientTosDesc = 'CLIENT_TOS_DESC',
  DocusignSoboEmailAddressAsc = 'DOCUSIGN_SOBO_EMAIL_ADDRESS_ASC',
  DocusignSoboEmailAddressDesc = 'DOCUSIGN_SOBO_EMAIL_ADDRESS_DESC',
  DocusignUserIdAsc = 'DOCUSIGN_USER_ID_ASC',
  DocusignUserIdDesc = 'DOCUSIGN_USER_ID_DESC',
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  EmailDomainAsc = 'EMAIL_DOMAIN_ASC',
  EmailDomainDesc = 'EMAIL_DOMAIN_DESC',
  HasUnifiedPortalAsc = 'HAS_UNIFIED_PORTAL_ASC',
  HasUnifiedPortalDesc = 'HAS_UNIFIED_PORTAL_DESC',
  HasUnifiedPortalPreviewAsc = 'HAS_UNIFIED_PORTAL_PREVIEW_ASC',
  HasUnifiedPortalPreviewDesc = 'HAS_UNIFIED_PORTAL_PREVIEW_DESC',
  HideCashFlowBarChartInPortalAsc = 'HIDE_CASH_FLOW_BAR_CHART_IN_PORTAL_ASC',
  HideCashFlowBarChartInPortalDesc = 'HIDE_CASH_FLOW_BAR_CHART_IN_PORTAL_DESC',
  IdsTermsOfServiceSignedDateAsc = 'IDS_TERMS_OF_SERVICE_SIGNED_DATE_ASC',
  IdsTermsOfServiceSignedDateDesc = 'IDS_TERMS_OF_SERVICE_SIGNED_DATE_DESC',
  IsActivatedAsc = 'IS_ACTIVATED_ASC',
  IsActivatedDesc = 'IS_ACTIVATED_DESC',
  IsPartialAsc = 'IS_PARTIAL_ASC',
  IsPartialDesc = 'IS_PARTIAL_DESC',
  LoginMessageAsc = 'LOGIN_MESSAGE_ASC',
  LoginMessageDesc = 'LOGIN_MESSAGE_DESC',
  LookerInstanceIdAsc = 'LOOKER_INSTANCE_ID_ASC',
  LookerInstanceIdDesc = 'LOOKER_INSTANCE_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnershipDisplayPrecisionAsc = 'OWNERSHIP_DISPLAY_PRECISION_ASC',
  OwnershipDisplayPrecisionDesc = 'OWNERSHIP_DISPLAY_PRECISION_DESC',
  PackagingEditionIdAsc = 'PACKAGING_EDITION_ID_ASC',
  PackagingEditionIdDesc = 'PACKAGING_EDITION_ID_DESC',
  PerfMetricsAsOfDateAsc = 'PERF_METRICS_AS_OF_DATE_ASC',
  PerfMetricsAsOfDateDesc = 'PERF_METRICS_AS_OF_DATE_DESC',
  ShowDisregardedEntityQuestionnaireAsc = 'SHOW_DISREGARDED_ENTITY_QUESTIONNAIRE_ASC',
  ShowDisregardedEntityQuestionnaireDesc = 'SHOW_DISREGARDED_ENTITY_QUESTIONNAIRE_DESC',
  SmtpHostAsc = 'SMTP_HOST_ASC',
  SmtpHostDesc = 'SMTP_HOST_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  WelcomeMessageAsc = 'WELCOME_MESSAGE_ASC',
  WelcomeMessageDesc = 'WELCOME_MESSAGE_DESC'
}

export type ArenaSection = {
  __typename?: 'ArenaSection';
  /** A list of section ids which depend on this section, including sections whose prompts depend on this section. */
  dependentSectionIds: Array<Scalars['StrawberryObjectId']>;
  /** A list of section ids that this section depends on, including sections whose prompts this section depends on. */
  dependsOnSectionIds: Array<Scalars['StrawberryObjectId']>;
  /** Whether all entities that include the section have been upgraded to the new entity types */
  hasAllEntitiesUpgradedNewEntityTypes?: Maybe<Scalars['Boolean']>;
  /** Whether a section has an existing published version */
  hasPublishedVersion: Scalars['Boolean'];
  /** Whether a section has a draft version with unpublished changes */
  hasUnpublishedChanges: Scalars['Boolean'];
  /** Whether a section is a built-in section that applies to all arenas */
  isGlobal: Scalars['Boolean'];
  section: Section;
  usedInActiveSubscription: Scalars['Boolean'];
};

export type ArenaSectionMutationErrorResponse = ArenaSection | MutationErrorResponse;

export type ArenaSectionPublishSectionErrorResponse = ArenaSection | PublishSectionErrorResponse;

export type ArenaSettingsObjectType = PermissionCheckedNode & {
  __typename?: 'ArenaSettingsObjectType';
  autoSubscribeLeadsContactAccessGroupId?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isMulticurrencyPortalEnabled: Scalars['Boolean'];
  portfolioOverviewDisclosureText?: Maybe<Scalars['String']>;
};

export type ArenaSubscriptionSettings = {
  __typename?: 'ArenaSubscriptionSettings';
  customSections: Array<ArenaSection>;
  sectionsAvailableForEnablingLogic: Array<Section>;
  supportingDocuments: Array<ArenaSection>;
};


export type ArenaSubscriptionSettingsCustomSectionsArgs = {
  publishedOnly?: InputMaybe<Scalars['Boolean']>;
  sectionIdFilter?: InputMaybe<Array<Scalars['StrawberryObjectId']>>;
};


export type ArenaSubscriptionSettingsSectionsAvailableForEnablingLogicArgs = {
  sectionId?: InputMaybe<Scalars['StrawberryObjectId']>;
};


export type ArenaSubscriptionSettingsSupportingDocumentsArgs = {
  sectionIdFilter?: InputMaybe<Array<Scalars['StrawberryObjectId']>>;
};

export type ArenaUpsertPromptInput = {
  arenaId: Scalars['Int'];
  positionIndex: Scalars['Int'];
  prompt: PromptInput;
  sectionId: Scalars['StrawberryObjectId'];
};

export type AssetAcquisitionObjectType = NodeWithTimeSeries & PermissionCheckedNode & {
  __typename?: 'AssetAcquisitionObjectType';
  acquisitionSource?: Maybe<Scalars['String']>;
  dateAcquired?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  initialCapDebt?: Maybe<Wallet>;
  initialCapEquity?: Maybe<Wallet>;
  latestTimeSeries?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesByPeriod?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesProjections?: Maybe<TimeSeriesDateToListTuple>;
  listPrice?: Maybe<Wallet>;
  netPurchasePrice?: Maybe<Wallet>;
  pctManagerFee?: Maybe<Scalars['Float']>;
  pctThirdPartyFee?: Maybe<Scalars['Float']>;
  percentAcquired?: Maybe<Scalars['Float']>;
  price?: Maybe<Wallet>;
  replacementCost?: Maybe<Wallet>;
  seller?: Maybe<Scalars['String']>;
  timeSeries: Array<TimeSeriesDateToListTuple>;
  timeSeriesAtAcquisitionDate?: Maybe<TimeSeriesDateToListTuple>;
  timeSeriesProjections: Array<TimeSeriesDateToListTuple>;
};


export type AssetAcquisitionObjectTypeLatestTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetAcquisitionObjectTypeLatestTimeSeriesByPeriodArgs = {
  period: Scalars['Period'];
  timeSeriesTypeNames: Array<Scalars['FieldName']>;
};


export type AssetAcquisitionObjectTypeLatestTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetAcquisitionObjectTypeTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetAcquisitionObjectTypeTimeSeriesAtAcquisitionDateArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetAcquisitionObjectTypeTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};

export type AssetAcquisitionObjectTypeConnection = {
  __typename?: 'AssetAcquisitionObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AssetAcquisitionObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type AssetAcquisitionObjectTypeEdge = {
  __typename?: 'AssetAcquisitionObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssetAcquisitionObjectType;
};

/** An enumeration. */
export enum AssetClass {
  AMinus = 'a_minus',
  ANeutral = 'a_neutral',
  APlus = 'a_plus',
  BMinus = 'b_minus',
  BNeutral = 'b_neutral',
  BPlus = 'b_plus',
  CMinus = 'c_minus',
  CNeutral = 'c_neutral',
  CPlus = 'c_plus',
  DNeutral = 'd_neutral'
}

export type AssetConnection = {
  __typename?: 'AssetConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AssetEdge>;
  pageInfo: PageInfo;
};

export type AssetDispositionObjectType = PermissionCheckedNode & {
  __typename?: 'AssetDispositionObjectType';
  assetId: Scalars['Int'];
  buyer?: Maybe<Scalars['String']>;
  dateDisposed?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  finalCapDebt?: Maybe<Wallet>;
  finalCapEquity?: Maybe<Wallet>;
  finalIrr?: Maybe<Scalars['Float']>;
  finalMultiple?: Maybe<Scalars['Float']>;
  /** The ID of the object */
  id: Scalars['ID'];
  netProceeds?: Maybe<Wallet>;
  netSalePrice?: Maybe<Wallet>;
  pctManagerFee?: Maybe<Scalars['Float']>;
  pctThirdPartyFee?: Maybe<Scalars['Float']>;
  percentDisposed?: Maybe<Scalars['Float']>;
  price?: Maybe<Wallet>;
  timeSeriesAtDispositionDate?: Maybe<TimeSeriesDateToListTuple>;
};


export type AssetDispositionObjectTypeTimeSeriesAtDispositionDateArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};

export type AssetDispositionObjectTypeConnection = {
  __typename?: 'AssetDispositionObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AssetDispositionObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type AssetDispositionObjectTypeEdge = {
  __typename?: 'AssetDispositionObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssetDispositionObjectType;
};

export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssetObjectType;
};

export type AssetLoanMapObjectType = PermissionCheckedNode & {
  __typename?: 'AssetLoanMapObjectType';
  allocationRatio?: Maybe<Scalars['Float']>;
  asset?: Maybe<AssetObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  loan?: Maybe<LoanObjectType>;
};

export type AssetLoanMapObjectTypeConnection = {
  __typename?: 'AssetLoanMapObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AssetLoanMapObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type AssetLoanMapObjectTypeEdge = {
  __typename?: 'AssetLoanMapObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssetLoanMapObjectType;
};

export type AssetObjectType = NodeWithTimeSeries & ObjectLevelEditPermissions & PermissionCheckedNode & {
  __typename?: 'AssetObjectType';
  acquisitions?: Maybe<AssetAcquisitionObjectTypeConnection>;
  ageRestricted?: Maybe<Scalars['Boolean']>;
  arena?: Maybe<ArenaObjectType>;
  assetReportTemplate?: Maybe<AssetReportTemplateObjectType>;
  businessStrategy?: Maybe<BusinessStrategy>;
  canEdit: Scalars['Boolean'];
  canLimitedEdit?: Maybe<Scalars['Boolean']>;
  certificateOfOccupancyDate?: Maybe<Scalars['Date']>;
  classUponEntry?: Maybe<AssetClass>;
  classUponStabilization?: Maybe<AssetClass>;
  constructionLoanCloseDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<CurrencyObjectType>;
  dateAcquired?: Maybe<Scalars['Date']>;
  dateSold?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  dispositions?: Maybe<AssetDispositionObjectTypeConnection>;
  entity: AccountObjectType;
  entityId: Scalars['Int'];
  expectedHoldMonths?: Maybe<Scalars['Float']>;
  expectedHoldYears?: Maybe<Scalars['Float']>;
  extensionFields: Array<ExtensionFieldTuple>;
  firstDistributionDate?: Maybe<Scalars['Date']>;
  firstOccupancyDate?: Maybe<Scalars['Date']>;
  generalContractor?: Maybe<Scalars['String']>;
  grandOpeningDate?: Maybe<Scalars['Date']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isSold: Scalars['Boolean'];
  landLoanCloseDate?: Maybe<Scalars['Date']>;
  latestTimeSeries?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesByPeriod?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesProjections?: Maybe<TimeSeriesDateToListTuple>;
  loans?: Maybe<LoanObjectTypeConnection>;
  loansMap?: Maybe<AssetLoanMapObjectTypeConnection>;
  locations?: Maybe<LocationObjectTypeConnection>;
  name: Scalars['String'];
  parkingSpaces?: Maybe<Scalars['Int']>;
  percentOwned?: Maybe<Scalars['Float']>;
  permanentFinancingDate?: Maybe<Scalars['Date']>;
  photos?: Maybe<PhotoObjectTypeConnection>;
  primaryPhotoId?: Maybe<Scalars['ID']>;
  productTypes?: Maybe<ProductTypeAndSizeObjectTypeConnection>;
  propertyManager?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['DateTime']>;
  saleDate?: Maybe<Scalars['Date']>;
  siteWorkEndDate?: Maybe<Scalars['Date']>;
  siteWorkStartDate?: Maybe<Scalars['Date']>;
  stabilizationDate?: Maybe<Scalars['Date']>;
  stateCbsa?: Maybe<StateCbsaObjectType>;
  thumbnail?: Maybe<PhotoObjectType>;
  timeSeries: Array<TimeSeriesDateToListTuple>;
  timeSeriesProjections: Array<TimeSeriesDateToListTuple>;
  totalGrossSqft?: Maybe<Scalars['Int']>;
  totalNetSqft?: Maybe<Scalars['Int']>;
  updatedInReportingPeriod: Scalars['Boolean'];
  verticalConstructionEndDate?: Maybe<Scalars['Date']>;
  verticalConstructionStartDate?: Maybe<Scalars['Date']>;
  visible: Scalars['Boolean'];
  websiteUrl?: Maybe<Scalars['String']>;
  yearBuilt?: Maybe<Scalars['String']>;
  yearBuiltOrRenovated?: Maybe<Scalars['String']>;
  yearRenovated?: Maybe<Scalars['String']>;
  zoning?: Maybe<Scalars['String']>;
};


export type AssetObjectTypeAcquisitionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AssetObjectTypeDispositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AssetObjectTypeFirstDistributionDateArgs = {
  publishedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type AssetObjectTypeLatestTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetObjectTypeLatestTimeSeriesByPeriodArgs = {
  period: Scalars['Period'];
  timeSeriesTypeNames: Array<Scalars['FieldName']>;
};


export type AssetObjectTypeLatestTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetObjectTypeLoansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AssetObjectTypeLoansMapArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AssetObjectTypeLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AssetObjectTypePhotosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AssetObjectTypeProductTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AssetObjectTypeTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetObjectTypeTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type AssetObjectTypeUpdatedInReportingPeriodArgs = {
  period: Scalars['Period'];
};

export type AssetObjectTypeConnection = {
  __typename?: 'AssetObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AssetObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type AssetObjectTypeEdge = {
  __typename?: 'AssetObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssetObjectType;
};

/** An enumeration. */
export enum AssetObjectTypeSortEnum {
  AgeRestrictedAsc = 'AGE_RESTRICTED_ASC',
  AgeRestrictedDesc = 'AGE_RESTRICTED_DESC',
  BusinessStrategyAsc = 'BUSINESS_STRATEGY_ASC',
  BusinessStrategyDesc = 'BUSINESS_STRATEGY_DESC',
  CertificateOfOccupancyDateAsc = 'CERTIFICATE_OF_OCCUPANCY_DATE_ASC',
  CertificateOfOccupancyDateDesc = 'CERTIFICATE_OF_OCCUPANCY_DATE_DESC',
  ClassUponEntryAsc = 'CLASS_UPON_ENTRY_ASC',
  ClassUponEntryDesc = 'CLASS_UPON_ENTRY_DESC',
  ClassUponStabilizationAsc = 'CLASS_UPON_STABILIZATION_ASC',
  ClassUponStabilizationDesc = 'CLASS_UPON_STABILIZATION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAcquiredAsc = 'DATE_ACQUIRED_ASC',
  DateAcquiredDesc = 'DATE_ACQUIRED_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DeveloperAsc = 'DEVELOPER_ASC',
  DeveloperDesc = 'DEVELOPER_DESC',
  EntityIdAsc = 'ENTITY_ID_ASC',
  EntityIdDesc = 'ENTITY_ID_DESC',
  ExpectedHoldMonthsAsc = 'EXPECTED_HOLD_MONTHS_ASC',
  ExpectedHoldMonthsDesc = 'EXPECTED_HOLD_MONTHS_DESC',
  ExpectedHoldYearsAsc = 'EXPECTED_HOLD_YEARS_ASC',
  ExpectedHoldYearsDesc = 'EXPECTED_HOLD_YEARS_DESC',
  FirstOccupancyDateAsc = 'FIRST_OCCUPANCY_DATE_ASC',
  FirstOccupancyDateDesc = 'FIRST_OCCUPANCY_DATE_DESC',
  GeneralContractorAsc = 'GENERAL_CONTRACTOR_ASC',
  GeneralContractorDesc = 'GENERAL_CONTRACTOR_DESC',
  GrandOpeningDateAsc = 'GRAND_OPENING_DATE_ASC',
  GrandOpeningDateDesc = 'GRAND_OPENING_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ParkingSpacesAsc = 'PARKING_SPACES_ASC',
  ParkingSpacesDesc = 'PARKING_SPACES_DESC',
  PrimaryPhotoIdAsc = 'PRIMARY_PHOTO_ID_ASC',
  PrimaryPhotoIdDesc = 'PRIMARY_PHOTO_ID_DESC',
  PropertyManagerAsc = 'PROPERTY_MANAGER_ASC',
  PropertyManagerDesc = 'PROPERTY_MANAGER_DESC',
  PublishedAsc = 'PUBLISHED_ASC',
  PublishedDesc = 'PUBLISHED_DESC',
  SiteWorkEndDateAsc = 'SITE_WORK_END_DATE_ASC',
  SiteWorkEndDateDesc = 'SITE_WORK_END_DATE_DESC',
  SiteWorkStartDateAsc = 'SITE_WORK_START_DATE_ASC',
  SiteWorkStartDateDesc = 'SITE_WORK_START_DATE_DESC',
  StabilizationDateAsc = 'STABILIZATION_DATE_ASC',
  StabilizationDateDesc = 'STABILIZATION_DATE_DESC',
  VerticalConstructionEndDateAsc = 'VERTICAL_CONSTRUCTION_END_DATE_ASC',
  VerticalConstructionEndDateDesc = 'VERTICAL_CONSTRUCTION_END_DATE_DESC',
  VerticalConstructionStartDateAsc = 'VERTICAL_CONSTRUCTION_START_DATE_ASC',
  VerticalConstructionStartDateDesc = 'VERTICAL_CONSTRUCTION_START_DATE_DESC',
  WebsiteUrlAsc = 'WEBSITE_URL_ASC',
  WebsiteUrlDesc = 'WEBSITE_URL_DESC',
  YearBuiltAsc = 'YEAR_BUILT_ASC',
  YearBuiltDesc = 'YEAR_BUILT_DESC',
  YearBuiltOrRenovatedAsc = 'YEAR_BUILT_OR_RENOVATED_ASC',
  YearBuiltOrRenovatedDesc = 'YEAR_BUILT_OR_RENOVATED_DESC',
  YearRenovatedAsc = 'YEAR_RENOVATED_ASC',
  YearRenovatedDesc = 'YEAR_RENOVATED_DESC',
  ZoningAsc = 'ZONING_ASC',
  ZoningDesc = 'ZONING_DESC'
}

/** An enumeration. */
export enum AssetReportFieldName {
  AcquisitionDate = 'AcquisitionDate',
  AssetCurrency = 'AssetCurrency',
  AssetExtensionField = 'AssetExtensionField',
  AssetName = 'AssetName',
  AssetUpdate = 'AssetUpdate',
  AverageDailyRate = 'AverageDailyRate',
  AvgAnnualMarketRentPerSqft = 'AvgAnnualMarketRentPerSqft',
  AvgAnnualOccupiedRentPerSqft = 'AvgAnnualOccupiedRentPerSqft',
  AvgMonthlyMarketRentPerUnit = 'AvgMonthlyMarketRentPerUnit',
  AvgMonthlyOccupiedRentPerUnit = 'AvgMonthlyOccupiedRentPerUnit',
  Beds = 'Beds',
  Buildings = 'Buildings',
  BusinessStrategy = 'BusinessStrategy',
  CapRate = 'CapRate',
  CapitalExpenditures = 'CapitalExpenditures',
  City = 'City',
  ClassExpectedUponStabilization = 'ClassExpectedUponStabilization',
  ClassUponEntry = 'ClassUponEntry',
  Country = 'Country',
  CurrentEquityBalance = 'CurrentEquityBalance',
  DebtBalance = 'DebtBalance',
  DebtService = 'DebtService',
  DebtServiceCoverageRatio = 'DebtServiceCoverageRatio',
  DebtValue = 'DebtValue',
  DevelopmentSpread = 'DevelopmentSpread',
  DevelopmentStatus = 'DevelopmentStatus',
  DevelopmentYield = 'DevelopmentYield',
  DispositionDate = 'DispositionDate',
  DispositionNetSalePrice = 'DispositionNetSalePrice',
  EconomicOccupancy = 'EconomicOccupancy',
  EntityCollection = 'EntityCollection',
  EntityCurrency = 'EntityCurrency',
  ExpectedHoldMonths = 'ExpectedHoldMonths',
  ExpectedHoldYears = 'ExpectedHoldYears',
  Floors = 'Floors',
  GrossAssetValue = 'GrossAssetValue',
  GrossAssetValueEntity = 'GrossAssetValueEntity',
  GrossLeveragedEquityMultiple = 'GrossLeveragedEquityMultiple',
  GrossLeveragedIrr = 'GrossLeveragedIrr',
  GrossLeveragedResidualMultiple = 'GrossLeveragedResidualMultiple',
  GrossLeveragedYield = 'GrossLeveragedYield',
  GrossPurchaseCost = 'GrossPurchaseCost',
  GrossRealEstateValue = 'GrossRealEstateValue',
  GrossSizesMeters = 'GrossSizesMeters',
  GrossSizesSqft = 'GrossSizesSqft',
  GrossUnleveragedEquityMultiple = 'GrossUnleveragedEquityMultiple',
  GrossUnleveragedIrr = 'GrossUnleveragedIrr',
  GrossUnleveragedResidualMultiple = 'GrossUnleveragedResidualMultiple',
  GrossUnleveragedYield = 'GrossUnleveragedYield',
  InitialEquityBalance = 'InitialEquityBalance',
  Keys = 'Keys',
  LeasingStatus = 'LeasingStatus',
  LoanFirstPrincipalPaymentDates = 'LoanFirstPrincipalPaymentDates',
  LoanFloatingRateFloors = 'LoanFloatingRateFloors',
  LoanIndices = 'LoanIndices',
  LoanInitialDebtBalances = 'LoanInitialDebtBalances',
  LoanInterestRateDescriptions = 'LoanInterestRateDescriptions',
  LoanLenderNames = 'LoanLenderNames',
  LoanLenderTypes = 'LoanLenderTypes',
  LoanMaturityDates = 'LoanMaturityDates',
  LoanName = 'LoanName',
  LoanNextPaymentDueDates = 'LoanNextPaymentDueDates',
  LoanOriginationDates = 'LoanOriginationDates',
  LoanToCostRatio = 'LoanToCostRatio',
  LoanToValueRatio = 'LoanToValueRatio',
  LoanTotalAmounts = 'LoanTotalAmounts',
  LoanType = 'LoanType',
  Lots = 'Lots',
  MarketName = 'MarketName',
  MarketTier = 'MarketTier',
  NetAssetValue = 'NetAssetValue',
  NetAssetValueEntity = 'NetAssetValueEntity',
  NetCashFlowAfterDebtService = 'NetCashFlowAfterDebtService',
  NetLeveragedEquityMultiple = 'NetLeveragedEquityMultiple',
  NetLeveragedIrr = 'NetLeveragedIrr',
  NetLeveragedYield = 'NetLeveragedYield',
  NetOperatingIncome = 'NetOperatingIncome',
  NetPurchasePrice = 'NetPurchasePrice',
  NetSizesMeters = 'NetSizesMeters',
  NetSizesSqft = 'NetSizesSqft',
  NetUnleveragedCashFlow = 'NetUnleveragedCashFlow',
  OperatingExpenses = 'OperatingExpenses',
  PercentAcquired = 'PercentAcquired',
  PercentConstructionCompleted = 'PercentConstructionCompleted',
  PercentLeased = 'PercentLeased',
  Period = 'Period',
  PhysicalOccupancy = 'PhysicalOccupancy',
  ProductSubtypes = 'ProductSubtypes',
  ProductTypes = 'ProductTypes',
  ProjectedDevelopmentSpread = 'ProjectedDevelopmentSpread',
  ProjectedDevelopmentYield = 'ProjectedDevelopmentYield',
  ProjectedGrossLeveragedEquityMultiple = 'ProjectedGrossLeveragedEquityMultiple',
  ProjectedGrossLeveragedIrr = 'ProjectedGrossLeveragedIrr',
  ProjectedNetLeveragedEquityMultiple = 'ProjectedNetLeveragedEquityMultiple',
  ProjectedNetLeveragedIrr = 'ProjectedNetLeveragedIrr',
  Revenue = 'Revenue',
  RiskProfile = 'RiskProfile',
  State = 'State',
  StreetAddress = 'StreetAddress',
  SubmarketName = 'SubmarketName',
  TotalInterestRate = 'TotalInterestRate',
  Units = 'Units',
  YearBuilt = 'YearBuilt',
  YearLastRenovated = 'YearLastRenovated',
  Zip = 'Zip'
}

/** An enumeration. */
export enum AssetReportTemplateLayout {
  Default = 'default'
}

export type AssetReportTemplateModuleConnection = {
  __typename?: 'AssetReportTemplateModuleConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AssetReportTemplateModuleEdge>;
  pageInfo: PageInfo;
};

export type AssetReportTemplateModuleEdge = {
  __typename?: 'AssetReportTemplateModuleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssetReportTemplateModuleObjectType;
};

export type AssetReportTemplateModuleFieldConnection = {
  __typename?: 'AssetReportTemplateModuleFieldConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<AssetReportTemplateModuleFieldEdge>;
  pageInfo: PageInfo;
};

export type AssetReportTemplateModuleFieldEdge = {
  __typename?: 'AssetReportTemplateModuleFieldEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssetReportTemplateModuleFieldObjectType;
};

export type AssetReportTemplateModuleFieldObjectType = PermissionCheckedNode & {
  __typename?: 'AssetReportTemplateModuleFieldObjectType';
  assetExtensionFieldId?: Maybe<Scalars['ID']>;
  /** The ID of the object */
  id: Scalars['ID'];
  relativePeriod?: Maybe<RelativePeriod>;
  sortOrder: Scalars['Int'];
  templateField: AssetReportFieldName;
};

/** An enumeration. */
export enum AssetReportTemplateModuleFieldObjectTypeSortEnum {
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC'
}

export type AssetReportTemplateModuleObjectType = PermissionCheckedNode & {
  __typename?: 'AssetReportTemplateModuleObjectType';
  assetReportTemplateModuleType: AssetReportTemplateModuleType;
  enabled: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  moduleFields?: Maybe<AssetReportTemplateModuleFieldConnection>;
  title: Scalars['String'];
  titleEnabled: Scalars['Boolean'];
};


export type AssetReportTemplateModuleObjectTypeModuleFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AssetReportTemplateModuleFieldObjectTypeSortEnum>>>;
};

/** An enumeration. */
export enum AssetReportTemplateModuleObjectTypeSortEnum {
  AssetReportTemplateModuleTypeAsc = 'ASSET_REPORT_TEMPLATE_MODULE_TYPE_ASC',
  AssetReportTemplateModuleTypeDesc = 'ASSET_REPORT_TEMPLATE_MODULE_TYPE_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TitleEnabledAsc = 'TITLE_ENABLED_ASC',
  TitleEnabledDesc = 'TITLE_ENABLED_DESC'
}

/** An enumeration. */
export enum AssetReportTemplateModuleType {
  AssetDescription = 'asset_description',
  AssetUpdate = 'asset_update',
  DataList = 'data_list',
  Subtitle = 'subtitle'
}

export type AssetReportTemplateObjectType = PermissionCheckedNode & {
  __typename?: 'AssetReportTemplateObjectType';
  assets?: Maybe<AssetConnection>;
  hasUpdateOwnPermission: Scalars['Boolean'];
  hasUpdatePermission: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<AssetReportTemplateLayout>;
  modules?: Maybe<AssetReportTemplateModuleConnection>;
  name: Scalars['String'];
  stationery?: Maybe<StationeryObjectType>;
};


export type AssetReportTemplateObjectTypeAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AssetObjectTypeSortEnum>>>;
};


export type AssetReportTemplateObjectTypeModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AssetReportTemplateModuleObjectTypeSortEnum>>>;
};

export type AssigneeFilterInput = {
  quantifier?: InputMaybe<SetFilterQuantifier>;
  selected?: InputMaybe<Array<Scalars['String']>>;
};

export enum AssociatePaymentPrefType {
  AllOtherPositionsInAccount = 'ALL_OTHER_POSITIONS_IN_ACCOUNT',
  AllOtherPositionsWithPaymentPref = 'ALL_OTHER_POSITIONS_WITH_PAYMENT_PREF',
  OnlyThisPosition = 'ONLY_THIS_POSITION',
  PositionsWithoutPaymentPrefOfAccount = 'POSITIONS_WITHOUT_PAYMENT_PREF_OF_ACCOUNT'
}

export type AssociatePositionsOfAccountObjectType = {
  __typename?: 'AssociatePositionsOfAccountObjectType';
  /** The account that will be impacted by the payment pref association */
  account?: Maybe<AccountObjectType>;
  /** Used only for key */
  accountId: Scalars['ID'];
  /** Used only for key */
  arenaId: Scalars['ID'];
  /** The positions that will be associated with the payment pref, which will be all positions for this account */
  positions?: Maybe<PositionConnection>;
};


export type AssociatePositionsOfAccountObjectTypePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};

export type AssociatePositionsWithSourcePaymentPrefObjectType = {
  __typename?: 'AssociatePositionsWithSourcePaymentPrefObjectType';
  /** The account that will be impacted by the payment pref association */
  account?: Maybe<AccountObjectType>;
  /** Used only for key */
  accountId: Scalars['ID'];
  /** Used only for key */
  arenaId: Scalars['ID'];
  /** The positions that will be associated with the payment pref, which will be all positions for this account that have the specified payment pref */
  positions?: Maybe<PositionConnection>;
  /** Used only for key */
  sourcePaymentPrefId: Scalars['ID'];
};


export type AssociatePositionsWithSourcePaymentPrefObjectTypePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};

export type AssociatePositionsWithSourcePaymentPrefObjectTypeAssociateWithPositionObjectTypeAssociatePositionsOfAccountObjectTypeAssociatePositionsWithoutPaymentPrefObjectType = AssociatePositionsOfAccountObjectType | AssociatePositionsWithSourcePaymentPrefObjectType | AssociatePositionsWithoutPaymentPrefObjectType | AssociateWithPositionObjectType;

export type AssociatePositionsWithoutPaymentPrefObjectType = {
  __typename?: 'AssociatePositionsWithoutPaymentPrefObjectType';
  /** The account that will be impacted by the payment pref association */
  account?: Maybe<AccountObjectType>;
  /** Used only for key */
  accountId: Scalars['ID'];
  /** Used only for key */
  arenaId: Scalars['ID'];
  /** The positions that will be associated with the payment pref, which will be all positions for this account that do not have a payment pref */
  positions?: Maybe<PositionConnection>;
};


export type AssociatePositionsWithoutPaymentPrefObjectTypePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};

export type AssociateWithPositionObjectType = {
  __typename?: 'AssociateWithPositionObjectType';
  /** The account that will be impacted by the payment pref association */
  account?: Maybe<AccountObjectType>;
  /** Used only for key */
  accountId: Scalars['ID'];
  /** Used only for key */
  arenaId: Scalars['ID'];
  /** Used only for key */
  positionId: Scalars['ID'];
  /** The positions that will be associated with the payment pref, which will be the specified position */
  positions?: Maybe<PositionConnection>;
};


export type AssociateWithPositionObjectTypePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};

export type AsyncJobObjectType = PermissionCheckedNode & {
  __typename?: 'AsyncJobObjectType';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSONString']>;
  endTime?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  jobType?: Maybe<JobType>;
  progress?: Maybe<AsyncJobProgress>;
  redisData?: Maybe<Scalars['JSONString']>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
};

export type AsyncJobOrError = AsyncJobObjectType | MutationErrorResponse;

export type AsyncJobProgress = {
  __typename?: 'AsyncJobProgress';
  /** Async jobs may have different ways of tracking progress.Returns null if the async job does not utilize the progress tracking fields. */
  processedCount?: Maybe<Scalars['Int']>;
  /** Async jobs may have different ways of tracking progress.Returns null if the async job does not utilize the progress tracking fields. */
  totalCount?: Maybe<Scalars['Int']>;
};

export type AttachmentObjectType = {
  __typename?: 'AttachmentObjectType';
  documentName: Scalars['String'];
  url: Scalars['String'];
};

/** An enumeration. */
export enum AuthorizedClass {
  Account = 'account',
  Contact = 'contact',
  CustomDataFields = 'custom_data_fields',
  DocumentCategory = 'document_category',
  Entity = 'entity',
  Offering = 'offering'
}

/** Corresponds to the BankAccountObjectType in the main subgraph. */
export type BankAccountObjectType = PermissionCheckedNode & {
  __typename?: 'BankAccountObjectType';
  bankInfoRedacted?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  ownerName?: Maybe<Scalars['String']>;
  verificationStatus: BankAccountVerificationStatus;
};

/** An enumeration. */
export enum BankAccountType {
  Checking = 'checking',
  Savings = 'savings'
}

export enum BankAccountVerificationStatus {
  ExceedAttempts = 'EXCEED_ATTEMPTS',
  Expired = 'EXPIRED',
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  TerminalError = 'TERMINAL_ERROR',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type BankAccountsInput = {
  /** A list of bank account global IDs */
  ids: Array<Scalars['ID']>;
};

export enum BaseDocumentType {
  AmlRelianceLetter = 'aml_reliance_letter',
  AnnualReport = 'annual_report',
  AuthorizedSignatoryList = 'authorized_signatory_list',
  CertifiedId = 'certified_id',
  ConfirmationOfBeneficialOwners = 'confirmation_of_beneficial_owners',
  ConfirmationOfClassBeneficiaries = 'confirmation_of_class_beneficiaries',
  EvidenceOfFormation = 'evidence_of_formation',
  EvidenceOfRegulation = 'evidence_of_regulation',
  FalsePositiveEvidence = 'false_positive_evidence',
  Form_990 = 'form_990',
  FormationDocument = 'formation_document',
  Id = 'id',
  KycChecklist = 'kyc_checklist',
  OrganizationChart = 'organization_chart',
  OrganizationalDocument = 'organizational_document',
  Other = 'other',
  PayeeMismatchExplanation = 'payee_mismatch_explanation',
  ScreeningReport = 'screening_report',
  ShareRegister = 'share_register',
  SubscriptionDocument = 'subscription_document',
  WSeriesTaxForm = 'w_series_tax_form',
  Waiver = 'waiver'
}

export enum BatchPeriod {
  Daily = 'daily',
  Monthly = 'monthly',
  Quarterly = 'quarterly'
}

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  calculatedRequiresLogin: Scalars['Boolean'];
  calculatedSharedWithAllProspects: Scalars['Boolean'];
  calculatedSharedWithProspectIds: Array<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BulkCountersignInput = {
  jobId: Scalars['String'];
};

export type BulkPositionImportTaskObjectType = {
  __typename?: 'BulkPositionImportTaskObjectType';
  spreadsheet?: Maybe<SpreadsheetResponseType>;
  summary?: Maybe<SummaryType>;
};

/** An enumeration. */
export enum BusinessStrategy {
  BuyAndHold = 'buy_and_hold',
  DevelopAndHold = 'develop_and_hold',
  DevelopAndSell = 'develop_and_sell',
  ImmediateSale = 'immediate_sale',
  RepositionAndHold = 'reposition_and_hold',
  RepositionAndSell = 'reposition_and_sell'
}

export type CapitalActivityGlSettingsInput = {
  active: Scalars['Boolean'];
  glStartDate?: InputMaybe<Scalars['Date']>;
  transactionCategory: CapitalActivityType;
};

export type CapitalActivityGlSettingsResponse = {
  __typename?: 'CapitalActivityGLSettingsResponse';
  active: Scalars['Boolean'];
  glStartDate?: Maybe<Scalars['Date']>;
};

export enum CapitalActivityType {
  CapitalCall = 'capital_call',
  CapitalTransaction = 'capital_transaction',
  Distribution = 'distribution',
  Transfer = 'transfer'
}

export type CapitalActivityTypeTotalsObjectType = {
  __typename?: 'CapitalActivityTypeTotalsObjectType';
  amount: Wallet;
  transactionTypeName: Scalars['String'];
};

export type CapitalCallBatchObjectType = PermissionCheckedNode & {
  __typename?: 'CapitalCallBatchObjectType';
  batchCategory?: Maybe<Scalars['String']>;
  batchTotalDetails?: Maybe<CapitalCallBatchTotalDetailsObjectType>;
  description: Scalars['String'];
  effectiveDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<AccountObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  instances: Array<CapitalCallObjectType>;
  noun?: Maybe<Scalars['String']>;
  remittanceInstructions?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  storedTotals: Array<CapitalCallBatchTotalObjectType>;
  viewRoute?: Maybe<Scalars['String']>;
};

export type CapitalCallBatchTotalByTypeObjectType = {
  __typename?: 'CapitalCallBatchTotalByTypeObjectType';
  amount: Wallet;
  transactionType: TransactionTypeObjectType;
};

export type CapitalCallBatchTotalDetailsObjectType = {
  __typename?: 'CapitalCallBatchTotalDetailsObjectType';
  batchTotalsByType: Array<CapitalCallBatchTotalByTypeObjectType>;
  totalAmount: Wallet;
  totalCapitalCalled: Wallet;
};

export type CapitalCallBatchTotalObjectType = PermissionCheckedNode & {
  __typename?: 'CapitalCallBatchTotalObjectType';
  amount: Wallet;
  batchId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  typeId: Scalars['Int'];
};

export type CapitalCallDetailObjectType = PermissionCheckedNode & {
  __typename?: 'CapitalCallDetailObjectType';
  amount: Wallet;
  hasRebalancedCapitalCallBatch: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  transactionType?: Maybe<TransactionTypeObjectType>;
};

export type CapitalCallDetailObjectTypeConnection = {
  __typename?: 'CapitalCallDetailObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<CapitalCallDetailObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type CapitalCallDetailObjectTypeEdge = {
  __typename?: 'CapitalCallDetailObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: CapitalCallDetailObjectType;
};

export type CapitalCallMetricsObjectType = {
  __typename?: 'CapitalCallMetricsObjectType';
  lifetimeCapitalContributed: Wallet;
  positionRemainingRecallable: Wallet;
  positionUnfundedCommitment: Wallet;
  totalOutstandingCapitalCalled: Wallet;
};

export type CapitalCallObjectType = PermissionCheckedNode & {
  __typename?: 'CapitalCallObjectType';
  adjustedMetrics?: Maybe<CapitalCallMetricsObjectType>;
  amount?: Maybe<Wallet>;
  amountReceived?: Maybe<Wallet>;
  balance?: Maybe<Wallet>;
  batch?: Maybe<CapitalCallBatchObjectType>;
  capitalCalled?: Maybe<Wallet>;
  capitalRebalancedExcludedFromMetrics?: Maybe<Wallet>;
  capitalRebalancedIncludedInMetrics?: Maybe<Wallet>;
  contributions?: Maybe<TransactionObjectTypeConnection>;
  details?: Maybe<CapitalCallDetailObjectTypeConnection>;
  email?: Maybe<EmailObjectType>;
  hasDefaulted?: Maybe<Scalars['Boolean']>;
  hideCommitment?: Maybe<Scalars['Boolean']>;
  hideUnfundedCommitment?: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  metrics: CapitalCallMetricsObjectType;
  position?: Maybe<PositionObjectType>;
  uploadedAccountName?: Maybe<Scalars['String']>;
};


export type CapitalCallObjectTypeContributionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CapitalCallObjectTypeDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CapitalCallObjectTypeConnection = {
  __typename?: 'CapitalCallObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<CapitalCallObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type CapitalCallObjectTypeEdge = {
  __typename?: 'CapitalCallObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: CapitalCallObjectType;
};

/** An enumeration. */
export enum CapitalCallObjectTypeSortEnum {
  HasDefaultedAsc = 'HAS_DEFAULTED_ASC',
  HasDefaultedDesc = 'HAS_DEFAULTED_DESC',
  UploadedAccountNameAsc = 'UPLOADED_ACCOUNT_NAME_ASC',
  UploadedAccountNameDesc = 'UPLOADED_ACCOUNT_NAME_DESC'
}

export type CashflowHistoryChartObjectType = {
  __typename?: 'CashflowHistoryChartObjectType';
  commitmentDeltas: Array<CashflowHistoryDeltaItem>;
  contributions: Array<CashflowHistoryTransactionItem>;
  currency: CurrencyObjectType;
  distributions: Array<CashflowHistoryDistributionItem>;
  redemptions: Array<CashflowHistoryTransactionItem>;
  useContributionsLabel: Scalars['Boolean'];
};

export type CashflowHistoryDeltaItem = CashflowHistoryItem & {
  __typename?: 'CashflowHistoryDeltaItem';
  date: Scalars['Date'];
  deltas: Array<PositionDeltaObjectType>;
  runningTotal: Wallet;
};

export type CashflowHistoryDistributionItem = CashflowHistoryItem & {
  __typename?: 'CashflowHistoryDistributionItem';
  date: Scalars['Date'];
  distributions: Array<DistributionObjectType>;
  runningTotal: Wallet;
};

export type CashflowHistoryItem = {
  date: Scalars['Date'];
  runningTotal: Wallet;
};

export type CashflowHistoryTransactionItem = CashflowHistoryItem & {
  __typename?: 'CashflowHistoryTransactionItem';
  date: Scalars['Date'];
  runningTotal: Wallet;
  transactions: Array<TransactionObjectType>;
};

export type ChangeRequest = {
  __typename?: 'ChangeRequest';
  changeRequestType: ChangeRequestType;
  changeRequestedObj?: Maybe<ContactObjectTypeChangeRequestedObj>;
};

export type ChangeRequestAccountContactMap = {
  __typename?: 'ChangeRequestAccountContactMap';
  accountContactMapId: Scalars['String'];
  changeRequestCommunicationPrefs: Array<ChangeRequestEmailDistributionList>;
  changeRequestLabel: ChangeRequestLabel;
  isAdminContact: Scalars['Boolean'];
  isMainContact: Scalars['Boolean'];
};

export type ChangeRequestArena = {
  __typename?: 'ChangeRequestArena';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ChangeRequestContact = {
  __typename?: 'ChangeRequestContact';
  addressedAs?: Maybe<Scalars['String']>;
  changeRequestContactAddress?: Maybe<Array<ChangeRequestContactAddress>>;
  changeRequestContactPhone?: Maybe<Array<ChangeRequestContactPhone>>;
  company?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ChangeRequestContactAddress = {
  __typename?: 'ChangeRequestContactAddress';
  addressType?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
};

export type ChangeRequestContactPhone = {
  __typename?: 'ChangeRequestContactPhone';
  phoneNumber: Scalars['String'];
  phoneType?: Maybe<Scalars['String']>;
};

export type ChangeRequestEmailDistributionList = {
  __typename?: 'ChangeRequestEmailDistributionList';
  emailDistributionList: EmailDistributionListObjectType;
};

export type ChangeRequestFilter = {
  accountId?: InputMaybe<Array<Scalars['String']>>;
  accountNameText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<ChangeRequestStatus>>;
};

export type ChangeRequestInput = {
  metadata?: InputMaybe<Scalars['JSON']>;
  requestPayloads: Array<ChangeRequestPayload>;
  requestType: ChangeRequestType;
};

export type ChangeRequestLabel = {
  __typename?: 'ChangeRequestLabel';
  labelText?: Maybe<Scalars['String']>;
};

export type ChangeRequestPayload = {
  metadata?: InputMaybe<Scalars['JSON']>;
  requestPayload: Scalars['JSON'];
};

export type ChangeRequestQueryInput = {
  accountId?: InputMaybe<Array<Scalars['String']>>;
  arenaId?: InputMaybe<Scalars['Int']>;
  changeRequestFilters?: InputMaybe<ChangeRequestFilter>;
  client: Client;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: SortOrder;
};

export type ChangeRequestQueryResponse = {
  __typename?: 'ChangeRequestQueryResponse';
  approvalRequests: Array<ApprovalRequest>;
  totalCount: Scalars['Int'];
};

export type ChangeRequestQueryResponseQueryErrorResponse = ChangeRequestQueryResponse | QueryErrorResponse;

export type ChangeRequestResponse = {
  __typename?: 'ChangeRequestResponse';
  actions: Array<Scalars['JSON']>;
  requestId: Scalars['StrawberryObjectId'];
  requestPayload: Scalars['JSON'];
  requestType: ChangeRequestType;
  status: Scalars['String'];
};

export enum ChangeRequestStatus {
  Approved = 'APPROVED',
  AutoApproved = 'AUTO_APPROVED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED'
}

export enum ChangeRequestType {
  AddContact = 'ADD_CONTACT',
  RemoveContact = 'REMOVE_CONTACT',
  UpdateContact = 'UPDATE_CONTACT',
  UpdatePositionPaymentPref = 'UPDATE_POSITION_PAYMENT_PREF'
}

export type ChangeRequestedObj = {
  __typename?: 'ChangeRequestedObj';
  name: Scalars['String'];
};

export type CheckPaymentPrefObjectType = {
  __typename?: 'CheckPaymentPrefObjectType';
  accountHolderType?: Maybe<PaymentPersonType>;
  address?: Maybe<SubscriptionAddressObjectType>;
  bankCountry?: Maybe<CountryObjectType>;
  bankInfoRedacted?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  /** May be None if the payment pref is in draft state via subscriptions workflows. In this case, they may return a CommonPaymentPrefObjectType with the ID set to None. */
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  naturalPersonDateOfBirth?: Maybe<Scalars['Date']>;
  naturalPersonEmailAddress?: Maybe<Scalars['String']>;
  naturalPersonFirstName?: Maybe<Scalars['String']>;
  naturalPersonLastName?: Maybe<Scalars['String']>;
  naturalPersonMiddleName?: Maybe<Scalars['String']>;
  payeeName?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentPrefId?: Maybe<Scalars['Int']>;
  positions?: Maybe<Array<PositionObjectType>>;
  useAccountMailingAddress?: Maybe<Scalars['Boolean']>;
};

export type Choice = {
  __typename?: 'Choice';
  body: Scalars['String'];
  followUpPrompts?: Maybe<Array<Prompt>>;
  id: Scalars['StrawberryObjectId'];
};

export type ChoiceInput = {
  body: Scalars['String'];
  followUpPrompts?: InputMaybe<Array<PromptInput>>;
  id?: InputMaybe<Scalars['StrawberryObjectId']>;
};

export enum Client {
  Gp = 'GP',
  Lp = 'LP'
}

export type CloneAchPaymentPrefInput = {
  arenaId: Scalars['ID'];
  signatureFullName: Scalars['String'];
  sourcePaymentPrefId: Scalars['ID'];
};

export type CloneAchPaymentPrefMutation = {
  __typename?: 'CloneAchPaymentPrefMutation';
  paymentPref?: Maybe<PaymentPrefObjectType>;
};

export type CommonCompanyInvestorDataInterface = {
  beneficiaries?: Maybe<Array<Maybe<CounterpartyObjectType>>>;
  dateOfOrganization?: Maybe<Scalars['Date']>;
  domicileAddress?: Maybe<AddressObjectType>;
  isUsPerson?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<AddressObjectType>;
  signatories?: Maybe<Array<Maybe<CounterpartyObjectType>>>;
  taxId?: Maybe<Scalars['String']>;
};

export type CommunicationPrefObjectType = {
  __typename?: 'CommunicationPrefObjectType';
  accountContactMap?: Maybe<AccountContactMapObjectType>;
  accountContactMapId?: Maybe<Scalars['Int']>;
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  emailDistributionList?: Maybe<EmailDistributionListObjectType>;
  emailDistributionListId: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
};

export type CompleteBankAccountAchVerificationInput = {
  bankAccountId: Scalars['ID'];
  input1: Scalars['Int'];
  input2: Scalars['Int'];
};

export enum ComplianceCaseAction {
  Accept = 'accept',
  Accepted = 'accepted',
  Approved = 'approved',
  Assign = 'assign',
  AssignToFundReview = 'assign_to_fund_review',
  AssignToWaitingResubmit = 'assign_to_waiting_resubmit',
  Cancel = 'cancel',
  Cleared = 'cleared',
  Confirmed = 'confirmed',
  DeEscalate = 'de_escalate',
  DeEscalated = 'de_escalated',
  Escalate = 'escalate',
  Escalated = 'escalated',
  Halted = 'halted',
  Invalid = 'invalid',
  Invalidate = 'invalidate',
  MarkDeficient = 'mark_deficient',
  Proceed = 'proceed',
  ProposeDeficient = 'propose_deficient',
  ProposeFundEscalation = 'propose_fund_escalation',
  ProposeInvalid = 'propose_invalid',
  ProposeWaive = 'propose_waive',
  Reject = 'reject',
  Rejected = 'rejected',
  Reopen = 'reopen',
  Resolved = 'resolved',
  Restart = 'restart',
  ReturnToMaker = 'return_to_maker',
  SubmitPartialSubscription = 'submit_partial_subscription',
  SubmitSubscription = 'submit_subscription',
  Unassign = 'unassign',
  Waive = 'waive'
}

export type ComplianceCaseAuditLogObject = {
  __typename?: 'ComplianceCaseAuditLogObject';
  caseId: Scalars['Int'];
  createdAt: Scalars['String'];
  createdBy: ExternalObjectType;
  eventData: Scalars['JSON'];
  eventType: ComplianceCaseEventType;
  id: Scalars['Int'];
};

export enum ComplianceCaseAuditTagType {
  CaseCanceled = 'case_canceled',
  CaseClosed = 'case_closed',
  CaseReopened = 'case_reopened',
  GpResponseRecorded = 'gp_response_recorded'
}

export type ComplianceCaseEvent = {
  __typename?: 'ComplianceCaseEvent';
  caseId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  eventType: ComplianceCaseEventType;
  status: ComplianceCaseStatus;
  subStatus?: Maybe<ComplianceCaseSubStatus>;
  tag: ComplianceCaseAuditTagType;
};

export enum ComplianceCaseEventType {
  BankAccountAdded = 'bank_account_added',
  BankAccountRemoved = 'bank_account_removed',
  BankAccountUpdated = 'bank_account_updated',
  CaseCreated = 'case_created',
  CaseUpdated = 'case_updated',
  CommentAdded = 'comment_added',
  DocumentAdded = 'document_added',
  DocumentRemoved = 'document_removed',
  DocumentUpdated = 'document_updated',
  GpResponseRecorded = 'gp_response_recorded',
  ProfileAdded = 'profile_added',
  ProfileRemoved = 'profile_removed',
  ProfileUpdated = 'profile_updated',
  ReportGenerated = 'report_generated',
  RiskRatingUpdated = 'risk_rating_updated',
  ScreeningMatchUpdated = 'screening_match_updated'
}

export type ComplianceCaseFilters = {
  field: Scalars['String'];
  filterValues: Array<Scalars['String']>;
};

export enum ComplianceCaseOrigin {
  ManagedClosed = 'managed_closed',
  Manual = 'manual',
  RecurringWatchlistHit = 'recurring_watchlist_hit'
}

export type ComplianceCaseProfileMapType = {
  __typename?: 'ComplianceCaseProfileMapType';
  caseId: Scalars['Int'];
  complianceCase?: Maybe<ComplianceCaseType>;
  id: Scalars['Int'];
  isInvestingProfile: Scalars['Boolean'];
  profile?: Maybe<ProfileType>;
  profileId: Scalars['Int'];
};

export type ComplianceCaseSliceType = {
  __typename?: 'ComplianceCaseSliceType';
  filteredCount: Scalars['Int'];
  rows: Array<ComplianceCaseType>;
};

export type ComplianceCaseSort = {
  field: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};

export enum ComplianceCaseStatus {
  Closed = 'closed',
  Escalated = 'escalated',
  InProgress = 'in_progress',
  PendingDataResubmission = 'pending_data_resubmission',
  PendingDataSubmission = 'pending_data_submission',
  PendingFundReview = 'pending_fund_review',
  PendingReview = 'pending_review',
  PendingSubmission = 'pending_submission',
  Ready = 'ready',
  UnderReview = 'under_review'
}

export enum ComplianceCaseSubStatus {
  Accepted = 'accepted',
  Approved = 'approved',
  Assigned = 'assigned',
  Canceled = 'canceled',
  CanceledByInvestor = 'canceled_by_investor',
  Cleared = 'cleared',
  DeEscalated = 'de_escalated',
  Escalated = 'escalated',
  Halted = 'halted',
  Invalid = 'invalid',
  PendingFundEscalation = 'pending_fund_escalation',
  PendingResubmission = 'pending_resubmission',
  ProposedAcceptance = 'proposed_acceptance',
  ProposedDeficient = 'proposed_deficient',
  ProposedEscalation = 'proposed_escalation',
  ProposedFundEscalation = 'proposed_fund_escalation',
  ProposedInvalid = 'proposed_invalid',
  ProposedRejection = 'proposed_rejection',
  ProposedResolved = 'proposed_resolved',
  ProposedWaived = 'proposed_waived',
  Rejected = 'rejected',
  RejectedByFund = 'rejected_by_fund',
  Reopened = 'reopened',
  Resolved = 'resolved',
  Resubmitted = 'resubmitted',
  ReturnedToMaker = 'returned_to_maker',
  Waived = 'waived'
}

export type ComplianceCaseType = {
  __typename?: 'ComplianceCaseType';
  assignee?: Maybe<ReviewerType>;
  assigneeId?: Maybe<Scalars['Int']>;
  caseType: ComplianceCaseTypeEnum;
  checker?: Maybe<ReviewerType>;
  checkerId?: Maybe<Scalars['Int']>;
  comments: Array<ComplianceCommentObject>;
  createdAt: Scalars['DateTime'];
  documents: Array<DocumentType>;
  escalations?: Maybe<Array<ComplianceEscalationType>>;
  events: Array<ComplianceCaseEvent>;
  externalArena?: Maybe<ExternalObjectType>;
  externalArenaId?: Maybe<Scalars['Int']>;
  externalEntity?: Maybe<ExternalObjectType>;
  externalEntityId?: Maybe<Scalars['Int']>;
  externalObject?: Maybe<ExternalObjectType>;
  externalObjectId?: Maybe<Scalars['Int']>;
  externalObjects: Array<ExternalObjectType>;
  id: Scalars['Int'];
  investingProfile?: Maybe<ProfileType>;
  maker?: Maybe<ReviewerType>;
  makerId?: Maybe<Scalars['Int']>;
  metaData?: Maybe<Scalars['JSON']>;
  modifiedAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  origin: ComplianceCaseOrigin;
  profiles: Array<Maybe<ProfileType>>;
  program: Program;
  programId: Scalars['Int'];
  readonly?: Maybe<Scalars['Boolean']>;
  rules?: Maybe<Scalars['String']>;
  status: ComplianceCaseStatus;
  subStatus?: Maybe<ComplianceCaseSubStatus>;
};


export type ComplianceCaseTypeEventsArgs = {
  tag?: InputMaybe<ComplianceCaseAuditTagType>;
};

export type ComplianceCaseTypeConnection = {
  __typename?: 'ComplianceCaseTypeConnection';
  edges: Array<ComplianceCaseTypeEdge>;
  pageInfo: PageInfo;
};

export type ComplianceCaseTypeEdge = {
  __typename?: 'ComplianceCaseTypeEdge';
  cursor: Scalars['String'];
  node: ComplianceCaseType;
};

export enum ComplianceCaseTypeEnum {
  GpSourceOfFunds = 'gp_source_of_funds',
  GpTransactionMonitoring = 'gp_transaction_monitoring',
  InvestorOnboarding = 'investor_onboarding',
  RecurringHit = 'recurring_hit'
}

export enum ComplianceCommentFormat {
  Html = 'html',
  Text = 'text'
}

export type ComplianceCommentObject = {
  __typename?: 'ComplianceCommentObject';
  comment: Scalars['String'];
  createdBy: Scalars['Int'];
  format: ComplianceCommentFormat;
  id: Scalars['Int'];
  internal: Scalars['Boolean'];
  objectId: Scalars['Int'];
  objectType: ComplianceCommentObjectType;
};

export enum ComplianceCommentObjectType {
  Activity = 'activity',
  Case = 'case',
  Document = 'document',
  PaymentAccount = 'payment_account',
  Profile = 'profile',
  RequestMoreInfo = 'request_more_info',
  RiskRating = 'risk_rating',
  ScreeningRecord = 'screening_record',
  WatchlistResult = 'watchlist_result'
}

export type ComplianceEscalationInput = {
  caseId: Scalars['Int'];
  description: Scalars['String'];
};

export type ComplianceEscalationType = {
  __typename?: 'ComplianceEscalationType';
  caseId: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['Int'];
  verdict?: Maybe<EscalationVerdict>;
  waiverDocumentId?: Maybe<Scalars['Int']>;
};

export enum ComplianceExternalObjectType {
  Account = 'account',
  Acquisition = 'acquisition',
  Arena = 'arena',
  Asset = 'asset',
  AssetDateSampledData = 'asset_date_sampled_data',
  BankAccount = 'bank_account',
  CapitalCall = 'capital_call',
  CapitalCallBatch = 'capital_call_batch',
  CommPref = 'comm_pref',
  ComplianceProfile = 'compliance_profile',
  ConsolidatedPaymentBatch = 'consolidated_payment_batch',
  Contact = 'contact',
  CrmNotificationList = 'crm_notification_list',
  DataRoom = 'data_room',
  Disposition = 'disposition',
  Distribution = 'distribution',
  DistributionBatch = 'distribution_batch',
  Document = 'document',
  DocumentCategory = 'document_category',
  DocumentFolder = 'document_folder',
  ESignatureDocument = 'e_signature_document',
  ESignatureGpSigner = 'e_signature_gp_signer',
  ESignatureLpConfigurator = 'e_signature_lp_configurator',
  ESignatureLpSigner = 'e_signature_lp_signer',
  ESignatureRequest = 'e_signature_request',
  ESignatureRequestBatch = 'e_signature_request_batch',
  ESignatureRequestRecipient = 'e_signature_request_recipient',
  ESignatureRequestTemplate = 'e_signature_request_template',
  Email = 'email',
  EmailBatch = 'email_batch',
  EntityCollection = 'entity_collection',
  EventLog = 'event_log',
  FeatureFlag = 'feature_flag',
  FieldAccessRole = 'field_access_role',
  FieldAccessRoleObjectMap = 'field_access_role_object_map',
  FundAdminService = 'fund_admin_service',
  ImportTemplate = 'import_template',
  InvestorDiligence = 'investor_diligence',
  KycDetail = 'kyc_detail',
  KycDocument = 'kyc_document',
  KycDocumentVersion = 'kyc_document_version',
  KycDocumentVersionActivity = 'kyc_document_version_activity',
  Lead = 'lead',
  Loan = 'loan',
  Location = 'location',
  LpConsolidationRequest = 'lp_consolidation_request',
  ManagementFeeBatch = 'management_fee_batch',
  Nav = 'nav',
  NavBatch = 'nav_batch',
  NetIncome = 'net_income',
  NetIncomeBatch = 'net_income_batch',
  Note = 'note',
  Offering = 'offering',
  Payment = 'payment',
  PaymentBatch = 'payment_batch',
  PaymentPref = 'payment_pref',
  Position = 'position',
  ProductType = 'product_type',
  Property = 'property',
  Prospect = 'prospect',
  ProspectContactMap = 'prospect_contact_map',
  Publication = 'publication',
  StoredEmail = 'stored_email',
  Subscription = 'subscription',
  SubscriptionBatch = 'subscription_batch',
  Task = 'task',
  Transaction = 'transaction',
  TransactionBatch = 'transaction_batch',
  TransactionFlagged = 'transaction_flagged',
  TwrPeriodMetric = 'twr_period_metric',
  UnitValue = 'unit_value',
  UnitValueBatch = 'unit_value_batch',
  User = 'user',
  WatchlistScreeningDetail = 'watchlist_screening_detail'
}

export type ComplianceIndividualProfileInput = {
  addresses: Array<Address>;
  caseId?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  taxIdType?: InputMaybe<TaxIdType>;
};

export type ComplianceInstitutionProfileInput = {
  accountType: InstitutionAccountTypeEnum;
  addresses: Array<Address>;
  caseId?: InputMaybe<Scalars['Int']>;
  dateOfFormation?: InputMaybe<Scalars['Date']>;
  legalBusinessName: Scalars['String'];
  taxId?: InputMaybe<Scalars['String']>;
  taxIdType?: InputMaybe<TaxIdType>;
};

export enum ComplianceObjectType {
  Individual = 'individual',
  Institution = 'institution'
}

export type CompliancePresignedUrlInput = {
  name: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type CompliancePresignedUrlObjectType = {
  __typename?: 'CompliancePresignedUrlObjectType';
  fileName: Scalars['String'];
  objectKey: Scalars['String'];
  s3Url: Scalars['String'];
};

export type ComplianceReportObject = {
  __typename?: 'ComplianceReportObject';
  createdBy: Scalars['Int'];
  errorMsg?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  info: Scalars['JSON'];
  reportType: ComplianceReportType;
  s3Url?: Maybe<Scalars['String']>;
  status: ComplianceReportStatus;
};

export enum ComplianceReportStatus {
  Error = 'error',
  Pending = 'pending',
  Ready = 'ready'
}

export enum ComplianceReportType {
  AuditLog = 'audit_log',
  CaseDetails = 'case_details',
  ClientStatus = 'client_status'
}

export type ComplianceSettingsObjectType = PermissionCheckedNode & {
  __typename?: 'ComplianceSettingsObjectType';
  complianceProgram?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
};

export type ConfigureIntegrationInput = {
  companyHubspotToJsqFields?: InputMaybe<Array<Scalars['String']>>;
  companyJsqToHubspotFields?: InputMaybe<Array<Scalars['Int']>>;
  contactHubspotToJsqFields?: InputMaybe<Array<Scalars['String']>>;
  contactJsqToHubspotFields?: InputMaybe<Array<Scalars['Int']>>;
  provenance: Scalars['String'];
};

export type ConfigureIntegrationObject = {
  __typename?: 'ConfigureIntegrationObject';
  job: AsyncJobObjectType;
};

export type ConfigureIntegrationOrError = ConfigureIntegrationObject | MutationErrorResponse;

export type ContactActivityFeedObjectType = EmailObjectType | NoteObjectType | StoredEmailObjectType;

export type ContactActivityFeedWrapperObjectType = {
  __typename?: 'ContactActivityFeedWrapperObjectType';
  items: Array<ContactActivityFeedObjectType>;
  newStartTimestamp?: Maybe<Scalars['String']>;
};

export type ContactAddressInput = {
  addressType?: InputMaybe<AddressType>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
};

export type ContactAddressObjectType = AddressObjectTypeBase & {
  __typename?: 'ContactAddressObjectType';
  CompositeExternalId?: Maybe<Scalars['String']>;
  addressType?: Maybe<AddressType>;
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  contactId: Scalars['Int'];
  country?: Maybe<ObjectRefObjectType>;
  countryId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  externalId?: Maybe<Scalars['String']>;
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  geocodingFailed?: Maybe<Scalars['DateTime']>;
  geocodingPending?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
  postalCode?: Maybe<Scalars['String']>;
  provenance?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  state?: Maybe<ObjectRefObjectType>;
  stateId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  subProvenance?: Maybe<Scalars['String']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
};

export type ContactDeleteConfirmationObjectType = {
  __typename?: 'ContactDeleteConfirmationObjectType';
  accountCount: Scalars['Int'];
  duplicateCount: Scalars['Int'];
  duplicates: Array<Scalars['String']>;
  ids: Array<Scalars['String']>;
  portalAccountCount: Scalars['Int'];
  prospectCount: Scalars['Int'];
};

export type ContactDeleteConfirmationObjectTypeOrError = ContactDeleteConfirmationObjectType | MutationErrorResponse;

export type ContactDeletedCountObjectType = {
  __typename?: 'ContactDeletedCountObjectType';
  deletedCount: Scalars['Int'];
  deletedIds: Array<Scalars['String']>;
};

export type ContactDeletedCountObjectTypeOrError = ContactDeletedCountObjectType | MutationErrorResponse;

export type ContactEdge = {
  __typename?: 'ContactEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ContactObjectType;
};

export type ContactEmailAddressInput = {
  emailAddress: Scalars['String'];
  emailType: EmailType;
  id?: InputMaybe<Scalars['ID']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

export type ContactEmailAddressObjectType = EmailAddressObjectTypeBase & {
  __typename?: 'ContactEmailAddressObjectType';
  CompositeExternalId?: Maybe<Scalars['String']>;
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  contact?: Maybe<ContactObjectType>;
  contactId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  emailAddress: Scalars['String'];
  emailType?: Maybe<EmailType>;
  externalId?: Maybe<Scalars['String']>;
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
  provenance?: Maybe<Scalars['String']>;
  subProvenance?: Maybe<Scalars['String']>;
};

export type ContactEmailDomainAcceptanceInput = {
  acceptance?: InputMaybe<Scalars['String']>;
  emailDomain?: InputMaybe<Scalars['String']>;
};

export type ContactEmploymentInput = {
  employer?: InputMaybe<ObjectRefInput>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type ContactFilterDeletedCountObjectTypeOrError = DeletedContactFilterCountObjectType | MutationErrorResponse;

export type ContactFilterInput = {
  /** Determine the assignee to filter the contacts list */
  assigneeFilter?: InputMaybe<AssigneeFilterInput>;
  /** Determine the name to filter the contacts list */
  contactNameFilter?: InputMaybe<ContactNameFilterInput>;
  extensionFieldFilters?: InputMaybe<ExtensionFieldFiltersInput>;
  fieldFilters?: InputMaybe<Array<FieldFilterInput>>;
  /** Determine the location to filter the contacts list */
  locationFilter?: InputMaybe<LocationFilterInput>;
  /** Determine the investment entities to filter position in */
  positionInFilter?: InputMaybe<PositionInFilterInput>;
  /** Determine the offering to filter prospect for */
  prospectForFilter?: InputMaybe<ProspectForFilterInput>;
  /** Determine the tag to filter for contacts list */
  tagFilter?: InputMaybe<TagFilterInput>;
  /** Determine the type to filter the contacts list */
  typeFilter?: InputMaybe<TypeFilterInput>;
};

export type ContactFiltersObjectType = {
  __typename?: 'ContactFiltersObjectType';
  /** Determine the assignee to filter the contacts list */
  assigneeFilter?: Maybe<StringFilterObjectType>;
  contactNameFilter?: Maybe<ContactNameFilter>;
  extensionFieldFilters?: Maybe<ExtensionFieldFiltersObjectType>;
  fieldFilters?: Maybe<Array<FieldFilterObjectType>>;
  /** Determine the location to filter the contacts list */
  locationFilter?: Maybe<LocationFilterObjectType>;
  /** Determine the investment entities to filter position in */
  positionInFilter?: Maybe<StringFilterObjectType>;
  /** Determine the offering to filter prospect for */
  prospectForFilter?: Maybe<StringFilterObjectType>;
  /** Determine the tag to filter for contacts list */
  tagFilter?: Maybe<StringFilterObjectType>;
  /** Determine the type to filter the contacts list */
  typeFilter?: Maybe<TypeFilterObjectType>;
};

export type ContactNameFilter = {
  __typename?: 'ContactNameFilter';
  filter: Scalars['String'];
};

export type ContactNameFilterInput = {
  filter: Scalars['String'];
};

/** Used exclusively for inputs. This is due to federation requiring input enums to be updated across all subgraphs if changes are needed. */
export enum ContactObjectFilterEnum {
  IsAccountForOwningPositions = 'is_account_for_owning_positions',
  IsAnyStaff = 'is_any_staff',
  IsEntity = 'is_entity',
  IsEntityForUpdate = 'is_entity_for_update',
  IsNotEntity = 'is_not_entity',
  IsNotEntityForUpdate = 'is_not_entity_for_update',
  IsNotFundAdminStaff = 'is_not_fund_admin_staff',
  IsOrg = 'is_org',
  IsOrgExactNameMatch = 'is_org_exact_name_match',
  IsOrgForUpdate = 'is_org_for_update',
  IsPerson = 'is_person',
  IsPersonExactEmailMatch = 'is_person_exact_email_match',
  IsPersonForUpdate = 'is_person_for_update',
  IsStaff = 'is_staff',
  IsStaffCrmCrmr = 'is_staff_crm_crmr',
  IsStaffFm = 'is_staff_fm',
  IsStaffIm = 'is_staff_im',
  IsStaffOrPerson = 'is_staff_or_person'
}

export type ContactObjectType = PermissionCheckedNode & TaggableObjectType & {
  __typename?: 'ContactObjectType';
  acceptedEmailDomains: Array<Scalars['String']>;
  accessGroups: Array<ObjectGroupObjectType>;
  accounts: Array<AccountObjectType>;
  activityFeed: Array<ContactActivityFeedObjectType>;
  addresses: Array<ContactAddressObjectType>;
  assignee?: Maybe<UserObjectType>;
  company?: Maybe<ContactObjectType>;
  companyName?: Maybe<Scalars['String']>;
  contactType: ContactType;
  emails: Array<ContactEmailAddressObjectType>;
  extensionFields: Array<ExtensionFieldTuple>;
  faxes: Array<ContactPhoneObjectType>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  hasDeleteContactAccess: Scalars['Boolean'];
  hasFullClientAccess: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  leads: Array<LeadObjectType>;
  linkedinUrl?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  offerings: Array<OfferingObjectType>;
  pastEmployments: Array<PastEmploymentObjectType>;
  phones: Array<ContactPhoneObjectType>;
  /** Portal metadata relates to the contact */
  portal?: Maybe<ContactPortalMetadataObjectType>;
  portalFullName?: Maybe<Scalars['String']>;
  portalIsActivated: Scalars['Boolean'];
  positions?: Maybe<PositionObjectTypeConnection>;
  preqinId?: Maybe<Scalars['Int']>;
  preqinRole?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<ContactEmailAddressObjectType>;
  prospects?: Maybe<ProspectObjectTypeConnection>;
  referred: Array<ContactObjectType>;
  referrer?: Maybe<ContactObjectType>;
  referrerName?: Maybe<Scalars['String']>;
  relations: Array<ContactRelationObjectType>;
  salutationName?: Maybe<Scalars['String']>;
  staff: Array<ContactObjectType>;
  tags?: Maybe<TagConnection>;
  thumbnail?: Maybe<ContactPhotoObjectType>;
  title?: Maybe<Scalars['String']>;
  /** Totals of the contact's owned positions by status */
  transactionTotals?: Maybe<ContactTransactionTotalsByStatusObjectType>;
  user?: Maybe<UserObjectType>;
  websiteUrl?: Maybe<Scalars['String']>;
};


export type ContactObjectTypePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ContactObjectTypeProspectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ContactObjectTypeTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<TagObjectTypeSortEnum>>>;
};

export type ContactObjectTypeChangeRequestedObj = ChangeRequestedObj | ContactObjectType;

export type ContactObjectTypeConnection = {
  __typename?: 'ContactObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ContactObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type ContactObjectTypeEdge = {
  __typename?: 'ContactObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ContactObjectType;
};

export type ContactObjectTypeOrError = ContactObjectType | MutationErrorResponse;

/** An enumeration. */
export enum ContactObjectTypeSortEnum {
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  JobTitleAsc = 'JOB_TITLE_ASC',
  JobTitleDesc = 'JOB_TITLE_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  LinkedinUrlAsc = 'LINKEDIN_URL_ASC',
  LinkedinUrlDesc = 'LINKEDIN_URL_DESC',
  MiddleNameAsc = 'MIDDLE_NAME_ASC',
  MiddleNameDesc = 'MIDDLE_NAME_DESC',
  PreqinIdAsc = 'PREQIN_ID_ASC',
  PreqinIdDesc = 'PREQIN_ID_DESC',
  ReferrerNameAsc = 'REFERRER_NAME_ASC',
  ReferrerNameDesc = 'REFERRER_NAME_DESC',
  SalutationNameAsc = 'SALUTATION_NAME_ASC',
  SalutationNameDesc = 'SALUTATION_NAME_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  WebsiteUrlAsc = 'WEBSITE_URL_ASC',
  WebsiteUrlDesc = 'WEBSITE_URL_DESC'
}

export type ContactPhoneInput = {
  id?: InputMaybe<Scalars['ID']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneType: PhoneType;
};

export type ContactPhoneObjectType = PhoneObjectTypeBase & {
  __typename?: 'ContactPhoneObjectType';
  CompositeExternalId?: Maybe<Scalars['String']>;
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  contactId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  externalId?: Maybe<Scalars['String']>;
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isFax?: Maybe<Scalars['Boolean']>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
  phoneNumber: Scalars['String'];
  phoneType?: Maybe<PhoneType>;
  provenance?: Maybe<Scalars['String']>;
  subProvenance?: Maybe<Scalars['String']>;
};

export type ContactPhotoObjectType = PermissionCheckedNode & PhotoInterface & {
  __typename?: 'ContactPhotoObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  contact?: Maybe<ContactObjectType>;
  contactId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  documentVersion?: Maybe<DocumentVersionObjectType>;
  documentVersionId: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  naturalHeight?: Maybe<Scalars['Int']>;
  naturalWidth?: Maybe<Scalars['Int']>;
  options?: Maybe<Scalars['Int']>;
  originalPhotoId?: Maybe<Scalars['Int']>;
  owner?: Maybe<UserObjectType>;
  photoUrl: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};


export type ContactPhotoObjectTypePhotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  option?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ContactPortalMetadataObjectType = {
  __typename?: 'ContactPortalMetadataObjectType';
  accountEmailAddress?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['DateTime']>;
  hasFullPortalAccess?: Maybe<Scalars['Boolean']>;
  hasPreviewAccess?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isTwoFaActive?: Maybe<Scalars['Boolean']>;
  lastAccessTime?: Maybe<Scalars['DateTime']>;
  twoFaRequiredPortal?: Maybe<Scalars['Boolean']>;
};

export type ContactRelationInput = {
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  relatedContact?: InputMaybe<ObjectRefInput>;
};

export type ContactRelationObjectType = PermissionCheckedNode & {
  __typename?: 'ContactRelationObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  contact: ContactObjectType;
  contactId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  inverseLabel?: Maybe<Scalars['String']>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  labelId: Scalars['Int'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
  relatedContact: ContactObjectType;
  relatedContactId: Scalars['Int'];
};

export type ContactSearchResultObjectType = {
  __typename?: 'ContactSearchResultObjectType';
  result: Array<Maybe<SearchedContatctObjectType>>;
};

export type ContactTransactionTotalsByCurrencyObjectType = {
  __typename?: 'ContactTransactionTotalsByCurrencyObjectType';
  currency: CurrencyObjectType;
  transactionTotals: ContactTransactionTotalsObjectType;
};

export type ContactTransactionTotalsByStatusObjectType = {
  __typename?: 'ContactTransactionTotalsByStatusObjectType';
  active?: Maybe<Array<Maybe<ContactTransactionTotalsByCurrencyObjectType>>>;
  completed?: Maybe<Array<Maybe<ContactTransactionTotalsByCurrencyObjectType>>>;
};

export type ContactTransactionTotalsObjectType = {
  __typename?: 'ContactTransactionTotalsObjectType';
  accountValue?: Maybe<Wallet>;
  amountCommitted?: Maybe<Wallet>;
  lifetimeAmountDistributed?: Maybe<Wallet>;
  lifetimeCapitalContributed?: Maybe<Wallet>;
  lifetimeTotalAmountCalled?: Maybe<Wallet>;
  positionCount?: Maybe<Scalars['Int']>;
};

/** Enumeration that defines the types of contacts.  Used in ContactModel. */
export enum ContactType {
  Organization = 'organization',
  Person = 'person'
}

/** Used exclusively for inputs. This is due to federation requiring input enums to be updated across all subgraphs if changes are needed. */
export enum ContactTypeFilterEnum {
  Organization = 'organization',
  Person = 'person'
}

export type ContactsPageGridLocationFilterResultObjectType = {
  __typename?: 'ContactsPageGridLocationFilterResultObjectType';
  addressType: AddressType;
  milesFromLocation: Scalars['Decimal'];
};

export type ContactsPageGridObjectType = {
  __typename?: 'ContactsPageGridObjectType';
  filteredCount: Scalars['Int'];
  rows: Array<ContactsPageGridRowObjectType>;
  totalCount: Scalars['Int'];
};

export type ContactsPageGridRowObjectType = {
  __typename?: 'ContactsPageGridRowObjectType';
  committedAmount?: Maybe<Scalars['Decimal']>;
  company?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOrg: Scalars['Boolean'];
  linkedinUrl?: Maybe<Scalars['String']>;
  locationFilterResult?: Maybe<ContactsPageGridLocationFilterResultObjectType>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  positionCount: Scalars['Int'];
  tags: Array<ContactsPageGridTagObjectType>;
  thumbnail?: Maybe<ContactPhotoObjectType>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type ContactsPageGridTagObjectType = {
  __typename?: 'ContactsPageGridTagObjectType';
  id: Scalars['String'];
  text: Scalars['String'];
};

/** An enumeration. */
export enum ContentType {
  TextHtml = 'text_html',
  TextPlain = 'text_plain'
}

export type ConversionGl = {
  __typename?: 'ConversionGl';
  exchangeRate: Scalars['Decimal'];
  original: Scalars['Money'];
  to: Scalars['Money'];
};

export type ConversionTotalGl = {
  __typename?: 'ConversionTotalGl';
  conversions: Array<ConversionGl>;
  total: Scalars['Money'];
};

export type CoordinatesFilter = {
  latitude: Scalars['Decimal'];
  locationId?: InputMaybe<Scalars['String']>;
  locationName?: InputMaybe<Scalars['String']>;
  longitude: Scalars['Decimal'];
  radius: Scalars['Int'];
  radiusType?: InputMaybe<CoordinatesRadiusType>;
};

export type CoordinatesFilterObjectType = {
  __typename?: 'CoordinatesFilterObjectType';
  latitude: Scalars['Decimal'];
  locationId?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  longitude: Scalars['Decimal'];
  radius: Scalars['Int'];
  radiusType?: Maybe<CoordinatesRadiusType>;
};

export enum CoordinatesRadiusType {
  Kilometers = 'kilometers',
  Miles = 'miles'
}

export type CounterpartyObjectType = {
  __typename?: 'CounterpartyObjectType';
  domicileAddress?: Maybe<AddressObjectType>;
  mailingAddress?: Maybe<AddressObjectType>;
  name: Scalars['String'];
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<CountryEdge>;
  pageInfo: PageInfo;
};

export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: CountryObjectType;
};

export type CountryObjectType = PermissionCheckedNode & {
  __typename?: 'CountryObjectType';
  alpha2code?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  population?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum CountryObjectTypeSortEnum {
  Alpha2CodeAsc = 'ALPHA2CODE_ASC',
  Alpha2CodeDesc = 'ALPHA2CODE_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PopulationAsc = 'POPULATION_ASC',
  PopulationDesc = 'POPULATION_DESC'
}

export type CountryType = {
  __typename?: 'CountryType';
  alpha2code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  riskRating?: Maybe<Scalars['String']>;
};

export type CreateAchPaymentPrefInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountOwnership?: InputMaybe<AchAccountOwnershipType>;
  accountType: BankAccountType;
  additionalInstructions?: InputMaybe<Scalars['String']>;
  arenaId: Scalars['ID'];
  bankName: Scalars['String'];
  routingNumber: Scalars['String'];
  signatureFullName?: InputMaybe<Scalars['String']>;
};

export type CreateAchPaymentPrefMutation = {
  __typename?: 'CreateAchPaymentPrefMutation';
  paymentPref?: Maybe<PaymentPrefObjectType>;
};

export type CreateAnnouncementNotificationInput = {
  arenaGroups: Array<AnnouncementArenaGroup>;
  body: Scalars['String'];
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  header: Scalars['String'];
  name: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  userRoles: Array<Scalars['Int']>;
};

export type CreateCheckPaymentPrefAddressInput = {
  city: Scalars['String'];
  countryId: Scalars['ID'];
  postalCode: Scalars['String'];
  /** If United States is not selected, state maps to this freeform text field. */
  region?: InputMaybe<Scalars['String']>;
  /** If United States is selected, state_id maps to a valid US state. */
  stateId?: InputMaybe<Scalars['ID']>;
  street: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
};

export type CreateCheckPaymentPrefInput = {
  address: CreateCheckPaymentPrefAddressInput;
  arenaId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  payeeName: Scalars['String'];
  useAccountMailingAddress: Scalars['Boolean'];
};

export type CreateCheckPaymentPrefMutation = {
  __typename?: 'CreateCheckPaymentPrefMutation';
  paymentPref?: Maybe<PaymentPrefObjectType>;
};

export type CreateComplianceCommentInput = {
  comment: Scalars['String'];
  format: ComplianceCommentFormat;
  internal: Scalars['Boolean'];
  objectId: Scalars['Int'];
  objectType: ComplianceCommentObjectType;
};

export type CreateComplianceReportInput = {
  caseIds: Array<Scalars['Int']>;
  filters?: InputMaybe<Array<ComplianceCaseFilters>>;
  idsAreSelectedVsUnselected?: InputMaybe<Scalars['Boolean']>;
  reportType: ComplianceReportType;
};

export type CreateContactInput = {
  accessGroupIds?: InputMaybe<Array<Scalars['String']>>;
  addressType?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contactType: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  emailType?: InputMaybe<Scalars['String']>;
  employmentId?: InputMaybe<Scalars['String']>;
  employmentName?: InputMaybe<Scalars['String']>;
  employmentNameText?: InputMaybe<Scalars['String']>;
  expandNames?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasSingleContactAccessGroup?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  offeringId?: InputMaybe<Scalars['String']>;
  phone1Number?: InputMaybe<Scalars['String']>;
  phone1Type?: InputMaybe<Scalars['String']>;
  phone2Number?: InputMaybe<Scalars['String']>;
  phone2Type?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prospectClassId?: InputMaybe<Scalars['String']>;
  prospectExpectedAmount?: InputMaybe<CurrencyInput>;
  prospectLikelihood?: InputMaybe<Scalars['Decimal']>;
  prospectStatusId?: InputMaybe<Scalars['String']>;
  prospectTypeId?: InputMaybe<Scalars['String']>;
  stateProvince?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateNetNoticeLinkageFederation = {
  __typename?: 'CreateNetNoticeLinkageFederation';
  result: NetNoticeLinkageResponse;
};

export type CreateOrUpdateContactFilterInput = {
  contactFilter?: InputMaybe<ContactFilterInput>;
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CreatePaymentBatchInput = {
  /** Use this field for storing debit payment process date, currently only for Modern Treasury payments (use scheduledPaymentProcessDate instead for SWIFT payments). */
  fundingDebitPaymentProcessDate?: InputMaybe<Scalars['DateTime']>;
  objectId: Scalars['Int'];
  objectType: ObjectType;
  paymentDescription?: InputMaybe<Scalars['String']>;
  payments: Array<CreatePaymentInput>;
  /** Use this field for storing payment process date for Swift payments. Not used for Modern Treasury payments (use fundingDebitPaymentProcessDate instead for scheduling ACH debiting) */
  scheduledPaymentProcessDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePaymentInput = {
  amount: Scalars['Money'];
  method: PaymentMethod;
  objectId: Scalars['Int'];
  objectType: ObjectType;
  paymentPrefId: Scalars['Int'];
  positionId: Scalars['Int'];
};

export type CreatePaymentsApplicationInput = {
  objectId: Scalars['Int'];
  objectType: ObjectType;
  profileId: Scalars['Int'];
};

export type CreateWireBankAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  countryId: Scalars['ID'];
  postalCode?: InputMaybe<Scalars['String']>;
  /** If United States is not selected, state maps to this freeform text field. */
  region?: InputMaybe<Scalars['String']>;
  /** If United States is selected, state_id maps to a valid US state. */
  stateId?: InputMaybe<Scalars['ID']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
};

export type CreateWireBankInput = {
  address: CreateWireBankAddressInput;
  /** May also be referred to as routing code */
  intlRoutingNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** May also be referred to as ABA number. Either this or swift code must be provided */
  routingNumber?: InputMaybe<Scalars['String']>;
  /** May also be referred to as BIC code. Either this or routing number must be provided */
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type CreateWirePaymentPrefBeneficiaryAddressInput = {
  city: Scalars['String'];
  countryId: Scalars['ID'];
  postalCode: Scalars['String'];
  /** If United States is not selected, state maps to this freeform text field. */
  region?: InputMaybe<Scalars['String']>;
  /** If United States is selected, state_id maps to a valid US state. */
  stateId?: InputMaybe<Scalars['ID']>;
  street: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
};

export type CreateWirePaymentPrefInput = {
  arenaId: Scalars['ID'];
  bank: CreateWireBankInput;
  beneficiaryAccountNumber: Scalars['String'];
  beneficiaryAddress: CreateWirePaymentPrefBeneficiaryAddressInput;
  beneficiaryName: Scalars['String'];
  beneficiaryType?: InputMaybe<Scalars['String']>;
  hasIntermediary: Scalars['Boolean'];
  intermediaryBank?: InputMaybe<CreateWireBankInput>;
  referenceToBeneficiary?: InputMaybe<Scalars['String']>;
  wireBbi?: InputMaybe<WireExtraFieldInput>;
  wireObi?: InputMaybe<WireExtraFieldInput>;
};

export type CreateWirePaymentPrefMutation = {
  __typename?: 'CreateWirePaymentPrefMutation';
  paymentPref?: Maybe<PaymentPrefObjectType>;
};

/** An enumeration. */
export enum CropType {
  Almonds = 'almonds',
  Apples = 'apples',
  Corn = 'corn',
  Cotton = 'cotton',
  FreshVegetables = 'fresh_vegetables',
  Oranges = 'oranges',
  OtherAnnual = 'other_annual',
  OtherCitrus = 'other_citrus',
  OtherPermanent = 'other_permanent',
  Pistachios = 'pistachios',
  Rice = 'rice',
  Soybeans = 'soybeans',
  Wheat = 'wheat',
  WineGrapes = 'wine_grapes'
}

/** An enumeration. */
export enum CurrencyCode {
  Aed = 'AED',
  Ars = 'ARS',
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Mxn = 'MXN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pen = 'PEN',
  Pln = 'PLN',
  Ron = 'RON',
  Rub = 'RUB',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Try = 'TRY',
  Twd = 'TWD',
  Uah = 'UAH',
  Usd = 'USD',
  Uyu = 'UYU',
  Vnd = 'VND',
  Zar = 'ZAR'
}

export type CurrencyInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  currency?: InputMaybe<Scalars['String']>;
  precision?: InputMaybe<Scalars['Int']>;
};

export type CurrencyObjectType = Node & {
  __typename?: 'CurrencyObjectType';
  code: CurrencyCode;
  flagUnicode?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  symbol: Scalars['String'];
};

export enum CurrencyType {
  Aed = 'aed',
  Ars = 'ars',
  Aud = 'aud',
  Brl = 'brl',
  Cad = 'cad',
  Chf = 'chf',
  Clp = 'clp',
  Cny = 'cny',
  Cop = 'cop',
  Czk = 'czk',
  Dkk = 'dkk',
  Eur = 'eur',
  Gbp = 'gbp',
  Hkd = 'hkd',
  Inr = 'inr',
  Jpy = 'jpy',
  Krw = 'krw',
  Mxn = 'mxn',
  Nok = 'nok',
  Nzd = 'nzd',
  Pen = 'pen',
  Pln = 'pln',
  Ron = 'ron',
  Rub = 'rub',
  Sek = 'sek',
  Sgd = 'sgd',
  Thb = 'thb',
  Try = 'try_',
  Twd = 'twd',
  Uah = 'uah',
  Usd = 'usd',
  Uyu = 'uyu',
  Vnd = 'vnd',
  Zar = 'zar'
}

export type DashboardObjectType = {
  __typename?: 'DashboardObjectType';
  arenaId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  dashboardMetadata?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  requiredRoles?: Maybe<Array<Scalars['String']>>;
  s3ObjectKey: Scalars['String'];
};

export type DataEntryStatus = {
  __typename?: 'DataEntryStatus';
  controllersCompleted: Scalars['Boolean'];
  id: Scalars['Int'];
  ownersCompleted: Scalars['Boolean'];
};

export type DataRoomMetricLabelObjectType = PermissionCheckedNode & {
  __typename?: 'DataRoomMetricLabelObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  labelText: Scalars['String'];
};

export type DataRoomMetricObjectType = PermissionCheckedNode & {
  __typename?: 'DataRoomMetricObjectType';
  dataRoomId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  label: DataRoomMetricLabelObjectType;
  labelId: Scalars['Int'];
  value: Scalars['String'];
};

export type DataRoomObjectType = PermissionCheckedNode & {
  __typename?: 'DataRoomObjectType';
  capEquity?: Maybe<Scalars['Float']>;
  capTotal?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['String']>;
  cocMax?: Maybe<Scalars['Float']>;
  cocMin?: Maybe<Scalars['Float']>;
  cocType?: Maybe<MetricsEstimateType>;
  description?: Maybe<Scalars['String']>;
  /** The data_room_key argument is always required unless the request is for a staff user previewing the data room. Please do not query this field for a list/connection of data rooms. */
  documents: Array<DocumentObjectType>;
  equityMultipleMax?: Maybe<Scalars['Float']>;
  equityMultipleMin?: Maybe<Scalars['Float']>;
  equityMultipleType?: Maybe<MetricsEstimateType>;
  extensionFields: Array<ExtensionFieldTuple>;
  /** The data_room_key argument is always required unless the request is for a staff user previewing the data room. Please do not query this field for a list/connection of data rooms. */
  folderDocuments: Array<FolderDocument>;
  holdTime?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  invitedProspectCount: Scalars['Int'];
  irrMax?: Maybe<Scalars['Float']>;
  irrMin?: Maybe<Scalars['Float']>;
  irrType?: Maybe<MetricsEstimateType>;
  locations: Array<LocationObjectType>;
  metrics: Array<DataRoomMetricObjectType>;
  minimum?: Maybe<Scalars['Int']>;
  nda?: Maybe<NdaObjectType>;
  photos: Array<PhotoObjectType>;
  published?: Maybe<Scalars['DateTime']>;
  requiresLogin: Scalars['Boolean'];
  restrictDownloadOptions: Scalars['Boolean'];
  size?: Maybe<Scalars['Float']>;
  summary?: Maybe<Scalars['String']>;
  unitSize?: Maybe<Scalars['Int']>;
  videoEmbedCode?: Maybe<Scalars['String']>;
};


export type DataRoomObjectTypeDocumentsArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
};


export type DataRoomObjectTypeFolderDocumentsArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
};

export enum DateFilterType {
  Blank = 'blank',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  InRange = 'in_range',
  LessThan = 'less_than',
  NotBlank = 'not_blank',
  NotEquals = 'not_equals'
}

export type DeclinePortalInvitationInput = {
  arenaId: Scalars['String'];
};

export type DeclinePortalInvitationResponse = MutationErrorResponse | MutationSuccessResponse;

export type DeleteContactFilterInput = {
  filterIds: Array<Scalars['String']>;
};

export type DeleteContactsInput = {
  contactIds: Array<Scalars['String']>;
};

export type DeleteDashboardInput = {
  arenaId: Scalars['Int'];
  dashboardId: Scalars['ID'];
};

export type DeleteNotesInput = {
  noteIds: Array<Scalars['ID']>;
};

export type DeletedContactFilterCountObjectType = {
  __typename?: 'DeletedContactFilterCountObjectType';
  deletedCount: Scalars['Int'];
  deletedIds: Array<Scalars['String']>;
};

/** An enumeration. */
export enum DeltaDirection {
  From = 'from_',
  To = 'to_'
}

export type DenyCanopyPaymentBatchInput = {
  paymentBatchId: Scalars['String'];
};

export type DenyPaymentBatchInput = {
  paymentBatchId: Scalars['String'];
  reason: Scalars['String'];
};

/**
 * A graphene object for developer feature flags assigned to their on/off state as boolean
 * i.e. has_networked_portal_asset_map = graphene.Boolean()
 */
export type DevFeatures = {
  __typename?: 'DevFeatures';
  accessProspectSubscriptionPermission: Scalars['Boolean'];
  asyncAuditDownload: Scalars['Boolean'];
  automatedPaymentBatchesSummaryDownload: Scalars['Boolean'];
  automatedPaymentsAchDebit: Scalars['Boolean'];
  automatedPaymentsDistributionsUi: Scalars['Boolean'];
  automatedPaymentsEmailNotifications: Scalars['Boolean'];
  bankAccountSwiftUi: Scalars['Boolean'];
  broadstreetPerformanceOptimization: Scalars['Boolean'];
  bulkAddOrgProspects: Scalars['Boolean'];
  bulkUpdatingCommitmentAmounts: Scalars['Boolean'];
  canopyDistributionPayments: Scalars['Boolean'];
  ccTransactionBatchSingleTransactionEnforcement: Scalars['Boolean'];
  commSettingsModal: Scalars['Boolean'];
  complianceSettingsPanelUpdate: Scalars['Boolean'];
  contactsPortalInvite: Scalars['Boolean'];
  contributionEffectiveDate: Scalars['Boolean'];
  dataRoomRestrictDownloads: Scalars['Boolean'];
  deduplicateAccount: Scalars['Boolean'];
  deprecateLegacyCustomSections: Scalars['Boolean'];
  displayTwilioOutageBanner: Scalars['Boolean'];
  disregardedEntityQuestionnaire: Scalars['Boolean'];
  documentCategoryAdvancedPermissions: Scalars['Boolean'];
  documentFolderSorting: Scalars['Boolean'];
  documentMatchAlgorithmV1: Scalars['Boolean'];
  documentMatchAndShareAlgorithmImprovements: Scalars['Boolean'];
  documentMatchAndSharePerformanceImprovements: Scalars['Boolean'];
  documentMatchAndShareScoreImprovements: Scalars['Boolean'];
  documentMatchAndShareUiFeedback: Scalars['Boolean'];
  docusignWebhookSafeMode: Scalars['Boolean'];
  duplicateTaxIdValidation: Scalars['Boolean'];
  enableCoBrowseFeature: Scalars['Boolean'];
  enableComplianceAutomatedScreening: Scalars['Boolean'];
  enableComplianceEscalationFlow: Scalars['Boolean'];
  enableComplianceKyc: Scalars['Boolean'];
  enableComplianceScreening: Scalars['Boolean'];
  enableDiagonalDocumentWatermarking: Scalars['Boolean'];
  enableEnhancedEditorTemplating: Scalars['Boolean'];
  enableEntitySettings: Scalars['Boolean'];
  enableLpEditPaymentPreferenceAdminSettings: Scalars['Boolean'];
  enableLpQuestionnairePrefill: Scalars['Boolean'];
  enableLpcmCommPrefsSubscription: Scalars['Boolean'];
  enableMicrosoftOauthIntegration: Scalars['Boolean'];
  enableMobileContactFiltering: Scalars['Boolean'];
  enableOperatingCompanies: Scalars['Boolean'];
  enableOutlookAddInAuthNaa: Scalars['Boolean'];
  enablePaginatePositionOwnership: Scalars['Boolean'];
  enableParallelDocumentScore: Scalars['Boolean'];
  enableReactifiedEmailPreview: Scalars['Boolean'];
  enableScheduledEmails: Scalars['Boolean'];
  enableTransferTransactionApi: Scalars['Boolean'];
  enableYtdTtmItdForPcap: Scalars['Boolean'];
  enablementAnnouncements: Scalars['Boolean'];
  enhancedEditorM1: Scalars['Boolean'];
  equitiesPerformanceOptimization: Scalars['Boolean'];
  exportSubsCsv: Scalars['Boolean'];
  externalAuthentication: Scalars['Boolean'];
  faAccountingTab: Scalars['Boolean'];
  gpLoginReact: Scalars['Boolean'];
  gpxIntegrations: Scalars['Boolean'];
  hasGpxKycPaymentsOnboarding: Scalars['Boolean'];
  hasKycInUnifiedPortal: Scalars['Boolean'];
  hasSkippedEmailResend: Scalars['Boolean'];
  hubspotIntegration: Scalars['Boolean'];
  investorPortalUserProfile: Scalars['Boolean'];
  inviteManager: Scalars['Boolean'];
  lpRequestManagementM1: Scalars['Boolean'];
  lpRequestManagementM2: Scalars['Boolean'];
  lpZendeskFeatureFlag: Scalars['Boolean'];
  mainGlDataExportEnabled: Scalars['Boolean'];
  manageActivityStaffPermission: Scalars['Boolean'];
  newInvestorQuestionnairePreview: Scalars['Boolean'];
  newSubscriptionAccountType: Scalars['Boolean'];
  notifyBankAccountChangeDiligence: Scalars['Boolean'];
  offeringsAdvancedPermissions: Scalars['Boolean'];
  offlineGpxOnboarding: Scalars['Boolean'];
  outlookReactifyLogin: Scalars['Boolean'];
  ownershipTabWithNewLogic: Scalars['Boolean'];
  paymentsLpComplianceScreening: Scalars['Boolean'];
  portalAcceptanceEnabled: Scalars['Boolean'];
  portalDatetimeInternationalization: Scalars['Boolean'];
  portalInvitationEnhancements: Scalars['Boolean'];
  preqinIntegration: Scalars['Boolean'];
  privateEdgeTemplateExport: Scalars['Boolean'];
  prospectBaseQueryOptimization: Scalars['Boolean'];
  prospectDocumentShare: Scalars['Boolean'];
  prospectOptimizationSubscriptionFiltering: Scalars['Boolean'];
  publicationsPreviewBulkDownload: Scalars['Boolean'];
  q2AssetDataEnhancementsV2: Scalars['Boolean'];
  queueUploadDocuments: Scalars['Boolean'];
  reactifyMatchAndShare: Scalars['Boolean'];
  recoveryCodes: Scalars['Boolean'];
  showActivityEditedTooltip: Scalars['Boolean'];
  showComplianceCanopyNestedOwnership: Scalars['Boolean'];
  sso: Scalars['Boolean'];
  ssoMilestone2: Scalars['Boolean'];
  subscriptionBulkCountersign: Scalars['Boolean'];
  subscriptionFootnotes: Scalars['Boolean'];
  subscriptionInternationalTax: Scalars['Boolean'];
  subscriptionSubquestions: Scalars['Boolean'];
  subsequentClosingMapping: Scalars['Boolean'];
  swiftPayments: Scalars['Boolean'];
  taxDocDeliveryPreferences: Scalars['Boolean'];
  trxTotalsWithPmx: Scalars['Boolean'];
  turnOffEmailForAccountCommunication: Scalars['Boolean'];
  undeliverableSmsEmailRerouting: Scalars['Boolean'];
  unifiedPortal: Scalars['Boolean'];
  useRefactoredProfileRelationships: Scalars['Boolean'];
  useUpdatedOwnershipQueriesFf: Scalars['Boolean'];
  writeSubsV3States: Scalars['Boolean'];
  zapierIntegration: Scalars['Boolean'];
  zendeskMessagingWidget: Scalars['Boolean'];
};

/** An enumeration. */
export enum DiligenceCategory {
  PaymentsOnboardingFlow = 'payments_onboarding_flow',
  SubscriptionsFlow = 'subscriptions_flow'
}

export type DiligenceDataObjectType = IndividualDiligenceDataObjectType | InstitutionDiligenceDataObjectType;

export type DisableIntegrationInput = {
  provenance: Scalars['String'];
};

export type DisableIntegrationObject = {
  __typename?: 'DisableIntegrationObject';
  job: AsyncJobObjectType;
};

export type DisableIntegrationOrError = DisableIntegrationObject | MutationErrorResponse;

export type DistributionBatchConnection = {
  __typename?: 'DistributionBatchConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<DistributionBatchEdge>;
  pageInfo: PageInfo;
};

export type DistributionBatchEdge = {
  __typename?: 'DistributionBatchEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DistributionBatchObjectType;
};

/** Corresponds to the DistributionBatchObjectType in the main subgraph. */
export type DistributionBatchObjectType = PermissionCheckedNode & {
  __typename?: 'DistributionBatchObjectType';
  amountPaid?: Maybe<AmountPaidObjectType>;
  amountPaidViaAutomatedPayments: Scalars['Money'];
  arena?: Maybe<ArenaObjectType>;
  batchCategory?: Maybe<Scalars['String']>;
  batchTotalDetails?: Maybe<DistributionBatchTotalDetailsObjectType>;
  dateRecallable?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  distributionCount?: Maybe<Scalars['Int']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<AccountObjectType>;
  excludeFromYield?: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  instances: Array<DistributionObjectType>;
  noun?: Maybe<Scalars['String']>;
  periodEndDate?: Maybe<Scalars['Date']>;
  published?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  storedTotals: Array<DistributionBatchTotalObjectType>;
  viewRoute?: Maybe<Scalars['String']>;
  waterfall?: Maybe<WaterfallObjectType>;
};

/** An enumeration. */
export enum DistributionBatchObjectTypeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PublishedAsc = 'PUBLISHED_ASC',
  PublishedDesc = 'PUBLISHED_DESC'
}

export type DistributionBatchTotalByTypeObjectType = {
  __typename?: 'DistributionBatchTotalByTypeObjectType';
  amount: Wallet;
  amountRecallable: Wallet;
  transactionType: DistributionTypeObjectType;
};

export type DistributionBatchTotalDetailsObjectType = {
  __typename?: 'DistributionBatchTotalDetailsObjectType';
  amountDistributed: Wallet;
  amountPayable: Wallet;
  batchTotalsByType: Array<DistributionBatchTotalByTypeObjectType>;
  netAmount: Wallet;
};

export type DistributionBatchTotalObjectType = PermissionCheckedNode & {
  __typename?: 'DistributionBatchTotalObjectType';
  amount: Wallet;
  amountRecallable?: Maybe<Wallet>;
  batchId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  typeId: Scalars['Int'];
};

export type DistributionBatchesFilters = {
  arenaIds?: InputMaybe<Array<Scalars['ID']>>;
  automatedPaymentsEnabled?: InputMaybe<Scalars['Boolean']>;
  earliestEffectiveDate?: InputMaybe<Scalars['Date']>;
  entityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type DistributionConnection = {
  __typename?: 'DistributionConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<DistributionEdge>;
  pageInfo: PageInfo;
  totalDistributed: Wallet;
};

export type DistributionDetailObjectType = PermissionCheckedNode & {
  __typename?: 'DistributionDetailObjectType';
  amountDistributed: Wallet;
  amountRecallable: Wallet;
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  instance?: Maybe<DistributionObjectType>;
  instanceId: Scalars['Int'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
  sortOrder?: Maybe<Scalars['Int']>;
  transactionType?: Maybe<DistributionTypeObjectType>;
  typeId: Scalars['Int'];
};

export type DistributionDetailObjectTypeConnection = {
  __typename?: 'DistributionDetailObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<DistributionDetailObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type DistributionDetailObjectTypeEdge = {
  __typename?: 'DistributionDetailObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DistributionDetailObjectType;
};

export type DistributionEdge = {
  __typename?: 'DistributionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DistributionObjectType;
};

export type DistributionFilterInput = {
  filterPayee?: InputMaybe<Scalars['String']>;
  /** Returns only the distributions with a specified payment methods.  */
  filterPaymentMethod?: InputMaybe<Array<PaymentMethodInputEnum>>;
};

export type DistributionMetricsObjectType = {
  __typename?: 'DistributionMetricsObjectType';
  distributionsToDate: Wallet;
  distributionsTotal: Wallet;
};

/** Corresponds to the DistributionObjectType in the main subgraph. */
export type DistributionObjectType = PermissionCheckedNode & {
  __typename?: 'DistributionObjectType';
  amountAdjustments: Wallet;
  amountDistributed?: Maybe<Wallet>;
  amountPayable: Wallet;
  amountRecallable: Wallet;
  amountReinvested: Wallet;
  automatedPayments?: Maybe<Array<PaymentDto>>;
  batch?: Maybe<DistributionBatchObjectType>;
  details?: Maybe<DistributionDetailObjectTypeConnection>;
  email?: Maybe<EmailObjectType>;
  hasAutomatedPayment: Scalars['Boolean'];
  hasPayment: Scalars['Boolean'];
  hideCommitment: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  latestPayment?: Maybe<PaymentObjectType>;
  manualSettlementDate?: Maybe<Scalars['Date']>;
  metrics: DistributionMetricsObjectType;
  netAmount: Wallet;
  netAmountAdjustments: Wallet;
  paymentNotes?: Maybe<Scalars['String']>;
  payments: Array<PaymentObjectType>;
  position?: Maybe<PositionObjectType>;
  uploadedAccountName?: Maybe<Scalars['String']>;
  waterfallStats?: Maybe<WaterfallStatsObjectType>;
};


/** Corresponds to the DistributionObjectType in the main subgraph. */
export type DistributionObjectTypeDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum DistributionObjectTypeSortEnum {
  UploadedAccountNameAsc = 'UPLOADED_ACCOUNT_NAME_ASC',
  UploadedAccountNameDesc = 'UPLOADED_ACCOUNT_NAME_DESC'
}

export type DistributionTypeObjectType = PermissionCheckedNode & {
  __typename?: 'DistributionTypeObjectType';
  excludeFromYield: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  isReturnOfCapital: Scalars['Boolean'];
  isWithholding: Scalars['Boolean'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type DocumentCategoryObjectType = PermissionCheckedNode & {
  __typename?: 'DocumentCategoryObjectType';
  arenaId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  isTaxCategory: Scalars['Boolean'];
  name: Scalars['String'];
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  availableDocumentCategories?: Maybe<Array<Maybe<DocumentCategoryObjectType>>>;
  count?: Maybe<Scalars['Int']>;
  earliestDocumentDate?: Maybe<Scalars['Date']>;
  edges: Array<DocumentEdge>;
  pageInfo: PageInfo;
};

export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DocumentObjectType;
};

export type DocumentEnrichment = {
  contextDependent: Scalars['Boolean'];
  enrichmentType: DocumentEnrichmentType;
};

/** Types of document enrichments. */
export enum DocumentEnrichmentType {
  HtmlWatermark = 'HTML_WATERMARK',
  PasswordProtection = 'PASSWORD_PROTECTION'
}

export type DocumentFolder = PermissionCheckedNode & {
  __typename?: 'DocumentFolder';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  breadcrumb: Array<Breadcrumb>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedById?: Maybe<Scalars['Int']>;
  documents?: Maybe<FolderDocumentConnection>;
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags: Scalars['Int'];
  folderSortRank?: Maybe<Scalars['Int']>;
  folders?: Maybe<DocumentFolderConnection>;
  id: Scalars['ID'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  /** Number of immediate child documents/folders in folder. For portal view only */
  itemCountForPortal: Scalars['Int'];
  metadata?: Maybe<DocumentMetaMap>;
  metadataUpdatedAt?: Maybe<Scalars['DateTime']>;
  metadataUpdatedBy?: Maybe<Scalars['String']>;
  metadataUpdatedById?: Maybe<Scalars['Int']>;
  metadata_?: Maybe<Scalars['JSONString']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  owner?: Maybe<UserObjectType>;
  parent?: Maybe<DocumentFolder>;
  parentFolder?: Maybe<DocumentFolder>;
  parentFolderId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['UUID']>;
  permsAllProspects?: Maybe<Scalars['Boolean']>;
  sharedWithAllProspects?: Maybe<Scalars['Boolean']>;
  sharedWithProspectIds: Array<Scalars['Int']>;
  soaId: Scalars['UUID'];
  state: DocumentFolderState;
};


export type DocumentFolderDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<FolderDocumentSortEnum>>>;
};


export type DocumentFolderFoldersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  depth?: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  objectId?: InputMaybe<Scalars['Int']>;
  objectType?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DocumentFolderSortEnum>;
};


export type DocumentFolderItemCountForPortalArgs = {
  objectId?: InputMaybe<Scalars['Int']>;
  objectType?: InputMaybe<Scalars['Int']>;
};

export type DocumentFolderConnection = {
  __typename?: 'DocumentFolderConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<DocumentFolderEdge>;
  pageInfo: PageInfo;
};

export type DocumentFolderEdge = {
  __typename?: 'DocumentFolderEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DocumentFolder;
};

export enum DocumentFolderSortEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FolderSortRankAsc = 'FOLDER_SORT_RANK_ASC',
  FolderSortRankDesc = 'FOLDER_SORT_RANK_DESC'
}

/** An enumeration. */
export enum DocumentFolderState {
  Created = 'CREATED',
  Deleted = 'DELETED'
}

export type DocumentInfoInput = {
  contentType?: InputMaybe<Scalars['String']>;
  documentPurpose?: InputMaybe<BaseDocumentType>;
  documentType?: InputMaybe<BaseDocumentType>;
  fileName: Scalars['String'];
  metadata?: InputMaybe<Array<DocumentValueInput>>;
  s3Key: Scalars['String'];
};

export type DocumentMetaMap = {
  __typename?: 'DocumentMetaMap';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type DocumentObjectType = PermissionCheckedNode & {
  __typename?: 'DocumentObjectType';
  /** @deprecated This field does not gracefully handle multiple accounts in the response. If the contact is on multiple accounts that have access to the document, this field only returns one of them. Use userAccountsWithDocumentAccess instead */
  account?: Maybe<AccountObjectType>;
  arena?: Maybe<ArenaObjectType>;
  category?: Maybe<DocumentCategoryObjectType>;
  currentVersion?: Maybe<DocumentVersionObjectType>;
  downloadName: Scalars['String'];
  downloadUrl?: Maybe<Scalars['String']>;
  entity?: Maybe<AccountObjectType>;
  /** Indicates if the user has access to this document through more than 1 entity */
  hasAccessViaMultipleEntities?: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Indicates if download options for document should be restricted. Currently only implemented for data room documents */
  isDownloadRestricted: Scalars['Boolean'];
  name: Scalars['String'];
  nda?: Maybe<NdaObjectType>;
  ndaId?: Maybe<Scalars['Int']>;
  offering?: Maybe<OfferingObjectType>;
  previewUrl?: Maybe<Scalars['String']>;
  sortingDate?: Maybe<Scalars['Date']>;
  /** A list of accounts that the current user is linked to that grant the user access to this document */
  userAccountsWithDocumentAccess?: Maybe<Array<AccountObjectType>>;
};


export type DocumentObjectTypeCategoryArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
};


export type DocumentObjectTypeCurrentVersionArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
};


export type DocumentObjectTypeDownloadUrlArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
  downloadKey?: InputMaybe<Scalars['String']>;
  viewToken?: InputMaybe<Scalars['String']>;
};


export type DocumentObjectTypeIsDownloadRestrictedArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
};


export type DocumentObjectTypePreviewUrlArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
  downloadKey?: InputMaybe<Scalars['String']>;
  viewToken?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum DocumentObjectTypeSortEnum {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NdaIdAsc = 'NDA_ID_ASC',
  NdaIdDesc = 'NDA_ID_DESC',
  SortingDateAsc = 'SORTING_DATE_ASC',
  SortingDateDesc = 'SORTING_DATE_DESC'
}

export type DocumentPurposeType = {
  __typename?: 'DocumentPurposeType';
  baseType: BaseDocumentType;
  complianceObjectType?: Maybe<ComplianceObjectType>;
  expires?: Maybe<Scalars['Boolean']>;
};

export type DocumentShareObjectType = PermissionCheckedNode & {
  __typename?: 'DocumentShareObjectType';
  documentId: Scalars['Int'];
  downloadKey: Scalars['String'];
  email?: Maybe<EmailObjectType>;
  firstDownloaded?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  lastDownloaded?: Maybe<Scalars['DateTime']>;
  lastDownloadedVersion?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
  objectType: Scalars['Int'];
};

export enum DocumentStatus {
  Invalid = 'invalid',
  MakerChecked = 'maker_checked',
  New = 'new',
  Verified = 'verified'
}

export type DocumentType = {
  __typename?: 'DocumentType';
  contentType?: Maybe<Scalars['String']>;
  documentPurpose?: Maybe<DocumentPurposeType>;
  documentPurposeId: Scalars['Int'];
  /** @deprecated Renamed to document_purpose */
  documentType: DocumentPurposeType;
  /** @deprecated Renamed to document_purpose_id */
  documentTypeId: Scalars['Int'];
  id: Scalars['Int'];
  metadata?: Maybe<Array<DocumentValueType>>;
  name: Scalars['String'];
  profileId?: Maybe<Scalars['Int']>;
  s3Link: Scalars['String'];
  status: DocumentStatus;
};

export type DocumentUpdateInfoInput = {
  documentPurpose?: InputMaybe<BaseDocumentType>;
  documentStatus?: InputMaybe<DocumentStatus>;
  metadata?: InputMaybe<Array<DocumentValueInput>>;
  profileId?: InputMaybe<Scalars['Int']>;
};

export type DocumentUpload = PermissionCheckedNode & {
  __typename?: 'DocumentUpload';
  extension?: Maybe<Scalars['String']>;
  fileHash: Scalars['String'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  replacedAt?: Maybe<Scalars['DateTime']>;
  s3ObjectKey: Scalars['String'];
  size: Scalars['Int'];
  uploadVersion: Scalars['Int'];
  uploadedAt: Scalars['DateTime'];
  uploadedBy: Scalars['ID'];
  uploadedFilesystemName: Scalars['String'];
};

export type DocumentValueInput = {
  key: Scalars['String'];
  type?: InputMaybe<ValueTypeEnum>;
  value: Scalars['String'];
};

export type DocumentValueType = {
  __typename?: 'DocumentValueType';
  key: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentVersionObjectType = PermissionCheckedNode & {
  __typename?: 'DocumentVersionObjectType';
  contentType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  directDownloadUrl?: Maybe<Scalars['String']>;
  downloadUrl: Scalars['String'];
  embedUrl: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Whether the document is one of the supported types for document preview */
  isPreviewable: Scalars['Boolean'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type DocumentViewObjectType = PermissionCheckedNode & {
  __typename?: 'DocumentViewObjectType';
  contactId?: Maybe<Scalars['Int']>;
  documentId: Scalars['Int'];
  firstOpened?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  lastOpened?: Maybe<Scalars['DateTime']>;
  lastOpenedVersion?: Maybe<Scalars['Int']>;
};

export type DocusignDataObjectType = {
  __typename?: 'DocusignDataObjectType';
  designatedSigner?: Maybe<Scalars['String']>;
  docusignErrorCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uiMessage?: Maybe<Scalars['String']>;
};

export type ESignatureEntityEnvelopeDetails = {
  __typename?: 'ESignatureEntityEnvelopeDetails';
  entityId: Scalars['ID'];
  entityName: Scalars['String'];
  positionTitles: Array<Scalars['String']>;
  requestTemplateId: Scalars['ID'];
  requestTemplateTitle: Scalars['String'];
};

export type ESignatureOfferingEnvelopeDetails = {
  __typename?: 'ESignatureOfferingEnvelopeDetails';
  offeringId: Scalars['ID'];
  offeringName: Scalars['String'];
  prospectTitles: Array<Scalars['String']>;
  requestTemplateId: Scalars['ID'];
  requestTemplateTitle: Scalars['String'];
};

export type EditCustomSectionFootnotesInput = {
  arenaId: Scalars['Int'];
  footnotes: Array<FootnoteInput>;
  sectionId: Scalars['StrawberryObjectId'];
};

export type EditCustomSectionLogicInput = {
  arenaId: Scalars['Int'];
  enablingLogic: Scalars['JsonLogicRule'];
  sectionId: Scalars['StrawberryObjectId'];
};

export type EditExtensionFieldErrorObjectType = {
  __typename?: 'EditExtensionFieldErrorObjectType';
  fieldError?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
};

export type EditExtensionFieldsErrors = EditExtensionFieldsResult | MutationErrorResponse;

export type EditExtensionFieldsInput = {
  extensionFields: Array<ExtensionFieldInput>;
  objectId: Scalars['String'];
  objectType: JsqObjectType;
};

export type EditExtensionFieldsResult = {
  __typename?: 'EditExtensionFieldsResult';
  fields: Array<EditExtensionFieldErrorObjectType>;
};

export type EditionObjectType = PermissionCheckedNode & {
  __typename?: 'EditionObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  label: Scalars['String'];
  name: ArenaEdition;
};

export type EditorTemplateObjectMapObjectType = PermissionCheckedNode & {
  __typename?: 'EditorTemplateObjectMapObjectType';
  globalObjectId?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  objectId: Scalars['Int'];
  objectType: Scalars['Int'];
  templateId: Scalars['Int'];
};

export type EditorTemplateObjectType = PermissionCheckedNode & {
  __typename?: 'EditorTemplateObjectType';
  createdAt: Scalars['DateTime'];
  htmlBody: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  objectMaps?: Maybe<Array<Maybe<EditorTemplateObjectMapObjectType>>>;
  objectType?: Maybe<Scalars['Int']>;
  owner?: Maybe<UserObjectType>;
  savedToAllObjects: Scalars['Boolean'];
  templateMetadata: Scalars['JSONString'];
  templateType: EditorTemplateType;
};

export type EditorTemplateOrError = EditorTemplateObjectType | MutationErrorResponse;

/** An enumeration. */
export enum EditorTemplateType {
  CapitalCallReminder = 'CAPITAL_CALL_REMINDER',
  DataRoomInvitation = 'DATA_ROOM_INVITATION',
  ElectronicDeliveryConsent = 'ELECTRONIC_DELIVERY_CONSENT',
  EmailAccount = 'EMAIL_ACCOUNT',
  EmailAccountStatement = 'EMAIL_ACCOUNT_STATEMENT',
  EmailCapitalActivityNotice = 'EMAIL_CAPITAL_ACTIVITY_NOTICE',
  EmailCapitalCallNotice = 'EMAIL_CAPITAL_CALL_NOTICE',
  EmailContact = 'EMAIL_CONTACT',
  EmailDistributionNotice = 'EMAIL_DISTRIBUTION_NOTICE',
  EmailEntity = 'EMAIL_ENTITY',
  EmailInvestorReport = 'EMAIL_INVESTOR_REPORT',
  EmailManagementFeeNotice = 'EMAIL_MANAGEMENT_FEE_NOTICE',
  EmailNetAssetValueStatement = 'EMAIL_NET_ASSET_VALUE_STATEMENT',
  EmailNetIncomeNotice = 'EMAIL_NET_INCOME_NOTICE',
  EmailNetNotice = 'EMAIL_NET_NOTICE',
  EmailOffering = 'EMAIL_OFFERING',
  KycRequestBatch = 'KYC_REQUEST_BATCH',
  KycRequestVerification = 'KYC_REQUEST_VERIFICATION',
  PortalInvitation = 'PORTAL_INVITATION',
  PortalUpgrade = 'PORTAL_UPGRADE',
  ProvideDisregardedEntityInfo = 'PROVIDE_DISREGARDED_ENTITY_INFO',
  PublicationAccountStatement = 'PUBLICATION_ACCOUNT_STATEMENT',
  PublicationCapitalActivityNotice = 'PUBLICATION_CAPITAL_ACTIVITY_NOTICE',
  PublicationCapitalCallNotice = 'PUBLICATION_CAPITAL_CALL_NOTICE',
  PublicationDistributionNotice = 'PUBLICATION_DISTRIBUTION_NOTICE',
  PublicationDistributionNoticeSummary = 'PUBLICATION_DISTRIBUTION_NOTICE_SUMMARY',
  PublicationInvestorReport = 'PUBLICATION_INVESTOR_REPORT',
  PublicationManagementFeeNotice = 'PUBLICATION_MANAGEMENT_FEE_NOTICE',
  PublicationNetAssetValueStatement = 'PUBLICATION_NET_ASSET_VALUE_STATEMENT',
  PublicationNetIncomeNotice = 'PUBLICATION_NET_INCOME_NOTICE',
  PublicationNetNoticeCapitalCall = 'PUBLICATION_NET_NOTICE_CAPITAL_CALL',
  PublicationNetNoticeDistribution = 'PUBLICATION_NET_NOTICE_DISTRIBUTION',
  PublicationNetNoticeNetCapitalCall = 'PUBLICATION_NET_NOTICE_NET_CAPITAL_CALL',
  PublicationNetNoticeNetDistribution = 'PUBLICATION_NET_NOTICE_NET_DISTRIBUTION',
  PurchaseKyc = 'PURCHASE_KYC',
  SubscriptionConfirmation = 'SUBSCRIPTION_CONFIRMATION',
  SubscriptionInvitation = 'SUBSCRIPTION_INVITATION',
  UpdatePaymentPrefs = 'UPDATE_PAYMENT_PREFS'
}

export type EmailAddressInfoObjectType = {
  __typename?: 'EmailAddressInfoObjectType';
  emailAddress?: Maybe<Scalars['String']>;
  error?: Maybe<EmailErrorEnum>;
  name?: Maybe<Scalars['String']>;
};

export type EmailAddressObjectTypeBase = {
  emailAddress: Scalars['String'];
  emailType?: Maybe<EmailType>;
};

export type EmailAttachmentObjectType = DocumentObjectType | RestrictedDocumentObjectType;

export type EmailBatchRecipientsVerificationInput = {
  emailBatchId: Scalars['ID'];
};

export type EmailBatchRecipientsVerificationJobOrError = AsyncJobObjectType | MutationErrorResponse;

export type EmailBatchSendInput = {
  emailBatchId: Scalars['ID'];
  recipientIdList: Array<InputMaybe<Scalars['Int']>>;
  saveEmailAsTemplate?: InputMaybe<Scalars['Boolean']>;
  scheduledSendTime?: InputMaybe<Scalars['DateTime']>;
};

export type EmailBatchSendResponse = AsyncJobObjectType | MutationErrorResponse;

export type EmailDistributionListObjectType = PermissionCheckedNode & {
  __typename?: 'EmailDistributionListObjectType';
  canInvestorsManage: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  documentCategories: Array<Maybe<DocumentCategoryObjectType>>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  newContactDefault: Scalars['Boolean'];
  sortOrder: Scalars['Int'];
};

export enum EmailErrorEnum {
  ContactDeleted = 'CONTACT_DELETED',
  InvalidEmail = 'INVALID_EMAIL',
  NoEmailAddress = 'NO_EMAIL_ADDRESS',
  NoRecipients = 'NO_RECIPIENTS'
}

export type EmailObjectType = PermissionCheckedNode & {
  __typename?: 'EmailObjectType';
  arena?: Maybe<ArenaObjectType>;
  attachments: Array<ActivityAttachmentObjectType>;
  batchType?: Maybe<JsqObjectType>;
  bodyHtml: Scalars['String'];
  bodyText?: Maybe<Scalars['String']>;
  dateSent?: Maybe<Scalars['DateTime']>;
  emailType?: Maybe<EmailType>;
  errorCount?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  investmentName?: Maybe<Scalars['String']>;
  lastError?: Maybe<Scalars['String']>;
  recipientInfo?: Maybe<RecipientInfoObjectType>;
  recipients?: Maybe<EmailRecipientObjectTypeConnection>;
  regarding?: Maybe<RegardingObjectType>;
  sendFailed?: Maybe<Scalars['Boolean']>;
  senderEmailAddress?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
};


export type EmailObjectTypeRecipientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type EmailPreviewObjectType = {
  __typename?: 'EmailPreviewObjectType';
  attachedDocuments?: Maybe<Array<EmailAttachmentObjectType>>;
  bccAddresses?: Maybe<Array<EmailAddressInfoObjectType>>;
  bodyHtml?: Maybe<Scalars['String']>;
  ccAddresses?: Maybe<Array<EmailAddressInfoObjectType>>;
  errors?: Maybe<Array<EmailErrorEnum>>;
  excludedAttachments?: Maybe<Array<EmailAttachmentObjectType>>;
  fromAddress: EmailAddressInfoObjectType;
  missingAttachments?: Maybe<Array<EmailAttachmentObjectType>>;
  recipientTitle?: Maybe<Scalars['String']>;
  signatureHtml?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  toAddresses?: Maybe<Array<EmailAddressInfoObjectType>>;
};

export type EmailRecipientObjectType = PermissionCheckedNode & {
  __typename?: 'EmailRecipientObjectType';
  contactId?: Maybe<Scalars['Int']>;
  dateSent?: Maybe<Scalars['DateTime']>;
  deliverStatusCode?: Maybe<Scalars['Int']>;
  deliverStatusMessage?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  firstOpened?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  lastError?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recipientType: EmailRecipientType;
};

export type EmailRecipientObjectTypeConnection = {
  __typename?: 'EmailRecipientObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<EmailRecipientObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type EmailRecipientObjectTypeEdge = {
  __typename?: 'EmailRecipientObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: EmailRecipientObjectType;
};

/** An enumeration. */
export enum EmailRecipientType {
  Bcc = 'BCC',
  Cc = 'CC',
  To = 'TO'
}

/** Enumeration that defines the types of email addresses.  Used in ContactEmailModel. */
export enum EmailType {
  Personal = 'personal',
  Unknown = 'unknown',
  Work = 'work'
}

export type EnableAutomatedPaymentsForEntity = {
  __typename?: 'EnableAutomatedPaymentsForEntity';
  entity?: Maybe<AccountObjectType>;
};

export type EntitiesFilters = {
  /** Restrict to just certain arenas; only really applicable for admin users */
  arenaIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Partial match filter by the name of the entity's arena */
  arenaSearchTerm?: InputMaybe<Scalars['String']>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** List of compliance programs to include */
  compliancePrograms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hasBankAccount?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isFundAdmin?: InputMaybe<Scalars['Boolean']>;
  metricsAsOfDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Partial match filter by the name of the entity */
  nameSearchTerm?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<InvestmentEntityStatus>;
  subtypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EntitiesGlBatchSyncStatusResponse = {
  __typename?: 'EntitiesGLBatchSyncStatusResponse';
  entityId: Scalars['Int'];
  glBatchSyncStatuses: Array<GlBatchSyncStatus>;
};

export type EntitiesPageGridObjectType = {
  __typename?: 'EntitiesPageGridObjectType';
  filteredCount: Scalars['Int'];
  rows: Array<AccountObjectType>;
  totalCount: Scalars['Int'];
};

export type EntitiesSort = {
  field: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};

export type EntityCollectionObjectType = PermissionCheckedNode & {
  __typename?: 'EntityCollectionObjectType';
  entities?: Maybe<AccountObjectTypeConnection>;
  entitiesFromCanopy?: Maybe<AccountObjectTypeConnection>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type EntityCollectionObjectTypeEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fundAdministeredOnly?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
};


export type EntityCollectionObjectTypeEntitiesFromCanopyArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fundAdministeredOnly?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
};

export type EntityConnection = {
  __typename?: 'EntityConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<EntityEdge>;
  pageInfo: PageInfo;
  totals: Array<EntityTotalObjectType>;
};

export type EntityEdge = {
  __typename?: 'EntityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AccountObjectType;
};

export type EntityGlSyncDetail = {
  __typename?: 'EntityGLSyncDetail';
  active: Scalars['Boolean'];
  batchPeriod?: Maybe<BatchPeriod>;
  glAsOfDate?: Maybe<Scalars['Date']>;
  glStartDate?: Maybe<Scalars['Date']>;
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastSyncUserId?: Maybe<Scalars['Int']>;
  lastSyncUserName?: Maybe<Scalars['String']>;
  newBatchCount: Scalars['Int'];
  newTransactionCount: Scalars['Int'];
  revisedBatchCount: Scalars['Int'];
  revisedTransactionCount: Scalars['Int'];
  syncedGlBatchCount: Scalars['Int'];
};

export type EntityGlSyncDetails = {
  __typename?: 'EntityGLSyncDetails';
  capitalCallDetails?: Maybe<EntityGlSyncDetail>;
  capitalTransactionDetails?: Maybe<EntityGlSyncDetail>;
  distributionDetails?: Maybe<EntityGlSyncDetail>;
  entityId: Scalars['Int'];
  netIncomeDetails?: Maybe<EntityGlSyncDetail>;
  transferBatchDetails?: Maybe<EntityGlSyncDetail>;
};

export type EntityGlSyncSettingsInput = {
  capitalActivitySettings?: InputMaybe<Array<CapitalActivityGlSettingsInput>>;
  entityId: Scalars['Int'];
  netIncomeSettings: NetIncomeGlSettingsInput;
  transferSettings?: InputMaybe<CapitalActivityGlSettingsInput>;
};

export type EntityGlSyncSettingsResponse = {
  __typename?: 'EntityGLSyncSettingsResponse';
  capitalCallSettings?: Maybe<CapitalActivityGlSettingsResponse>;
  capitalTransactionSettings?: Maybe<CapitalActivityGlSettingsResponse>;
  distributionSettings?: Maybe<CapitalActivityGlSettingsResponse>;
  netIncomeSettings?: Maybe<NetIncomeGlSettingsResponse>;
  transferSettings?: Maybe<CapitalActivityGlSettingsResponse>;
};

export type EntityGlSyncStatusResponse = {
  __typename?: 'EntityGLSyncStatusResponse';
  capitalCallStatus?: Maybe<SyncStatus>;
  capitalTransactionStatus?: Maybe<SyncStatus>;
  distributionStatus?: Maybe<SyncStatus>;
  entityId: Scalars['Int'];
  netIncomeStatus?: Maybe<SyncStatus>;
  status?: Maybe<SyncStatus>;
};

export type EntityInheritedDescription = {
  __typename?: 'EntityInheritedDescription';
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['Int']>;
  entityName?: Maybe<Scalars['String']>;
};

export type EntityPreviewResult = {
  __typename?: 'EntityPreviewResult';
  capitalCall: TransactionCategoryPreviewData;
  capitalTransaction: TransactionCategoryPreviewData;
  distribution: TransactionCategoryPreviewData;
  netIncome: TransactionCategoryPreviewData;
};

export type EntitySection = {
  __typename?: 'EntitySection';
  /** A list of section ids that this section depends on. */
  dependsOnSectionIds: Array<Scalars['StrawberryObjectId']>;
  hasUnpublishedChanges: Scalars['Boolean'];
  included: Scalars['Boolean'];
  section: Section;
};

export type EntitySectionEnabledStatusInput = {
  enabled: Scalars['Boolean'];
  sectionId: Scalars['StrawberryObjectId'];
};

export type EntitySoiInput = {
  arenaId: Scalars['Int'];
  asOfDate?: InputMaybe<Scalars['Date']>;
  entityId: Scalars['Int'];
};

/** An enumeration. */
export enum EntityStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED'
}

export type EntityStoredTotalsObjectType = {
  __typename?: 'EntityStoredTotalsObjectType';
  amount: Wallet;
  batchDate: Scalars['Date'];
  batchId: Scalars['ID'];
  niType?: Maybe<Scalars['String']>;
  typeId: Scalars['ID'];
};

export type EntitySubTypeObjectType = PermissionCheckedNode & {
  __typename?: 'EntitySubTypeObjectType';
  hidden: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EntitySubscriptionSettings = {
  __typename?: 'EntitySubscriptionSettings';
  customSections: Array<EntitySection>;
  id: Scalars['ID'];
  supportingDocuments: Array<EntitySection>;
};

export type EntitySubscriptionSettingsMutationErrorResponse = EntitySubscriptionSettings | MutationErrorResponse;

export type EntityTotalObjectType = {
  __typename?: 'EntityTotalObjectType';
  currency: CurrencyObjectType;
  investorCapitalOut: Wallet;
  investorCommitted: Wallet;
  investorContributions: Wallet;
  investorPositionCount: Scalars['Int'];
  status?: Maybe<InvestmentEntityStatus>;
};

export enum EntityTotalsPeriodOptions {
  CurrentPeriod = 'current_period',
  PreviousPeriod = 'previous_period'
}

export type EntityTransactionTotalsObjectType = {
  __typename?: 'EntityTransactionTotalsObjectType';
  asOfDate?: Maybe<Scalars['Date']>;
  capitalAccountBalance?: Maybe<Wallet>;
  hasAnyPublished?: Maybe<Scalars['Boolean']>;
  hasAnyUnpublished?: Maybe<Scalars['Boolean']>;
  investedEquityBalance: Wallet;
  lifetimeAmountCalled: Wallet;
  lifetimeAmountDistributed: Wallet;
  lifetimeAmountPassedThrough: Wallet;
  lifetimeAmountReceived: Wallet;
  lifetimeAmountReturned: Wallet;
  lifetimeCapitalContributed: Wallet;
  lifetimeCapitalContributedAgainstCommitment: Wallet;
  lifetimeCapitalOut: Wallet;
  lifetimeUncalledContributionsAgainstCommitment: Wallet;
  lifetimeWithheld: Wallet;
  navBalance?: Maybe<Wallet>;
};

export type EntityTransferGlBatchSyncDetail = {
  __typename?: 'EntityTransferGlBatchSyncDetail';
  history: Array<GlBatch>;
  unsynced: Array<GlBatch>;
};

export type EnvelopeDetails = ESignatureEntityEnvelopeDetails | ESignatureOfferingEnvelopeDetails | SubscriptionEmbeddedEnvelopeDetails;

export type EnvelopePollObjectType = {
  __typename?: 'EnvelopePollObjectType';
  complete?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum EscalationVerdict {
  Accept = 'Accept',
  Reject = 'Reject'
}

export type ExtensionFieldConnection = {
  __typename?: 'ExtensionFieldConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ExtensionFieldEdge>;
  pageInfo: PageInfo;
};

export type ExtensionFieldDateFilterInput = {
  dateFilterType: DateFilterType;
  extensionFieldId: Scalars['ID'];
  filter: Scalars['Date'];
  filterTo?: InputMaybe<Scalars['Date']>;
};

export type ExtensionFieldDateFilterObjectType = {
  __typename?: 'ExtensionFieldDateFilterObjectType';
  dateFilterType: DateFilterType;
  extensionFieldId: Scalars['ID'];
  filter: Scalars['Date'];
  filterTo?: Maybe<Scalars['Date']>;
};

export type ExtensionFieldEdge = {
  __typename?: 'ExtensionFieldEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ExtensionFieldObjectType;
};

export type ExtensionFieldEnumObjectType = PermissionCheckedNode & {
  __typename?: 'ExtensionFieldEnumObjectType';
  enumName: Scalars['String'];
  enumValueCode: Scalars['String'];
  enumValueLabel: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
};

export type ExtensionFieldFiltersInput = {
  dateFilters?: InputMaybe<Array<ExtensionFieldDateFilterInput>>;
  floatFilters?: InputMaybe<Array<ExtensionFieldFloatFilterInput>>;
  intFilters?: InputMaybe<Array<ExtensionFieldIntegerFilterInput>>;
  textFilters?: InputMaybe<Array<ExtensionFieldTextFilterInput>>;
};

export type ExtensionFieldFiltersObjectType = {
  __typename?: 'ExtensionFieldFiltersObjectType';
  dateFilters?: Maybe<Array<ExtensionFieldDateFilterObjectType>>;
  floatFilters?: Maybe<Array<ExtensionFieldFloatFilterObjectType>>;
  intFilters?: Maybe<Array<ExtensionFieldIntegerFilterObjectType>>;
  textFilters?: Maybe<Array<ExtensionFieldTextFilterObjectType>>;
};

export type ExtensionFieldFloatFilterInput = {
  extensionFieldId: Scalars['ID'];
  filter?: InputMaybe<Scalars['Float']>;
  filterTo?: InputMaybe<Scalars['Float']>;
  numberFilterType: NumberFilterExtendedType;
};

export type ExtensionFieldFloatFilterObjectType = {
  __typename?: 'ExtensionFieldFloatFilterObjectType';
  extensionFieldId: Scalars['ID'];
  filter?: Maybe<Scalars['Float']>;
  filterTo?: Maybe<Scalars['Float']>;
  numberFilterType: NumberFilterExtendedType;
};

export type ExtensionFieldInput = {
  dirty?: InputMaybe<Scalars['Boolean']>;
  extensionFieldId: Scalars['ID'];
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type ExtensionFieldIntegerFilterInput = {
  extensionFieldId: Scalars['ID'];
  filter?: InputMaybe<Scalars['Int']>;
  filterTo?: InputMaybe<Scalars['Int']>;
  numberFilterType: NumberFilterExtendedType;
};

export type ExtensionFieldIntegerFilterObjectType = {
  __typename?: 'ExtensionFieldIntegerFilterObjectType';
  extensionFieldId: Scalars['ID'];
  filter?: Maybe<Scalars['Int']>;
  filterTo?: Maybe<Scalars['Int']>;
  numberFilterType: NumberFilterExtendedType;
};

export type ExtensionFieldObjectType = PermissionCheckedNode & {
  __typename?: 'ExtensionFieldObjectType';
  activeEnumValues?: Maybe<Array<ExtensionFieldEnumObjectType>>;
  enumName?: Maybe<Scalars['String']>;
  enumValues: Array<ExtensionFieldEnumObjectType>;
  extensionFieldTypeId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  isMultivalued?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/** An enumeration. */
export enum ExtensionFieldObjectTypeSortEnum {
  EnumNameAsc = 'ENUM_NAME_ASC',
  EnumNameDesc = 'ENUM_NAME_DESC',
  ExtensionFieldTypeIdAsc = 'EXTENSION_FIELD_TYPE_ID_ASC',
  ExtensionFieldTypeIdDesc = 'EXTENSION_FIELD_TYPE_ID_DESC',
  IsMultivaluedAsc = 'IS_MULTIVALUED_ASC',
  IsMultivaluedDesc = 'IS_MULTIVALUED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type ExtensionFieldTextFilterInput = {
  extensionFieldId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
  textFilterType: TextFilterType;
};

export type ExtensionFieldTextFilterObjectType = {
  __typename?: 'ExtensionFieldTextFilterObjectType';
  extensionFieldId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  textFilterType: TextFilterType;
};

export type ExtensionFieldTuple = {
  __typename?: 'ExtensionFieldTuple';
  extensionField: ExtensionFieldObjectType;
  values: Array<ExtensionFieldValueObjectType>;
};

export type ExtensionFieldValueObjectType = PermissionCheckedNode & {
  __typename?: 'ExtensionFieldValueObjectType';
  currencyValue?: Maybe<Wallet>;
  dateValue?: Maybe<Scalars['Date']>;
  enumValue?: Maybe<ExtensionFieldEnumObjectType>;
  extensionField?: Maybe<ExtensionFieldObjectType>;
  extensionFieldEnum?: Maybe<ExtensionFieldEnumObjectType>;
  floatValue?: Maybe<Scalars['Float']>;
  /** The ID of the object */
  id: Scalars['ID'];
  intValue?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
  stringValue?: Maybe<Scalars['String']>;
};

export type ExternalObjectInput = {
  name?: InputMaybe<Scalars['String']>;
  objectId: Scalars['String'];
  objectType?: InputMaybe<Scalars['String']>;
  objectTypeEnum?: InputMaybe<ComplianceExternalObjectType>;
  service: Scalars['String'];
};

export type ExternalObjectType = {
  __typename?: 'ExternalObjectType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  objectId: Scalars['String'];
  objectType?: Maybe<Scalars['String']>;
  objectTypeEnum?: Maybe<ComplianceExternalObjectType>;
  service: Scalars['String'];
};

export type ExternalSsoConfigurationOutput = {
  __typename?: 'ExternalSsoConfigurationOutput';
  /** The URL to redirect to for external SSO configuration. If not present, an issue occurred. */
  url?: Maybe<Scalars['String']>;
};

export type ExternalSsoConfigurationResponse = ExternalSsoConfigurationOutput | MutationErrorResponse;

export type FieldFilterInput = {
  field: Scalars['String'];
  quantifier?: InputMaybe<SetFilterQuantifier>;
  selected?: InputMaybe<Array<Scalars['String']>>;
};

export type FieldFilterObjectType = {
  __typename?: 'FieldFilterObjectType';
  field: Scalars['String'];
  quantifier?: Maybe<SetFilterQuantifier>;
  selected?: Maybe<Array<Scalars['String']>>;
};

export type File = {
  __typename?: 'File';
  key: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FileInput = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type FolderDocument = PermissionCheckedNode & {
  __typename?: 'FolderDocument';
  calculatedSharedWithAllProspects: Scalars['Boolean'];
  calculatedSharedWithProspectIds: Array<Maybe<Scalars['Int']>>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  currentUpload?: Maybe<DocumentUpload>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy: Scalars['ID'];
  documentCollectionMemberCount?: Maybe<Scalars['Int']>;
  documentShare?: Maybe<DocumentShareObjectType>;
  documentView?: Maybe<DocumentViewObjectType>;
  enrichments?: Maybe<Array<Maybe<DocumentEnrichment>>>;
  folder: DocumentFolder;
  folderId: Scalars['ID'];
  folderSortRank?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isCollection: Scalars['Boolean'];
  latestUploadID?: Maybe<Scalars['ID']>;
  logicalDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['String'];
  shareNotInheritable: Scalars['Boolean'];
  sharedWithAllProspects: Scalars['Boolean'];
  sharedWithContactsCount?: Maybe<Scalars['Int']>;
  sharedWithProspectIds: Array<Maybe<Scalars['Int']>>;
  sortingDate?: Maybe<Scalars['Date']>;
  state?: Maybe<FolderDocumentState>;
};


export type FolderDocumentCurrentUploadArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
};


export type FolderDocumentDocumentShareArgs = {
  objectId?: InputMaybe<Scalars['String']>;
};


export type FolderDocumentDocumentViewArgs = {
  objectId?: InputMaybe<Scalars['String']>;
};

export type FolderDocumentConnection = {
  __typename?: 'FolderDocumentConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<FolderDocumentEdge>;
  pageInfo: PageInfo;
};

export type FolderDocumentEdge = {
  __typename?: 'FolderDocumentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: FolderDocument;
};

/** An enumeration. */
export enum FolderDocumentSortEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FolderSortRankAsc = 'FOLDER_SORT_RANK_ASC',
  FolderSortRankDesc = 'FOLDER_SORT_RANK_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SortingDateAsc = 'SORTING_DATE_ASC',
  SortingDateDesc = 'SORTING_DATE_DESC'
}

/** Pseudo state for document model. Coincides with FolderDocumentState. */
export enum FolderDocumentState {
  Created = 'CREATED',
  Deleted = 'DELETED'
}

export type Footnote = {
  __typename?: 'Footnote';
  body: Scalars['String'];
  id: Scalars['StrawberryObjectId'];
  label: Scalars['String'];
};

export type FootnoteInput = {
  body: Scalars['String'];
  id?: InputMaybe<Scalars['StrawberryObjectId']>;
  label: Scalars['String'];
};

/** An answer for a free-form text field question for a single user. */
export type FreeFormAnswer = {
  __typename?: 'FreeFormAnswer';
  /** The contact to which this answer is bound, if any. */
  contactUUID?: Maybe<Scalars['String']>;
  /** The answer to this revision of the question. */
  text?: Maybe<Scalars['String']>;
};

/** A question allowing a user to enter free-form text as an answer. */
export type FreeFormQuestion = {
  __typename?: 'FreeFormQuestion';
  /** If True, the block or question shows regardless of the subscriber's account type. */
  allSubscriberTypes?: Maybe<Scalars['Boolean']>;
  /** A list of contact-bound answers. */
  answersByContact?: Maybe<Array<Maybe<FreeFormAnswer>>>;
  id?: Maybe<Scalars['Int']>;
  /** If True, the question must be answered in order to proceed to the next subscription step. */
  required?: Maybe<Scalars['Boolean']>;
  /** The revision is incremented whenever the GP modifies the question.  Answers apply only to the revision of their corresponding question. */
  revision?: Maybe<Scalars['Int']>;
  /** If allSubscriberTypes is False, the block or question should only be shown for these subscriber account types. */
  subscriberTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The short title of the question to display. */
  title?: Maybe<Scalars['String']>;
  type?: Maybe<QuestionTypeEnum>;
};

/** An enumeration. */
export enum Frequency {
  Never = 'never',
  Weekly = 'weekly'
}

export enum FromFieldType {
  GlAccount = 'gl_account',
  TransactionType = 'transaction_type'
}

export type GlBatchSyncStatus = {
  __typename?: 'GLBatchSyncStatus';
  glBatchId: Scalars['Int'];
  status?: Maybe<SyncStatus>;
};

export type GlSyncTransactionPreview = {
  __typename?: 'GLSyncTransactionPreview';
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  totalAmount: Scalars['Decimal'];
  transactionTypeName: Scalars['String'];
  transactions: Array<GlTransactionSummary>;
};

export type GlTransactionSummary = {
  __typename?: 'GLTransactionSummary';
  amount: Scalars['Decimal'];
  batchExternalId: Scalars['String'];
  effectiveDate: Scalars['Date'];
  glBatchCategory?: Maybe<Scalars['String']>;
  glBatchDescription?: Maybe<Scalars['String']>;
  glDate: Scalars['Date'];
  includesDeleted: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  positionAccountName?: Maybe<Scalars['String']>;
  transactionTypeName: Scalars['String'];
};

export type GenerateMappingFileGqlInput = {
  arenaId: Scalars['GlobalID'];
  mappingId?: InputMaybe<Scalars['GlobalID']>;
  templateMetadata?: InputMaybe<MappingFileTemplateMetadataGqlInput>;
};

export type GeneratePresignedUrls = {
  __typename?: 'GeneratePresignedUrls';
  presignedUrlObjs?: Maybe<Array<Maybe<PresignedUrlObjectType>>>;
};

export type GetAllDashboardsInput = {
  arenaId: Scalars['Int'];
};

export type GetDashboardByEmbedLocationInput = {
  arenaId: Scalars['Int'];
  embedLocation: Scalars['String'];
};

export type GetDashboardInput = {
  arenaId: Scalars['Int'];
  dashboardId: Scalars['ID'];
};

export type GlBatch = {
  __typename?: 'GlBatch';
  batchDate?: Maybe<Scalars['Date']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastSyncAt?: Maybe<Scalars['Date']>;
  lastSyncUserName?: Maybe<Scalars['String']>;
  positionIds?: Maybe<Array<Scalars['Int']>>;
};

export type GpLiteTransactionTotalsObjectType = {
  __typename?: 'GpLiteTransactionTotalsObjectType';
  contributionsInPeriod: Wallet;
  distributionsInPeriod: Wallet;
  endDateTransactionTotals: TransactionTotalsObjectTypeBase;
  hasHistoricalTransactions: Scalars['Boolean'];
  netIncomesInPeriod: Wallet;
  percentOwnershipAsOfEndDate?: Maybe<Scalars['Float']>;
  publishStatusOfDistributions: PublishStatus;
  publishStatusOfNetIncomes: PublishStatus;
  publishStatusOfTransactions: PublishStatus;
  redemptionsInPeriod: Wallet;
  reportingPeriodEndDate?: Maybe<Scalars['Date']>;
  reportingPeriodStartDate?: Maybe<Scalars['Date']>;
  startDateTransactionTotals: TransactionTotalsObjectTypeBase;
};

export type GpSignedDocumentObjectType = {
  __typename?: 'GpSignedDocumentObjectType';
  gpDocumentName?: Maybe<Scalars['String']>;
  hasInputFields?: Maybe<Scalars['Boolean']>;
  isQuestionnaire: Scalars['Boolean'];
  isTaxForm: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  lpDocumentName?: Maybe<Scalars['String']>;
  lpDocumentUploadedDate?: Maybe<Scalars['String']>;
  lpDocumentUrl?: Maybe<Scalars['String']>;
  lpDocumentVersionId?: Maybe<Scalars['Int']>;
  subscriptionDocumentId: Scalars['Int'];
};

export type GroupConnection = {
  __typename?: 'GroupConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<GroupEdge>;
  pageInfo: PageInfo;
};

export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: GroupObjectType;
};

export type GroupObjectType = PermissionCheckedNode & {
  __typename?: 'GroupObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  roles?: Maybe<RoleObjectTypeConnection>;
  title?: Maybe<Scalars['String']>;
};


export type GroupObjectTypeRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum GroupObjectTypeSortEnum {
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export type HubspotIntegrationOauthUrlType = {
  __typename?: 'HubspotIntegrationOauthUrlType';
  url: Scalars['String'];
};

export type ImportBulkPositions = {
  __typename?: 'ImportBulkPositions';
  job?: Maybe<AsyncJobObjectType>;
};

export type ImportTemplateConfig = {
  __typename?: 'ImportTemplateConfig';
  acquisitions?: Maybe<Scalars['Int']>;
  assetAddresses?: Maybe<Scalars['Int']>;
  currencies?: Maybe<Array<Maybe<CurrencyObjectType>>>;
  dispositions?: Maybe<Scalars['Int']>;
  loans?: Maybe<Scalars['Int']>;
  productTypes?: Maybe<Scalars['Int']>;
};

export type IndividualDiligenceDataObjectType = {
  __typename?: 'IndividualDiligenceDataObjectType';
  dateOfBirth?: Maybe<Scalars['Date']>;
  domicileAddress?: Maybe<AddressObjectType>;
  emailAddress: Scalars['String'];
  employer?: Maybe<Scalars['String']>;
  isUsPerson?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<AddressObjectType>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type IndividualInvestorDataObjectType = {
  __typename?: 'IndividualInvestorDataObjectType';
  dateOfBirth?: Maybe<Scalars['Date']>;
  domicileAddress?: Maybe<AddressObjectType>;
  emailAddress: Scalars['String'];
  employer?: Maybe<Scalars['String']>;
  isUsPerson?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<AddressObjectType>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type IndividualProfileInput = {
  addresses: Array<Address>;
  caseId?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  taxIdType?: InputMaybe<TaxIdType>;
};

export enum IndustryRestrictionLevel {
  Elevated = 'elevated',
  None = 'none',
  Prohibited = 'prohibited'
}

export type IndustryType = {
  __typename?: 'IndustryType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InitiateBankAccountVerificationInput = {
  bankAccountId: Scalars['ID'];
};

export type InitiateBulkCountersignResponse = AsyncJobObjectType | MutationErrorResponse;

export type InitiateCoBrowseInput = {
  /** The user to perform 2FA reset on. */
  emailAddress: Scalars['String'];
  /** If the email address is a lookup code */
  isLookupCode: Scalars['Boolean'];
};

export enum InstitutionAccountTypeEnum {
  Ag = 'ag',
  Corporation = 'corporation',
  Custodian = 'custodian',
  Dac = 'dac',
  EmployeePension = 'employee_pension',
  Field_501c3 = 'field_501c3',
  Foundation = 'foundation',
  Gmbh = 'gmbh',
  Ira = 'ira',
  IraOrNonUs = 'ira_or_non_us',
  IrrevocableTrust = 'irrevocable_trust',
  Joint = 'joint',
  JointOther = 'joint_other',
  Llc = 'llc',
  Lllp = 'lllp',
  Llp = 'llp',
  Ltd = 'ltd',
  NonProfit = 'non_profit',
  Other = 'other',
  Partnership = 'partnership',
  PensionPlan = 'pension_plan',
  Plc = 'plc',
  Ptc = 'ptc',
  RevocableTrust = 'revocable_trust',
  Sa = 'sa',
  Sarl = 'sarl',
  SelfDirectedIra = 'self_directed_ira',
  Sovereign = 'sovereign',
  Spc = 'spc',
  Tic = 'tic',
  Trust = 'trust'
}

export type InstitutionDiligenceDataObjectType = {
  __typename?: 'InstitutionDiligenceDataObjectType';
  dateOfOrganization?: Maybe<Scalars['Date']>;
  domicileAddress?: Maybe<AddressObjectType>;
  mailingAddress?: Maybe<AddressObjectType>;
  name: Scalars['String'];
  organizationJurisdiction?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type InstitutionProfileInput = {
  accountType: InstitutionAccountTypeEnum;
  addresses: Array<Address>;
  caseId?: InputMaybe<Scalars['Int']>;
  dateOfFormation?: InputMaybe<Scalars['DateTime']>;
  legalBusinessName: Scalars['String'];
  taxId?: InputMaybe<Scalars['String']>;
  taxIdType?: InputMaybe<TaxIdType>;
};

/** An enumeration. */
export enum IntegrationDetailsConfigurationStatusEnum {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

/** An enumeration. */
export enum IntegrationDetailsConnectionStatusEnum {
  Connected = 'CONNECTED',
  Denied = 'DENIED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotConnected = 'NOT_CONNECTED'
}

export type IntegrationDetailsObjectType = {
  __typename?: 'IntegrationDetailsObjectType';
  configurationOptions?: Maybe<Scalars['JSONString']>;
  configurationStatus: IntegrationDetailsConfigurationStatusEnum;
  configurationStatusDetails?: Maybe<Scalars['JSONString']>;
  connectionStatus: IntegrationDetailsConnectionStatusEnum;
  connectionStatusDetails: Scalars['JSONString'];
  integrationStatus: IntegrationDetailsStatusEnum;
  provenance: Scalars['String'];
};

/** An enumeration. */
export enum IntegrationDetailsStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  InProgress = 'IN_PROGRESS'
}

export type IntegrationEntityDetailsContactSyncInput = {
  jsqContactIds: Array<Scalars['String']>;
  provenance: Scalars['String'];
  syncType: IntegrationEntityDetailsSyncType;
};

export type IntegrationEntityDetailsContactSyncObjectType = {
  __typename?: 'IntegrationEntityDetailsContactSyncObjectType';
  job?: Maybe<AsyncJobObjectType>;
};

export type IntegrationEntityDetailsContactSyncObjectTypeOrError = IntegrationEntityDetailsContactSyncObjectType | MutationErrorResponse;

export enum IntegrationEntityDetailsSyncType {
  RemoveObjectFromSync = 'remove_object_from_sync',
  SyncObject = 'sync_object'
}

/** An enumeration. */
export enum InterestRateDescription {
  Fixed = 'fixed',
  Floating = 'floating'
}

export type InvalidPrompt = {
  __typename?: 'InvalidPrompt';
  errorMessage: Scalars['String'];
  promptId: Scalars['StrawberryObjectId'];
};

export enum InvestmentEntityStatus {
  Active = 'Active',
  Completed = 'Completed'
}

export type InvestmentTotals = {
  __typename?: 'InvestmentTotals';
  acquisitionDate?: Maybe<Scalars['Date']>;
  cost: WalletGl;
  fairValue: WalletGl;
  unitCost?: Maybe<WalletGl>;
  unitCount?: Maybe<Scalars['Decimal']>;
  unitValue?: Maybe<WalletGl>;
  unrealizedGainOrLoss: WalletGl;
};

export type Investments = {
  __typename?: 'Investments';
  currency: CurrencyType;
  name: Scalars['String'];
  opcoId: Scalars['Int'];
  positionsOwned: Array<Position>;
  totals?: Maybe<InvestmentTotals>;
};

export type InvestorDataObjectType = AccountObjectType | IndividualInvestorDataObjectType | LlcPartnershipInvestorDataObjectType | TrustInvestorDataObjectType;

export type InvestorDiligenceCcLogDumpObjectType = {
  __typename?: 'InvestorDiligenceCCLogDumpObjectType';
  arenaId: Scalars['Int'];
  id: Scalars['Int'];
  investorDiligenceComments?: Maybe<Scalars['String']>;
  kycDetails?: Maybe<Scalars['String']>;
  kycDocuments?: Maybe<Scalars['String']>;
  peerReviewEndDate?: Maybe<Scalars['String']>;
  peerReviewStartDate?: Maybe<Scalars['String']>;
  peerReviewerUserId?: Maybe<Scalars['String']>;
  reviewEndDate?: Maybe<Scalars['String']>;
  reviewStartDate?: Maybe<Scalars['String']>;
  reviewerUserId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  watchlistScreeningDetails?: Maybe<Scalars['String']>;
  watchlistScreeningLogs?: Maybe<Scalars['String']>;
};

export type InvestorDiligenceCommentConnection = {
  __typename?: 'InvestorDiligenceCommentConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<InvestorDiligenceCommentEdge>;
  pageInfo: PageInfo;
};

export type InvestorDiligenceCommentEdge = {
  __typename?: 'InvestorDiligenceCommentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: InvestorDiligenceCommentObjectType;
};

export type InvestorDiligenceCommentObjectType = PermissionCheckedNode & {
  __typename?: 'InvestorDiligenceCommentObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  comment: Scalars['String'];
  commentType: InvestorDiligenceCommentType;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  investorDiligence?: Maybe<InvestorDiligenceObjectType>;
  investorDiligenceId: Scalars['Int'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
};

/** An enumeration. */
export enum InvestorDiligenceCommentObjectTypeSortEnum {
  ArenaIdAsc = 'ARENA_ID_ASC',
  ArenaIdDesc = 'ARENA_ID_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  CommentTypeAsc = 'COMMENT_TYPE_ASC',
  CommentTypeDesc = 'COMMENT_TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  FlagsAsc = 'FLAGS_ASC',
  FlagsDesc = 'FLAGS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvestorDiligenceIdAsc = 'INVESTOR_DILIGENCE_ID_ASC',
  InvestorDiligenceIdDesc = 'INVESTOR_DILIGENCE_ID_DESC',
  IsNotDeletedAsc = 'IS_NOT_DELETED_ASC',
  IsNotDeletedDesc = 'IS_NOT_DELETED_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC'
}

/** An enumeration. */
export enum InvestorDiligenceCommentType {
  Acknowledgment = 'ACKNOWLEDGMENT',
  Informational = 'INFORMATIONAL'
}

export type InvestorDiligenceObjectObjectType = AccountObjectType | ContactObjectType | SubscriptionObjectType;

export type InvestorDiligenceObjectType = PermissionCheckedNode & {
  __typename?: 'InvestorDiligenceObjectType';
  arenaId: Scalars['Int'];
  category?: Maybe<DiligenceCategory>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  dateSubmitted?: Maybe<Scalars['DateTime']>;
  externalObjectId?: Maybe<Scalars['Int']>;
  externalObjectType?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  investorData?: Maybe<InvestorDataObjectType>;
  investorDiligenceCcLogDump?: Maybe<InvestorDiligenceCcLogDumpObjectType>;
  investorDiligenceComments?: Maybe<InvestorDiligenceCommentConnection>;
  investorDiligenceObject?: Maybe<InvestorDiligenceObjectObjectType>;
  investorDiligenceSteps?: Maybe<InvestorDiligenceStepsObjectType>;
  investorDiligenceType: InvestorDiligenceType;
  isAcknowledgementAllowed: Scalars['Boolean'];
  isHighRiskAcknowledged?: Maybe<Scalars['Boolean']>;
  keyIndividuals?: Maybe<Array<KeyIndividualObjectType>>;
  keyInstitutions?: Maybe<Array<KeyInstitutionObjectType>>;
  kycDetails?: Maybe<KycDetailObjectTypeConnection>;
  kycDocuments?: Maybe<KycDocumentObjectTypeConnection>;
  lexisNexisInfo?: Maybe<LexisNexisInfoObjectType>;
  objectType?: Maybe<JsqObjectType>;
  paymentBanks?: Maybe<Array<PaymentBankObjectType>>;
  peerReviewEndDate?: Maybe<Scalars['DateTime']>;
  peerReviewStartDate?: Maybe<Scalars['DateTime']>;
  peerReviewer?: Maybe<UserObjectType>;
  peerReviewerUserId?: Maybe<Scalars['Int']>;
  reviewEndDate?: Maybe<Scalars['DateTime']>;
  reviewStartDate?: Maybe<Scalars['DateTime']>;
  reviewer?: Maybe<UserObjectType>;
  reviewerUserId?: Maybe<Scalars['Int']>;
  riskScore?: Maybe<RiskScoreObjectType>;
  status: InvestorDiligenceStatus;
  watchlistScreeningDetails?: Maybe<WatchlistScreeningDetailObjectTypeConnection>;
};


export type InvestorDiligenceObjectTypeInvestorDiligenceCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<InvestorDiligenceCommentObjectTypeSortEnum>>>;
};


export type InvestorDiligenceObjectTypeKycDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type InvestorDiligenceObjectTypeKycDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type InvestorDiligenceObjectTypeWatchlistScreeningDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum InvestorDiligenceStatus {
  Confirmed = 'confirmed',
  InPeerReview = 'in_peer_review',
  InReview = 'in_review',
  PendingPeerReview = 'pending_peer_review',
  PendingReview = 'pending_review',
  RequestedLpForInfo = 'requested_lp_for_info',
  Started = 'started'
}

export type InvestorDiligenceStepsObjectType = {
  __typename?: 'InvestorDiligenceStepsObjectType';
  entity?: Maybe<Array<Maybe<Scalars['ID']>>>;
  individuals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  kba?: Maybe<Array<Maybe<Scalars['ID']>>>;
  supplemental?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

/** An enumeration. */
export enum InvestorDiligenceType {
  Kyc = 'kyc',
  Manual = 'manual',
  WatchlistScreening = 'watchlist_screening'
}

export type InvestorGroupObjectType = PermissionCheckedNode & {
  __typename?: 'InvestorGroupObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  entity?: Maybe<AccountObjectType>;
  entityId: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  latestUnitValues: Array<UnitValueObjectType>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<UserObjectType>;
  positions?: Maybe<PositionObjectTypeConnection>;
  sortOrder: Scalars['Int'];
  unit?: Maybe<OwnershipUnitObjectType>;
  unitId: Scalars['Int'];
};


export type InvestorGroupObjectTypePositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type InvestorSignatureRecipientObjectType = {
  __typename?: 'InvestorSignatureRecipientObjectType';
  dateSignedAnchorString?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  entityTitle?: Maybe<Scalars['String']>;
  isDesignatedSigner?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  signatureAnchorString?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type InvitationCountObjectType = {
  __typename?: 'InvitationCountObjectType';
  inviteCount: Scalars['Int'];
  inviteIds: Array<Scalars['String']>;
};

export type InvitationObjectTypeOrError = InvitationCountObjectType | MutationErrorResponse;

export type InviteToDataRoomInput = {
  prospectIds: Array<Scalars['String']>;
};

/** An enumeration. */
export enum JsqObjectType {
  Account = 'account',
  Acquisition = 'acquisition',
  Arena = 'arena',
  Asset = 'asset',
  AssetDateSampledData = 'asset_date_sampled_data',
  BankAccount = 'bank_account',
  BulkCountersign = 'bulk_countersign',
  CapitalCall = 'capital_call',
  CapitalCallBatch = 'capital_call_batch',
  CommPref = 'comm_pref',
  ComplianceProfile = 'compliance_profile',
  ConsolidatedPaymentBatch = 'consolidated_payment_batch',
  Contact = 'contact',
  CrmNotificationList = 'crm_notification_list',
  DataRoom = 'data_room',
  Disposition = 'disposition',
  Distribution = 'distribution',
  DistributionBatch = 'distribution_batch',
  Document = 'document',
  DocumentCategory = 'document_category',
  DocumentFolder = 'document_folder',
  ESignatureDocument = 'e_signature_document',
  ESignatureGpSigner = 'e_signature_gp_signer',
  ESignatureLpConfigurator = 'e_signature_lp_configurator',
  ESignatureLpSigner = 'e_signature_lp_signer',
  ESignatureRequest = 'e_signature_request',
  ESignatureRequestBatch = 'e_signature_request_batch',
  ESignatureRequestRecipient = 'e_signature_request_recipient',
  ESignatureRequestTemplate = 'e_signature_request_template',
  Email = 'email',
  EmailBatch = 'email_batch',
  EntityCollection = 'entity_collection',
  EventLog = 'event_log',
  ExtensionField = 'extension_field',
  FeatureFlag = 'feature_flag',
  FieldAccessRole = 'field_access_role',
  FieldAccessRoleObjectMap = 'field_access_role_object_map',
  FundAdminService = 'fund_admin_service',
  ImportTemplate = 'import_template',
  InvestorDiligence = 'investor_diligence',
  KycDetail = 'kyc_detail',
  KycDocument = 'kyc_document',
  KycDocumentVersion = 'kyc_document_version',
  KycDocumentVersionActivity = 'kyc_document_version_activity',
  Lead = 'lead',
  Loan = 'loan',
  Location = 'location',
  LpConsolidationRequest = 'lp_consolidation_request',
  ManagementFeeBatch = 'management_fee_batch',
  Nav = 'nav',
  NavBatch = 'nav_batch',
  NetIncome = 'net_income',
  NetIncomeBatch = 'net_income_batch',
  Note = 'note',
  Offering = 'offering',
  Payment = 'payment',
  PaymentBatch = 'payment_batch',
  PaymentPref = 'payment_pref',
  Position = 'position',
  ProductType = 'product_type',
  Property = 'property',
  Prospect = 'prospect',
  ProspectContactMap = 'prospect_contact_map',
  Publication = 'publication',
  StoredEmail = 'stored_email',
  Subscription = 'subscription',
  SubscriptionBatch = 'subscription_batch',
  Task = 'task',
  Transaction = 'transaction',
  TransactionBatch = 'transaction_batch',
  TwrPeriodMetric = 'twr_period_metric',
  UnitValue = 'unit_value',
  UnitValueBatch = 'unit_value_batch',
  User = 'user',
  WatchlistScreeningDetail = 'watchlist_screening_detail'
}

/** An enumeration. */
export enum JobStatus {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Queued = 'QUEUED',
  Running = 'RUNNING'
}

/** An enumeration. */
export enum JobType {
  AccountDedup = 'AccountDedup',
  AccountExport = 'AccountExport',
  AccountPositionExport = 'AccountPositionExport',
  ActivityExport = 'ActivityExport',
  AnalystAuditLogExport = 'AnalystAuditLogExport',
  ArenaTransactionsExport = 'ArenaTransactionsExport',
  AssetExport = 'AssetExport',
  AsyncAuditLogDownload = 'AsyncAuditLogDownload',
  AutomatedPaymentsExport = 'AutomatedPaymentsExport',
  BulkPositionImportTask = 'BulkPositionImportTask',
  CapAcctBalExport = 'CapAcctBalExport',
  CapAcctBalStandardExport = 'CapAcctBalStandardExport',
  CapitalCallExport = 'CapitalCallExport',
  CollectionMatching = 'CollectionMatching',
  CommunicationSettingsExport = 'CommunicationSettingsExport',
  ConfigureIntegrationTask = 'ConfigureIntegrationTask',
  ContactCsvExport = 'ContactCSVExport',
  ContactDedup = 'ContactDedup',
  ContactImport = 'ContactImport',
  ContactInvestorReport = 'ContactInvestorReport',
  ContactPositionExport = 'ContactPositionExport',
  ContactReport = 'ContactReport',
  ContributionExport = 'ContributionExport',
  CreateEmailsForBatchSendTask = 'CreateEmailsForBatchSendTask',
  CreateLobChecks = 'CreateLobChecks',
  CrossEntityTransactionImport = 'CrossEntityTransactionImport',
  CurrencyRateBackfill = 'CurrencyRateBackfill',
  DelegatePerfMetrics = 'DelegatePerfMetrics',
  DeleteUploadTransactionFiles = 'DeleteUploadTransactionFiles',
  DisableIntegrationTask = 'DisableIntegrationTask',
  DistributionExport = 'DistributionExport',
  DoWatchlistScreeningForDiligence = 'DoWatchlistScreeningForDiligence',
  DocumentsZip = 'DocumentsZip',
  DownloadArenasTouAndPp = 'DownloadArenasTouAndPP',
  DownloadAssetReport = 'DownloadAssetReport',
  DownloadAsyncJobErrorReport = 'DownloadAsyncJobErrorReport',
  DownloadCapitalAccountBalance = 'DownloadCapitalAccountBalance',
  DownloadPortalAccessData = 'DownloadPortalAccessData',
  ESignatureDownloadExecutedDocument = 'ESignatureDownloadExecutedDocument',
  ESignatureInitiateRequests = 'ESignatureInitiateRequests',
  ESignatureReInitiateRequest = 'ESignatureReInitiateRequest',
  EmailBatchSave = 'EmailBatchSave',
  EmailEncryptSmtpCredentials = 'EmailEncryptSmtpCredentials',
  EnableAuthorization = 'EnableAuthorization',
  ExportAllSubscriptions = 'ExportAllSubscriptions',
  ExtensionFieldExport = 'ExtensionFieldExport',
  ExtensionFieldImport = 'ExtensionFieldImport',
  FinalizeAssetsImport = 'FinalizeAssetsImport',
  FinalizeSubscriptionDocuments = 'FinalizeSubscriptionDocuments',
  GenerateImportAssetsPreview = 'GenerateImportAssetsPreview',
  GeneratePrivateEdgeTemplate = 'GeneratePrivateEdgeTemplate',
  IdentifySubscriptionReadyForBulkCountersign = 'IdentifySubscriptionReadyForBulkCountersign',
  InitiateBulkCountersign = 'InitiateBulkCountersign',
  KycWatchlistExport = 'KycWatchlistExport',
  ManagedEmailDnsInstructionEmail = 'ManagedEmailDnsInstructionEmail',
  ManuallyEditedEntityTransactionsExport = 'ManuallyEditedEntityTransactionsExport',
  MergeDocumentCategories = 'MergeDocumentCategories',
  MigrateOfferingToSubfolders = 'MigrateOfferingToSubfolders',
  NetIncomeExport = 'NetIncomeExport',
  OfferingProspectExport = 'OfferingProspectExport',
  OwnershipExport = 'OwnershipExport',
  PaymentAlertMissingEntries = 'PaymentAlertMissingEntries',
  PaymentAlertStalledEntries = 'PaymentAlertStalledEntries',
  PaymentAutoRefundForOldReturns = 'PaymentAutoRefundForOldReturns',
  PaymentCreateLpCreditsFromGpDebit = 'PaymentCreateLPCreditsFromGPDebit',
  PaymentExport = 'PaymentExport',
  PaymentGatherAndSendNachaFile = 'PaymentGatherAndSendNachaFile',
  PaymentInitiatePrenote = 'PaymentInitiatePrenote',
  PaymentInitiateRefund = 'PaymentInitiateRefund',
  PaymentInstructionChangeReadyForReviewEmail = 'PaymentInstructionChangeReadyForReviewEmail',
  PaymentMoveGpDebitToReady = 'PaymentMoveGPDebitToReady',
  PaymentPrefExport = 'PaymentPrefExport',
  PaymentSettleEntries = 'PaymentSettleEntries',
  PerfMetrics = 'PerfMetrics',
  PortalInvestmentsExport = 'PortalInvestmentsExport',
  PortalInvitationEmail = 'PortalInvitationEmail',
  PortalTransactionsExport = 'PortalTransactionsExport',
  PositionExport = 'PositionExport',
  PreviewAccountCleanup = 'PreviewAccountCleanup',
  PreviewAssetReport = 'PreviewAssetReport',
  PreviewPublication = 'PreviewPublication',
  PreviewPublicationAllRecipients = 'PreviewPublicationAllRecipients',
  ProcessCitNachaReturn = 'ProcessCitNachaReturn',
  PublicationMergeFieldsValidation = 'PublicationMergeFieldsValidation',
  PublishAssetReport = 'PublishAssetReport',
  PublishUploadTransactionFiles = 'PublishUploadTransactionFiles',
  RemoveTransferAccountingPositionAllocations = 'RemoveTransferAccountingPositionAllocations',
  ScheduleOfInvestmentExport = 'ScheduleOfInvestmentExport',
  SendDistributionDataToCrowdStreet = 'SendDistributionDataToCrowdStreet',
  StatsRecord = 'StatsRecord',
  TwrGlobalUserData = 'TWRGlobalUserData',
  TwrGlobalUserDataExport = 'TWRGlobalUserDataExport',
  TwrPeriodMetricsForEntity = 'TWRPeriodMetricsForEntity',
  TwrPeriodMetricsForPosition = 'TWRPeriodMetricsForPosition',
  TransactionDeletion = 'TransactionDeletion',
  TransactionExport = 'TransactionExport',
  TriggerBulkSyncTask = 'TriggerBulkSyncTask',
  /** @deprecated ENG-12545 */
  TurnOnWaterfallCalculationFix = 'TurnOnWaterfallCalculationFix',
  UpdateLobChecks = 'UpdateLobChecks',
  UpdatePositionAllocation = 'UpdatePositionAllocation',
  UpdatePositionAllocationPublication = 'UpdatePositionAllocationPublication',
  UpdatePositionAllocationWaterfall = 'UpdatePositionAllocationWaterfall',
  UpdateProspectContactMapAccessDate = 'UpdateProspectContactMapAccessDate',
  UserRolesExport = 'UserRolesExport',
  VerifyEmailBatchRecipientsTask = 'VerifyEmailBatchRecipientsTask',
  VerifySmtpCred = 'VerifySmtpCred',
  VerifySmtpHost = 'VerifySmtpHost',
  VoidBulkCountersignTask = 'VoidBulkCountersignTask',
  WaterfallCalculations = 'WaterfallCalculations',
  WaterfallError = 'WaterfallError',
  WaterfallPeriodRecords = 'WaterfallPeriodRecords',
  YardiImExport = 'YardiIMExport',
  YardiImImport = 'YardiIMImport',
  YardiImTransactionsImportUi = 'YardiIMTransactionsImportUI',
  YardiVoyagerPaymentsExport = 'YardiVoyagerPaymentsExport',
  ZendeskRequestTicketDetails = 'ZendeskRequestTicketDetails',
  ZendeskUserTag = 'ZendeskUserTag'
}

/** An enumeration. */
export enum KbaStatus {
  Attempted = 'attempted',
  DidNotAttempt = 'did_not_attempt',
  FailedThirdPartyCheck = 'failed_third_party_check',
  Ineligible = 'ineligible',
  PassedThirdPartyCheck = 'passed_third_party_check',
  ThirdPartyError = 'third_party_error'
}

export type KeyIndividualObjectType = {
  __typename?: 'KeyIndividualObjectType';
  alertId?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  hasSentInvite?: Maybe<Scalars['Boolean']>;
  identityDocumentName?: Maybe<Scalars['String']>;
  identityDocumentVersionCreatedAt?: Maybe<Scalars['DateTime']>;
  identityDocumentVersionId?: Maybe<Scalars['Int']>;
  isCurrentUser?: Maybe<Scalars['Boolean']>;
  kbaStatus?: Maybe<KbaStatus>;
  name?: Maybe<Scalars['String']>;
  requestedRedo?: Maybe<RequestRedoActivityObjectType>;
  uuid?: Maybe<Scalars['String']>;
};

export type KeyInstitutionObjectType = {
  __typename?: 'KeyInstitutionObjectType';
  alertId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type KycDetailObjectType = PermissionCheckedNode & {
  __typename?: 'KycDetailObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  externalReferenceKey?: Maybe<Scalars['String']>;
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  info?: Maybe<Scalars['JSONString']>;
  investorDiligence?: Maybe<InvestorDiligenceObjectType>;
  investorDiligenceId: Scalars['Int'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  kbaStatus: KbaStatus;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
  verificationInfo?: Maybe<Scalars['JSONString']>;
};

export type KycDetailObjectTypeConnection = {
  __typename?: 'KycDetailObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<KycDetailObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type KycDetailObjectTypeEdge = {
  __typename?: 'KycDetailObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: KycDetailObjectType;
};

export type KycDocumentObjectType = PermissionCheckedNode & {
  __typename?: 'KycDocumentObjectType';
  arenaId?: Maybe<Scalars['ID']>;
  currentKycDocumentVersion?: Maybe<KycDocumentVersionObjectType>;
  documentInfo?: Maybe<SubscriptionAccountInfoObjectType>;
  documentType: KycDocumentType;
  externalReferenceKey?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  investorDiligenceId: Scalars['Int'];
  kycDocumentActivities?: Maybe<KycDocumentVersionActivityConnection>;
  label?: Maybe<Scalars['String']>;
  status: KycDocumentStatus;
  subscriptionNonSignedLpUploadedDocumentId?: Maybe<Scalars['ID']>;
};


export type KycDocumentObjectTypeKycDocumentActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kycDocumentVersionId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<KycDocumentVersionActivityObjectTypeSortEnum>>>;
};

export type KycDocumentObjectTypeConnection = {
  __typename?: 'KycDocumentObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<KycDocumentObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type KycDocumentObjectTypeEdge = {
  __typename?: 'KycDocumentObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: KycDocumentObjectType;
};

/** An enumeration. */
export enum KycDocumentStatus {
  PassedPeerReview = 'passed_peer_review',
  PassedReview = 'passed_review',
  PendingReview = 'pending_review',
  RequestedLpForInfo = 'requested_lp_for_info',
  RequestedReviewerToRedo = 'requested_reviewer_to_redo'
}

/** An enumeration. */
export enum KycDocumentType {
  AuditedFinancial = 'audited_financial',
  CorporateResolution = 'corporate_resolution',
  File = 'file',
  FormationDocument = 'formation_document',
  IdentityDocument = 'identity_document',
  TaxDocument = 'tax_document'
}

export type KycDocumentVersionActivityConnection = {
  __typename?: 'KycDocumentVersionActivityConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<KycDocumentVersionActivityEdge>;
  pageInfo: PageInfo;
};

export type KycDocumentVersionActivityEdge = {
  __typename?: 'KycDocumentVersionActivityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: KycDocumentVersionActivityObjectType;
};

export type KycDocumentVersionActivityObjectType = PermissionCheckedNode & {
  __typename?: 'KycDocumentVersionActivityObjectType';
  activityType: KycDocumentVersionActivityType;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  kycDocumentVersionId: Scalars['Int'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
};

export type KycDocumentVersionActivityObjectTypeConnection = {
  __typename?: 'KycDocumentVersionActivityObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<KycDocumentVersionActivityObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type KycDocumentVersionActivityObjectTypeEdge = {
  __typename?: 'KycDocumentVersionActivityObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: KycDocumentVersionActivityObjectType;
};

/** An enumeration. */
export enum KycDocumentVersionActivityObjectTypeSortEnum {
  ActivityTypeAsc = 'ACTIVITY_TYPE_ASC',
  ActivityTypeDesc = 'ACTIVITY_TYPE_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  KycDocumentVersionIdAsc = 'KYC_DOCUMENT_VERSION_ID_ASC',
  KycDocumentVersionIdDesc = 'KYC_DOCUMENT_VERSION_ID_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC'
}

/** An enumeration. */
export enum KycDocumentVersionActivityType {
  Comment = 'comment',
  PassPeerReview = 'pass_peer_review',
  PassReview = 'pass_review',
  RequestLpForInfo = 'request_lp_for_info',
  RequestReviewerToRedo = 'request_reviewer_to_redo'
}

export type KycDocumentVersionObjectType = PermissionCheckedNode & {
  __typename?: 'KycDocumentVersionObjectType';
  currentRequestKycDocumentVersionActivity?: Maybe<KycDocumentVersionActivityObjectType>;
  documentVersion?: Maybe<DocumentVersionObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  kycDocumentId: Scalars['Int'];
  kycDocumentVersionActivities?: Maybe<KycDocumentVersionActivityObjectTypeConnection>;
};


export type KycDocumentVersionObjectTypeKycDocumentVersionActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LlcPartnershipInvestorDataObjectType = CommonCompanyInvestorDataInterface & {
  __typename?: 'LLCPartnershipInvestorDataObjectType';
  beneficiaries?: Maybe<Array<Maybe<CounterpartyObjectType>>>;
  dateOfOrganization?: Maybe<Scalars['Date']>;
  domicileAddress?: Maybe<AddressObjectType>;
  isUsPerson?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<AddressObjectType>;
  name: Scalars['String'];
  organizationJurisdiction?: Maybe<Scalars['String']>;
  signatories?: Maybe<Array<Maybe<CounterpartyObjectType>>>;
  taxId?: Maybe<Scalars['String']>;
};

export type LeadObjectType = PermissionCheckedNode & {
  __typename?: 'LeadObjectType';
  amountExpected?: Maybe<Scalars['Float']>;
  country?: Maybe<CountryObjectType>;
  createdAt: Scalars['DateTime'];
  emailAddress: Scalars['String'];
  fullName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  leadSource?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  offering?: Maybe<OfferingObjectType>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum LenderType {
  BalanceSheet = 'balance_sheet',
  Cmbs = 'cmbs',
  DebtFund = 'debt_fund',
  Gse = 'gse',
  InsuranceCompany = 'insurance_company'
}

export type LexisNexisAccountInfoObjectType = {
  __typename?: 'LexisNexisAccountInfoObjectType';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  organizationalJurisdiction?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type LexisNexisIndividualInfoObjectType = {
  __typename?: 'LexisNexisIndividualInfoObjectType';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type LexisNexisInfoObjectType = {
  __typename?: 'LexisNexisInfoObjectType';
  accountInfo?: Maybe<LexisNexisAccountInfoObjectType>;
  individualInfo?: Maybe<Array<Maybe<LexisNexisIndividualInfoObjectType>>>;
};

/** An enumeration. */
export enum LienPosition {
  ANote = 'a_note',
  BNote = 'b_note',
  CNote = 'c_note',
  ConvertibleNote = 'convertible_note',
  Junior = 'junior',
  Mezzanine = 'mezzanine',
  Other = 'other',
  Senior = 'senior',
  Unsecured = 'unsecured'
}

export type LinkedBatchObjectType = CapitalCallBatchObjectType | DistributionBatchObjectType;

/**
 * this is here to give access to asset names and allocations regardless
 * of whether those assets are accessible given the user's permissions, without
 * exposing the asset objects themselves.
 */
export type LoanAssetAllocation = {
  __typename?: 'LoanAssetAllocation';
  allocationPct?: Maybe<Scalars['Float']>;
  assetId: Scalars['ID'];
  assetName: Scalars['String'];
};

/** An enumeration. */
export enum LoanIndex {
  Cmt = 'cmt',
  Libor = 'libor',
  Mta = 'mta',
  Prime = 'prime',
  Sofr = 'sofr'
}

export type LoanMetricObjectType = PermissionCheckedNode & {
  __typename?: 'LoanMetricObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type LoanMetricPeriodConnection = {
  __typename?: 'LoanMetricPeriodConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<LoanMetricPeriodEdge>;
  pageInfo: PageInfo;
};

export type LoanMetricPeriodEdge = {
  __typename?: 'LoanMetricPeriodEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: LoanMetricPeriodObjectType;
};

export type LoanMetricPeriodObjectType = PermissionCheckedNode & {
  __typename?: 'LoanMetricPeriodObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  period: Scalars['Period'];
  values: Array<LoanMetricPeriodValueObjectType>;
};

/** An enumeration. */
export enum LoanMetricPeriodObjectTypeSortEnum {
  PeriodAsc = 'PERIOD_ASC',
  PeriodDesc = 'PERIOD_DESC'
}

export type LoanMetricPeriodValueObjectType = PermissionCheckedNode & {
  __typename?: 'LoanMetricPeriodValueObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  metric: LoanMetricObjectType;
  value: Wallet;
};

export type LoanObjectType = NodeWithTimeSeries & ObjectLevelEditPermissions & PermissionCheckedNode & {
  __typename?: 'LoanObjectType';
  amortizationMonths?: Maybe<Scalars['Int']>;
  assetAllocations?: Maybe<Array<LoanAssetAllocation>>;
  borrowerName?: Maybe<Scalars['String']>;
  canEdit: Scalars['Boolean'];
  canLimitedEdit?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyObjectType>;
  description?: Maybe<Scalars['String']>;
  entity?: Maybe<AccountObjectType>;
  entityIsLender?: Maybe<Scalars['Boolean']>;
  extendedMaturityDate?: Maybe<Scalars['Date']>;
  extensionOptions?: Maybe<Scalars['String']>;
  extensionsGranted?: Maybe<Scalars['String']>;
  finalAmount?: Maybe<Wallet>;
  firstPrincipalPaymentDate?: Maybe<Scalars['Date']>;
  floatingRateFloor?: Maybe<Scalars['Float']>;
  futureFunding?: Maybe<Wallet>;
  /** The ID of the object */
  id: Scalars['ID'];
  initialAmount?: Maybe<Wallet>;
  interestOnlyMonths?: Maybe<Scalars['Int']>;
  interestRateDescription?: Maybe<InterestRateDescription>;
  latestTimeSeries?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesByPeriod?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesProjections?: Maybe<TimeSeriesDateToListTuple>;
  lender?: Maybe<Scalars['String']>;
  lenderType?: Maybe<LenderType>;
  lienPosition?: Maybe<LienPosition>;
  loanIndex?: Maybe<LoanIndex>;
  loanMetricPeriods?: Maybe<LoanMetricPeriodConnection>;
  loanType?: Maybe<LoanType>;
  maturityDate?: Maybe<Scalars['Date']>;
  minimumLoanInterestYield?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originationDate?: Maybe<Scalars['Date']>;
  originationFee?: Maybe<Scalars['Float']>;
  partialReleaseConditions?: Maybe<Scalars['String']>;
  paymentDueDate?: Maybe<Scalars['Date']>;
  paymentType?: Maybe<PaymentType>;
  payoffDate?: Maybe<Scalars['Date']>;
  prepaymentPenalty?: Maybe<Scalars['String']>;
  swapsCaps?: Maybe<Scalars['String']>;
  thirdPartyFee?: Maybe<Scalars['Float']>;
  timeSeries: Array<TimeSeriesDateToListTuple>;
  timeSeriesProjections: Array<TimeSeriesDateToListTuple>;
  totalAmount?: Maybe<Wallet>;
};


export type LoanObjectTypeLatestTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type LoanObjectTypeLatestTimeSeriesByPeriodArgs = {
  period: Scalars['Period'];
  timeSeriesTypeNames: Array<Scalars['FieldName']>;
};


export type LoanObjectTypeLatestTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type LoanObjectTypeLoanMetricPeriodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<LoanMetricPeriodObjectTypeSortEnum>>>;
};


export type LoanObjectTypeTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type LoanObjectTypeTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};

export type LoanObjectTypeConnection = {
  __typename?: 'LoanObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<LoanObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type LoanObjectTypeEdge = {
  __typename?: 'LoanObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: LoanObjectType;
};

/** An enumeration. */
export enum LoanType {
  Bridge = 'bridge',
  BuildToSuit = 'build_to_suit',
  Construction = 'construction',
  Land = 'land',
  LineOfCredit = 'line_of_credit',
  Permanent = 'permanent',
  Rehabilitation = 'rehabilitation'
}

export type LocationFilterInput = {
  coordinates?: InputMaybe<CoordinatesFilter>;
  countryName?: InputMaybe<Scalars['String']>;
  field: Scalars['String'];
  stateName?: InputMaybe<Scalars['String']>;
};

export type LocationFilterObjectType = {
  __typename?: 'LocationFilterObjectType';
  coordinates?: Maybe<CoordinatesFilterObjectType>;
  countryName?: Maybe<Scalars['String']>;
  field: Scalars['String'];
  stateName?: Maybe<Scalars['String']>;
};

/** This is tightly coupled with AssetModel. For address related fields, extend the AddressObjectType interface */
export type LocationObjectType = PermissionCheckedNode & {
  __typename?: 'LocationObjectType';
  asset?: Maybe<AssetObjectType>;
  caption?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryObjectType>;
  countryId?: Maybe<Scalars['Int']>;
  explicitLatLon: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  marketName?: Maybe<Scalars['String']>;
  marketTier?: Maybe<MarketTierEnum>;
  opportunityZone?: Maybe<Scalars['Boolean']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  state?: Maybe<StateObjectType>;
  stateId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  submarketName?: Maybe<Scalars['String']>;
};

export type LocationObjectTypeConnection = {
  __typename?: 'LocationObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<LocationObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type LocationObjectTypeEdge = {
  __typename?: 'LocationObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: LocationObjectType;
};

export type LpNonSignedDocumentObjectType = {
  __typename?: 'LpNonSignedDocumentObjectType';
  contactUuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentType?: Maybe<LpSubscriptionDocumentType>;
  isRemovable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  lpDocumentName?: Maybe<Scalars['String']>;
  lpDocumentUploadedDate?: Maybe<Scalars['String']>;
  lpDocumentVersionId?: Maybe<Scalars['Int']>;
  requestedRedo?: Maybe<RequestRedoActivityObjectType>;
  url?: Maybe<Scalars['String']>;
};

export type LpPrompt = {
  __typename?: 'LpPrompt';
  /** Whether this prompt is applicable for the LP. */
  applicable: Scalars['Boolean'];
  body?: Maybe<Scalars['String']>;
  choices?: Maybe<Array<Choice>>;
  enablingLogic: Scalars['JsonLogicRule'];
  /** Whether this prompt has dependent prompts i.e. answers to this question triggers other conditional prompts. */
  hasDependentPrompts: Scalars['Boolean'];
  id: Scalars['StrawberryObjectId'];
  internalTitle?: Maybe<Scalars['String']>;
  /** Whether this prompt is the last prompt in the section. */
  isFinalPrompt: Scalars['Boolean'];
  promptType: LpResponseType;
  required: Scalars['Boolean'];
};

export type LpPromptsForSection = {
  __typename?: 'LpPromptsForSection';
  id: Scalars['String'];
  progressPercentage: Scalars['Float'];
  prompts: Array<LpPrompt>;
};

export type LpResponse = {
  __typename?: 'LpResponse';
  contactUuid?: Maybe<Scalars['UUID']>;
  files?: Maybe<Array<File>>;
  id: Scalars['StrawberryObjectId'];
  promptId: Scalars['StrawberryObjectId'];
  responseType: LpResponseType;
  sectionId: Scalars['StrawberryObjectId'];
  value?: Maybe<Scalars['String']>;
  valueList?: Maybe<Array<Scalars['String']>>;
  version: Scalars['Int'];
};

export type LpResponseInput = {
  contactUuid?: InputMaybe<Scalars['UUID']>;
  files?: InputMaybe<Array<FileInput>>;
  mainSubscriptionId: Scalars['ID'];
  promptId: Scalars['StrawberryObjectId'];
  responseType: LpResponseType;
  sectionId: Scalars['StrawberryObjectId'];
  value?: InputMaybe<Scalars['String']>;
  valueList?: InputMaybe<Array<Scalars['String']>>;
  version: Scalars['Int'];
};

export enum LpResponseSectionStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  Unstarted = 'UNSTARTED'
}

export enum LpResponseType {
  ChoiceMultiple = 'CHOICE_MULTIPLE',
  ChoiceSingle = 'CHOICE_SINGLE',
  Files = 'FILES',
  NoResponse = 'NO_RESPONSE',
  TextLong = 'TEXT_LONG',
  TextShort = 'TEXT_SHORT'
}

/** An enumeration. */
export enum LpSubscriptionDocumentType {
  AuditedFinancial = 'audited_financial',
  CorporateResolution = 'corporate_resolution',
  File = 'file',
  FormationDocument = 'formation_document',
  IdentityDocument = 'identity_document',
  TaxDocument = 'tax_document'
}

export type ManagementFeeBatchObjectType = PermissionCheckedNode & {
  __typename?: 'ManagementFeeBatchObjectType';
  dateDue: Scalars['Date'];
  description: Scalars['String'];
  entity?: Maybe<AccountObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  noun?: Maybe<Scalars['String']>;
  remittanceInstructions?: Maybe<Scalars['String']>;
  viewRoute?: Maybe<Scalars['String']>;
};

export type ManagementFeeDetailObjectType = PermissionCheckedNode & {
  __typename?: 'ManagementFeeDetailObjectType';
  amount: Wallet;
  /** The ID of the object */
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  transactionType: ManagementFeeTypeObjectType;
};

export type ManagementFeeDetailObjectTypeConnection = {
  __typename?: 'ManagementFeeDetailObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ManagementFeeDetailObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type ManagementFeeDetailObjectTypeEdge = {
  __typename?: 'ManagementFeeDetailObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ManagementFeeDetailObjectType;
};

export type ManagementFeeObjectType = PermissionCheckedNode & {
  __typename?: 'ManagementFeeObjectType';
  amount: Wallet;
  batch?: Maybe<ManagementFeeBatchObjectType>;
  details?: Maybe<ManagementFeeDetailObjectTypeConnection>;
  email?: Maybe<EmailObjectType>;
  hasDefaulted: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  uploadedAccountName?: Maybe<Scalars['String']>;
};


export type ManagementFeeObjectTypeDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManagementFeeObjectTypeConnection = {
  __typename?: 'ManagementFeeObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ManagementFeeObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type ManagementFeeObjectTypeEdge = {
  __typename?: 'ManagementFeeObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ManagementFeeObjectType;
};

export type ManagementFeeTypeGroupObjectType = PermissionCheckedNode & {
  __typename?: 'ManagementFeeTypeGroupObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type ManagementFeeTypeObjectType = PermissionCheckedNode & {
  __typename?: 'ManagementFeeTypeObjectType';
  group: ManagementFeeTypeGroupObjectType;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type ManuallyEditedEntityTransactionsExport = {
  __typename?: 'ManuallyEditedEntityTransactionsExport';
  job?: Maybe<AsyncJobObjectType>;
};

export enum MappingDataValidationErrorType {
  DuplicateFromType = 'DUPLICATE_FROM_TYPE',
  DuplicateSourceTargetCombination = 'DUPLICATE_SOURCE_TARGET_COMBINATION',
  InvalidFromType = 'INVALID_FROM_TYPE',
  InvalidRecallableType = 'INVALID_RECALLABLE_TYPE',
  InvalidSignDirection = 'INVALID_SIGN_DIRECTION',
  InvalidToType = 'INVALID_TO_TYPE',
  MissingFromType = 'MISSING_FROM_TYPE',
  MissingRequiredTarget = 'MISSING_REQUIRED_TARGET',
  MissingToType = 'MISSING_TO_TYPE',
  OtherError = 'OTHER_ERROR'
}

export type MappingFileTemplateMetadataGqlInput = {
  mappingType: MappingTypeEnum;
  sourceId: Scalars['GlobalID'];
  sourceType: FromFieldType;
};

export type MappingGroupType = {
  __typename?: 'MappingGroupType';
  arenaId: Scalars['Int'];
  baseMapping: MappingType;
  baseMappingId: Scalars['Int'];
  createdBy?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  mappingOverrides: Array<MappingType>;
  mappingType: MappingTypeEnum;
  name: Scalars['String'];
  sourceId: Scalars['Int'];
};

export type MappingType = {
  __typename?: 'MappingType';
  arenaId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdUser?: Maybe<UserObjectType>;
  entities?: Maybe<Array<AccountObjectType>>;
  entityIds?: Maybe<Array<Scalars['Int']>>;
  fromField: FromFieldType;
  id: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['Int']>;
  lastUpdatedUser?: Maybe<UserObjectType>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export enum MappingTypeEnum {
  JsqMain = 'jsq_main',
  ScheduleOfInvestment = 'schedule_of_investment',
  SubsequentClosing = 'subsequent_closing'
}

/** An enumeration. */
export enum MarketTierEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary'
}

/** An enumeration. */
export enum MetricsEstimateType {
  Open = 'open',
  Point = 'point',
  Range = 'range'
}

/** An enumeration. */
export enum MobilePlatformType {
  Ios = 'ios'
}

/** An enumeration. */
export enum MonthOrQuarter {
  April = 'april',
  August = 'august',
  December = 'december',
  February = 'february',
  FirstQuarter = 'first_quarter',
  FourthQuarter = 'fourth_quarter',
  January = 'january',
  July = 'july',
  June = 'june',
  March = 'march',
  May = 'may',
  November = 'november',
  October = 'october',
  SecondQuarter = 'second_quarter',
  September = 'september',
  ThirdQuarter = 'third_quarter'
}

/** All selected answers to a multiple choice question by a single user. */
export type MultipleChoiceAnswer = {
  __typename?: 'MultipleChoiceAnswer';
  /** The contact to which this answer is bound, if any. */
  contactUUID?: Maybe<Scalars['String']>;
  /** List of selected choice IDs */
  selected?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/**
 * A multiple choice question.
 *
 * There is some looseness in the underlying system where a user can select "can select multiple" and "show none of
 * the above" and both select a normal answer and none.  Similarly, if they can't select multiple but the field is
 * not required and there is no "none of the above" option, there isn't a way to uncheck a radio button without
 * additional UI.
 *
 * It would be better to make the "none of the above" option be derived from whether the field is required and whether
 * selecting multiple is allowed, but that will require a minor overhaul on the feature, both on the GP and LP sides.
 */
export type MultipleChoiceQuestion = {
  __typename?: 'MultipleChoiceQuestion';
  /** If True, the block or question shows regardless of the subscriber's account type. */
  allSubscriberTypes?: Maybe<Scalars['Boolean']>;
  /** A list of contact-bound answers. */
  answersByContact?: Maybe<Array<Maybe<MultipleChoiceAnswer>>>;
  /** If True, more than one choice may be selected. */
  canSelectMultiple?: Maybe<Scalars['Boolean']>;
  choices?: Maybe<Array<Maybe<MultipleChoiceQuestionChoice>>>;
  /** Display a none-of-the-above option. */
  hasNoneOption?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  /** If True, the question must be answered in order to proceed to the next subscription step. */
  required?: Maybe<Scalars['Boolean']>;
  /** The revision is incremented whenever the GP modifies the question.  Answers apply only to the revision of their corresponding question. */
  revision?: Maybe<Scalars['Int']>;
  /** If allSubscriberTypes is False, the block or question should only be shown for these subscriber account types. */
  subscriberTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The short title of the question to display. */
  title?: Maybe<Scalars['String']>;
  type?: Maybe<QuestionTypeEnum>;
};

/** A single choice the user may select for a multiple-choice question. */
export type MultipleChoiceQuestionChoice = {
  __typename?: 'MultipleChoiceQuestionChoice';
  /** Label for the choice */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accepts mobile TOU for the mobile user user record,marking the user ToS as accepted. */
  acceptMobileTou?: Maybe<AcceptMobileTouResponse>;
  /** Accepts a portal invitation for the arena_bound portal user record,marking the users manager ToS as accepted. */
  acceptPortalInvitation?: Maybe<AcceptPortalInvitationResponse>;
  addAssignees?: Maybe<UpdatedContactIdsOrError>;
  /** Mutation to add tag to object. */
  addTag?: Maybe<AddTag>;
  /** Approve a PaymentBatch created by Treasury user on Canopy dashbaord */
  approveCanopyPaymentBatch: PaymentBatchDto;
  /** Approve an existing PaymentBatch that is ready to be reviewed */
  approvePaymentBatch: PaymentBatchDto;
  assignCase: ComplianceCaseType;
  cancelAssignment: ComplianceCaseType;
  cancelPaymentBatch: PaymentBatchDto;
  /** Clone an ACH Payment Pref with a new signature */
  cloneAchPaymentPref?: Maybe<CloneAchPaymentPrefMutation>;
  completeBankAccountAchVerification: BankAccountObjectType;
  complianceGeneratePresignedUrls: Array<CompliancePresignedUrlObjectType>;
  /** Used to configure external SSO for an arena */
  configureExternalSso?: Maybe<ExternalSsoConfigurationResponse>;
  /** Mutation that configure integration of the given provenance */
  configureIntegration?: Maybe<ConfigureIntegrationOrError>;
  /** Create an Ach Payment Pref not related to any object */
  createAchPaymentPref?: Maybe<CreateAchPaymentPrefMutation>;
  createAnnouncementNotification: AnnouncementNotificationDto;
  createCase: ComplianceCaseType;
  createCaseWithNewIndividualProfile: ProfileCaseType;
  createCaseWithNewInstitutionProfile: ProfileCaseType;
  /** Create a Check Payment Pref not related to any object */
  createCheckPaymentPref?: Maybe<CreateCheckPaymentPrefMutation>;
  createComplianceComment: ComplianceCommentObject;
  createComplianceDocuments: Array<DocumentType>;
  createComplianceReport: ComplianceReportObject;
  createContact?: Maybe<ContactObjectTypeOrError>;
  createEscalation: ComplianceEscalationType;
  createIndividualProfile: ProfileType;
  createInstitutionProfile: ProfileType;
  createNetNoticeLinkage?: Maybe<CreateNetNoticeLinkageFederation>;
  /** Used to create or update contact filter */
  createOrUpdateContactFilter?: Maybe<SavedContactFilterObjectTypeOrError>;
  createOrUpdateNote?: Maybe<NoteOrError>;
  createPaymentAccount: PaymentAccountType;
  createPaymentBatch: PaymentBatchDto;
  createPaymentBeneficiaryProfile: ProfileType;
  createPaymentsApplication: PaymentsApplicationDto;
  /** Create a Wire Payment Pref not related to any object */
  createWirePaymentPref?: Maybe<CreateWirePaymentPrefMutation>;
  /** Decline a portal invitation for the arena_bound portal user record */
  declinePortalInvitation?: Maybe<DeclinePortalInvitationResponse>;
  deleteAnnouncementNotification: Scalars['Boolean'];
  deleteComplianceDocument?: Maybe<Scalars['Void']>;
  /** Used to delete saved contact filters */
  deleteContactFilters?: Maybe<ContactFilterDeletedCountObjectTypeOrError>;
  /**
   * This mutation deletes contacts.
   * Ported from jsq/web/staff/contact/view.py::Controller::handle_delete_contacts_bulk
   */
  deleteContacts?: Maybe<ContactDeletedCountObjectTypeOrError>;
  /**
   * This mutation confirms the deletion of contacts and provides the confirmation message.
   * Ported from jsq/web/staff/contact/view.py::Controller::delete_contacts_bulk
   */
  deleteContactsConfirmation?: Maybe<ContactDeleteConfirmationObjectTypeOrError>;
  deleteDashboard?: Maybe<DashboardObjectType>;
  /** This mutation deletes notes. */
  deleteNotes?: Maybe<NoteDeletedCountObjectTypeOrError>;
  deletePaymentAccount?: Maybe<Scalars['Void']>;
  deleteProfile: Scalars['Int'];
  /** Deny an existing PaymentBatch that is ready to be reviewed by treasury user */
  denyCanopyPaymentBatch: PaymentBatchDto;
  /** Deny an existing PaymentBatch that is ready to be reviewed */
  denyPaymentBatch: PaymentBatchDto;
  /** Mutation that disable integration of the given provenance */
  disableIntegration?: Maybe<DisableIntegrationOrError>;
  downloadSoiSpreadsheet: Scalars['String'];
  /** Used to edit extension fields */
  editExtensionFields?: Maybe<EditExtensionFieldsErrors>;
  /** Starts an async job to verify all recipients for an email batch. This includes: checking that recipients have an email address; performing permission checks on attached documents; checkingfor any other critical errors that prevent the email from being sent to a recipient. */
  emailBatchRecipientsVerificationTask?: Maybe<EmailBatchRecipientsVerificationJobOrError>;
  /** Used to verify whether a list of emails match the criteria ofa signatory across JSQ. */
  enableAutomatedPaymentForEntity?: Maybe<EnableAutomatedPaymentsForEntity>;
  exampleMutation: Scalars['String'];
  generatePresignedUrls?: Maybe<GeneratePresignedUrls>;
  /** Import bulk positions from a spreadsheet */
  importBulkPositions?: Maybe<ImportBulkPositions>;
  initiateBankAccountVerification: BankAccountObjectType;
  integrationEntityDetailsContactSyncTrigger?: Maybe<IntegrationEntityDetailsContactSyncObjectTypeOrError>;
  /** Mutation to send invitation to data room. */
  inviteToDataRoom?: Maybe<InvitationObjectTypeOrError>;
  linkBeneficialOwner: ProfileRelationshipType;
  linkControllingPerson: ProfileRelationshipType;
  linkPaymentBeneficiary: ProfileRelationshipType;
  linkProfileToEntityOwnershipDomicile: ProfileEntityOwnershipDomicileCountryType;
  linkProfileToIndustry: ProfileIndustryType;
  linkRelationships: Array<ProfileRelationshipType>;
  linkSignatory: ProfileRelationshipType;
  manuallyEditedEntityTransactionExport?: Maybe<ManuallyEditedEntityTransactionsExport>;
  overrideRiskRating?: Maybe<RiskRatingType>;
  performAction: ComplianceCaseType;
  processTransactionMappingFile: ProcessTransactionMappingResponse;
  promoteTransactionBatches?: Maybe<PromoteTransactionBatchMutation>;
  /** Mutation to identify prospects ready for bulk countersign */
  prospectsReadyForBulkCountersign?: Maybe<ProspectReadyForCountersignResponse>;
  refundPayment: PaymentDto;
  reissuePayment: PaymentDto;
  removeNetNoticeLinkage?: Maybe<RemoveNetNoticeLinkageFederation>;
  /** Remove payment preference from an account. */
  removePaymentPrefFromAccount?: Maybe<RemovePaymentPrefFromAccountOutput>;
  /** Remove payment preference from a position. */
  removePaymentPrefFromPosition?: Maybe<RemovePaymentPrefFromPositionResponse>;
  removeProfileFromCase: ComplianceCaseType;
  /** Mutation to remove tag from object. */
  removeTag?: Maybe<RemoveTag>;
  reopenCase: ComplianceCaseType;
  restartBankAccountVerification: BankAccountObjectType;
  returnCase: ComplianceCaseType;
  reviewChangeRequest: ReviewChangeRequestMutationResponseMutationErrorResponse;
  /** Starts an async job that will create individual emails in an email batch. The emails will be sent by a separate task. */
  sendEmailBatch?: Maybe<EmailBatchSendResponse>;
  setAllAnnouncementNotificationRead: Scalars['Boolean'];
  setAnnouncementNotificationRead: Scalars['Boolean'];
  /** Set the compliance program for an entity.  Additionally, a compliance_settings object will be created if one doesn't exist. */
  setComplianceProgram?: Maybe<AccountObjectType>;
  singleIntegrationEntityDetailsContactSyncTrigger?: Maybe<SingleIntegrationEntityDetailsContactSyncObjectTypeOrError>;
  /** Mutation to start a co-browsing session for a user. */
  startCobrowseSession?: Maybe<StartCoBrowseSession>;
  startEntityGLSync: StartEntityGlSyncResponse;
  subSoaArenaDeleteCustomSection: MutationSuccessResponseMutationErrorResponse;
  subSoaArenaDeletePrompt: MutationSuccessResponseMutationErrorResponse;
  subSoaArenaDeleteSupportingDocument: MutationSuccessResponse;
  subSoaArenaEditCustomSectionEnablingLogic: ArenaSection;
  subSoaArenaEditCustomSectionFootnotes: ArenaSection;
  subSoaArenaPublishCustomSectionsDraft: ArenaSectionPublishSectionErrorResponse;
  subSoaArenaPublishSupportingDocumentsDraft: ArenaSection;
  subSoaArenaReorderCustomSections: ReorderCustomSectionsResponseMutationErrorResponse;
  subSoaArenaReorderPrompts: ArenaSectionMutationErrorResponse;
  subSoaArenaReorderSupportingDocuments: Array<ArenaSection>;
  subSoaArenaUpsertCustomSectionDraft: ArenaSection;
  subSoaArenaUpsertCustomSectionSettings: ArenaSection;
  subSoaArenaUpsertPrompt: ArenaSectionMutationErrorResponse;
  subSoaArenaUpsertSupportingDocumentDraft: ArenaSection;
  subSoaCustomSectionsComplete: MutationSuccessResponseMutationErrorResponse;
  subSoaEntityReorderCustomSections: EntitySubscriptionSettingsMutationErrorResponse;
  subSoaEntityReorderSupportingDocs: EntitySubscriptionSettings;
  subSoaLpStepStarted: MutationSuccessResponseMutationErrorResponse;
  subSoaSetEntityCustomSections: EntitySubscriptionSettings;
  subSoaSetEntitySupportingDocs: EntitySubscriptionSettings;
  subSoaSupportingDocumentsComplete: MutationSuccessResponseMutationErrorResponse;
  subSoaUpdateSectionProgress: MutationSuccessResponseMutationErrorResponse;
  subSoaUpsertPromptResponse: MutationSuccessResponseMutationErrorResponse;
  subSoaValidateSubscription: MutationSuccessResponseMutationErrorResponse;
  submitArenaPaymentsApplication: PaymentsApplicationDto;
  submitChangeRequest: SubmitChangeRequestResponseMutationErrorResponse;
  submitForReview: ComplianceCaseType;
  submitInvestmentEntityPaymentsApplication: PaymentsApplicationDto;
  /** Mutation to initiate bulk countersign for subscriptions. */
  subscriptionsConfigureBulkDocusign?: Maybe<InitiateBulkCountersignResponse>;
  unlinkRelationship?: Maybe<Scalars['Void']>;
  updateComplianceDocumentDetails: DocumentType;
  updateCompliancePaymentAccount: PaymentAccountType;
  updateContactBio?: Maybe<UpdatedContactOrError>;
  updateContactDetails?: Maybe<UpdatedContactOrError>;
  updateEntityGLSyncSettings: UpdateEntityGlSyncSettingsResponse;
  updateIndividualProfile: ProfileType;
  updateInstitutionProfile: ProfileType;
  updateMappingSetting: MappingType;
  /**  Mutation that updates all the underlying positions of the accounts to have the same payment preference information. */
  updatePaymentPrefForAccounts?: Maybe<UpdatePaymentPrefForAccountsResponse>;
  /** Mutation that updates the payment pref of the given position. */
  updatePaymentPrefForPosition?: Maybe<UpdatePaymentPrefForPositionResponse>;
  /** Mutation that updates the association for a list of positions that match the source payment preference ID to have the target payment preference id. All positions will be associated with the passed in payment preference ID. */
  updatePaymentPrefForPositionsOfPaymentPref?: Maybe<UpdatePaymentPrefForPositionsOfPaymentPrefResponse>;
  /** Mutation that updates all the positions of the passed in account that do not have a payment preference to be associated with the passed in payment preference ID. */
  updatePaymentPrefForPositionsWithoutPaymentPref?: Maybe<UpdatePaymentPrefForPositionsWithoutPaymentPrefResponse>;
  /** Mutation to start the position allocation update for a publication. */
  updatePositionAllocationForPublication?: Maybe<AsyncJobOrError>;
  updateScreeningMatches: ScreeningRecordType;
  /** This mutation upserts an editor template. */
  upsertEditorTemplate?: Maybe<EditorTemplateOrError>;
  upsertIndividualProfile: ProfileType;
  upsertIndividualProfileAndCreateCase: ProfileCaseType;
  upsertInstitutionProfile: ProfileType;
  upsertInstitutionProfileAndCreateCase: ProfileCaseType;
  /** Used to verify whether a list of emails match the criteria ofa signatory across JSQ. */
  verifyUserSignatories?: Maybe<VerifySignatoriesResponse>;
  /** Mutation to void the subscription bulk countersign */
  voidBulkCountersign?: Maybe<VoidBulkCountersignResponse>;
};


export type MutationAcceptMobileTouArgs = {
  confirmedInput: AcceptMobileTouInput;
};


export type MutationAcceptPortalInvitationArgs = {
  objectInput: AcceptPortalInvitationInput;
};


export type MutationAddAssigneesArgs = {
  addAssigneesInput: AddAssigneesInput;
};


export type MutationAddTagArgs = {
  jsqObjectType: JsqObjectType;
  objectIds?: InputMaybe<Array<Scalars['ID']>>;
  objectInput: AddTagInput;
};


export type MutationApproveCanopyPaymentBatchArgs = {
  approveCanopyPaymentBatchInput: ApproveCanopyPaymentBatchInput;
};


export type MutationApprovePaymentBatchArgs = {
  approvePaymentBatchInput: ApprovePaymentBatchInput;
};


export type MutationAssignCaseArgs = {
  caseId: Scalars['Int'];
};


export type MutationCancelAssignmentArgs = {
  caseId: Scalars['Int'];
};


export type MutationCancelPaymentBatchArgs = {
  id: Scalars['ID'];
};


export type MutationCloneAchPaymentPrefArgs = {
  cloneAchPaymentPrefInput: CloneAchPaymentPrefInput;
};


export type MutationCompleteBankAccountAchVerificationArgs = {
  completeBankAccountAchVerificationInput: CompleteBankAccountAchVerificationInput;
};


export type MutationComplianceGeneratePresignedUrlsArgs = {
  urlGeneratorInput: Array<CompliancePresignedUrlInput>;
};


export type MutationConfigureIntegrationArgs = {
  configureIntegrationInput: ConfigureIntegrationInput;
};


export type MutationCreateAchPaymentPrefArgs = {
  createAchPaymentPrefInput: CreateAchPaymentPrefInput;
};


export type MutationCreateAnnouncementNotificationArgs = {
  createAnnouncementNotificationInput: CreateAnnouncementNotificationInput;
};


export type MutationCreateCaseArgs = {
  assignSelf?: InputMaybe<Scalars['Boolean']>;
  externalArena: ExternalObjectInput;
  externalEntity: ExternalObjectInput;
  externalObject?: InputMaybe<ExternalObjectInput>;
  profileId: Scalars['Int'];
  programId: Scalars['Int'];
};


export type MutationCreateCaseWithNewIndividualProfileArgs = {
  assignSelf?: InputMaybe<Scalars['Boolean']>;
  caseExternalObject?: InputMaybe<ExternalObjectInput>;
  externalArena: ExternalObjectInput;
  externalEntity: ExternalObjectInput;
  profileData: ComplianceIndividualProfileInput;
  programId: Scalars['Int'];
};


export type MutationCreateCaseWithNewInstitutionProfileArgs = {
  assignSelf?: InputMaybe<Scalars['Boolean']>;
  caseExternalObject?: InputMaybe<ExternalObjectInput>;
  externalArena: ExternalObjectInput;
  externalEntity: ExternalObjectInput;
  profileData: ComplianceInstitutionProfileInput;
  programId: Scalars['Int'];
};


export type MutationCreateCheckPaymentPrefArgs = {
  createCheckPaymentPrefInput: CreateCheckPaymentPrefInput;
};


export type MutationCreateComplianceCommentArgs = {
  createCommentInput: CreateComplianceCommentInput;
};


export type MutationCreateComplianceDocumentsArgs = {
  caseId: Scalars['Int'];
  documents: Array<DocumentInfoInput>;
};


export type MutationCreateComplianceReportArgs = {
  createReportInput: CreateComplianceReportInput;
};


export type MutationCreateContactArgs = {
  contact: CreateContactInput;
};


export type MutationCreateEscalationArgs = {
  escalationInput: ComplianceEscalationInput;
};


export type MutationCreateIndividualProfileArgs = {
  arenaAccessId?: InputMaybe<Scalars['Int']>;
  data: ComplianceIndividualProfileInput;
};


export type MutationCreateInstitutionProfileArgs = {
  arenaAccessId?: InputMaybe<Scalars['Int']>;
  data: ComplianceInstitutionProfileInput;
};


export type MutationCreateNetNoticeLinkageArgs = {
  capitalCallBatchId: Scalars['ID'];
  distributionBatchId: Scalars['ID'];
};


export type MutationCreateOrUpdateContactFilterArgs = {
  filterInput: CreateOrUpdateContactFilterInput;
};


export type MutationCreateOrUpdateNoteArgs = {
  noteInput: NoteInput;
};


export type MutationCreatePaymentAccountArgs = {
  caseId: Scalars['Int'];
  data: PaymentAccountInput;
};


export type MutationCreatePaymentBatchArgs = {
  createPaymentBatchInput: CreatePaymentBatchInput;
};


export type MutationCreatePaymentBeneficiaryProfileArgs = {
  caseId?: InputMaybe<Scalars['Int']>;
  complianceObjectType: ComplianceObjectType;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  parentProfileId: Scalars['Int'];
};


export type MutationCreatePaymentsApplicationArgs = {
  createPaymentsApplicationInput: CreatePaymentsApplicationInput;
};


export type MutationCreateWirePaymentPrefArgs = {
  createWirePaymentPrefInput: CreateWirePaymentPrefInput;
};


export type MutationDeclinePortalInvitationArgs = {
  objectInput: DeclinePortalInvitationInput;
};


export type MutationDeleteAnnouncementNotificationArgs = {
  announcementNotificationId: Scalars['ID'];
};


export type MutationDeleteComplianceDocumentArgs = {
  caseId: Scalars['Int'];
  documentId: Scalars['Int'];
};


export type MutationDeleteContactFiltersArgs = {
  deleteFilterInput: DeleteContactFilterInput;
};


export type MutationDeleteContactsArgs = {
  deleteContactsInput: DeleteContactsInput;
};


export type MutationDeleteContactsConfirmationArgs = {
  deleteContactsInput: DeleteContactsInput;
};


export type MutationDeleteDashboardArgs = {
  objectInput: DeleteDashboardInput;
};


export type MutationDeleteNotesArgs = {
  deleteNotesInput: DeleteNotesInput;
};


export type MutationDeletePaymentAccountArgs = {
  caseId: Scalars['Int'];
  paymentAccountId: Scalars['Int'];
};


export type MutationDeleteProfileArgs = {
  profileId: Scalars['Int'];
};


export type MutationDenyCanopyPaymentBatchArgs = {
  denyCanopyPaymentBatchInput: DenyCanopyPaymentBatchInput;
};


export type MutationDenyPaymentBatchArgs = {
  denyPaymentBatchInput: DenyPaymentBatchInput;
};


export type MutationDisableIntegrationArgs = {
  disableIntegrationInput: DisableIntegrationInput;
};


export type MutationDownloadSoiSpreadsheetArgs = {
  input: EntitySoiInput;
};


export type MutationEditExtensionFieldsArgs = {
  editInput: EditExtensionFieldsInput;
};


export type MutationEmailBatchRecipientsVerificationTaskArgs = {
  objectInput: EmailBatchRecipientsVerificationInput;
};


export type MutationEnableAutomatedPaymentForEntityArgs = {
  entityId: Scalars['Int'];
};


export type MutationGeneratePresignedUrlsArgs = {
  urlGeneratorInput: Array<InputMaybe<PresignedUrlInput>>;
};


export type MutationImportBulkPositionsArgs = {
  arenaId: Scalars['ID'];
  dryRun: Scalars['Boolean'];
  entityId: Scalars['ID'];
  fileUuid: Scalars['String'];
};


export type MutationInitiateBankAccountVerificationArgs = {
  initiateBankAccountVerificationInput: InitiateBankAccountVerificationInput;
};


export type MutationIntegrationEntityDetailsContactSyncTriggerArgs = {
  integrationEntityDetailsContactSyncInput: IntegrationEntityDetailsContactSyncInput;
};


export type MutationInviteToDataRoomArgs = {
  inviteInput: InviteToDataRoomInput;
};


export type MutationLinkBeneficialOwnerArgs = {
  childProfileId: Scalars['Int'];
  entityId: Scalars['Int'];
  percentage: Scalars['Float'];
};


export type MutationLinkControllingPersonArgs = {
  childProfileId: Scalars['Int'];
  entityId: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationLinkPaymentBeneficiaryArgs = {
  childProfileId: Scalars['Int'];
  entityId: Scalars['Int'];
};


export type MutationLinkProfileToEntityOwnershipDomicileArgs = {
  countryId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type MutationLinkProfileToIndustryArgs = {
  industryId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type MutationLinkRelationshipsArgs = {
  childProfileId: Scalars['Int'];
  entityId: Scalars['Int'];
  percentage?: InputMaybe<Scalars['Float']>;
  relationshipTypes: Array<ProfileRelationshipEnum>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationLinkSignatoryArgs = {
  childProfileId: Scalars['Int'];
  entityId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationManuallyEditedEntityTransactionExportArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type MutationOverrideRiskRatingArgs = {
  data: RiskRatingInput;
};


export type MutationPerformActionArgs = {
  action: ComplianceCaseAction;
  caseId: Scalars['Int'];
  comment?: InputMaybe<CreateComplianceCommentInput>;
};


export type MutationProcessTransactionMappingFileArgs = {
  input: ProcessTransactionMappingInput;
};


export type MutationPromoteTransactionBatchesArgs = {
  batchId: Scalars['ID'];
  objectTypeId: Scalars['Int'];
};


export type MutationProspectsReadyForBulkCountersignArgs = {
  input: ProspectReadyForCountersignInput;
};


export type MutationRefundPaymentArgs = {
  refundPaymentInput: RefundPaymentInput;
};


export type MutationReissuePaymentArgs = {
  reissuePaymentInput: ReissuePaymentInput;
};


export type MutationRemoveNetNoticeLinkageArgs = {
  capitalCallBatchId?: InputMaybe<Scalars['ID']>;
  distributionBatchId?: InputMaybe<Scalars['ID']>;
};


export type MutationRemovePaymentPrefFromAccountArgs = {
  objectInput: RemovePaymentPrefFromAccountInput;
};


export type MutationRemovePaymentPrefFromPositionArgs = {
  objectInput: RemovePaymentPrefFromPositionInput;
};


export type MutationRemoveProfileFromCaseArgs = {
  caseId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type MutationRemoveTagArgs = {
  jsqObjectType: JsqObjectType;
  objectIds?: InputMaybe<Array<Scalars['ID']>>;
  tagId: Scalars['ID'];
};


export type MutationReopenCaseArgs = {
  caseId: Scalars['Int'];
};


export type MutationRestartBankAccountVerificationArgs = {
  restartBankAccountVerificationInput: RestartBankAccountVerificationInput;
};


export type MutationReturnCaseArgs = {
  caseId: Scalars['Int'];
};


export type MutationReviewChangeRequestArgs = {
  objectInput: ReviewChangeRequestInput;
};


export type MutationSendEmailBatchArgs = {
  objectInput: EmailBatchSendInput;
};


export type MutationSetAllAnnouncementNotificationReadArgs = {
  read: Scalars['Boolean'];
};


export type MutationSetAnnouncementNotificationReadArgs = {
  announcementNotificationId: Array<Scalars['Float']>;
  read: Scalars['Boolean'];
};


export type MutationSetComplianceProgramArgs = {
  arenaId: Scalars['ID'];
  entityId: Scalars['ID'];
  programString: Scalars['String'];
};


export type MutationSingleIntegrationEntityDetailsContactSyncTriggerArgs = {
  singleIntegrationEntityDetailsContactSyncInput: SingleIntegrationEntityDetailsContactSyncInput;
};


export type MutationStartCobrowseSessionArgs = {
  objectInput: InitiateCoBrowseInput;
};


export type MutationStartEntityGlSyncArgs = {
  input: StartEntityGlSyncInput;
};


export type MutationSubSoaArenaDeleteCustomSectionArgs = {
  arenaId: Scalars['Int'];
  sectionId: Scalars['StrawberryObjectId'];
};


export type MutationSubSoaArenaDeletePromptArgs = {
  objectInput: ArenaDeletePromptInput;
};


export type MutationSubSoaArenaDeleteSupportingDocumentArgs = {
  arenaId: Scalars['Int'];
  sectionId: Scalars['StrawberryObjectId'];
};


export type MutationSubSoaArenaEditCustomSectionEnablingLogicArgs = {
  objectInput: EditCustomSectionLogicInput;
};


export type MutationSubSoaArenaEditCustomSectionFootnotesArgs = {
  objectInput: EditCustomSectionFootnotesInput;
};


export type MutationSubSoaArenaPublishCustomSectionsDraftArgs = {
  arenaId: Scalars['Int'];
  sectionId: Scalars['StrawberryObjectId'];
};


export type MutationSubSoaArenaPublishSupportingDocumentsDraftArgs = {
  arenaId: Scalars['Int'];
  sectionId: Scalars['StrawberryObjectId'];
};


export type MutationSubSoaArenaReorderCustomSectionsArgs = {
  reorderInput: ReorderSectionsInput;
};


export type MutationSubSoaArenaReorderPromptsArgs = {
  objectInput: ReorderPromptsInput;
};


export type MutationSubSoaArenaReorderSupportingDocumentsArgs = {
  reorderInput: ReorderSectionsInput;
};


export type MutationSubSoaArenaUpsertCustomSectionDraftArgs = {
  objectInput: SectionInput;
};


export type MutationSubSoaArenaUpsertCustomSectionSettingsArgs = {
  objectInput: UpsertCustomSectionSettingsInput;
};


export type MutationSubSoaArenaUpsertPromptArgs = {
  objectInput: ArenaUpsertPromptInput;
};


export type MutationSubSoaArenaUpsertSupportingDocumentDraftArgs = {
  objectInput: SectionInput;
};


export type MutationSubSoaCustomSectionsCompleteArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationSubSoaEntityReorderCustomSectionsArgs = {
  objectInput: ReorderEntitySectionsInput;
};


export type MutationSubSoaEntityReorderSupportingDocsArgs = {
  objectInput: ReorderEntitySectionsInput;
};


export type MutationSubSoaLpStepStartedArgs = {
  objectInput: SubscriptionStageStartedInput;
};


export type MutationSubSoaSetEntityCustomSectionsArgs = {
  objectInput: SetEntitySectionsInput;
};


export type MutationSubSoaSetEntitySupportingDocsArgs = {
  objectInput: SetEntitySectionsInput;
};


export type MutationSubSoaSupportingDocumentsCompleteArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationSubSoaUpdateSectionProgressArgs = {
  objectInput: UpdateSectionProgressInput;
};


export type MutationSubSoaUpsertPromptResponseArgs = {
  arenaId?: InputMaybe<Scalars['Int']>;
  objectInput: LpResponseInput;
};


export type MutationSubSoaValidateSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationSubmitArenaPaymentsApplicationArgs = {
  submitArenaPaymentsApplicationInput: SubmitArenaPaymentsApplicationInput;
};


export type MutationSubmitChangeRequestArgs = {
  objectInput: ChangeRequestInput;
};


export type MutationSubmitForReviewArgs = {
  action?: InputMaybe<ComplianceCaseAction>;
  caseId: Scalars['Int'];
  comment?: InputMaybe<CreateComplianceCommentInput>;
};


export type MutationSubmitInvestmentEntityPaymentsApplicationArgs = {
  submitInvestmentEntityPaymentsApplicationInput: SubmitInvestmentEntityPaymentsApplicationInput;
};


export type MutationSubscriptionsConfigureBulkDocusignArgs = {
  objectInput: BulkCountersignInput;
};


export type MutationUnlinkRelationshipArgs = {
  childProfileId: Scalars['Int'];
  entityId: Scalars['Int'];
  relationshipType: ProfileRelationshipEnum;
};


export type MutationUpdateComplianceDocumentDetailsArgs = {
  caseId: Scalars['Int'];
  documentId: Scalars['Int'];
  updateInfo: DocumentUpdateInfoInput;
};


export type MutationUpdateCompliancePaymentAccountArgs = {
  caseId: Scalars['Int'];
  data: PaymentAccountInput;
  paymentAccountId: Scalars['Int'];
};


export type MutationUpdateContactBioArgs = {
  contact: UpdateContactBioInput;
};


export type MutationUpdateContactDetailsArgs = {
  contact: UpdateContactDetailsInput;
};


export type MutationUpdateEntityGlSyncSettingsArgs = {
  input: EntityGlSyncSettingsInput;
};


export type MutationUpdateIndividualProfileArgs = {
  data: ComplianceIndividualProfileInput;
  profileId: Scalars['Int'];
};


export type MutationUpdateInstitutionProfileArgs = {
  data: ComplianceInstitutionProfileInput;
  profileId: Scalars['Int'];
};


export type MutationUpdateMappingSettingArgs = {
  input: UpdateMappingSettingInput;
};


export type MutationUpdatePaymentPrefForAccountsArgs = {
  objectInput: UpdatePaymentPrefForAccountsInput;
};


export type MutationUpdatePaymentPrefForPositionArgs = {
  objectInput: UpdatePaymentPrefForPositionInput;
};


export type MutationUpdatePaymentPrefForPositionsOfPaymentPrefArgs = {
  objectInput: UpdatePaymentPrefForPositionsOfPaymentPrefInput;
};


export type MutationUpdatePaymentPrefForPositionsWithoutPaymentPrefArgs = {
  objectInput: UpdatePaymentPrefForPositionsWithoutPaymentPrefInput;
};


export type MutationUpdatePositionAllocationForPublicationArgs = {
  objectInput: UpdatePositionAllocationForPublicationInput;
};


export type MutationUpdateScreeningMatchesArgs = {
  screeningRecordId: Scalars['Int'];
  updateMatchInput: Array<ScreeningMatchUpdateInput>;
};


export type MutationUpsertEditorTemplateArgs = {
  editorTemplateInput: UpsertEditorTemplateInput;
};


export type MutationUpsertIndividualProfileArgs = {
  arenaId?: InputMaybe<Scalars['Int']>;
  data: IndividualProfileInput;
  externalIds?: InputMaybe<Array<ProfileExternalObjectInput>>;
  idDocuments?: InputMaybe<Array<DocumentInfoInput>>;
  profileId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpsertIndividualProfileAndCreateCaseArgs = {
  assignSelf?: InputMaybe<Scalars['Boolean']>;
  caseExternalObject?: InputMaybe<ExternalObjectInput>;
  data: IndividualProfileInput;
  externalArena: ExternalObjectInput;
  externalEntity: ExternalObjectInput;
  profileExternalObjects?: InputMaybe<Array<ProfileExternalObjectInput>>;
  programId: Scalars['Int'];
};


export type MutationUpsertInstitutionProfileArgs = {
  arenaId?: InputMaybe<Scalars['Int']>;
  data: InstitutionProfileInput;
  externalIds?: InputMaybe<Array<ProfileExternalObjectInput>>;
  idDocuments?: InputMaybe<Array<DocumentInfoInput>>;
  profileId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpsertInstitutionProfileAndCreateCaseArgs = {
  assignSelf?: InputMaybe<Scalars['Boolean']>;
  caseExternalObject?: InputMaybe<ExternalObjectInput>;
  data: InstitutionProfileInput;
  externalArena: ExternalObjectInput;
  externalEntity: ExternalObjectInput;
  profileExternalObjects?: InputMaybe<Array<ProfileExternalObjectInput>>;
  programId: Scalars['Int'];
};


export type MutationVerifyUserSignatoriesArgs = {
  objectInput: VerifySignatoriesInput;
};


export type MutationVoidBulkCountersignArgs = {
  objectInput: VoidBulkCountersignInput;
};

export type MutationErrorResponse = {
  __typename?: 'MutationErrorResponse';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

export type MutationSuccessResponse = {
  __typename?: 'MutationSuccessResponse';
  /** Whether the mutation succeeded. If present, should typically be `True`, as errors are generally returned as `MutationErrorResponse`. */
  didSucceed: Scalars['Boolean'];
};

export type MutationSuccessResponseMutationErrorResponse = MutationErrorResponse | MutationSuccessResponse;

/** An enumeration. */
export enum NaicsSector {
  Sector_11 = 'sector_11',
  Sector_21 = 'sector_21',
  Sector_22 = 'sector_22',
  Sector_23 = 'sector_23',
  Sector_31_33 = 'sector_31_33',
  Sector_42 = 'sector_42',
  Sector_44_45 = 'sector_44_45',
  Sector_48_49 = 'sector_48_49',
  Sector_51 = 'sector_51',
  Sector_52 = 'sector_52',
  Sector_53 = 'sector_53',
  Sector_54 = 'sector_54',
  Sector_55 = 'sector_55',
  Sector_56 = 'sector_56',
  Sector_61 = 'sector_61',
  Sector_62 = 'sector_62',
  Sector_71 = 'sector_71',
  Sector_72 = 'sector_72',
  Sector_81 = 'sector_81',
  Sector_92 = 'sector_92'
}

export type NavBatchObjectType = PermissionCheckedNode & {
  __typename?: 'NavBatchObjectType';
  batchTotalDetails?: Maybe<NavBatchTotalDetailsObjectType>;
  description: Scalars['String'];
  effectiveDate?: Maybe<Scalars['Date']>;
  hasWaterfall: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  noun?: Maybe<Scalars['String']>;
  storedTotals: Array<NavBatchTotalObjectType>;
  viewRoute?: Maybe<Scalars['String']>;
};

export type NavBatchTotalByTypeObjectType = {
  __typename?: 'NavBatchTotalByTypeObjectType';
  amount: Wallet;
  transactionType: NavTypeObjectType;
};

export type NavBatchTotalDetailsObjectType = {
  __typename?: 'NavBatchTotalDetailsObjectType';
  batchTotalsByType: Array<NavBatchTotalByTypeObjectType>;
  totalAmount: Wallet;
};

export type NavBatchTotalObjectType = PermissionCheckedNode & {
  __typename?: 'NavBatchTotalObjectType';
  amount: Wallet;
  batchId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  typeId: Scalars['Int'];
};

export type NavDetailObjectType = PermissionCheckedNode & {
  __typename?: 'NavDetailObjectType';
  amount: Wallet;
  amountEstimated: Scalars['Float'];
  /** The ID of the object */
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  transactionType?: Maybe<NavTypeObjectType>;
};

export type NavDetailObjectTypeConnection = {
  __typename?: 'NavDetailObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<NavDetailObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type NavDetailObjectTypeEdge = {
  __typename?: 'NavDetailObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: NavDetailObjectType;
};

export type NavObjectType = PermissionCheckedNode & {
  __typename?: 'NavObjectType';
  amount: Wallet;
  batch?: Maybe<NavBatchObjectType>;
  details?: Maybe<NavDetailObjectTypeConnection>;
  email?: Maybe<EmailObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  uploadedAccountName?: Maybe<Scalars['String']>;
  waterfallStats?: Maybe<WaterfallStatsObjectType>;
};


export type NavObjectTypeDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type NavObjectTypeConnection = {
  __typename?: 'NavObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<NavObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type NavObjectTypeEdge = {
  __typename?: 'NavObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: NavObjectType;
};

export type NavTypeObjectType = PermissionCheckedNode & {
  __typename?: 'NavTypeObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type NdaObjectType = PermissionCheckedNode & {
  __typename?: 'NdaObjectType';
  agreementText: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  signedByCurrentUser?: Maybe<Scalars['Boolean']>;
};


export type NdaObjectTypeSignedByCurrentUserArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
};

/**
 * Used to populate select dropdown for NDA configuration forms
 * Created separate object as list of full NDA object should be restricted to staff users with
 * CAN_MANAGE_AGREEMENTS
 */
export type NdaSelectObjectType = {
  __typename?: 'NdaSelectObjectType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NetIncomeBatchObjectType = PermissionCheckedNode & {
  __typename?: 'NetIncomeBatchObjectType';
  batchCategory?: Maybe<Scalars['String']>;
  batchTotalDetails?: Maybe<NetIncomeBatchTotalDetailsObjectType>;
  description: Scalars['String'];
  effectiveDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<AccountObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  noun?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['DateTime']>;
  storedTotals: Array<NetIncomeBatchTotalObjectType>;
  viewRoute?: Maybe<Scalars['String']>;
};

export type NetIncomeBatchTotalByTypeObjectType = {
  __typename?: 'NetIncomeBatchTotalByTypeObjectType';
  amount: Wallet;
  transactionType: NetIncomeTypeObjectType;
  typeDescription?: Maybe<Scalars['String']>;
};

export type NetIncomeBatchTotalDetailsObjectType = {
  __typename?: 'NetIncomeBatchTotalDetailsObjectType';
  batchTotalsByType: Array<NetIncomeBatchTotalByTypeObjectType>;
  totalAmount: Wallet;
  totalAmountTaxExempt: Wallet;
  totalAmountTaxable: Wallet;
};

export type NetIncomeBatchTotalObjectType = PermissionCheckedNode & {
  __typename?: 'NetIncomeBatchTotalObjectType';
  amount: Wallet;
  batchId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  typeId: Scalars['Int'];
};

export type NetIncomeConnection = {
  __typename?: 'NetIncomeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<NetIncomeEdge>;
  pageInfo: PageInfo;
  totalNetIncome: Wallet;
};

export type NetIncomeDetailConnection = {
  __typename?: 'NetIncomeDetailConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<NetIncomeDetailEdge>;
  pageInfo: PageInfo;
};

export type NetIncomeDetailEdge = {
  __typename?: 'NetIncomeDetailEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: NetIncomeDetailObjectType;
};

export type NetIncomeDetailObjectType = PermissionCheckedNode & {
  __typename?: 'NetIncomeDetailObjectType';
  amount: Wallet;
  /** The ID of the object */
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  transactionType?: Maybe<NetIncomeTypeObjectType>;
  typeDescription?: Maybe<Scalars['String']>;
  typeId: Scalars['Int'];
};

/** An enumeration. */
export enum NetIncomeDetailObjectTypeSortEnum {
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  TypeIdAsc = 'TYPE_ID_ASC',
  TypeIdDesc = 'TYPE_ID_DESC'
}

export type NetIncomeEdge = {
  __typename?: 'NetIncomeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: NetIncomeObjectType;
};

export type NetIncomeGlSettingsInput = {
  active: Scalars['Boolean'];
  batchPeriod: BatchPeriod;
  glStartDate?: InputMaybe<Scalars['Date']>;
};

export type NetIncomeGlSettingsResponse = {
  __typename?: 'NetIncomeGLSettingsResponse';
  active: Scalars['Boolean'];
  batchPeriod?: Maybe<BatchPeriod>;
  glStartDate?: Maybe<Scalars['Date']>;
};

export type NetIncomeObjectType = PermissionCheckedNode & {
  __typename?: 'NetIncomeObjectType';
  amount?: Maybe<Wallet>;
  amountTaxExempt: Wallet;
  amountTaxable: Wallet;
  basisName: Scalars['String'];
  batch?: Maybe<NetIncomeBatchObjectType>;
  batchDescription: Scalars['String'];
  currency: Scalars['String'];
  datePassedThrough: Scalars['Date'];
  details?: Maybe<NetIncomeDetailConnection>;
  email?: Maybe<EmailObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  position?: Maybe<PositionObjectType>;
  uploadedAccountName?: Maybe<Scalars['String']>;
};


export type NetIncomeObjectTypeDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  publishedOnly?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<NetIncomeDetailObjectTypeSortEnum>>>;
};

/** An enumeration. */
export enum NetIncomeObjectTypeSortEnum {
  UploadedAccountNameAsc = 'UPLOADED_ACCOUNT_NAME_ASC',
  UploadedAccountNameDesc = 'UPLOADED_ACCOUNT_NAME_DESC'
}

export type NetIncomeTypeObjectType = PermissionCheckedNode & {
  __typename?: 'NetIncomeTypeObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  niType?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
};

export type NetNoticeLinkResults = {
  __typename?: 'NetNoticeLinkResults';
  ccOnly?: Maybe<Scalars['Int']>;
  distOnly?: Maybe<Scalars['Int']>;
  matched?: Maybe<Scalars['Int']>;
  netCapitalCalls?: Maybe<Scalars['Int']>;
  netDistributions?: Maybe<Scalars['Int']>;
  unlinked?: Maybe<Scalars['Int']>;
};

export type NetNoticeLinkageResponse = MutationErrorResponse | NetNoticeLinkResults;

export type NetworkActivityFeedObjectType = {
  __typename?: 'NetworkActivityFeedObjectType';
  activityObject: ActivityFeedObjectType;
  activityType: Scalars['String'];
  arena: ArenaObjectType;
  arenaAdjustedDate?: Maybe<Scalars['Date']>;
  attachments: Array<AttachmentObjectType>;
  effectiveDate?: Maybe<Scalars['Date']>;
  emailId?: Maybe<Scalars['ID']>;
  recipientsLabel?: Maybe<Scalars['String']>;
  regarding?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type NetworkActivityFeedWrapperObjectType = {
  __typename?: 'NetworkActivityFeedWrapperObjectType';
  items: Array<NetworkActivityFeedObjectType>;
  newStartTimestamp?: Maybe<Scalars['String']>;
};

export enum NewsletterSubscriptionTypes {
  Frequent = 'frequent',
  Infrequent = 'infrequent'
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type NodeWithProfitLoss = {
  profitLossPeriods: Array<ProfitLossPeriodObjectType>;
};


export type NodeWithProfitLossProfitLossPeriodsArgs = {
  period?: InputMaybe<Scalars['Period']>;
  plCategories: Array<InputMaybe<Scalars['FieldName']>>;
};

export type NodeWithTimeSeries = {
  latestTimeSeries?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesByPeriod?: Maybe<TimeSeriesDateToListTuple>;
  latestTimeSeriesProjections?: Maybe<TimeSeriesDateToListTuple>;
  timeSeries: Array<TimeSeriesDateToListTuple>;
  timeSeriesProjections: Array<TimeSeriesDateToListTuple>;
};


export type NodeWithTimeSeriesLatestTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type NodeWithTimeSeriesLatestTimeSeriesByPeriodArgs = {
  period: Scalars['Period'];
  timeSeriesTypeNames: Array<Scalars['FieldName']>;
};


export type NodeWithTimeSeriesLatestTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type NodeWithTimeSeriesTimeSeriesArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};


export type NodeWithTimeSeriesTimeSeriesProjectionsArgs = {
  timeSeriesTypeNames: Array<InputMaybe<Scalars['FieldName']>>;
};

export type NonUsPaymentPrefObjectType = {
  __typename?: 'NonUsPaymentPrefObjectType';
  bankInfoRedacted?: Maybe<Scalars['String']>;
  /** May be None if the payment pref is in draft state via subscriptions workflows. In this case, they may return a CommonPaymentPrefObjectType with the ID set to None. */
  id?: Maybe<Scalars['ID']>;
  nonUsPaymentPrefInstructions: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
  paymentPrefId?: Maybe<Scalars['Int']>;
  positions?: Maybe<Array<PositionObjectType>>;
};

export type NoteDeletedCountObjectType = {
  __typename?: 'NoteDeletedCountObjectType';
  deletedCount: Scalars['Int'];
  deletedIds: Array<Scalars['ID']>;
};

export type NoteDeletedCountObjectTypeOrError = MutationErrorResponse | NoteDeletedCountObjectType;

export type NoteInput = {
  contentType?: InputMaybe<ContentType>;
  date?: InputMaybe<Scalars['Date']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  noteId?: InputMaybe<Scalars['ID']>;
  noteType: NoteType;
  objectId: Scalars['ID'];
  participants?: InputMaybe<Array<Scalars['ID']>>;
  summary?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['Time']>;
};

export type NoteObjectType = PermissionCheckedNode & {
  __typename?: 'NoteObjectType';
  attachments: Array<ActivityAttachmentObjectType>;
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['Date']>;
  entities: Array<AccountObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  isDraft: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  noteType: Scalars['Int'];
  objectId: Scalars['Int'];
  objectType: Scalars['Int'];
  offerings: Array<OfferingObjectType>;
  organizations: Array<ContactObjectType>;
  owner: UserObjectType;
  people: Array<ContactObjectType>;
  regarding?: Maybe<RegardingObjectType>;
  sortDatetime?: Maybe<Scalars['DateTime']>;
  staff: Array<UserObjectType>;
  summary?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
};

export type NoteOrError = MutationErrorResponse | NoteObjectType;

/**
 * Enumeration that defines the types of a note.
 * Please update JsqNoteType in ActivityFeedCard/index.tsx when modifying this
 */
export enum NoteType {
  Meeting = 'meeting',
  Note = 'note',
  PhoneCall = 'phone_call'
}

export type NotificationCampaignObjectType = PermissionCheckedNode & {
  __typename?: 'NotificationCampaignObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export enum NumberFilterExtendedType {
  Blank = 'blank',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  GreaterThanOrEqual = 'greater_than_or_equal',
  InRange = 'in_range',
  LessThan = 'less_than',
  LessThanOrEqual = 'less_than_or_equal',
  NotBlank = 'not_blank',
  NotEquals = 'not_equals'
}

export type ObjectDocuments = {
  objectDocuments?: Maybe<DocumentConnection>;
};


export type ObjectDocumentsObjectDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  manualShareOnly?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<DocumentObjectTypeSortEnum>>>;
};

export type ObjectGroupConnection = {
  __typename?: 'ObjectGroupConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ObjectGroupEdge>;
  pageInfo: PageInfo;
};

export type ObjectGroupEdge = {
  __typename?: 'ObjectGroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ObjectGroupObjectType;
};

export type ObjectGroupObjectObjectType = PermissionCheckedNode & {
  __typename?: 'ObjectGroupObjectObjectType';
  group?: Maybe<ObjectGroupObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  memberObjectId?: Maybe<Scalars['ID']>;
};

export type ObjectGroupObjectObjectTypeConnection = {
  __typename?: 'ObjectGroupObjectObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ObjectGroupObjectObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type ObjectGroupObjectObjectTypeEdge = {
  __typename?: 'ObjectGroupObjectObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ObjectGroupObjectObjectType;
};

export type ObjectGroupObjectType = PermissionCheckedNode & {
  __typename?: 'ObjectGroupObjectType';
  groupObjects?: Maybe<ObjectGroupObjectObjectTypeConnection>;
  /** The ID of the object */
  id: Scalars['ID'];
  investmentEntities?: Maybe<EntityConnection>;
  isFundAdmin?: Maybe<Scalars['Boolean']>;
  isReadyForReporting: Scalars['Boolean'];
  minimumReportingPeriod: Scalars['Period'];
  title?: Maybe<Scalars['String']>;
};


export type ObjectGroupObjectTypeGroupObjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ObjectGroupObjectTypeInvestmentEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  editableOnly?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  investorOwnedOnly?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
};

/** An enumeration. */
export enum ObjectGroupObjectTypeSortEnum {
  IsFundAdminAsc = 'IS_FUND_ADMIN_ASC',
  IsFundAdminDesc = 'IS_FUND_ADMIN_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

/**
 * This interface checks object level edit permissions for an object. It's equipped to handle:
 * -- PositionModel
 * -- AccountModel
 * -- ContactModel
 * -- any model that has an entity_id or contact_id field, and for which checking that field is
 * adequate to assess whether the user has object level access.
 * If you need any logic more advanced than that, roll your own elsewhere or update the resolver
 * below to handle it.
 */
export type ObjectLevelEditPermissions = {
  canEdit: Scalars['Boolean'];
  canLimitedEdit?: Maybe<Scalars['Boolean']>;
};

export type ObjectRefInput = {
  lazyCreate?: InputMaybe<Scalars['Boolean']>;
  objectId?: InputMaybe<Scalars['String']>;
  objectName?: InputMaybe<Scalars['String']>;
  objectType?: InputMaybe<JsqObjectType>;
};

export type ObjectRefObjectType = {
  __typename?: 'ObjectRefObjectType';
  objectId?: Maybe<Scalars['Int']>;
  objectName?: Maybe<Scalars['String']>;
  objectType?: Maybe<JsqObjectType>;
};

export enum ObjectType {
  Account = 'ACCOUNT',
  Arena = 'ARENA',
  BankAccount = 'BANK_ACCOUNT',
  ComplianceProfile = 'COMPLIANCE_PROFILE',
  Distribution = 'DISTRIBUTION',
  DistributionBatch = 'DISTRIBUTION_BATCH',
  Position = 'POSITION',
  User = 'USER'
}

export type OfferingConnection = {
  __typename?: 'OfferingConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<OfferingEdge>;
  pageInfo: PageInfo;
};

export type OfferingEdge = {
  __typename?: 'OfferingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: OfferingObjectType;
};

export type OfferingFolderObjectType = DocumentFolder | PortalLoginRedirectObjectType;

export type OfferingObjectType = ObjectDocuments & PermissionCheckedNode & {
  __typename?: 'OfferingObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  currency: CurrencyObjectType;
  dataRoom?: Maybe<DataRoomObjectType>;
  documentFolder?: Maybe<OfferingFolderObjectType>;
  entities?: Maybe<AccountObjectTypeConnection>;
  /** The ID of the object */
  id: Scalars['ID'];
  lpDisplayName: Scalars['String'];
  name: Scalars['String'];
  objectDocuments?: Maybe<DocumentConnection>;
  prospects?: Maybe<ProspectConnection>;
};


export type OfferingObjectTypeDocumentFolderArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<DocumentFolderSortEnum>;
};


export type OfferingObjectTypeEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type OfferingObjectTypeObjectDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  manualShareOnly?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<DocumentObjectTypeSortEnum>>>;
};


export type OfferingObjectTypeProspectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ProspectObjectTypeSortEnum>>>;
};

/** An enumeration. */
export enum OfferingObjectTypeSortEnum {
  ArenaIdAsc = 'ARENA_ID_ASC',
  ArenaIdDesc = 'ARENA_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

/** An enumeration. */
export enum OpcoIndustryType {
  AbsoluteReturn = 'absolute_return',
  AdministrativeAndSupportServices = 'administrative_and_support_services',
  AdvertisingTechnology = 'advertising_technology',
  Aerospace = 'aerospace',
  Automotive = 'automotive',
  Biotechnology = 'biotechnology',
  BusinessIntelligenceAndAnalytics = 'business_intelligence_and_analytics',
  BusinessProductivitySoftware = 'business_productivity_software',
  Cash = 'cash',
  CommunicationSoftware = 'communication_software',
  CommunicationsAndEnablingTechnologies = 'communications_and_enabling_technologies',
  ConsumerFinance = 'consumer_finance',
  ConsumerMediaAndAdvertising = 'consumer_media_and_advertising',
  ConsumerServicesAndTransactions = 'consumer_services_and_transactions',
  Credit = 'credit',
  CryptoCurrency = 'crypto_currency',
  CryptoFund = 'crypto_fund',
  DeveloperTools = 'developer_tools',
  Development = 'development',
  EducationTechnology = 'education_technology',
  Energy = 'energy',
  EnterpriseApplicationsAndServices = 'enterprise_applications_and_services',
  EnterpriseSoftware = 'enterprise_software',
  Entertainment = 'entertainment',
  EnvironmentalServices = 'environmental_services',
  Equity = 'equity',
  FinancialServices = 'financial_services',
  FoodAndBeverageStores = 'food_and_beverage_stores',
  FoodServicesAndDrinkingPlaces = 'food_services_and_drinking_places',
  FundInterest = 'fund_interest',
  FundsTrustsAndOtherFinancialVehicles = 'funds_trusts_and_other_financial_vehicles',
  GlobalEquity = 'global_equity',
  GlobalMacro = 'global_macro',
  Healthcare = 'healthcare',
  HealthcareLifeSciences = 'healthcare_life_sciences',
  HealthcareSoftware = 'healthcare_software',
  HedgedEquity = 'hedged_equity',
  Hospitality = 'hospitality',
  Industrial = 'industrial',
  Insurance = 'insurance',
  LifeLabScience = 'life_lab_science',
  Media = 'media',
  MediaAndInformationServices = 'media_and_information_services',
  MixedUse = 'mixed_use',
  Multifamily = 'multifamily',
  MultimediaAndDesignSoftware = 'multimedia_and_design_software',
  NetworkManagementSoftware = 'network_management_software',
  NonperformingLoan = 'nonperforming_loan',
  PerformingLoan = 'performing_loan',
  PrivateCredit = 'private_credit',
  PrivateEquity = 'private_equity',
  PublicEquity = 'public_equity',
  RealAssets = 'real_assets',
  RealEstate = 'real_estate',
  RealEstateHotel = 'real_estate_hotel',
  RealEstateMedicalOffices = 'real_estate_medical_offices',
  RealEstateMixedUse = 'real_estate_mixed_use',
  RealEstateMixedUseCommercial = 'real_estate_mixed_use_commercial',
  RealEstateMultifamily = 'real_estate_multifamily',
  RealEstateOffice = 'real_estate_office',
  RealEstateOwned = 'real_estate_owned',
  RealEstateRetail = 'real_estate_retail',
  RealEstateSeniorLiving = 'real_estate_senior_living',
  RentalAndLeasingServices = 'rental_and_leasing_services',
  Retail = 'retail',
  Software = 'software',
  SoftwareDevelopmentApplications = 'software_development_applications',
  SoftwareServices = 'software_services',
  SpecialSituation = 'special_situation',
  TradingAccount = 'trading_account',
  Venture = 'venture'
}

/** An enumeration. */
export enum OpcoSectorType {
  AccommodationAndFoodServices = 'accommodation_and_food_services',
  AdminSupportWasteManagementAndRemediationServices = 'admin_support_waste_management_and_remediation_services',
  AgricultureForestryFishingAndHunting = 'agriculture_forestry_fishing_and_hunting',
  ArtsEntertainmentAndRecreation = 'arts_entertainment_and_recreation',
  Construction = 'construction',
  ConsumerFinancialServices = 'consumer_financial_services',
  Credit = 'credit',
  EducationalServices = 'educational_services',
  FinanceAndInsurance = 'finance_and_insurance',
  HealthCareAndSocialAssistance = 'health_care_and_social_assistance',
  Industrial = 'industrial',
  Information = 'information',
  LongShortEquity = 'long_short_equity',
  ManagementOfCompaniesAndEnterprises = 'management_of_companies_and_enterprises',
  Manufacturing = 'manufacturing',
  Mining = 'mining',
  OilAndGas = 'oil_and_gas',
  Other = 'other',
  OtherServicesExceptPublicAdministration = 'other_services_except_public_administration',
  PrivateDebt = 'private_debt',
  ProfessionalScientificAndTechnicalServices = 'professional_scientific_and_technical_services',
  PublicAdministration = 'public_administration',
  RealEstate = 'real_estate',
  RealEstateAndRentalAndLeasing = 'real_estate_and_rental_and_leasing',
  RetailTrade = 'retail_trade',
  Software = 'software',
  TransportationAndWarehousing = 'transportation_and_warehousing',
  Utilities = 'utilities',
  WholesaleTrade = 'wholesale_trade'
}

export type OperatingCompanyDetailsObjectType = PermissionCheckedNode & {
  __typename?: 'OperatingCompanyDetailsObjectType';
  basicInformationNotes?: Maybe<Scalars['String']>;
  boardSeats?: Maybe<Scalars['Int']>;
  companyDescription?: Maybe<Scalars['String']>;
  fiscalYearEnd?: Maybe<MonthOrQuarter>;
  hqCountry?: Maybe<CountryObjectType>;
  hqState?: Maybe<StateObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  industry?: Maybe<OpcoIndustryType>;
  naicsIndustryGroup?: Maybe<Scalars['Int']>;
  naicsSector?: Maybe<NaicsSector>;
  primaryMarket?: Maybe<PrimaryMarketType>;
  sector?: Maybe<OpcoSectorType>;
  website?: Maybe<Scalars['String']>;
};

export type OperatingCompanyParentObjectType = {
  __typename?: 'OperatingCompanyParentObjectType';
  logo?: Maybe<PhotoObjectType>;
  lpDisplayName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  opcoId: Scalars['ID'];
  overviewAndFinancialsVisible: Scalars['Boolean'];
  owningPositions?: Maybe<Array<Maybe<PositionObjectType>>>;
  parentEntityId: Scalars['ID'];
  published?: Maybe<Scalars['Date']>;
  sector?: Maybe<Scalars['String']>;
  soi?: Maybe<ScheduleOfInvestmentsObjectType>;
};

/** An enumeration. */
export enum OwnershipCalculationMethod {
  CapitalAccountBalance = 'capital_account_balance',
  Commitment = 'commitment',
  Contribution = 'contribution',
  EquityBalance = 'equity_balance',
  Percentage = 'percentage',
  Unit = 'unit'
}

export type OwnershipSnapshotObjectType = {
  __typename?: 'OwnershipSnapshotObjectType';
  accountValue: Wallet;
  delta: PositionDeltaObjectType;
  deltaDirection?: Maybe<DeltaDirection>;
  isFirstDelta: Scalars['Boolean'];
  percentOwnership?: Maybe<Scalars['Float']>;
  transactionAmount?: Maybe<Wallet>;
  unitBalance?: Maybe<Scalars['Float']>;
};

export type OwnershipUnitObjectType = PermissionCheckedNode & {
  __typename?: 'OwnershipUnitObjectType';
  calculationMethod: OwnershipCalculationMethod;
  calculationValueMetric?: Maybe<UnitValueMetricObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  isMethodCommitment: Scalars['Boolean'];
  isMethodCommitmentOrPercentage: Scalars['Boolean'];
  isMethodContribution: Scalars['Boolean'];
  isMethodContributionOrUnit: Scalars['Boolean'];
  isMethodPercentage: Scalars['Boolean'];
  isMethodUnit: Scalars['Boolean'];
  name: Scalars['String'];
  precisionAccounting: Scalars['Int'];
  precisionMetrics: Scalars['Int'];
  precisionPortal: Scalars['Int'];
  shortName: Scalars['String'];
  sortOrder: Scalars['Int'];
  unitValueMetrics?: Maybe<UnitValueMetricObjectTypeConnection>;
};


export type OwnershipUnitObjectTypeUnitValueMetricsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  paginationType?: InputMaybe<PaymentsPaginationType>;
};

export type PastEmploymentObjectType = PermissionCheckedNode & {
  __typename?: 'PastEmploymentObjectType';
  employer?: Maybe<ContactObjectType>;
  employerName?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
};

export type PaymentAccountInput = {
  bankCountry: Scalars['String'];
  mismatchReason?: InputMaybe<Scalars['String']>;
  overriddenRiskRating?: InputMaybe<RiskRating>;
  payeeName?: InputMaybe<Scalars['String']>;
  profileId: Scalars['Int'];
  providerName: Scalars['String'];
};

export type PaymentAccountType = {
  __typename?: 'PaymentAccountType';
  bankCountry?: Maybe<CountryType>;
  bankCountryId: Scalars['Int'];
  id: Scalars['Int'];
  mismatchReason?: Maybe<Scalars['String']>;
  payeeName?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileType>;
  profileId: Scalars['Int'];
  providerName: Scalars['String'];
  riskRating?: Maybe<RiskRatingType>;
};


export type PaymentAccountTypeRiskRatingArgs = {
  programId: Scalars['Int'];
};

export enum PaymentApplicationStatus {
  Rejected = 'REJECTED',
  Started = 'STARTED',
  Submitted = 'SUBMITTED'
}

export type PaymentBankObjectType = {
  __typename?: 'PaymentBankObjectType';
  address?: Maybe<AddressObjectType>;
  isFincenSpecialMeasure?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type PaymentBatchDto = {
  __typename?: 'PaymentBatchDto';
  approvals: Array<PaymentBatchReviewDto>;
  arena: ArenaObjectType;
  bankAccount?: Maybe<BankAccountObjectType>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  canceledByUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creatorId: Scalars['ID'];
  denial?: Maybe<PaymentBatchReviewDto>;
  entity: AccountObjectType;
  fundingPayment?: Maybe<PaymentDto>;
  id: Scalars['ID'];
  isDifferentApproverRequired: Scalars['Boolean'];
  netAmount: Scalars['Money'];
  objectId: Scalars['ID'];
  objectsCount: Scalars['Int'];
  paymentDescription?: Maybe<Scalars['String']>;
  payments: Array<PaymentDto>;
  provider: PaymentProviderDto;
  referenceObject?: Maybe<PaymentsReferenceObjectTypeUnion>;
  requiredApprovalCount: Scalars['Int'];
  status: PaymentServicePaymentBatchStatus;
  wireFundingInstructions?: Maybe<WireFundingInstructionsDto>;
};

export type PaymentBatchDtoConnection = {
  __typename?: 'PaymentBatchDtoConnection';
  edges?: Maybe<Array<PaymentBatchDtoEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int'];
};

export type PaymentBatchDtoEdge = {
  __typename?: 'PaymentBatchDtoEdge';
  cursor: Scalars['String'];
  node: PaymentBatchDto;
};

export type PaymentBatchObjectType = PermissionCheckedNode & {
  __typename?: 'PaymentBatchObjectType';
  addendum?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['Date']>;
  checkMessage?: Maybe<Scalars['String']>;
  descriptiveDate?: Maybe<Scalars['Date']>;
  distributionBatch?: Maybe<DistributionBatchObjectType>;
  documents?: Maybe<FolderDocumentConnection>;
  effectiveDate?: Maybe<Scalars['Date']>;
  /** The ID of the object */
  id: Scalars['ID'];
  mailMethod?: Maybe<Scalars['String']>;
  payments: Array<PaymentObjectType>;
  status?: Maybe<Scalars['String']>;
  transmissionDate?: Maybe<Scalars['Date']>;
};


export type PaymentBatchObjectTypeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PaymentBatchReviewDto = {
  __typename?: 'PaymentBatchReviewDto';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type PaymentBatchesFilterInput = {
  amountRange?: InputMaybe<Array<Scalars['Int']>>;
  arenaIds?: InputMaybe<Array<Scalars['String']>>;
  bankAccountIds?: InputMaybe<Array<Scalars['String']>>;
  batchStatus?: InputMaybe<Array<PaymentServicePaymentBatchStatus>>;
  createdAtRange?: InputMaybe<Array<Scalars['DateTime']>>;
  distributionBatchIds?: InputMaybe<Array<Scalars['String']>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  orderByColumn?: InputMaybe<PaymentBatchesSortField>;
  orderByDirection?: InputMaybe<PaymentsSortDirection>;
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  positionIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum PaymentBatchesSortField {
  Amount = 'AMOUNT',
  BatchStatus = 'BATCH_STATUS',
  CreatedDate = 'CREATED_DATE'
}

export enum PaymentDirection {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type PaymentDto = {
  __typename?: 'PaymentDto';
  amount: Scalars['Money'];
  bankAccount?: Maybe<BankAccountObjectType>;
  bankAccountId?: Maybe<Scalars['ID']>;
  direction: PaymentDirection;
  failedAt?: Maybe<Scalars['DateTime']>;
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  method: PaymentMethod;
  objectId: Scalars['ID'];
  objectType: ObjectType;
  paymentPref?: Maybe<PaymentPrefObjectType>;
  returnCode?: Maybe<ReturnCode>;
  returnReason?: Maybe<Scalars['String']>;
  /** The scheduled process date in UTC. For MT, used to store ACH debit date. For SWIFT, used to store the payment processing date. */
  scheduledProcessDate?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status: PaymentStatus;
  /** The transmitted date in UTC. */
  transmittedAt?: Maybe<Scalars['DateTime']>;
};

export enum PaymentMethod {
  Ach = 'ACH',
  Check = 'CHECK',
  Wire = 'WIRE'
}

/** Payment methods */
export enum PaymentMethodEnum {
  Ach = 'ach',
  Check = 'check',
  Wire = 'wire'
}

/** Used exclusively for inputs. This is due to federation requiring input enums to be updated across all subgraphs if changes are needed. */
export enum PaymentMethodInputEnum {
  Ach = 'ACH',
  Check = 'Check',
  Wire = 'Wire'
}

export type PaymentMethodSupportedCountsObjectType = {
  __typename?: 'PaymentMethodSupportedCountsObjectType';
  /** Number of positions in this account supporting ACH payments. */
  ach: Scalars['Int'];
  /** Number of positions in this account supporting check payments. */
  check: Scalars['Int'];
  /** Number of positions in this account supporting wire payments. */
  wire: Scalars['Int'];
};

export type PaymentObjectType = PermissionCheckedNode & {
  __typename?: 'PaymentObjectType';
  amount: Wallet;
  distribution?: Maybe<DistributionObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  isPaid: Scalars['Boolean'];
  isVoided: Scalars['Boolean'];
  paymentBatch?: Maybe<PaymentBatchObjectType>;
  paymentPref?: Maybe<PaymentPrefObjectType>;
  settlementDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PaymentPersonType {
  LegalEntity = 'legal_entity',
  NaturalPerson = 'natural_person',
  Unknown = 'unknown'
}

/** Corresponds to the PaymentPrefObjectType in the main subgraph. */
export type PaymentPrefObjectType = PermissionCheckedNode & {
  __typename?: 'PaymentPrefObjectType';
  bankInfoRedacted?: Maybe<Scalars['String']>;
  checkAddrCity?: Maybe<Scalars['String']>;
  checkAddrCountry?: Maybe<CountryObjectType>;
  checkAddrCountryId?: Maybe<Scalars['Int']>;
  checkAddrPostalCode?: Maybe<Scalars['String']>;
  checkAddrRegion?: Maybe<Scalars['String']>;
  checkAddrState?: Maybe<StateObjectType>;
  checkAddrStateId?: Maybe<Scalars['Int']>;
  checkAddrStreet?: Maybe<Scalars['String']>;
  checkAddrStreet2?: Maybe<Scalars['String']>;
  checkAddrStreet3?: Maybe<Scalars['String']>;
  checkMailingAddressSameAsAccount?: Maybe<Scalars['Boolean']>;
  checkMemoField?: Maybe<Scalars['String']>;
  checkPayeeOverride?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  paymentMethod?: Maybe<Scalars['String']>;
  paymentPrefFull?: Maybe<PaymentPrefUnionObjectType>;
  /** @deprecated Do not use this field because it is not paginated. Use positions_paginated instead. */
  positions?: Maybe<Array<PositionObjectType>>;
  positionsPaginated?: Maybe<PositionConnection>;
  verificationStatus?: Maybe<VerificationStatus>;
  watchlistStatus: Array<ApiFirstWatchlistDiligenceObjectType>;
  /** Whether a payment pref method is wire and it has an intermediary. Null if payment pref method is not wire. */
  wireHasIntermediary?: Maybe<Scalars['Boolean']>;
  /** Whether a payment pref method is wire and has beneficiary address. Null if not a wire payment pref. */
  wireHasInternationalBeneficiaryAddress?: Maybe<Scalars['Boolean']>;
};


/** Corresponds to the PaymentPrefObjectType in the main subgraph. */
export type PaymentPrefObjectTypePositionsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PaymentPrefUnionObjectType = AchPaymentPrefObjectType | CheckPaymentPrefObjectType | NonUsPaymentPrefObjectType | WirePaymentPrefObjectType;

export enum PaymentProvider {
  ModernTreasury = 'MODERN_TREASURY',
  SwiftMt = 'SWIFT_MT'
}

export type PaymentProviderDto = {
  __typename?: 'PaymentProviderDto';
  id: Scalars['ID'];
  name: PaymentProvider;
};

export enum PaymentServicePaymentBatchStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Funded = 'FUNDED',
  FundingInProgress = 'FUNDING_IN_PROGRESS',
  ReadyForReview = 'READY_FOR_REVIEW',
  ReadyForTreasuryReview = 'READY_FOR_TREASURY_REVIEW',
  Transmitted = 'TRANSMITTED',
  TreasuryDenied = 'TREASURY_DENIED'
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Settled = 'SETTLED'
}

/** An enumeration. */
export enum PaymentType {
  Amortizing = 'amortizing',
  InterestOnly = 'interest_only'
}

export enum PaymentsAchDebitLimitType {
  Month = 'MONTH'
}

export type PaymentsApplicationDto = {
  __typename?: 'PaymentsApplicationDTO';
  id: Scalars['ID'];
  objectId: Scalars['ID'];
  objectType: ObjectType;
  profileId?: Maybe<Scalars['ID']>;
  signatoryUserId?: Maybe<Scalars['ID']>;
  signatureDate?: Maybe<Scalars['DateTime']>;
  status: PaymentApplicationStatus;
};

export enum PaymentsPaginationType {
  Cursor = 'CURSOR',
  Offset = 'OFFSET'
}

export type PaymentsReferenceObjectTypeUnion = DistributionBatchObjectType;

/** Used by Modern Treasury to help determine how to validate the routing number */
export enum PaymentsRoutingNumberType {
  Aba = 'aba',
  Swift = 'swift'
}

export enum PaymentsSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PercentageRange = {
  __typename?: 'PercentageRange';
  maxVal?: Maybe<Scalars['Float']>;
  minVal?: Maybe<Scalars['Float']>;
};

export type PerfMetricsWarningFields = {
  __typename?: 'PerfMetricsWarningFields';
  batches: Array<TransactionInstanceBatchObjectType>;
  newContributionsCount: Scalars['Int'];
  perfAsOfDate?: Maybe<Scalars['Date']>;
  perfLatestTransactionDate: Scalars['Date'];
};

export type PerformanceMetricsObjectType = PermissionCheckedNode & {
  __typename?: 'PerformanceMetricsObjectType';
  cocYield?: Maybe<Scalars['Float']>;
  dpi?: Maybe<Scalars['Float']>;
  effectiveDate?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  irr?: Maybe<Scalars['Float']>;
  irrActual?: Maybe<Scalars['Float']>;
  irrEstimated?: Maybe<Scalars['Float']>;
  rvpi?: Maybe<Scalars['Float']>;
  tvpi?: Maybe<Scalars['Float']>;
};

/**
 * Extends graphene.Node so that we can specify PermissionCheckedNodeField as the node's field.
 *
 * Here's an example usage for PermissionCheckedNode:
 *
 * class UserObjectType(JSQSQLAlchemyObjectType):
 * class Meta:
 * model = UserModel
 * interfaces = (PermissionCheckedNode,)
 *
 * And, here's an example usage of PermissionCheckedNodeField:
 *
 * class Query(ObjectType):
 * user = PermissionCheckedNode.Field(
 * UserObjectType,
 * permissions=['access_cc_tools'])
 *
 * IMPORTANT: It's important that the SQLAlchemy-based object type inherits from JSQSQLAlchemyObjectType, and NOT from
 * graphene.Node, because we want what's returned by PermissionCheckedNode.node_resolver() to be the default resolver,
 * which is JSQSQLAlchemyObjectType.get_node(). This is because JSQSQLAlchemyObjectType.get_node() performs crucial
 * steps, such as applying get_arena_transform(), etc.
 * See JSQSQLAlchemyObjectType and PermissionCheckedNodeField's documentations for more details.
 */
export type PermissionCheckedNode = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type PhoneObjectTypeBase = {
  phoneNumber: Scalars['String'];
  phoneType?: Maybe<PhoneType>;
};

/** Enumeration that defines the types of phone numbers. */
export enum PhoneType {
  Home = 'home',
  HomeFax = 'home_fax',
  Mobile = 'mobile',
  Primary = 'primary',
  Unknown = 'unknown',
  UnknownFax = 'unknown_fax',
  Work = 'work',
  WorkFax = 'work_fax'
}

export type PhotoInterface = {
  documentVersionId: Scalars['Int'];
  height?: Maybe<Scalars['Int']>;
  naturalHeight?: Maybe<Scalars['Int']>;
  naturalWidth?: Maybe<Scalars['Int']>;
  photoUrl: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};


export type PhotoInterfacePhotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  option?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PhotoObjectType = PermissionCheckedNode & PhotoInterface & {
  __typename?: 'PhotoObjectType';
  caption?: Maybe<Scalars['String']>;
  documentVersionId: Scalars['Int'];
  height?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  naturalHeight?: Maybe<Scalars['Int']>;
  naturalWidth?: Maybe<Scalars['Int']>;
  photoUrl: Scalars['String'];
  showOnLoginPage: Scalars['Boolean'];
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


export type PhotoObjectTypePhotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  option?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PhotoObjectTypeConnection = {
  __typename?: 'PhotoObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<PhotoObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type PhotoObjectTypeEdge = {
  __typename?: 'PhotoObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: PhotoObjectType;
};

export type PortalLoginRedirectObjectType = {
  __typename?: 'PortalLoginRedirectObjectType';
  globalInviteToken?: Maybe<Scalars['String']>;
  isStaffUser?: Maybe<Scalars['Boolean']>;
};

export enum PortalStatus {
  /** Portal status is "Metrics not visible" */
  MetricsNotVisible = 'METRICS_NOT_VISIBLE',
  /** Portal status is "Metrics out-of-date" */
  MetricsOutOfDate = 'METRICS_OUT_OF_DATE',
  /** Portal status is "Not Visible" */
  NotVisible = 'NOT_VISIBLE',
  /** Portal status is "Visible", highlighted with the red color */
  NotVisibleDanger = 'NOT_VISIBLE_DANGER',
  /** Portal status is "Visible" */
  Visible = 'VISIBLE',
  /** Portal status is "Visible", highlighted with the green color */
  VisibleSuccess = 'VISIBLE_SUCCESS'
}

/**
 * Represents which types of transactions the user and position has permissions to view.
 * Needed on the front end to determine, for example, whether to link a transaction total to
 * the appropriate transaction type
 */
export type PortalTransactionPermissionsObjectType = {
  __typename?: 'PortalTransactionPermissionsObjectType';
  capitalCalls?: Maybe<Scalars['Boolean']>;
  distributions?: Maybe<Scalars['Boolean']>;
  managementFees?: Maybe<Scalars['Boolean']>;
  nav?: Maybe<Scalars['Boolean']>;
  netIncome?: Maybe<Scalars['Boolean']>;
  transactions?: Maybe<Scalars['Boolean']>;
  unitValues?: Maybe<Scalars['Boolean']>;
};

export type Position = {
  __typename?: 'Position';
  id: Scalars['Int'];
  lots: Array<InvestmentTotals>;
  name: Scalars['String'];
  status: Scalars['String'];
  totals: InvestmentTotals;
};

export type PositionArgumentFilters = {
  /** Determines whether to return only positions with payment prefs or positions without payment prefs. By default all are returned. */
  hasPaymentPref?: InputMaybe<Scalars['Boolean']>;
  /** Determines whether to return only positions with a specific payment pref ID. */
  paymentPrefId?: InputMaybe<Scalars['ID']>;
  /** Determines whether to return only positions with a specific payment method. */
  supportedPaymentMethod?: InputMaybe<PaymentMethodInputEnum>;
  /** Determines whether to return only positions that do NOT support a specific payment method. Should not be used in conjunction with supported_payment_method. */
  unsupportedPaymentMethod?: InputMaybe<PaymentMethodInputEnum>;
};

export type PositionConnection = {
  __typename?: 'PositionConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<PositionEdge>;
  pageInfo: PageInfo;
};

export type PositionDeltaObjectType = PermissionCheckedNode & {
  __typename?: 'PositionDeltaObjectType';
  capitalCall?: Maybe<CapitalCallObjectType>;
  closeRound?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  deltaAmountCommitted: Wallet;
  deltaPercentOwnershipManual?: Maybe<Scalars['Float']>;
  deltaUnitCount?: Maybe<Scalars['Float']>;
  distribution?: Maybe<DistributionObjectType>;
  fromAccountName?: Maybe<Scalars['String']>;
  fromPosition?: Maybe<PositionObjectType>;
  fromPositionId?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  netIncome?: Maybe<NetIncomeObjectType>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<PositionStatus>;
  toAccountName?: Maybe<Scalars['String']>;
  toPosition?: Maybe<PositionObjectType>;
  toPositionId?: Maybe<Scalars['Int']>;
  transaction?: Maybe<TransactionObjectType>;
};

export type PositionDeltaObjectTypeConnection = {
  __typename?: 'PositionDeltaObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<PositionDeltaObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type PositionDeltaObjectTypeEdge = {
  __typename?: 'PositionDeltaObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: PositionDeltaObjectType;
};

export type PositionEdge = {
  __typename?: 'PositionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: PositionObjectType;
};

/**
 * This represents the statuses that we'll accept for requests for a particular position status. Positions will always
 * be one of two statuses, either Active or Complete (deleted statuses are not considered). A status is made up of the
 * position's status and the related entity's status. It follows this matrix:
 * Active: position is active and entity is active.
 * Completed: Any other case (position is inactive or entity is completed or has no status).
 *
 * When requesting all position regardless of status, use "All". This is not a valid status for a position to have but
 * is designed to return both active and completed positions.
 *
 * If combining statuses from multiple positions, it's possible not all positions will be the same status so use None
 * to represent that case.
 */
export enum PositionEntityStatus {
  Active = 'active',
  All = 'all',
  Completed = 'completed'
}

export type PositionFilterInput = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  arenaIds: Array<InputMaybe<Scalars['ID']>>;
  /** Include deleted positions */
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type PositionInFilterInput = {
  quantifier?: InputMaybe<SetFilterQuantifier>;
  selected?: InputMaybe<Array<Scalars['String']>>;
};

export type PositionObjectType = ObjectLevelEditPermissions & PermissionCheckedNode & {
  __typename?: 'PositionObjectType';
  account?: Maybe<AccountObjectType>;
  activeInOwnReportingPeriod: Scalars['Boolean'];
  activeInReportingPeriod: Scalars['Boolean'];
  amountCommitted?: Maybe<Wallet>;
  arena?: Maybe<ArenaObjectType>;
  canEdit: Scalars['Boolean'];
  canLimitedEdit?: Maybe<Scalars['Boolean']>;
  capitalCalls?: Maybe<CapitalCallObjectTypeConnection>;
  cashflowHistoryData?: Maybe<CashflowHistoryChartObjectType>;
  commitmentSnapshots: Array<OwnershipSnapshotObjectType>;
  contacts?: Maybe<ContactObjectTypeConnection>;
  contributions?: Maybe<TransactionConnection>;
  deltas?: Maybe<PositionDeltaObjectTypeConnection>;
  distributions?: Maybe<DistributionConnection>;
  dripPercentage?: Maybe<Scalars['Float']>;
  entityCurrency: CurrencyCode;
  extensionFields: Array<ExtensionFieldTuple>;
  gpLiteTransactionTotals: GpLiteTransactionTotalsObjectType;
  hasPendingPaymentPrefChangeRequests: Scalars['Boolean'];
  hideCommitment: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  initialAmountCommitted?: Maybe<Wallet>;
  investmentEntity: AccountObjectType;
  investorGroup?: Maybe<InvestorGroupObjectType>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isIlpaGpReporting: Scalars['Boolean'];
  lpTransactionDownloadUrl?: Maybe<Scalars['String']>;
  managementFees?: Maybe<ManagementFeeObjectTypeConnection>;
  navs?: Maybe<NavObjectTypeConnection>;
  netIncomes?: Maybe<NetIncomeConnection>;
  ownershipSnapshots: Array<OwnershipSnapshotObjectType>;
  ownershipUnit: OwnershipUnitObjectType;
  paymentPref?: Maybe<PaymentPrefObjectType>;
  paymentPrefUpdatedAt: Scalars['Date'];
  pendingCapitalCalls?: Maybe<Array<CapitalCallObjectType>>;
  percentOwnership?: Maybe<Scalars['Float']>;
  percentOwnershipManual?: Maybe<Scalars['Float']>;
  performanceMetrics?: Maybe<PerformanceMetricsObjectType>;
  photo?: Maybe<PhotoObjectType>;
  portalTransactionPermissions?: Maybe<PortalTransactionPermissionsObjectType>;
  redemptions?: Maybe<TransactionConnection>;
  reportRequestType: ReportRequestType;
  reportingCadence: ReportingCadence;
  showCashFlowHistoryChart?: Maybe<Scalars['Boolean']>;
  showOwnershipAsCommitment: Scalars['Boolean'];
  showTimeWeightedReturns?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  taxDocDeliveryPref?: Maybe<TaxDocDeliveryPrefObjectType>;
  totalManagementFeeAdjusted?: Maybe<Wallet>;
  totalsByTransactionType: Array<TotalCapitalActivityByTypeObjectType>;
  transactionTotals: TransactionTotalsObjectTypeBase;
  transactionTotalsSummary: TransactionTotalsSummaryObjectType;
  transactions?: Maybe<TransactionConnection>;
  twrCompoundingPeriod?: Maybe<Scalars['String']>;
  twrEndDate?: Maybe<Scalars['Date']>;
  twrPeriodMetrics: TwrPeriodMetricsTableObjectType;
  twrStartDate?: Maybe<Scalars['Date']>;
  twrSummaryMetrics?: Maybe<TwrSummaryMetricsObjectType>;
  unitCountOwnership?: Maybe<Scalars['Float']>;
  unitValues: Array<UnitValueObjectType>;
  waterfallReturnData?: Maybe<WaterfallReturnDataInfo>;
};


export type PositionObjectTypeActiveInReportingPeriodArgs = {
  period: Scalars['Period'];
};


export type PositionObjectTypeCapitalCallsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['Period']>;
  sort?: InputMaybe<Array<InputMaybe<CapitalCallObjectTypeSortEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PositionObjectTypeContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PositionObjectTypeContributionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['Period']>;
  sort?: InputMaybe<Array<InputMaybe<TransactionObjectTypeSortEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PositionObjectTypeDeltasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PositionObjectTypeDistributionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['Period']>;
  sort?: InputMaybe<Array<InputMaybe<DistributionObjectTypeSortEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PositionObjectTypeGpLiteTransactionTotalsArgs = {
  period: Scalars['Period'];
};


export type PositionObjectTypeManagementFeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PositionObjectTypeNavsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PositionObjectTypeNetIncomesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['Period']>;
  sort?: InputMaybe<Array<InputMaybe<NetIncomeObjectTypeSortEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PositionObjectTypeRedemptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['Period']>;
  sort?: InputMaybe<Array<InputMaybe<TransactionObjectTypeSortEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PositionObjectTypeTotalsByTransactionTypeArgs = {
  signEffect: TransactionTypeEffect;
};


export type PositionObjectTypeTransactionTotalsArgs = {
  currency?: InputMaybe<CurrencyCode>;
  restrictMetricsBalancesToAsOfDate?: InputMaybe<Scalars['Boolean']>;
};


export type PositionObjectTypeTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<Scalars['Period']>;
  sort?: InputMaybe<Array<InputMaybe<TransactionObjectTypeSortEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PositionObjectTypeTwrPeriodMetricsArgs = {
  period?: InputMaybe<Scalars['Period']>;
};

export type PositionObjectTypeConnection = {
  __typename?: 'PositionObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<PositionObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type PositionObjectTypeEdge = {
  __typename?: 'PositionObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: PositionObjectType;
};

/** An enumeration. */
export enum PositionObjectTypeSortEnum {
  DripPercentageAsc = 'DRIP_PERCENTAGE_ASC',
  DripPercentageDesc = 'DRIP_PERCENTAGE_DESC',
  IsIlpaGpReportingAsc = 'IS_ILPA_GP_REPORTING_ASC',
  IsIlpaGpReportingDesc = 'IS_ILPA_GP_REPORTING_DESC',
  PercentOwnershipManualAsc = 'PERCENT_OWNERSHIP_MANUAL_ASC',
  PercentOwnershipManualDesc = 'PERCENT_OWNERSHIP_MANUAL_DESC',
  ReportingCadenceAsc = 'REPORTING_CADENCE_ASC',
  ReportingCadenceDesc = 'REPORTING_CADENCE_DESC',
  UnitCountOwnershipAsc = 'UNIT_COUNT_OWNERSHIP_ASC',
  UnitCountOwnershipDesc = 'UNIT_COUNT_OWNERSHIP_DESC'
}

/** An enumeration. */
export enum PositionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type PresignedUrlInput = {
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PresignedUrlObjectType = {
  __typename?: 'PresignedUrlObjectType';
  fileName?: Maybe<Scalars['String']>;
  objectKey?: Maybe<Scalars['String']>;
  s3Url?: Maybe<Scalars['String']>;
};

export type PreviewEntityGlSyncInput = {
  arenaId: Scalars['GlobalID'];
  entityId: Scalars['Int'];
  includeAllTransactions?: InputMaybe<Scalars['Boolean']>;
  netIncomeEndDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type PreviewEntityGlSyncNewInput = {
  arenaId: Scalars['GlobalID'];
  endDate: Scalars['Date'];
  entityId: Scalars['Int'];
  includeAllTransactions?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type PreviewEntityGlSyncResponse = {
  __typename?: 'PreviewEntityGLSyncResponse';
  flaggedZeroOwnershipPositions?: Maybe<Array<SyncPositionInfo>>;
  missingPositions?: Maybe<Array<SyncPositionInfo>>;
  netIncomeExisting?: Maybe<Array<GlSyncTransactionPreview>>;
  netIncomeNew?: Maybe<Array<GlSyncTransactionPreview>>;
};

export type PreviewTransferGlSyncInput = {
  arenaId: Scalars['GlobalID'];
  endDate: Scalars['Date'];
  entityId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};

/** An enumeration. */
export enum PrimaryMarketType {
  EastAsia = 'east_asia',
  Europe = 'europe',
  LatinAmerica = 'latin_america',
  NorthAfrica = 'north_africa',
  NorthAmerica = 'north_america',
  SouthAsia = 'south_asia',
  SubSaharanAfrica = 'sub_saharan_africa'
}

export type ProcessTransactionMappingGqlError = {
  __typename?: 'ProcessTransactionMappingGQLError';
  errorMessage: Scalars['String'];
  errorType: MappingDataValidationErrorType;
  rows: Array<Array<Scalars['Int']>>;
};

export type ProcessTransactionMappingInput = {
  arenaId: Scalars['Int'];
  entityIds?: InputMaybe<Array<Scalars['Int']>>;
  isUpdate?: InputMaybe<Scalars['Boolean']>;
  mappingId?: InputMaybe<Scalars['Int']>;
  mappingName?: InputMaybe<Scalars['String']>;
  mappingType: MappingTypeEnum;
  objectKey: Scalars['String'];
  sourceId: Scalars['Int'];
};

export type ProcessTransactionMappingResponse = {
  __typename?: 'ProcessTransactionMappingResponse';
  error: Array<ProcessTransactionMappingGqlError>;
  success: Scalars['Boolean'];
};

export enum ProductSubtype {
  Agriculture = 'agriculture',
  AmusementPark = 'amusement_park',
  ApartmentMultifamilyTownhome = 'apartment_multifamily_townhome',
  AssistedLiving = 'assisted_living',
  Budget = 'budget',
  Casino = 'casino',
  CharterSchool = 'charter_school',
  ColdStorage = 'cold_storage',
  Commercial = 'commercial',
  CommunityCenter = 'community_center',
  Condominium = 'condominium',
  DataCenter = 'data_center',
  Duplex = 'duplex',
  FlexSpace = 'flex_space',
  Fourplex = 'fourplex',
  FullService = 'full_service',
  GardenStyle = 'garden_style',
  GolfCourse = 'golf_course',
  HighRise_10Plus = 'high_rise_10_plus',
  HighRise_25Plus = 'high_rise_25_plus',
  Hospice = 'hospice',
  Hospital = 'hospital',
  IndependentLiving = 'independent_living',
  LifestyleCenter = 'lifestyle_center',
  LimitedService = 'limited_service',
  LongTermAcuteCare = 'long_term_acute_care',
  LowRise = 'low_rise',
  LowRise_1_6 = 'low_rise_1_6',
  ManufacturedHousing = 'manufactured_housing',
  Manufacturing = 'manufacturing',
  Marina = 'marina',
  MedicalOffice = 'medical_office',
  MemoryCare = 'memory_care',
  MidRise_5_9 = 'mid_rise_5_9',
  MidRise_7_25 = 'mid_rise_7_25',
  MobileHomePark = 'mobile_home_park',
  MultiStory = 'multi_story',
  NeighborhoodCenter = 'neighborhood_center',
  NursingHomes = 'nursing_homes',
  OfficeShowroom = 'office_showroom',
  OtherEducationInfrastructure = 'other_education_infrastructure',
  OtherIndustrial = 'other_industrial',
  OtherRetail = 'other_retail',
  OutletCenter = 'outlet_center',
  ParkingStructure = 'parking_structure',
  PowerCenter = 'power_center',
  PrivateSchool = 'private_school',
  RaceTrack = 'race_track',
  RegionalMall = 'regional_mall',
  ResearchDevelopment = 'research_development',
  Residential = 'residential',
  Resort = 'resort',
  SingleFamilyDetached = 'single_family_detached',
  SingleStory = 'single_story',
  SingleTenant = 'single_tenant',
  Solar = 'solar',
  SportsRelated = 'sports_related',
  StripCenter = 'strip_center',
  SuperRegionalMall = 'super_regional_mall',
  SurfaceLot = 'surface_lot',
  TheaterConcertHall = 'theater_concert_hall',
  ThemeFestivalCenter = 'theme_festival_center',
  Timberland = 'timberland',
  Townhome = 'townhome',
  Townhouse = 'townhouse',
  Triplex = 'triplex',
  Warehouse = 'warehouse',
  Water = 'water',
  Wind = 'wind'
}

/** An enumeration. */
export enum ProductType {
  ApartmentMultifamily = 'apartment_multifamily',
  CondoDevelopment = 'condo_development',
  EducationInfrastructure = 'education_infrastructure',
  Entertainment = 'entertainment',
  HealthCare = 'health_care',
  Homebuilding = 'homebuilding',
  Hotel = 'hotel',
  Industrial = 'industrial',
  Infrastructure = 'infrastructure',
  Land = 'land',
  MixedUse = 'mixed_use',
  Office = 'office',
  Other = 'other',
  Parking = 'parking',
  Residential = 'residential',
  Retail = 'retail',
  SelfStorage = 'self_storage',
  SeniorLiving = 'senior_living',
  StudentHousing = 'student_housing',
  Unspecified = 'unspecified'
}

export type ProductTypeAndSizeObjectType = PermissionCheckedNode & {
  __typename?: 'ProductTypeAndSizeObjectType';
  asset?: Maybe<AssetObjectType>;
  bedCount?: Maybe<Scalars['Int']>;
  buildingCount?: Maybe<Scalars['Int']>;
  climateControlled?: Maybe<Scalars['Boolean']>;
  commercialUnitCount?: Maybe<Scalars['Int']>;
  cropTypes?: Maybe<Array<Maybe<CropType>>>;
  driveUpAccess?: Maybe<Scalars['Boolean']>;
  floorCount?: Maybe<Scalars['Int']>;
  fourBedroomUnitCount?: Maybe<Scalars['Int']>;
  grossSqft?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  indoorStorageAccess?: Maybe<Scalars['Boolean']>;
  keyCount?: Maybe<Scalars['Int']>;
  lotCount?: Maybe<Scalars['Int']>;
  navAllocation?: Maybe<Scalars['Float']>;
  netSqft?: Maybe<Scalars['Int']>;
  oneBedroomUnitCount?: Maybe<Scalars['Int']>;
  parcelAcres?: Maybe<Scalars['Float']>;
  parcelNumber?: Maybe<Scalars['String']>;
  parcelSqft?: Maybe<Scalars['Int']>;
  productSubtype?: Maybe<ProductSubtype>;
  productType?: Maybe<ProductType>;
  studioUnitCount?: Maybe<Scalars['Int']>;
  threeBedroomUnitCount?: Maybe<Scalars['Int']>;
  twoBedroomUnitCount?: Maybe<Scalars['Int']>;
  unitCount?: Maybe<Scalars['Int']>;
};

export type ProductTypeAndSizeObjectTypeConnection = {
  __typename?: 'ProductTypeAndSizeObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ProductTypeAndSizeObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type ProductTypeAndSizeObjectTypeEdge = {
  __typename?: 'ProductTypeAndSizeObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ProductTypeAndSizeObjectType;
};

export type ProfileAddressType = {
  __typename?: 'ProfileAddressType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryType>;
  countryId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isPrimaryDomicile?: Maybe<Scalars['Boolean']>;
  postalCode?: Maybe<Scalars['String']>;
  profileId: Scalars['Int'];
  province?: Maybe<Scalars['String']>;
  state?: Maybe<StateType>;
  stateId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  subprovince?: Maybe<Scalars['String']>;
};

export type ProfileCaseType = {
  __typename?: 'ProfileCaseType';
  case: ComplianceCaseType;
  profile: ProfileType;
};

export type ProfileEntityOwnershipDomicileCountryType = {
  __typename?: 'ProfileEntityOwnershipDomicileCountryType';
  countryId: Scalars['Int'];
  countryName: Scalars['String'];
  countryRiskRating: RiskRating;
  id: Scalars['Int'];
  profileId: Scalars['Int'];
};

export type ProfileExternalObjectInput = {
  externalObjectId: Scalars['String'];
  externalObjectType?: InputMaybe<Scalars['String']>;
  externalObjectTypeEnum?: InputMaybe<ComplianceExternalObjectType>;
};

export type ProfileIndustryType = {
  __typename?: 'ProfileIndustryType';
  id: Scalars['Int'];
  industryId: Scalars['Int'];
  industryName: Scalars['String'];
  industryRestrictionLevel: IndustryRestrictionLevel;
  profileId: Scalars['Int'];
};

export enum ProfileRelationshipEnum {
  Controller = 'controller',
  Owner = 'owner',
  PaymentBeneficiary = 'payment_beneficiary',
  Signatory = 'signatory',
  Unassigned = 'unassigned'
}

export type ProfileRelationshipType = {
  __typename?: 'ProfileRelationshipType';
  childProfile: ProfileType;
  childProfileId: Scalars['Int'];
  id: Scalars['Int'];
  parentProfileId: Scalars['Int'];
  percentOwnership?: Maybe<Scalars['Float']>;
  relationshipType: ProfileRelationshipEnum;
  title?: Maybe<Scalars['String']>;
};

export type ProfileType = {
  __typename?: 'ProfileType';
  accountType: AccountTypeEnum;
  addresses: Array<ProfileAddressType>;
  authorizedSignatories: Array<ProfileRelationshipType>;
  beneficialOwners: Array<ProfileRelationshipType>;
  caseDataEntryStatus?: Maybe<DataEntryStatus>;
  complianceCases: Array<ComplianceCaseProfileMapType>;
  complianceObjectType: ComplianceObjectType;
  controllingPersons: Array<ProfileRelationshipType>;
  countryOfCitizenship?: Maybe<CountryType>;
  countryOfCitizenshipId?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  dateOfOrganization?: Maybe<Scalars['Date']>;
  documents: Array<DocumentType>;
  entityOwnershipDomiciles: Array<ProfileEntityOwnershipDomicileCountryType>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  industries: Array<ProfileIndustryType>;
  lastName?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  natureOfBusiness?: Maybe<Scalars['String']>;
  organizationalJurisdiction?: Maybe<CountryType>;
  organizationalJurisdictionId?: Maybe<Scalars['Int']>;
  parentRelationships: Array<ProfileRelationshipType>;
  path?: Maybe<Scalars['String']>;
  paymentAccounts: Array<Maybe<PaymentAccountType>>;
  riskRating?: Maybe<RiskRatingType>;
  screeningRecord?: Maybe<ScreeningRecordType>;
  taxIdEncrypted?: Maybe<Scalars['String']>;
  taxIdType?: Maybe<TaxIdType>;
  taxIdUnencrypted?: Maybe<Scalars['String']>;
  taxIdUnencryptedLast4Digits: Scalars['String'];
};


export type ProfileTypeCaseDataEntryStatusArgs = {
  arenaId: Scalars['Int'];
  externalObject: ExternalObjectInput;
};


export type ProfileTypeRiskRatingArgs = {
  programId: Scalars['Int'];
};

export type ProfileTypeConnection = {
  __typename?: 'ProfileTypeConnection';
  edges: Array<ProfileTypeEdge>;
  pageInfo: PageInfo;
};

export type ProfileTypeEdge = {
  __typename?: 'ProfileTypeEdge';
  cursor: Scalars['String'];
  node: ProfileType;
};

export type ProfitLossEntryObjectType = {
  __typename?: 'ProfitLossEntryObjectType';
  categoryKey: Scalars['FieldName'];
  categoryLabel: Scalars['String'];
  sourceLabel: Scalars['String'];
  wallet: Wallet;
};

export type ProfitLossPeriodObjectType = {
  __typename?: 'ProfitLossPeriodObjectType';
  comment?: Maybe<Scalars['String']>;
  entries: Array<ProfitLossEntryObjectType>;
  period: Scalars['Int'];
  year: Scalars['Int'];
};

export type Program = {
  __typename?: 'Program';
  id: Scalars['Int'];
  name: Scalars['String'];
  programType: Scalars['String'];
};

export type PromoteTransactionBatchMutation = {
  __typename?: 'PromoteTransactionBatchMutation';
  errorMsg?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Prompt = {
  __typename?: 'Prompt';
  body?: Maybe<Scalars['String']>;
  choices?: Maybe<Array<Choice>>;
  /** Deprecated. Use depends_on_prompt_ids instead. */
  dependentPromptIds: Array<Scalars['StrawberryObjectId']>;
  /** A list of prompt ids that this prompt depends on. */
  dependsOnPromptIds: Array<Scalars['StrawberryObjectId']>;
  enablingLogic: Scalars['JsonLogicRule'];
  id: Scalars['StrawberryObjectId'];
  internalTitle?: Maybe<Scalars['String']>;
  promptType: LpResponseType;
  required: Scalars['Boolean'];
  templateFile?: Maybe<File>;
};

export type PromptInput = {
  body?: InputMaybe<Scalars['String']>;
  choices?: InputMaybe<Array<ChoiceInput>>;
  enablingLogic?: Scalars['JsonLogicRule'];
  id?: InputMaybe<Scalars['StrawberryObjectId']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  promptType: LpResponseType;
  required: Scalars['Boolean'];
  templateFile?: InputMaybe<FileInput>;
};

export type ProspectConnection = {
  __typename?: 'ProspectConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ProspectEdge>;
  pageInfo: PageInfo;
};

export type ProspectContactMapConnection = {
  __typename?: 'ProspectContactMapConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ProspectContactMapEdge>;
  pageInfo: PageInfo;
};

export type ProspectContactMapEdge = {
  __typename?: 'ProspectContactMapEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ProspectContactMapObjectType;
};

export type ProspectContactMapObjectType = PermissionCheckedNode & {
  __typename?: 'ProspectContactMapObjectType';
  amountExpected?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObjectType>;
  contactId?: Maybe<Scalars['ID']>;
  currency: CurrencyObjectType;
  dataRoomKey?: Maybe<Scalars['String']>;
  dataRoomLastAccessDate?: Maybe<Scalars['DateTime']>;
  dataRoomProvisionDate?: Maybe<Scalars['DateTime']>;
  extensionFields: Array<ExtensionFieldTuple>;
  /** The ID of the object */
  id: Scalars['ID'];
  isMainContact: Scalars['Boolean'];
  isProspectClosed?: Maybe<Scalars['Boolean']>;
  isProspectDeclined?: Maybe<Scalars['Boolean']>;
  isProspectOfferingCompleted?: Maybe<Scalars['Boolean']>;
  likelihood?: Maybe<Scalars['Int']>;
  offeringDataRoomPublished?: Maybe<Scalars['Boolean']>;
  offeringName?: Maybe<Scalars['String']>;
  offeringStage?: Maybe<Scalars['String']>;
  otherContacts?: Maybe<Array<ContactObjectType>>;
  prospect?: Maybe<ProspectObjectType>;
  prospectClass?: Maybe<Scalars['String']>;
  prospectId?: Maybe<Scalars['ID']>;
  prospectNotes?: Maybe<Scalars['String']>;
  prospectStatus?: Maybe<Scalars['String']>;
  prospectSubscriptionStatus?: Maybe<Scalars['String']>;
  prospectType?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ProspectContactMapObjectTypeSortEnum {
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  DataRoomKeyAsc = 'DATA_ROOM_KEY_ASC',
  DataRoomKeyDesc = 'DATA_ROOM_KEY_DESC',
  DataRoomLastAccessDateAsc = 'DATA_ROOM_LAST_ACCESS_DATE_ASC',
  DataRoomLastAccessDateDesc = 'DATA_ROOM_LAST_ACCESS_DATE_DESC',
  DataRoomProvisionDateAsc = 'DATA_ROOM_PROVISION_DATE_ASC',
  DataRoomProvisionDateDesc = 'DATA_ROOM_PROVISION_DATE_DESC',
  IsMainContactAsc = 'IS_MAIN_CONTACT_ASC',
  IsMainContactDesc = 'IS_MAIN_CONTACT_DESC',
  ProspectIdAsc = 'PROSPECT_ID_ASC',
  ProspectIdDesc = 'PROSPECT_ID_DESC'
}

export type ProspectEdge = {
  __typename?: 'ProspectEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ProspectObjectType;
};

export type ProspectForFilterInput = {
  quantifier?: InputMaybe<SetFilterQuantifier>;
  selected?: InputMaybe<Array<Scalars['String']>>;
};

export type ProspectObjectType = PermissionCheckedNode & {
  __typename?: 'ProspectObjectType';
  additionalDocuments?: Maybe<Array<Maybe<LpNonSignedDocumentObjectType>>>;
  contacts?: Maybe<ContactObjectTypeConnection>;
  documentFolder?: Maybe<DocumentFolder>;
  /** The ID of the object */
  id: Scalars['ID'];
  offeringName?: Maybe<Scalars['String']>;
  prospectContactMaps?: Maybe<Array<Maybe<ProspectContactMapObjectType>>>;
  /**
   * Concatenated list of names for all the contacts on this prospect.
   * Can be used within a title, such as "Prepared for" in data rooms.
   */
  prospectContactNameConcatenation?: Maybe<Scalars['String']>;
  recipientView?: Maybe<RecipientViewObjectType>;
  subscription?: Maybe<SubscriptionObjectType>;
  title?: Maybe<Scalars['String']>;
};


export type ProspectObjectTypeContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ProspectObjectTypeDocumentFolderArgs = {
  dataRoomKey?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['Int']>;
};

export type ProspectObjectTypeConnection = {
  __typename?: 'ProspectObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<ProspectObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type ProspectObjectTypeEdge = {
  __typename?: 'ProspectObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ProspectObjectType;
};

/** An enumeration. */
export enum ProspectObjectTypeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC'
}

export type ProspectReadyForCountersignInput = {
  offeringId: Scalars['Int'];
};

export type ProspectReadyForCountersignResponse = AsyncJobObjectType | MutationErrorResponse;

export type PublishSectionErrorResponse = {
  __typename?: 'PublishSectionErrorResponse';
  errorCode: Scalars['String'];
  invalidPrompts?: Maybe<Array<InvalidPrompt>>;
  message: Scalars['String'];
};

/** An enumeration. */
export enum PublishStatus {
  Mixed = 'mixed',
  None = 'none',
  PublishedOnly = 'published_only',
  UnpublishedOnly = 'unpublished_only'
}

export type Query = {
  __typename?: 'Query';
  account?: Maybe<AccountObjectType>;
  /** Used to fetch the list of contacts for a list of account IDs. */
  accountContacts?: Maybe<AccountContactsConnection>;
  accounts?: Maybe<AccountConnection>;
  achDebitLimitDetails: AchDebitLimitDto;
  /** Fetch all non Person contacts, such as all Organizations. */
  allNonPersonContacts?: Maybe<ContactConnection>;
  announcementNotifications: Array<AnnouncementNotificationDto>;
  arenas?: Maybe<ArenaConnection>;
  /** Used to query a list of bank accounts. A user must be authorized to view all bank accounts returned or else a GQL error will be thrown. */
  bankAccounts?: Maybe<Array<BankAccountObjectType>>;
  changeRequests: ChangeRequestQueryResponseQueryErrorResponse;
  complianceCase?: Maybe<ComplianceCaseType>;
  complianceCases: ComplianceCaseTypeConnection;
  complianceCasesByObjectId: Array<ComplianceCaseType>;
  complianceCasesSlice: ComplianceCaseSliceType;
  complianceCountries: Array<CountryType>;
  complianceCountry?: Maybe<CountryType>;
  complianceExternalObject?: Maybe<ExternalObjectType>;
  complianceIndustries?: Maybe<Array<IndustryType>>;
  compliancePaymentAccount?: Maybe<PaymentAccountType>;
  complianceProfile?: Maybe<ProfileType>;
  complianceProfileRelationship?: Maybe<ProfileRelationshipType>;
  complianceProfiles: ProfileTypeConnection;
  complianceReviewer?: Maybe<ReviewerType>;
  contact?: Maybe<ContactObjectType>;
  contactActivityFeed?: Maybe<ContactActivityFeedWrapperObjectType>;
  contactAssigneeUsers?: Maybe<UserConnection>;
  /** Fetch all contact extension fields. */
  contactExtensionFields?: Maybe<ExtensionFieldConnection>;
  /** Fetch single prospect of the given contact. */
  contactProspect?: Maybe<ProspectContactMapObjectType>;
  /** Fetch all prospects of the given contact. */
  contactProspects?: Maybe<ProspectContactMapConnection>;
  contacts?: Maybe<ContactsPageGridObjectType>;
  /** Used to get the all supported countries */
  countries?: Maybe<CountryConnection>;
  /** Used to query the current arena. A user must be authorized to view the arena. For staff requests only. */
  currentArena?: Maybe<ArenaObjectType>;
  currentUser?: Maybe<UserObjectType>;
  /** Deduped payment preferences across multiple accounts for global users. Used in a multi-arena context to present a list of payment preferences to users that they can then apply to other accounts. */
  dedupedPaymentPrefs: Array<PaymentPrefObjectType>;
  devFeatures: DevFeatures;
  /** Retrieves the distribution by its ID if the user has the requisite permissions to view it */
  distribution?: Maybe<DistributionObjectType>;
  distributionBatch?: Maybe<DistributionBatchObjectType>;
  /** Used to query for paginated list of distribution batches */
  distributionBatches?: Maybe<DistributionBatchConnection>;
  distributions?: Maybe<DistributionConnection>;
  emailActivity?: Maybe<EmailObjectType>;
  emailDistributionList: Array<EmailDistributionListObjectType>;
  emailPreview?: Maybe<EmailPreviewObjectType>;
  entitiesGLBatchSyncStatus: Array<EntitiesGlBatchSyncStatusResponse>;
  entitiesGLSyncDetails: Array<EntityGlSyncDetails>;
  entitiesGLSyncStatus: Array<EntityGlSyncStatusResponse>;
  entityGLSyncDetails: EntityGlSyncDetails;
  entityGLSyncSettings: EntityGlSyncSettingsResponse;
  entityPreviewGLSync: PreviewEntityGlSyncResponse;
  entityPreviewGLSyncNew: EntityPreviewResult;
  entityTransferGlBatchesDetails: EntityTransferGlBatchSyncDetail;
  entityTransferPreviewGlSync: Array<TransferGlBatchPreviewResult>;
  example: Scalars['String'];
  extensionFieldEnumValues?: Maybe<Array<ExtensionFieldEnumObjectType>>;
  getAllDashboards: Array<DashboardObjectType>;
  getComplianceCaseAuditLogs: Array<ComplianceCaseAuditLogObject>;
  getComplianceCaseEvent: Array<ComplianceCaseEvent>;
  getComplianceReport: ComplianceReportObject;
  getDashboard?: Maybe<DashboardObjectType>;
  getDashboardsByEmbedLocation: Array<DashboardObjectType>;
  getWhetherContainsMultipleClients: Scalars['Boolean'];
  /** Used to query an integration details. */
  integrationDetails?: Maybe<IntegrationDetailsObjectType>;
  /** Used to get the hubspot oauth url */
  integrationHubspotOauthUrl?: Maybe<HubspotIntegrationOauthUrlType>;
  /** Used to query a list of investment entities. A user must be authorized to view all investment */
  investmentEntities?: Maybe<EntityConnection>;
  investmentEntitiesByArena?: Maybe<EntityConnection>;
  /** Used to query a list of investment entities. A user must be authorized to view all investment */
  investmentEntitiesForProgramAssignment?: Maybe<EntitiesPageGridObjectType>;
  investmentEntitiesUnsorted?: Maybe<EntityConnection>;
  /** Used to query an investment entity. A user must be authorized to view the investment entity.For staff requests only. */
  investmentEntity?: Maybe<AccountObjectType>;
  investmentEntityFromProspect?: Maybe<AccountObjectType>;
  /** Get linked capital call batch or distribution batch */
  linkedTransactionBatch?: Maybe<LinkedBatchObjectType>;
  mappingFile: Scalars['String'];
  noteActivity?: Maybe<NoteObjectType>;
  objectGroups?: Maybe<ObjectGroupConnection>;
  payment: PaymentDto;
  paymentBatch: PaymentBatchDto;
  paymentBatches: Array<PaymentBatchDto>;
  paymentBatchesConnection: PaymentBatchDtoConnection;
  /** Used to query a list of payment pref IDs. A user must be authorized to view all payment pref IDs returned or else a GQL error will be thrown. */
  paymentPrefs?: Maybe<Array<PaymentPrefObjectType>>;
  paymentsApplication: PaymentsApplicationDto;
  positionImportTask?: Maybe<BulkPositionImportTaskObjectType>;
  positions?: Maybe<PositionConnection>;
  programsAssignableToEntities?: Maybe<Array<Program>>;
  /** Fetch all prospect extension fields. */
  prospectExtensionFields?: Maybe<ExtensionFieldConnection>;
  recentChangeRequests: RecentlyAddedContactsResponseQueryErrorResponse;
  reviewChangeRequest: ReviewChangeRequestResponseQueryErrorResponse;
  s3PresignedUrlObject: S3PresignedUrlObject;
  savedContactFilter?: Maybe<SavedContactFilterObjectType>;
  savedContactFilters?: Maybe<Array<SavedContactFilterObjectType>>;
  scheduleOfInvestments: ScheduleOfInvestments;
  searchAccounts?: Maybe<AccountConnection>;
  searchAllAccounts?: Maybe<AccountConnection>;
  searchAllOfferings?: Maybe<OfferingConnection>;
  searchContacts?: Maybe<ContactConnection>;
  searchOfferings?: Maybe<OfferingConnection>;
  searchPositions?: Maybe<PositionConnection>;
  searchProspectsByOffering?: Maybe<ProspectConnection>;
  searchUsers?: Maybe<UserConnection>;
  searchedContacts?: Maybe<ContactSearchResultObjectType>;
  session?: Maybe<SessionStatusObjectType>;
  sourceDataTypes: Array<FromFieldType>;
  sourceMappingGroupsList: Array<SourceMappingGroupItemType>;
  sources: Array<SourceType>;
  /** Used to get the all supported states */
  states?: Maybe<StateConnection>;
  storedEmailActivity?: Maybe<StoredEmailObjectType>;
  /** Used to query a subscription by data room key. A user must be authorized to view the subscription. For portal requests only. */
  subscriptionByDataRoomKey?: Maybe<SubscriptionV2ObjectType>;
  /** Used to query a subscription by ID. A user must be authorized to view the subscription. For staff requests only. */
  subscriptionById?: Maybe<SubscriptionV2ObjectType>;
  /** Used to query a subscription contact by UUID. A user must be authorized to view the subscription */
  subscriptionContactByUuid?: Maybe<SubscriptionContactObjectType>;
  /** Fetch all tags based on object type. */
  tags?: Maybe<TagConnection>;
  userAnnouncementNotifications: Array<UserAnnouncementNotificationDto>;
  /** Validate a routing number given a routing number type. Returns a boolean whether or not the routing number provided is valid. */
  validateRoutingNumber: RoutingNumberDto;
};


export type QueryAccountArgs = {
  accountId: Scalars['ID'];
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAccountContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AccountContactsFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountContactMapObjectTypeSortEnum>>>;
};


export type QueryAccountsArgs = {
  accountFilters: AccountFilterInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
};


export type QueryAchDebitLimitDetailsArgs = {
  distributionBatchId: Scalars['ID'];
  scheduledProcessDate: Scalars['DateTime'];
};


export type QueryAllNonPersonContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ContactObjectTypeSortEnum>>>;
};


export type QueryArenasArgs = {
  after?: InputMaybe<Scalars['String']>;
  applicationContext?: InputMaybe<ApplicationContext>;
  arenaIds?: InputMaybe<Array<Scalars['ID']>>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeAdminArena?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<ArenaObjectTypeSortEnum>>>;
  sortByName?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBankAccountsArgs = {
  input: BankAccountsInput;
};


export type QueryChangeRequestsArgs = {
  objectInput: ChangeRequestQueryInput;
};


export type QueryComplianceCaseArgs = {
  id: Scalars['Int'];
};


export type QueryComplianceCasesArgs = {
  filters?: InputMaybe<Array<ComplianceCaseFilters>>;
  sorts?: InputMaybe<Array<ComplianceCaseSort>>;
};


export type QueryComplianceCasesByObjectIdArgs = {
  arenaId: Scalars['Int'];
  externalObject: ExternalObjectInput;
};


export type QueryComplianceCasesSliceArgs = {
  filters?: InputMaybe<Array<ComplianceCaseFilters>>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  sorts?: InputMaybe<Array<ComplianceCaseSort>>;
};


export type QueryComplianceCountryArgs = {
  id: Scalars['Int'];
};


export type QueryComplianceExternalObjectArgs = {
  id: Scalars['Int'];
};


export type QueryCompliancePaymentAccountArgs = {
  id: Scalars['Int'];
};


export type QueryComplianceProfileArgs = {
  id: Scalars['Int'];
};


export type QueryComplianceProfileRelationshipArgs = {
  id: Scalars['Int'];
};


export type QueryComplianceProfilesArgs = {
  externalId?: InputMaybe<Scalars['String']>;
  externalType?: InputMaybe<Scalars['String']>;
  externalTypeEnum?: InputMaybe<ComplianceExternalObjectType>;
  pageArgs?: InputMaybe<PageArgs>;
  profileKind?: InputMaybe<ComplianceObjectType>;
  taxId?: InputMaybe<Scalars['String']>;
};


export type QueryComplianceReviewerArgs = {
  id: Scalars['Int'];
};


export type QueryContactArgs = {
  contactId: Scalars['ID'];
};


export type QueryContactActivityFeedArgs = {
  contactId: Scalars['ID'];
  maxTextBlobLength?: InputMaybe<Scalars['Int']>;
  startTimestamp?: InputMaybe<Scalars['String']>;
};


export type QueryContactAssigneeUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<UserObjectTypeSortEnum>>>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryContactExtensionFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeFieldIds?: InputMaybe<Array<Scalars['ID']>>;
  includeVisibleOnly?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ExtensionFieldObjectTypeSortEnum>>>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryContactProspectArgs = {
  contactId: Scalars['ID'];
  prospectId: Scalars['ID'];
};


export type QueryContactProspectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contactId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ProspectContactMapObjectTypeSortEnum>>>;
};


export type QueryContactsArgs = {
  contactFilter?: InputMaybe<ContactFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<CountryObjectTypeSortEnum>>>;
};


export type QueryDistributionArgs = {
  distributionId: Scalars['ID'];
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDistributionBatchArgs = {
  id: Scalars['ID'];
};


export type QueryDistributionBatchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DistributionBatchesFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<DistributionBatchObjectTypeSortEnum>>>;
};


export type QueryDistributionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distributionBatchId: Scalars['ID'];
  filters?: InputMaybe<DistributionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<DistributionObjectTypeSortEnum>>>;
};


export type QueryEmailActivityArgs = {
  emailId: Scalars['ID'];
};


export type QueryEmailDistributionListArgs = {
  arenaId: Scalars['String'];
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  investorCanManage?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEmailPreviewArgs = {
  emailBatchId: Scalars['ID'];
  recipientObjectId: Scalars['Int'];
  recipientObjectType: JsqObjectType;
};


export type QueryEntitiesGlBatchSyncStatusArgs = {
  arenaId: Scalars['GlobalID'];
  entityIds: Array<Scalars['Int']>;
};


export type QueryEntitiesGlSyncDetailsArgs = {
  arenaId: Scalars['GlobalID'];
  entityIds: Array<Scalars['Int']>;
};


export type QueryEntitiesGlSyncStatusArgs = {
  arenaId: Scalars['GlobalID'];
  entityIds: Array<Scalars['Int']>;
};


export type QueryEntityGlSyncDetailsArgs = {
  arenaId: Scalars['GlobalID'];
  entityId: Scalars['Int'];
};


export type QueryEntityGlSyncSettingsArgs = {
  arenaId: Scalars['GlobalID'];
  entityId: Scalars['Int'];
};


export type QueryEntityPreviewGlSyncArgs = {
  syncInput: PreviewEntityGlSyncInput;
};


export type QueryEntityPreviewGlSyncNewArgs = {
  syncInput: PreviewEntityGlSyncNewInput;
};


export type QueryEntityTransferGlBatchesDetailsArgs = {
  arenaId: Scalars['Int'];
  entityId: Scalars['Int'];
};


export type QueryEntityTransferPreviewGlSyncArgs = {
  syncInput: PreviewTransferGlSyncInput;
};


export type QueryExtensionFieldEnumValuesArgs = {
  enumName: Scalars['String'];
};


export type QueryGetAllDashboardsArgs = {
  objectInput: GetAllDashboardsInput;
};


export type QueryGetComplianceCaseAuditLogsArgs = {
  caseId: Scalars['Int'];
};


export type QueryGetComplianceCaseEventArgs = {
  arenaId: Scalars['Int'];
  caseId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  tag: ComplianceCaseAuditTagType;
};


export type QueryGetComplianceReportArgs = {
  id: Scalars['Int'];
};


export type QueryGetDashboardArgs = {
  objectInput: GetDashboardInput;
};


export type QueryGetDashboardsByEmbedLocationArgs = {
  objectInput: GetDashboardByEmbedLocationInput;
};


export type QueryGetWhetherContainsMultipleClientsArgs = {
  createReportInput: CreateComplianceReportInput;
};


export type QueryIntegrationDetailsArgs = {
  provenance: Scalars['String'];
};


export type QueryInvestmentEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<EntitiesFilters>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
};


export type QueryInvestmentEntitiesByArenaArgs = {
  after?: InputMaybe<Scalars['String']>;
  arenaId?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filterEntitiesWithTransactions?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
};


export type QueryInvestmentEntitiesForProgramAssignmentArgs = {
  filters?: InputMaybe<EntitiesFilters>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sorts?: InputMaybe<Array<InputMaybe<EntitiesSort>>>;
};


export type QueryInvestmentEntitiesUnsortedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  editableOnly?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  investorOwnedOnly?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryInvestmentEntityArgs = {
  entityId: Scalars['ID'];
};


export type QueryInvestmentEntityFromProspectArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  prospectId?: InputMaybe<Scalars['ID']>;
};


export type QueryLinkedTransactionBatchArgs = {
  batchId: Scalars['ID'];
  objectType: JsqObjectType;
};


export type QueryMappingFileArgs = {
  input: GenerateMappingFileGqlInput;
};


export type QueryNoteActivityArgs = {
  noteId: Scalars['ID'];
};


export type QueryObjectGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  authorizedClass?: InputMaybe<AuthorizedClass>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeReadonlyObjectGroups?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ObjectGroupObjectTypeSortEnum>>>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentBatchArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentBatchesArgs = {
  distributionBatchId: Scalars['ID'];
};


export type QueryPaymentBatchesConnectionArgs = {
  inputFilter: PaymentBatchesFilterInput;
  paginationArgs: PaginationArgs;
};


export type QueryPaymentPrefsArgs = {
  paymentPrefIds: Array<Scalars['ID']>;
};


export type QueryPaymentsApplicationArgs = {
  objectId: Scalars['ID'];
  objectType: ObjectType;
};


export type QueryPositionImportTaskArgs = {
  arenaId: Scalars['ID'];
  jobId: Scalars['ID'];
};


export type QueryPositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  positionFilters: PositionFilterInput;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
};


export type QueryProspectExtensionFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeFieldIds?: InputMaybe<Array<Scalars['ID']>>;
  includeVisibleOnly?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ExtensionFieldObjectTypeSortEnum>>>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryRecentChangeRequestsArgs = {
  objectInput: RecentlyAddedInput;
};


export type QueryReviewChangeRequestArgs = {
  objectInput: ReviewChangeRequestQueryInput;
};


export type QuerySavedContactFilterArgs = {
  filterId: Scalars['ID'];
};


export type QuerySavedContactFiltersArgs = {
  isMobile: Scalars['Boolean'];
};


export type QueryScheduleOfInvestmentsArgs = {
  input: EntitySoiInput;
};


export type QuerySearchAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  objectFilter?: InputMaybe<SearchObjectFilterInputEnum>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
  term: Scalars['String'];
};


export type QuerySearchAllAccountsArgs = {
  accountType?: InputMaybe<SearchAccountTypeInputEnum>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountObjectTypeSortEnum>>>;
  term?: InputMaybe<Scalars['String']>;
};


export type QuerySearchAllOfferingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  objectFilter?: InputMaybe<SearchObjectFilterInputEnum>;
  sort?: InputMaybe<Array<InputMaybe<OfferingObjectTypeSortEnum>>>;
};


export type QuerySearchContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  objectFilter?: InputMaybe<SearchObjectFilterInputEnum>;
  sort?: InputMaybe<Array<InputMaybe<ContactObjectTypeSortEnum>>>;
  term: Scalars['String'];
};


export type QuerySearchOfferingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  objectFilter?: InputMaybe<SearchObjectFilterInputEnum>;
  sort?: InputMaybe<Array<InputMaybe<OfferingObjectTypeSortEnum>>>;
  term: Scalars['String'];
};


export type QuerySearchPositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entityId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<PositionObjectTypeSortEnum>>>;
  term: Scalars['String'];
};


export type QuerySearchProspectsByOfferingArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offeringId: Scalars['ID'];
  sort?: InputMaybe<Array<InputMaybe<ProspectObjectTypeSortEnum>>>;
  term: Scalars['String'];
};


export type QuerySearchUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  objectFilter?: InputMaybe<SearchObjectFilterInputEnum>;
  sort?: InputMaybe<Array<InputMaybe<UserObjectTypeSortEnum>>>;
  term: Scalars['String'];
};


export type QuerySearchedContactsArgs = {
  contactObjectFilter?: InputMaybe<ContactObjectFilterEnum>;
  excludeContactIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  term: Scalars['String'];
};


export type QuerySourceDataTypesArgs = {
  arenaId: Scalars['GlobalID'];
  sourceId: Scalars['GlobalID'];
};


export type QuerySourceMappingGroupsListArgs = {
  arenaId: Scalars['GlobalID'];
};


export type QuerySourcesArgs = {
  arenaId: Scalars['GlobalID'];
};


export type QueryStatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<StateObjectTypeSortEnum>>>;
};


export type QueryStoredEmailActivityArgs = {
  emailId: Scalars['ID'];
};


export type QuerySubscriptionByDataRoomKeyArgs = {
  arenaDomain: Scalars['String'];
  dataRoomKey: Scalars['String'];
};


export type QuerySubscriptionByIdArgs = {
  subscriptionId: Scalars['ID'];
};


export type QuerySubscriptionContactByUuidArgs = {
  arenaDomain: Scalars['String'];
  contactUuid: Scalars['String'];
  dataRoomKey: Scalars['String'];
};


export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isAccount?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  objectType: JsqObjectType;
  sort?: InputMaybe<Array<InputMaybe<TagObjectTypeSortEnum>>>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryUserAnnouncementNotificationsArgs = {
  filters?: InputMaybe<Array<AnnouncementFilterType>>;
};


export type QueryValidateRoutingNumberArgs = {
  validateRoutingNumberInput: ValidateRoutingNumberInput;
};

export type QueryErrorResponse = {
  __typename?: 'QueryErrorResponse';
  errorCode: Scalars['String'];
  message: Scalars['String'];
};

/** Union type for all types of questions. */
export type Question = FreeFormQuestion | MultipleChoiceQuestion;

/** A group of related questions. */
export type QuestionSet = {
  __typename?: 'QuestionSet';
  /** If True, the block or question shows regardless of the subscriber's account type. */
  allSubscriberTypes?: Maybe<Scalars['Boolean']>;
  /** A longer description of the questions in this set.  This field is only available to GP staff users and is not displayed to the LP answering the questions. */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** True if the LP has completed all questions in this set. */
  isComplete?: Maybe<Scalars['Boolean']>;
  /** True if the LP has started filling out answers for this set. */
  isStarted?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<Maybe<Question>>>;
  /** The contacts who need to answer the questions.  An empty list indicates the questions must be answered once for the entire account. */
  responders?: Maybe<Array<Maybe<SubscriptionContactObjectType>>>;
  /** If allSubscriberTypes is False, the block or question should only be shown for these subscriber account types. */
  subscriberTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Short title to display to the user describing the questions in this set. */
  title?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum QuestionTypeEnum {
  Choice = 'choice',
  Text = 'text'
}

export type RecentlyAddedContactsResponse = {
  __typename?: 'RecentlyAddedContactsResponse';
  responses: Array<ChangeRequestResponse>;
};

export type RecentlyAddedContactsResponseQueryErrorResponse = QueryErrorResponse | RecentlyAddedContactsResponse;

export type RecentlyAddedInput = {
  accountIds: Array<Scalars['String']>;
  daysAgo: Scalars['Int'];
  limit: Scalars['Int'];
  requestTypes: Array<Scalars['String']>;
  sortField: Scalars['String'];
  sortOrder: SortOrder;
};

export type RecipientInfoObjectType = {
  __typename?: 'RecipientInfoObjectType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  objectType?: Maybe<JsqObjectType>;
};

export type RecipientObjectType = {
  __typename?: 'RecipientObjectType';
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RecipientViewObjectType = {
  __typename?: 'RecipientViewObjectType';
  isComplete?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type RedemptionDetail = {
  __typename?: 'RedemptionDetail';
  amountAvailable: Wallet;
  lotDate: Scalars['Date'];
  redeemedAmount: Wallet;
  unitsAvailable: Scalars['Float'];
};

export type RefundPaymentInput = {
  paymentId: Scalars['String'];
};

export type RegardingObjectType = ContactObjectType | PositionObjectType | ProspectObjectType;

export type ReissuePaymentInput = {
  paymentId: Scalars['String'];
  paymentPrefId: Scalars['Int'];
};

/** An enumeration. */
export enum RelativePeriod {
  PresentOnly = 'present_only',
  Qoq = 'qoq',
  Yoy = 'yoy'
}

export type RemoveContactRequest = {
  __typename?: 'RemoveContactRequest';
  account: AccountObjectType;
  accountContactMap?: Maybe<AccountContactMapObjectType>;
  changeRequester: UserObjectType;
  contactUnderReview: ContactObjectType;
};

export type RemoveNetNoticeLinkageFederation = {
  __typename?: 'RemoveNetNoticeLinkageFederation';
  result: NetNoticeLinkageResponse;
};

export type RemovePaymentPrefFromAccountInput = {
  /** Account to remove payment pref from */
  accountId: Scalars['ID'];
  /** Remove a given payment preference from all positions of an account */
  paymentPrefId: Scalars['ID'];
};

export type RemovePaymentPrefFromAccountOutput = MutationErrorResponse | RemovePaymentPrefFromAccountResponse;

export type RemovePaymentPrefFromAccountResponse = {
  __typename?: 'RemovePaymentPrefFromAccountResponse';
  updatedPositions: Array<PositionObjectType>;
};

export type RemovePaymentPrefFromPositionInput = {
  /** Position to remove payment pref from */
  positionId: Scalars['ID'];
};

export type RemovePaymentPrefFromPositionOutput = {
  __typename?: 'RemovePaymentPrefFromPositionOutput';
  position: PositionObjectType;
};

export type RemovePaymentPrefFromPositionResponse = MutationErrorResponse | RemovePaymentPrefFromPositionOutput;

export type RemoveTag = {
  __typename?: 'RemoveTag';
  /** The tag that was unassigned from the objects. */
  tag?: Maybe<TagObjectType>;
  /** Objects from whom the tag was unassigned. */
  updatedObjects?: Maybe<Array<Maybe<TaggableObjectType>>>;
};

export type ReorderCustomSectionsResponse = {
  __typename?: 'ReorderCustomSectionsResponse';
  sections: Array<ArenaSection>;
};

export type ReorderCustomSectionsResponseMutationErrorResponse = MutationErrorResponse | ReorderCustomSectionsResponse;

export type ReorderEntitySectionsInput = {
  arenaId: Scalars['Int'];
  mainEntityId: Scalars['Int'];
  orderedSectionIds: Array<Scalars['StrawberryObjectId']>;
};

export type ReorderPromptsInput = {
  arenaId: Scalars['Int'];
  orderedPromptIds: Array<Scalars['StrawberryObjectId']>;
  sectionId: Scalars['StrawberryObjectId'];
};

export type ReorderSectionsInput = {
  arenaId: Scalars['Int'];
  orderedSectionIds: Array<Scalars['StrawberryObjectId']>;
};

/** An enumeration. */
export enum ReportRequestType {
  ExcludeTransactions = 'exclude_transactions',
  Full = 'full',
  NotRequested = 'not_requested'
}

/** An enumeration. */
export enum ReportingCadence {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type RequestRedoActivityObjectType = {
  __typename?: 'RequestRedoActivityObjectType';
  id?: Maybe<Scalars['ID']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export type RestartBankAccountVerificationInput = {
  bankAccountId: Scalars['ID'];
};

export type RestrictedDocumentObjectType = {
  __typename?: 'RestrictedDocumentObjectType';
  downloadName: Scalars['String'];
  id: Scalars['ID'];
};

export enum ReturnCode {
  C01 = 'C01',
  C02 = 'C02',
  C03 = 'C03',
  C05 = 'C05',
  C06 = 'C06',
  C07 = 'C07',
  C08 = 'C08',
  C09 = 'C09',
  C13 = 'C13',
  C14 = 'C14',
  C61 = 'C61',
  C62 = 'C62',
  C63 = 'C63',
  C64 = 'C64',
  C65 = 'C65',
  C66 = 'C66',
  C67 = 'C67',
  C68 = 'C68',
  C69 = 'C69',
  Ca900 = 'CA900',
  Ca901 = 'CA901',
  Ca902 = 'CA902',
  Ca903 = 'CA903',
  Ca904 = 'CA904',
  Ca905 = 'CA905',
  Ca907 = 'CA907',
  Ca908 = 'CA908',
  Ca909 = 'CA909',
  Ca910 = 'CA910',
  Ca911 = 'CA911',
  Ca912 = 'CA912',
  Ca914 = 'CA914',
  Ca922 = 'CA922',
  R01 = 'R01',
  R02 = 'R02',
  R03 = 'R03',
  R04 = 'R04',
  R05 = 'R05',
  R06 = 'R06',
  R07 = 'R07',
  R08 = 'R08',
  R09 = 'R09',
  R10 = 'R10',
  R11 = 'R11',
  R12 = 'R12',
  R13 = 'R13',
  R14 = 'R14',
  R15 = 'R15',
  R16 = 'R16',
  R17 = 'R17',
  R18 = 'R18',
  R19 = 'R19',
  R20 = 'R20',
  R21 = 'R21',
  R22 = 'R22',
  R23 = 'R23',
  R24 = 'R24',
  R25 = 'R25',
  R26 = 'R26',
  R27 = 'R27',
  R28 = 'R28',
  R29 = 'R29',
  R30 = 'R30',
  R31 = 'R31',
  R32 = 'R32',
  R33 = 'R33',
  R34 = 'R34',
  R35 = 'R35',
  R36 = 'R36',
  R37 = 'R37',
  R38 = 'R38',
  R39 = 'R39',
  R40 = 'R40',
  R41 = 'R41',
  R42 = 'R42',
  R43 = 'R43',
  R44 = 'R44',
  R45 = 'R45',
  R46 = 'R46',
  R47 = 'R47',
  R48 = 'R48',
  R49 = 'R49',
  R50 = 'R50',
  R51 = 'R51',
  R52 = 'R52',
  R53 = 'R53',
  R54 = 'R54',
  R55 = 'R55',
  R56 = 'R56',
  R57 = 'R57',
  R58 = 'R58',
  R59 = 'R59',
  R60 = 'R60',
  R61 = 'R61',
  R62 = 'R62',
  R63 = 'R63',
  R64 = 'R64',
  R65 = 'R65',
  R66 = 'R66',
  R67 = 'R67',
  R68 = 'R68',
  R69 = 'R69',
  R70 = 'R70',
  R71 = 'R71',
  R72 = 'R72',
  R73 = 'R73',
  R74 = 'R74',
  R75 = 'R75',
  R76 = 'R76',
  R77 = 'R77',
  R78 = 'R78',
  R79 = 'R79',
  R80 = 'R80',
  R81 = 'R81',
  R82 = 'R82',
  R83 = 'R83',
  R84 = 'R84',
  R85 = 'R85'
}

export enum ReviewAction {
  Approve = 'APPROVE',
  Reject = 'REJECT'
}

export type ReviewChangeRequestInput = {
  metadata?: InputMaybe<Scalars['JSON']>;
  requestId: Scalars['StrawberryObjectId'];
  requestPayload: ChangeRequestPayload;
  requestStatus: ReviewAction;
};

export type ReviewChangeRequestMutationResponse = {
  __typename?: 'ReviewChangeRequestMutationResponse';
  actions: Array<Scalars['JSON']>;
  requestId: Scalars['StrawberryObjectId'];
  requestPayload: Scalars['JSON'];
  requestType: ChangeRequestType;
  status: Scalars['String'];
};

export type ReviewChangeRequestMutationResponseMutationErrorResponse = MutationErrorResponse | ReviewChangeRequestMutationResponse;

export type ReviewChangeRequestQueryInput = {
  requestId: Scalars['StrawberryObjectId'];
};

export type ReviewChangeRequestResponse = {
  __typename?: 'ReviewChangeRequestResponse';
  changeRequestArena?: Maybe<ChangeRequestArena>;
  note?: Maybe<Scalars['String']>;
  requestId: Scalars['StrawberryObjectId'];
  requestType: ChangeRequestType;
  requestedAt?: Maybe<Scalars['DateTime']>;
  reviewChangeRequest?: Maybe<UpdateCommunicationPrefsRequestRemoveContactRequestAddContactRequestUpdatePositionPaymentPrefRequest>;
  status: Scalars['String'];
};

export type ReviewChangeRequestResponseQueryErrorResponse = QueryErrorResponse | ReviewChangeRequestResponse;

export enum ReviewerRole {
  Analyst = 'analyst',
  Manager = 'manager',
  ReadOnly = 'read_only'
}

export type ReviewerType = {
  __typename?: 'ReviewerType';
  emailAddress: Scalars['String'];
  externalUser?: Maybe<ExternalObjectType>;
  externalUserId: Scalars['Int'];
  id: Scalars['Int'];
  role: ReviewerRole;
};

export enum RiskRating {
  Halt = 'halt',
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  None = 'none'
}

export type RiskRatingInput = {
  overriddenRating: RiskRating;
  profileId: Scalars['Int'];
  programId: Scalars['Int'];
};

export type RiskRatingType = {
  __typename?: 'RiskRatingType';
  calculatedRating: RiskRating;
  id: Scalars['Int'];
  overriddenRating?: Maybe<RiskRating>;
  overrideReviewerId?: Maybe<Scalars['Int']>;
  profileId: Scalars['Int'];
  programId: Scalars['Int'];
};

export type RiskScoreObjectType = {
  __typename?: 'RiskScoreObjectType';
  asOfDate?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  status?: Maybe<RiskScoreStatus>;
};

/** An enumeration. */
export enum RiskScoreStatus {
  ChecksCompleted = 'ChecksCompleted',
  Error = 'Error',
  NotStarted = 'NotStarted',
  Pending = 'Pending'
}

export type RoleObjectType = PermissionCheckedNode & {
  __typename?: 'RoleObjectType';
  description?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type RoleObjectTypeConnection = {
  __typename?: 'RoleObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<RoleObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type RoleObjectTypeEdge = {
  __typename?: 'RoleObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: RoleObjectType;
};

/** DTO used by validate routing number. */
export type RoutingNumberDto = {
  __typename?: 'RoutingNumberDTO';
  /** Address of the bank corresponding to a valid routing number. */
  bankAddress?: Maybe<AddressDto>;
  /** String representing the bank name of a valid routing number. Undefined if routing is invalid */
  bankName?: Maybe<Scalars['String']>;
  /** True if the routing number, routing number type combo is valid */
  isValid: Scalars['Boolean'];
  /** String representing a routing number. We will strip all non-alphanumeric characters before validating */
  routingNumber: Scalars['String'];
  routingNumberType: PaymentsRoutingNumberType;
};

export type S3PresignedUrlObject = {
  __typename?: 'S3PresignedUrlObject';
  fields: S3UrlFieldsObject;
  uploadUrl: Scalars['String'];
};

export type S3UrlFieldsObject = {
  __typename?: 'S3UrlFieldsObject';
  key: Scalars['String'];
  policy: Scalars['String'];
  xAmzAlgorithm?: Maybe<Scalars['String']>;
  xAmzCredential?: Maybe<Scalars['String']>;
  xAmzDate?: Maybe<Scalars['String']>;
  xAmzSecurityToken?: Maybe<Scalars['String']>;
  xAmzSignature?: Maybe<Scalars['String']>;
};

export type SavedContactFilterObjectType = {
  __typename?: 'SavedContactFilterObjectType';
  contactFilter?: Maybe<ContactFiltersObjectType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SavedContactFilterObjectTypeOrError = MutationErrorResponse | SavedContactFilterObjectType;

export type ScheduleOfInvestments = {
  __typename?: 'ScheduleOfInvestments';
  investments: Array<Investments>;
  totals: Array<InvestmentTotals>;
};

export type ScheduleOfInvestmentsObjectType = {
  __typename?: 'ScheduleOfInvestmentsObjectType';
  securities?: Maybe<Array<SecurityObjectType>>;
  totals?: Maybe<SecurityTotals>;
};

export enum ScreeningMatchResult {
  FalsePositive = 'FalsePositive',
  GatheringInformation = 'GatheringInformation',
  TrueMatch = 'TrueMatch',
  Unknown = 'Unknown'
}

export type ScreeningMatchType = {
  __typename?: 'ScreeningMatchType';
  details: Scalars['JSON'];
  id: Scalars['Int'];
  pepCategory?: Maybe<Scalars['Int']>;
  result: ScreeningMatchResult;
  screeningRecordId: Scalars['Int'];
};

export type ScreeningMatchUpdateInput = {
  id: Scalars['Int'];
  pepCategory?: InputMaybe<Scalars['Int']>;
  result: ScreeningMatchResult;
};

export enum ScreeningRecordResult {
  FalsePositive = 'FalsePositive',
  NoMatches = 'NoMatches',
  PotentialMatches = 'PotentialMatches',
  TrueMatch = 'TrueMatch'
}

export enum ScreeningRecordStatus {
  Complete = 'Complete',
  GatheringInformation = 'GatheringInformation',
  InProgress = 'InProgress',
  NotScreened = 'NotScreened',
  ReviewNeeded = 'ReviewNeeded'
}

export type ScreeningRecordType = {
  __typename?: 'ScreeningRecordType';
  createdBy?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  matches?: Maybe<Array<ScreeningMatchType>>;
  profileId: Scalars['Int'];
  result: ScreeningRecordResult;
  searchInput: Scalars['JSON'];
  status: ScreeningRecordStatus;
};

/** Used exclusively for inputs. This is due to federation requiring input enums to be updated across all subgraphs if changes are needed. */
export enum SearchAccountTypeInputEnum {
  InvestmentEntity = 'investment_entity',
  InvestorAccount = 'investor_account'
}

/** Used exclusively for inputs. This is due to federation requiring input enums to be updated across all subgraphs if changes are needed. */
export enum SearchObjectFilterInputEnum {
  IsAccountForOwningPositions = 'is_account_for_owning_positions',
  IsAnyStaff = 'is_any_staff',
  IsEntity = 'is_entity',
  IsEntityForUpdate = 'is_entity_for_update',
  IsNotEntity = 'is_not_entity',
  IsNotEntityForUpdate = 'is_not_entity_for_update',
  IsNotFundAdminStaff = 'is_not_fund_admin_staff',
  IsOrg = 'is_org',
  IsOrgExactNameMatch = 'is_org_exact_name_match',
  IsOrgForUpdate = 'is_org_for_update',
  IsPerson = 'is_person',
  IsPersonExactEmailMatch = 'is_person_exact_email_match',
  IsPersonForUpdate = 'is_person_for_update',
  IsStaff = 'is_staff',
  IsStaffCrmCrmr = 'is_staff_crm_crmr',
  IsStaffFm = 'is_staff_fm',
  IsStaffIm = 'is_staff_im',
  IsStaffOrPerson = 'is_staff_or_person'
}

export type SearchedContatctObjectType = {
  __typename?: 'SearchedContatctObjectType';
  id: Scalars['String'];
  isOrg: Scalars['Boolean'];
  label: Scalars['String'];
};

/** Used for both Custom Sections and Supporting Docs */
export type Section = {
  __typename?: 'Section';
  enablingLogic: Scalars['JsonLogicRule'];
  footnotes: Array<Footnote>;
  id: Scalars['StrawberryObjectId'];
  internalDescription?: Maybe<Scalars['String']>;
  promptAllResponders: Scalars['Boolean'];
  prompts: Array<Prompt>;
  title: Scalars['String'];
  version: Scalars['Int'];
};

export type SectionInput = {
  arenaId: Scalars['Int'];
  enablingLogic?: Scalars['JsonLogicRule'];
  footnotes: Array<FootnoteInput>;
  id?: InputMaybe<Scalars['StrawberryObjectId']>;
  internalDescription?: InputMaybe<Scalars['String']>;
  promptAllResponders: Scalars['Boolean'];
  prompts: Array<PromptInput>;
  title: Scalars['String'];
};

export type SecurityLotObjectType = {
  __typename?: 'SecurityLotObjectType';
  totals?: Maybe<SecurityTotals>;
};

export type SecurityObjectType = {
  __typename?: 'SecurityObjectType';
  lots?: Maybe<Array<SecurityLotObjectType>>;
  name: Scalars['String'];
  totals?: Maybe<SecurityTotals>;
};

export type SecurityTotals = {
  __typename?: 'SecurityTotals';
  cost?: Maybe<Wallet>;
  date?: Maybe<Scalars['Date']>;
  fairValue?: Maybe<Wallet>;
  unitCost?: Maybe<Wallet>;
  unitCount?: Maybe<Scalars['Float']>;
  unitValue?: Maybe<Wallet>;
  unrealizedGain?: Maybe<Wallet>;
};

export type SessionLogConnection = {
  __typename?: 'SessionLogConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<SessionLogEdge>;
  pageInfo: PageInfo;
};

export type SessionLogEdge = {
  __typename?: 'SessionLogEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: SessionLogObjectType;
};

export type SessionLogObjectType = PermissionCheckedNode & {
  __typename?: 'SessionLogObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  startTime: Scalars['DateTime'];
  user?: Maybe<UserObjectType>;
  userAgent?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum SessionLogObjectTypeSortEnum {
  ArenaIdAsc = 'ARENA_ID_ASC',
  ArenaIdDesc = 'ARENA_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IpAddressAsc = 'IP_ADDRESS_ASC',
  IpAddressDesc = 'IP_ADDRESS_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  UserAgentAsc = 'USER_AGENT_ASC',
  UserAgentDesc = 'USER_AGENT_DESC'
}

export type SessionStatusObjectType = {
  __typename?: 'SessionStatusObjectType';
  /** Current arena ID */
  arenaId?: Maybe<Scalars['ID']>;
  /** Represents if the current session is impersonating, where a cc tools user logs in as a GPx user. This is separate from spoofed because it's possible for an impersonating user to spoof a contact and we want to track both cases. */
  impersonating: Scalars['Boolean'];
  /** Spoofing user ID, representing a GPx user acting as a portal 2.0 user */
  originalUserId?: Maybe<Scalars['ID']>;
  /** Return url for legacy portal domain */
  returnUrl?: Maybe<Scalars['String']>;
  /** Is the current session spoofed, meaning a GPx user is acting as a portal 2.0 user. This is separate from impersonating because it's possible for an impersonating user to spoof a contact and we want to track both cases. */
  spoofed: Scalars['Boolean'];
  /** Active session user ID */
  userId: Scalars['ID'];
};

export type SetEntitySectionsInput = {
  arenaId: Scalars['Int'];
  mainEntityId: Scalars['Int'];
  sectionStatusList: Array<EntitySectionEnabledStatusInput>;
};

export enum SetFilterQuantifier {
  All = 'all',
  Any = 'any',
  None = 'none'
}

export type SingleIntegrationEntityDetailsContactSyncInput = {
  jsqContactId: Scalars['ID'];
  provenance: Scalars['String'];
  syncType: IntegrationEntityDetailsSyncType;
};

export type SingleIntegrationEntityDetailsContactSyncObjectType = {
  __typename?: 'SingleIntegrationEntityDetailsContactSyncObjectType';
  syncStatus: Scalars['String'];
};

export type SingleIntegrationEntityDetailsContactSyncObjectTypeOrError = MutationErrorResponse | SingleIntegrationEntityDetailsContactSyncObjectType;

/** An enumeration. */
export enum SoboUserState {
  Active = 'active',
  Closed = 'closed',
  Error = 'error',
  Missing = 'missing',
  NotActivated = 'not_activated',
  PendingConsent = 'pending_consent'
}

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SourceMappingGroupItemType = {
  __typename?: 'SourceMappingGroupItemType';
  mappingGroups?: Maybe<Array<MappingGroupType>>;
  source: SourceType;
};

export type SourceType = {
  __typename?: 'SourceType';
  id: Scalars['Int'];
  mappingGroups: Array<MappingGroupType>;
  name: Scalars['String'];
  subName: Scalars['String'];
};

export type SpreadsheetCellType = {
  __typename?: 'SpreadsheetCellType';
  errorMessage?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type SpreadsheetResponseType = {
  __typename?: 'SpreadsheetResponseType';
  headers?: Maybe<Array<Maybe<Scalars['String']>>>;
  isValid?: Maybe<Scalars['Boolean']>;
  rows?: Maybe<Array<Maybe<SpreadsheetRowType>>>;
  status?: Maybe<Scalars['String']>;
};

export type SpreadsheetRowType = {
  __typename?: 'SpreadsheetRowType';
  cells?: Maybe<Array<Maybe<SpreadsheetCellType>>>;
  rowIndex?: Maybe<Scalars['Int']>;
};

export type StartCoBrowseSession = {
  __typename?: 'StartCoBrowseSession';
  errorMsg?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  watchUrl?: Maybe<Scalars['String']>;
};

export type StartEntityGlSyncInput = {
  arenaId: Scalars['Int'];
  endDate: Scalars['Date'];
  entityId: Scalars['Int'];
  excludeGlBatchIds?: InputMaybe<Array<Scalars['Int']>>;
  includeAllTransactions?: InputMaybe<Scalars['Boolean']>;
  includeCapitalCall: Scalars['Boolean'];
  includeCapitalTransaction: Scalars['Boolean'];
  includeDistribution: Scalars['Boolean'];
  includeNetIncome: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
  transferOnly?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['Int'];
};

export type StartEntityGlSyncResponse = {
  __typename?: 'StartEntityGLSyncResponse';
  success: Scalars['Boolean'];
};

export type StateCbsaObjectType = PermissionCheckedNode & {
  __typename?: 'StateCBSAObjectType';
  code: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type StateConnection = {
  __typename?: 'StateConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<StateEdge>;
  pageInfo: PageInfo;
};

export type StateEdge = {
  __typename?: 'StateEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: StateObjectType;
};

export type StateObjectType = PermissionCheckedNode & {
  __typename?: 'StateObjectType';
  code: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  ncreifDivision: Scalars['String'];
  ncreifRegion: Scalars['String'];
};

/** An enumeration. */
export enum StateObjectTypeSortEnum {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NcreifDivisionAsc = 'NCREIF_DIVISION_ASC',
  NcreifDivisionDesc = 'NCREIF_DIVISION_DESC',
  NcreifRegionAsc = 'NCREIF_REGION_ASC',
  NcreifRegionDesc = 'NCREIF_REGION_DESC'
}

export type StateType = {
  __typename?: 'StateType';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type StationeryObjectType = PermissionCheckedNode & {
  __typename?: 'StationeryObjectType';
  color?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersionObjectType>;
  documentVersionUrl?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  marginBottom?: Maybe<Scalars['Int']>;
  marginTop?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type StoredEmailObjectType = PermissionCheckedNode & {
  __typename?: 'StoredEmailObjectType';
  activityFeedOwnerName?: Maybe<Scalars['String']>;
  attachments: Array<ActivityAttachmentObjectType>;
  bccRecipients: Array<RecipientObjectType>;
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  ccRecipients: Array<RecipientObjectType>;
  dateSent: Scalars['Date'];
  entities: Array<AccountObjectType>;
  hashIdentifier: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  offerings: Array<OfferingObjectType>;
  organizations?: Maybe<ContactObjectTypeConnection>;
  people?: Maybe<ContactObjectTypeConnection>;
  regarding?: Maybe<RegardingObjectType>;
  sender: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  toRecipients: Array<RecipientObjectType>;
};


export type StoredEmailObjectTypeOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type StoredEmailObjectTypePeopleArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum Strategy {
  Core = 'core',
  CorePlus = 'core_plus',
  Opportunistic = 'opportunistic',
  ValueAdd = 'value_add'
}

export type StringFilterObjectType = {
  __typename?: 'StringFilterObjectType';
  quantifier?: Maybe<SetFilterQuantifier>;
  selected?: Maybe<Array<Scalars['String']>>;
};

export type SubmitArenaPaymentsApplicationInput = {
  paymentsApplicationId: Scalars['String'];
};

export type SubmitChangeRequestResponse = {
  __typename?: 'SubmitChangeRequestResponse';
  responses: Array<ChangeRequestResponse>;
};

export type SubmitChangeRequestResponseMutationErrorResponse = MutationErrorResponse | SubmitChangeRequestResponse;

export type SubmitInvestmentEntityPaymentsApplicationInput = {
  objectId: Scalars['Int'];
  profileId: Scalars['Int'];
};

/** Covers account type 501C3, CORPORATION, IRA, LLC, PARTNERSHIP, and OTHER. */
export type SubscriptionAccountEntityObjectType = {
  __typename?: 'SubscriptionAccountEntityObjectType';
  /** The existing investor account being used to subscribe, if any. */
  accountId?: Maybe<Scalars['Int']>;
  /** The investor account type. */
  accountType?: Maybe<SubscriptionAccountTypeObjectType>;
  contactsList?: Maybe<Array<Scalars['String']>>;
  /** If null, the entity is not a disregarded entity.  Otherwise, include the tax information for the ultimate beneficial owner. */
  disregardedEntityInfo?: Maybe<SubscriptionDisregardedEntityObjectType>;
  domicileAddress?: Maybe<SubscriptionAddressObjectType>;
  erisaQuestion1?: Maybe<Scalars['Boolean']>;
  erisaQuestion2?: Maybe<Scalars['Boolean']>;
  formationDate?: Maybe<Scalars['String']>;
  isUSPerson?: Maybe<Scalars['Boolean']>;
  jurisdictionCountry?: Maybe<CountryObjectType>;
  jurisdictionState?: Maybe<StateObjectType>;
  mailingAddress?: Maybe<SubscriptionAddressObjectType>;
  name?: Maybe<Scalars['String']>;
  noTaxId?: Maybe<Scalars['Boolean']>;
  /** If True, domicile address is the same as mailing address.  If False, domicile address must be provided. */
  sameDomicileAddress?: Maybe<Scalars['Boolean']>;
  taxCountry?: Maybe<CountryObjectType>;
  taxId?: Maybe<Scalars['String']>;
};

/**
 * Covers account type INDIVIDUAL.  Note that for this type, the mailing address and the owner's mailing address
 * reference the same thing.
 */
export type SubscriptionAccountIndividualObjectType = {
  __typename?: 'SubscriptionAccountIndividualObjectType';
  /** The existing investor account being used to subscribe, if any. */
  accountId?: Maybe<Scalars['Int']>;
  /** The investor account type. */
  accountType?: Maybe<SubscriptionAccountTypeObjectType>;
  contactsList?: Maybe<Array<Scalars['String']>>;
  mailingAddress?: Maybe<SubscriptionAddressObjectType>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<SubscriptionNaturalPersonObjectType>;
  taxId?: Maybe<Scalars['String']>;
};

export type SubscriptionAccountInfoObjectType = IndividualInvestorDataObjectType | LlcPartnershipInvestorDataObjectType | TrustInvestorDataObjectType;

/** Covers account type JOINT and JOINT_OTHER. */
export type SubscriptionAccountJointObjectType = {
  __typename?: 'SubscriptionAccountJointObjectType';
  /** The existing investor account being used to subscribe, if any. */
  accountId?: Maybe<Scalars['Int']>;
  /** The investor account type. */
  accountType?: Maybe<SubscriptionAccountTypeObjectType>;
  contactsList?: Maybe<Array<Scalars['String']>>;
  /** If null, the entity is not a disregarded entity.  Otherwise, include the tax information for the ultimate beneficial owner. */
  disregardedEntityInfo?: Maybe<SubscriptionDisregardedEntityObjectType>;
  mailingAddress?: Maybe<SubscriptionAddressObjectType>;
  name?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

/** An investor account (not an account entity). */
export type SubscriptionAccountObjectType = SubscriptionAccountEntityObjectType | SubscriptionAccountIndividualObjectType | SubscriptionAccountJointObjectType | SubscriptionAccountTrustObjectType;

/** Covers account type TRUST. */
export type SubscriptionAccountTrustObjectType = {
  __typename?: 'SubscriptionAccountTrustObjectType';
  /** The existing investor account being used to subscribe, if any. */
  accountId?: Maybe<Scalars['Int']>;
  /** The investor account type. */
  accountType?: Maybe<SubscriptionAccountTypeObjectType>;
  contactsList?: Maybe<Array<Scalars['String']>>;
  counterparties?: Maybe<Array<Maybe<SubscriptionCounterpartyObjectType>>>;
  /** If null, the entity is not a disregarded entity.  Otherwise, include the tax information for the ultimate beneficial owner. */
  disregardedEntityInfo?: Maybe<SubscriptionDisregardedEntityObjectType>;
  domicileAddress?: Maybe<SubscriptionAddressObjectType>;
  formationDate?: Maybe<Scalars['String']>;
  isAcceptableByTrust?: Maybe<Scalars['Boolean']>;
  isGrantorTrust?: Maybe<Scalars['Boolean']>;
  isUSPerson?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<SubscriptionAddressObjectType>;
  name?: Maybe<Scalars['String']>;
  noTaxId?: Maybe<Scalars['Boolean']>;
  /** If True, domicile address is the same as mailing address.  If False, domicile address must be provided. */
  sameDomicileAddress?: Maybe<Scalars['Boolean']>;
  taxCountry?: Maybe<CountryObjectType>;
  taxId?: Maybe<Scalars['String']>;
};

/**
 * The investor account type being used for the subscription.  Subscriptions support various "other" account types
 * that allow the investor to provide a freeform text description of the subscribing account.  That description will
 * appear verbatim in the subscription agreement.
 */
export type SubscriptionAccountTypeObjectType = {
  __typename?: 'SubscriptionAccountTypeObjectType';
  /** The type of subscriber account.  This field is not constrained to an enum because the existing database enum include an entry that begins with a numeral, making it an invalid GraphQL enum. */
  accountType?: Maybe<Scalars['String']>;
  /** If the account type is JOINT_OTHER, the user may specify the string to be used in the subscription documents.  It is not stored on the account. */
  otherJointOwnership?: Maybe<Scalars['String']>;
  /** If the account type is OTHER, the user may specify the string to be used in the subscription documents.  It is not stored on the account. */
  otherLegalEntityType?: Maybe<Scalars['String']>;
};

/**
 * Separate object type from AddressObjectType to make separating subscriptions easier in the future, however it
 * likely makes sense to separate addresses into their own separate microservice.
 */
export type SubscriptionAddressObjectType = {
  __typename?: 'SubscriptionAddressObjectType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryObjectType>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  state?: Maybe<StateObjectType>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
};

/**
 * The subscription batch takes a snapshot of relevant subscripton settings for all prospects invited at the same
 * time, so that further changes to the settings will not affect in-flight subscriptions.
 */
export type SubscriptionBatchObjectType = {
  __typename?: 'SubscriptionBatchObjectType';
  /** If True, allow two decimal places for currency.  If False, allow whole numbers for currency. */
  allowFullPrecision?: Maybe<Scalars['Boolean']>;
  /** If True, the GP can lower the commitment amount.  If False, the GP accepts the commitment amount as-is.  This field is currently only stored on the subscription settings object, so in-flight subscriptions are vulnerable to it being changed.  It is referenced on the batch object because it should eventually be snapshotted to the batch. */
  allowGPOverrideCommitment?: Maybe<Scalars['Boolean']>;
  /** If True, the LP cannot change the commitment amount. */
  commitmentAmountIsFixed?: Maybe<Scalars['Boolean']>;
  /** commitment_minimum is the maximum commitment, represented as two decimal places of currency, if allow_full_precision is True, a whole number of currency, if allow_full_precision is False, a whole number of units, or null, if no minimum commitment is set. */
  commitmentMaximum?: Maybe<Scalars['String']>;
  /** commitment_minimum is the minimum commitment, represented as two decimal places of currency, if allow_full_precision is True, a whole number of currency, if allow_full_precision is False, a whole number of units, or null, if no minimum commitment is set. */
  commitmentMinimum?: Maybe<Scalars['String']>;
  /** This field is currently only stored on the subscription settings object, so in-flight subscriptions are vulnerable to it being changed.  It is referenced on the batch object because it should eventually be snapshotted to the batch. */
  currency?: Maybe<CurrencyObjectType>;
  /** If True, display and require the employer and job title fields. */
  displayEmployerAndJobTitleFields?: Maybe<Scalars['Boolean']>;
  /** If True, display and require the formation date field. */
  displayFormationDateField?: Maybe<Scalars['Boolean']>;
  requireErisa?: Maybe<Scalars['Boolean']>;
  requireTaxId?: Maybe<Scalars['Boolean']>;
  /** Determines if the amount requested from the LP is only a commitment, or if it also represents the initial contribution.  This field defines the behavior for the current batch of subscriptions.  It is copied from the subscription setting on batch creation. */
  subscriptionBehavior?: Maybe<SubscriptionBehavior>;
  subscriptionCompletionMessage?: Maybe<Scalars['String']>;
  subscriptionCompletionPrintMessage?: Maybe<Scalars['String']>;
  subscriptionReviewDocumentsMessage?: Maybe<Scalars['String']>;
  /** Allows for partial units up to five decimal places.ex. unit_increment = 0.5.  Accepted number of units would be a multiple of 0.5 such as 0.5, 1, etc */
  unitIncrement?: Maybe<Scalars['String']>;
  /** unit_price is the amount of currency paid per unit of a subscription.  If null, the commitment_amount will be treated as currency.  It is passed as a string representation of an decimal, to two decimal places. */
  unitPrice?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum SubscriptionBehavior {
  Commitment = 'commitment',
  InitialContribution = 'initial_contribution'
}

export type SubscriptionBoundSection = {
  __typename?: 'SubscriptionBoundSection';
  promptResponder?: Maybe<SubscriptionResponder>;
  section: Section;
  status: LpResponseSectionStatus;
};

export type SubscriptionCcLogDumpObjectType = {
  __typename?: 'SubscriptionCCLogDumpObjectType';
  accountContactsInfo?: Maybe<Scalars['String']>;
  accountInfo?: Maybe<Scalars['String']>;
  additionalContactsInfo?: Maybe<Scalars['String']>;
  counterparties?: Maybe<Scalars['String']>;
  docusignData?: Maybe<Scalars['String']>;
  paymentInfo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/**
 * Enumeration for how to filter the get_subscription_contacts function.
 * IncludeAdditional means include everything (signers, non-signer natural persons and additional contacts)
 * NoAdditional means everything except additional contacts (signers and non-signer natural persons)
 * OnlySigners means only signatories that can sign (signers)
 * ExcludeSigners means everything except signatories (non-signer natural persons and additional contacts)
 */
export enum SubscriptionContactFilter {
  ExcludeSigners = 'ExcludeSigners',
  IncludeAdditional = 'IncludeAdditional',
  NoAdditional = 'NoAdditional',
  OnlySigners = 'OnlySigners'
}

/** A contact person attached to a subscription. */
export type SubscriptionContactObjectType = {
  __typename?: 'SubscriptionContactObjectType';
  contactUUID: Scalars['String'];
  distributionListIds?: Maybe<Array<Scalars['Int']>>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** True for primary contact ('to: field') and False for secondary contacts ('cc: field'). */
  isMain?: Maybe<Scalars['Boolean']>;
  /** True for account contacts, False for additional contacts. */
  isReadonly?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<SubscriptionAddressObjectType>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

/**
 * A counterparty may be a natural person or a legal entity.  If a legal entity, the signatory is a natural
 * person.
 */
export type SubscriptionCounterpartyObjectType = {
  __typename?: 'SubscriptionCounterpartyObjectType';
  /** The counterparty natural person or the signatory for the legal entity. */
  contact?: Maybe<SubscriptionNaturalPersonObjectType>;
  /** Does the counterparty represent a signatory, a beneficiary, or both?  If IDS is not enabled, the counterparty is always a signatory, because beneficiaries are not collected. */
  counterpartyRole: SubscriptionCounterpartyRole;
  /** Is the counterparty a natural person or legal entity? */
  counterpartyType: SubscriptionCounterpartyType;
  /** If the counterparty is a legal entity, its name. */
  entityName?: Maybe<Scalars['String']>;
  /** If the counterparty is a legal entity, its title. */
  entityTitle?: Maybe<Scalars['String']>;
  /** When IDS is enabled, this field is required, and at least one counterparty must be a significant beneficial owner. */
  isSignificantBeneficialOwner?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<SubscriptionAddressObjectType>;
  /** If True, mailing address is the same as the account mailing address for this subscription.  If False, mailing address must be provided. */
  sameAsInvestorMailingAddress?: Maybe<Scalars['Boolean']>;
  /** An extended signature block to show a chain of signature authority.  If null, the basic signature block will be used. */
  signatureBlockText?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum SubscriptionCounterpartyRole {
  BeneficialOwnerOnly = 'beneficial_owner_only',
  SignatoryAndBeneficialOwner = 'signatory_and_beneficial_owner',
  SignatoryOnly = 'signatory_only'
}

/** An enumeration. */
export enum SubscriptionCounterpartyType {
  LegalEntity = 'legal_entity',
  NaturalPerson = 'natural_person'
}

/**
 * A disregarded entity is disregarded as an entity separate from its owner for tax purposes so the owner reports
 * its income as their own, but the entity is still separate for legal purposes or risk exposure.
 */
export type SubscriptionDisregardedEntityObjectType = {
  __typename?: 'SubscriptionDisregardedEntityObjectType';
  taxCountry?: Maybe<CountryObjectType>;
  taxId?: Maybe<Scalars['String']>;
  ultimateBeneficialOwner?: Maybe<Scalars['String']>;
};

export type SubscriptionDocumentsObjectType = {
  __typename?: 'SubscriptionDocumentsObjectType';
  gpSigned?: Maybe<Array<Maybe<GpSignedDocumentObjectType>>>;
  nonSignedLpUploaded?: Maybe<Array<Maybe<LpNonSignedDocumentObjectType>>>;
  taxFormOptions?: Maybe<Array<Maybe<TaxFormFieldOptionObjectType>>>;
};

/** An email distribution list that an LP can request to be added to as part of this subscription. */
export type SubscriptionEmailDistributionObjectType = {
  __typename?: 'SubscriptionEmailDistributionObjectType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type SubscriptionEmbeddedEnvelopeDetails = {
  __typename?: 'SubscriptionEmbeddedEnvelopeDetails';
  offeringId: Scalars['ID'];
  offeringName: Scalars['String'];
  prospectId: Scalars['ID'];
  prospectTitle: Scalars['String'];
};

export type SubscriptionInfoDetailsObjectType = {
  __typename?: 'SubscriptionInfoDetailsObjectType';
  dataRoomKey?: Maybe<Scalars['String']>;
  formattedInvestmentAmount: Scalars['String'];
  primaryContactNames: Array<Maybe<Scalars['String']>>;
  secondaryContactNames: Array<Maybe<Scalars['String']>>;
  subscriptionInfoHash: Scalars['String'];
};

export enum SubscriptionLifecycle {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Invited = 'INVITED',
  Started = 'STARTED'
}

/** A natural person is the subscriber for an individual account, or a counterparty on other types of accounts. */
export type SubscriptionNaturalPersonObjectType = {
  __typename?: 'SubscriptionNaturalPersonObjectType';
  citizenship?: Maybe<CountryObjectType>;
  /** A unique UUID assigned to every subscription contact.  It acts as a primary key identifier for contacts which may not exist in the GP's contacts.  If not supplied a new uuid will be generated. */
  contactUUID?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  domicileAddress?: Maybe<SubscriptionAddressObjectType>;
  emailAddress?: Maybe<Scalars['String']>;
  /** If subscribing on behalf of a legal entity, store their employer. */
  employer?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isUSPerson?: Maybe<Scalars['Boolean']>;
  /** If subscribing on behalf of a legal entity, store their job title. */
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<SubscriptionAddressObjectType>;
  middleName?: Maybe<Scalars['String']>;
  noTaxId?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** If True, mailing address is the same as the account mailing address for this subscription.  If False, mailing address must be provided. */
  sameAsInvestorMailingAddress?: Maybe<Scalars['Boolean']>;
  /** If True, domicile address is the same as mailing address.  If False, domicile address must be provided. */
  sameDomicileAddress?: Maybe<Scalars['Boolean']>;
  taxCountry?: Maybe<CountryObjectType>;
  taxId?: Maybe<Scalars['String']>;
};

export type SubscriptionObjectType = PermissionCheckedNode & {
  __typename?: 'SubscriptionObjectType';
  accountName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  alert?: Maybe<Scalars['String']>;
  allowDocusignSubmission?: Maybe<Scalars['Boolean']>;
  allowPrintSignature?: Maybe<Scalars['Boolean']>;
  amountRequested?: Maybe<Scalars['Float']>;
  arena?: Maybe<ArenaObjectType>;
  arenaName?: Maybe<Scalars['String']>;
  arenaSupportEmail?: Maybe<Scalars['String']>;
  arenaSupportPhone?: Maybe<Scalars['String']>;
  canInitiateElectronicSignatures?: Maybe<Scalars['Boolean']>;
  ccLogDump?: Maybe<SubscriptionCcLogDumpObjectType>;
  chosenDesignatedSigner?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  documents?: Maybe<SubscriptionDocumentsObjectType>;
  docusignData?: Maybe<DocusignDataObjectType>;
  envelopePoll?: Maybe<EnvelopePollObjectType>;
  expectsFormationDocument?: Maybe<Scalars['Boolean']>;
  expectsTaxDocument?: Maybe<Scalars['Boolean']>;
  hasMultipleDesignatedSignerOptions?: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  incompleteMessage?: Maybe<Scalars['String']>;
  investorDiligenceKyc?: Maybe<InvestorDiligenceObjectType>;
  investorDiligenceWatchlist?: Maybe<InvestorDiligenceObjectType>;
  investorSignatureRecipients?: Maybe<Array<Maybe<InvestorSignatureRecipientObjectType>>>;
  isDocusignStarted?: Maybe<Scalars['Boolean']>;
  /** Checks if a subscription is editable or not. Will be true if subscription is in progress, identity info is being collected, or analyst requests are pending. */
  isEditable: Scalars['Boolean'];
  isInvestorDiligenceEnabled?: Maybe<Scalars['Boolean']>;
  isStaffReviewEnabled: Scalars['Boolean'];
  lpHasUploadedAllDocs?: Maybe<Scalars['Boolean']>;
  prospect?: Maybe<ProspectObjectType>;
  requestedElectronicSignatures: Scalars['Boolean'];
  requiresGpCountersignature: Scalars['Boolean'];
  /** Staff reviewer settings that are set at the entity level. Main use case is to fetch staff reviewer details before the staff review step is initiated and subscription level objects are created. */
  reviewerSettings: Array<SubscriptionReviewerSettingObjectType>;
  /** Staff reviewers assigned to this subscription and their review status details. */
  reviewers: Array<SubscriptionReviewerObjectType>;
  soboUserEmailAddress?: Maybe<Scalars['String']>;
  soboUserId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subscriptionCompletionMessage?: Maybe<Scalars['String']>;
  subscriptionCompletionPrintMessage?: Maybe<Scalars['String']>;
  /** Info for primary/secondary contact names & investment amount used for grouping subscriptions. */
  subscriptionInfo?: Maybe<SubscriptionInfoDetailsObjectType>;
  taxFormType?: Maybe<Scalars['String']>;
};


export type SubscriptionObjectTypeDocumentsArgs = {
  dataRoomKey: Scalars['String'];
  mode: Scalars['String'];
};

export type SubscriptionResponder = {
  __typename?: 'SubscriptionResponder';
  arenaId: Scalars['Int'];
  contactUuid: Scalars['UUID'];
  name: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

/** An enumeration. */
export enum SubscriptionReviewerDecision {
  Approve = 'approve',
  InProgress = 'in_progress',
  Reject = 'reject'
}

export type SubscriptionReviewerObjectType = PermissionCheckedNode & {
  __typename?: 'SubscriptionReviewerObjectType';
  decision?: Maybe<SubscriptionReviewerDecision>;
  /** The ID of the object */
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  staffUser: UserObjectType;
};

export type SubscriptionReviewerSettingObjectType = PermissionCheckedNode & {
  __typename?: 'SubscriptionReviewerSettingObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  staffUser: UserObjectType;
};

/** Subscription state stage */
export type SubscriptionStage = {
  __typename?: 'SubscriptionStage';
  blockedBy?: Maybe<SubscriptionStageBlockedBy>;
  blockedByDetails?: Maybe<Scalars['String']>;
  name: SubscriptionStageName;
  status: SubscriptionStageStatus;
};

export enum SubscriptionStageBlockedBy {
  Gp = 'GP',
  Lp = 'LP'
}

export enum SubscriptionStageName {
  ComplianceReview = 'COMPLIANCE_REVIEW',
  LpStepAdditionalInformation = 'LP_STEP_ADDITIONAL_INFORMATION',
  LpStepCommunicationPreferences = 'LP_STEP_COMMUNICATION_PREFERENCES',
  LpStepIdentityVerification = 'LP_STEP_IDENTITY_VERIFICATION',
  LpStepInvestmentAmount = 'LP_STEP_INVESTMENT_AMOUNT',
  LpStepPaymentInstructions = 'LP_STEP_PAYMENT_INSTRUCTIONS',
  LpStepReview = 'LP_STEP_REVIEW',
  LpStepSign = 'LP_STEP_SIGN',
  LpStepSubscriberAccount = 'LP_STEP_SUBSCRIBER_ACCOUNT',
  LpStepSupportingDocuments = 'LP_STEP_SUPPORTING_DOCUMENTS',
  PositionCreationCollectAccount = 'POSITION_CREATION_COLLECT_ACCOUNT',
  PositionCreationCollectContacts = 'POSITION_CREATION_COLLECT_CONTACTS',
  PositionCreationCollectDocs = 'POSITION_CREATION_COLLECT_DOCS',
  PositionCreationSetPosition = 'POSITION_CREATION_SET_POSITION',
  StaffCounterSign = 'STAFF_COUNTER_SIGN',
  StaffReview = 'STAFF_REVIEW'
}

export type SubscriptionStageStartedInput = {
  arenaId: Scalars['Int'];
  mainSubscriptionId: Scalars['ID'];
  stageName: SubscriptionStageName;
};

export enum SubscriptionStageStatus {
  Blocked = 'BLOCKED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
  Started = 'STARTED',
  Unstarted = 'UNSTARTED'
}

export type SubscriptionStates = {
  __typename?: 'SubscriptionStates';
  /** Overall subscription lifecycle. */
  lifecycle: SubscriptionLifecycle;
  /** Next subscription stage in the flow */
  nextStage?: Maybe<SubscriptionStageName>;
  /** Previous subscription stage in the flow. */
  previousStage?: Maybe<SubscriptionStageName>;
  /** List of subscription stages and their statuses */
  stages: Array<SubscriptionStage>;
  /** Whether this prompt is applicable for the LP. */
  summary?: Maybe<Scalars['String']>;
};

export type SubscriptionV2ObjectType = {
  __typename?: 'SubscriptionV2ObjectType';
  account?: Maybe<SubscriptionAccountObjectType>;
  allGpsHaveSigned?: Maybe<Scalars['Boolean']>;
  /** The commitment amount requested by the GP.  If allow_full_precision is True, amount_requested is two decimal places of currency.  If allow_full_precision is False, amount_requested is a whole number of currency.  If a count of units, amount_requested is five decimal places of units. */
  amountRequested?: Maybe<Scalars['String']>;
  arenaContactEmail?: Maybe<Scalars['String']>;
  arenaContactPhone?: Maybe<Scalars['String']>;
  arenaId: Scalars['Int'];
  arenaName: Scalars['String'];
  /** The subscription batch contains a snapshot of the settings for a set of subscriptions sent at once. */
  batch?: Maybe<SubscriptionBatchObjectType>;
  completedSignerEmails?: Maybe<Array<Scalars['String']>>;
  /** Account contacts and additional contacts added for communication purposes. */
  contacts?: Maybe<Array<SubscriptionContactObjectType>>;
  countersignatureRequired?: Maybe<Scalars['Boolean']>;
  customSectionsRequested: Array<SubscriptionBoundSection>;
  customSectionsResponses: Array<LpResponse>;
  dataRoomPrimaryPhotoUrl?: Maybe<Scalars['String']>;
  docsGpSigned?: Maybe<Array<Maybe<GpSignedDocumentObjectType>>>;
  docsNonSignedLpUploaded?: Maybe<Array<Maybe<LpNonSignedDocumentObjectType>>>;
  docusignStarted?: Maybe<Scalars['Boolean']>;
  emailDistributionListsAvailable?: Maybe<Array<SubscriptionEmailDistributionObjectType>>;
  entityId: Scalars['Int'];
  entityName: Scalars['String'];
  envelopePoll?: Maybe<EnvelopePollObjectType>;
  /** Payment preferences previously used by the existing account bound to this subscription. */
  existingPaymentPrefs?: Maybe<Array<PaymentPrefObjectType>>;
  /** If True, investor must upload a formation document. */
  expectsFormationDocument?: Maybe<Scalars['Boolean']>;
  expectsTaxDocument?: Maybe<Scalars['Boolean']>;
  gpSignatureRequiredForDocusign?: Maybe<Scalars['Boolean']>;
  hasSubsV3Ui?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  investorDiligenceKyc?: Maybe<InvestorDiligenceObjectType>;
  isInvestorDiligenceEnabled?: Maybe<Scalars['Boolean']>;
  isNewAccountEntityTypesEnabled?: Maybe<Scalars['Boolean']>;
  isNonUsDirectDepositEnabled: Scalars['Boolean'];
  isTaxFormStarted?: Maybe<Scalars['Boolean']>;
  latestValidatedStep?: Maybe<Scalars['String']>;
  /** Prompts for LP subscription flow. Fetch all prompts in the section up to the next unanswered applicable prompt */
  lpPrompts: LpPromptsForSection;
  /** A JSON object that contains the allowed account types for the LP which is set by GP in the Entity settings, and this is the list that will be shown as options to the LP while creating a new account. */
  newAccountEntityTypesAllowlist: Array<Scalars['String']>;
  nonUsDirectDepositMethodInstructions?: Maybe<Scalars['String']>;
  nonUsDirectDepositMethodName?: Maybe<Scalars['String']>;
  offeringStatus: Scalars['String'];
  offeringSuspendedNotice?: Maybe<Scalars['String']>;
  /** The current payment preference for the subscription. */
  paymentPref?: Maybe<PaymentPrefUnionObjectType>;
  prospectTitle: Scalars['String'];
  /** Additional questions for the subscriber to answer. */
  questionBlocks?: Maybe<Array<Maybe<QuestionSet>>>;
  signatureMode?: Maybe<Scalars['String']>;
  /** Current subscription states for subs 3.0 */
  states: SubscriptionStates;
  status: Scalars['String'];
  subscriptionCompleteMessage?: Maybe<Scalars['String']>;
  supportedPaymentMethods: Array<Scalars['String']>;
  supportingDocumentsRequested: Array<SubscriptionBoundSection>;
  supportingDocumentsResponses: Array<LpResponse>;
  taxFormOptions: Array<TaxFormFieldOptionObjectType>;
  /** Identifier for the type of tax form selected for this subscription. */
  taxFormType?: Maybe<Scalars['String']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};


export type SubscriptionV2ObjectTypeContactsArgs = {
  contactFilter?: InputMaybe<SubscriptionContactFilter>;
};


export type SubscriptionV2ObjectTypeCustomSectionsRequestedArgs = {
  contactUuid?: InputMaybe<Scalars['UUID']>;
  sectionId?: InputMaybe<Scalars['StrawberryObjectId']>;
};


export type SubscriptionV2ObjectTypeCustomSectionsResponsesArgs = {
  contactUuid?: InputMaybe<Scalars['UUID']>;
  sectionId?: InputMaybe<Scalars['StrawberryObjectId']>;
};


export type SubscriptionV2ObjectTypeDocsGpSignedArgs = {
  requiresSignatureOnly?: InputMaybe<Scalars['Boolean']>;
};


export type SubscriptionV2ObjectTypeLpPromptsArgs = {
  contactUuid?: InputMaybe<Scalars['UUID']>;
  sectionId: Scalars['StrawberryObjectId'];
};


export type SubscriptionV2ObjectTypeQuestionBlocksArgs = {
  questionBlockId?: InputMaybe<Scalars['Int']>;
};

export type SummaryType = {
  __typename?: 'SummaryType';
  accountsCreated?: Maybe<Scalars['Int']>;
  errorMessage?: Maybe<Scalars['String']>;
  positionsCreated?: Maybe<Scalars['Int']>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SyncPositionInfo = {
  __typename?: 'SyncPositionInfo';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum SyncStatus {
  Completed = 'completed',
  Failed = 'failed',
  Published = 'published',
  Received = 'received',
  Started = 'started'
}

export type TagConnection = {
  __typename?: 'TagConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<TagEdge>;
  pageInfo: PageInfo;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: TagObjectType;
};

export type TagFilterInput = {
  quantifier?: InputMaybe<SetFilterQuantifier>;
  selected?: InputMaybe<Array<Scalars['String']>>;
};

export type TagObjectType = PermissionCheckedNode & {
  __typename?: 'TagObjectType';
  arena?: Maybe<ArenaObjectType>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  objectType?: Maybe<JsqObjectType>;
  owner?: Maybe<UserObjectType>;
  tagText: Scalars['String'];
};

/** An enumeration. */
export enum TagObjectTypeSortEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  FlagsAsc = 'FLAGS_ASC',
  FlagsDesc = 'FLAGS_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  ObjectTypeAsc = 'OBJECT_TYPE_ASC',
  ObjectTypeDesc = 'OBJECT_TYPE_DESC',
  TagTextAsc = 'TAG_TEXT_ASC',
  TagTextDesc = 'TAG_TEXT_DESC'
}

/** Object that can have tags */
export type TaggableObjectType = {
  tags?: Maybe<TagConnection>;
};


/** Object that can have tags */
export type TaggableObjectTypeTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<TagObjectTypeSortEnum>>>;
};

export type TaxDocDeliveryPrefObjectType = PermissionCheckedNode & {
  __typename?: 'TaxDocDeliveryPrefObjectType';
  deliveryPref: TaxDocumentDeliveryPref;
  /** The ID of the object */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum TaxDocumentDeliveryPref {
  Electronic = 'electronic',
  Paper = 'paper'
}

export type TaxFormFieldOptionObjectType = {
  __typename?: 'TaxFormFieldOptionObjectType';
  label: Scalars['String'];
  staticDirFilepath: Scalars['String'];
  value: Scalars['String'];
};

export enum TaxIdType {
  Ein = 'ein',
  Itin = 'itin',
  Ssn = 'ssn'
}

export enum TextFilterType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'ends_with',
  Equals = 'equals',
  NotBlank = 'not_blank',
  NotContains = 'not_contains',
  NotEqual = 'not_equal',
  StartsWith = 'starts_with'
}

/** An enumeration. */
export enum TimeSeriesDataType {
  Currency = 'currency',
  Date = 'date',
  Float = 'float',
  Int = 'int',
  NumericRange = 'numeric_range',
  Option = 'option',
  Percent = 'percent',
  String = 'string',
  Wallet = 'wallet'
}

export type TimeSeriesDateToListTuple = {
  __typename?: 'TimeSeriesDateToListTuple';
  asOfDate: Scalars['Date'];
  values: Array<Maybe<TimeSeriesFieldWrapperObjectType>>;
};

export type TimeSeriesFieldWrapperObjectType = {
  dataType?: Maybe<TimeSeriesDataType>;
  label: Scalars['FieldName'];
  typeName: Scalars['FieldName'];
};

export type TotalCapitalActivityByTypeObjectType = {
  __typename?: 'TotalCapitalActivityByTypeObjectType';
  new: Wallet;
  rollover: Wallet;
  transactionTypeName?: Maybe<Scalars['String']>;
};

export type TransactionBatchObjectType = PermissionCheckedNode & {
  __typename?: 'TransactionBatchObjectType';
  batchCategory?: Maybe<Scalars['String']>;
  batchTotalDetails?: Maybe<Array<TransactionBatchTotalDetailsObjectType>>;
  containsRebalancing?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  effectiveDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<AccountObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  instances?: Maybe<TransactionObjectTypeConnection>;
  noun?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['DateTime']>;
  rebalancedCapitalCallBatch?: Maybe<CapitalCallBatchObjectType>;
  rowDescription?: Maybe<Scalars['String']>;
  storedTotals: Array<TransactionBatchTotalObjectType>;
  transactionType?: Maybe<TransactionTypeObjectType>;
  viewRoute?: Maybe<Scalars['String']>;
};


export type TransactionBatchObjectTypeInstancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TransactionBatchTotalDetailsObjectType = {
  __typename?: 'TransactionBatchTotalDetailsObjectType';
  amount: Wallet;
  transactionType: TransactionTypeObjectType;
};

export type TransactionBatchTotalObjectType = PermissionCheckedNode & {
  __typename?: 'TransactionBatchTotalObjectType';
  amount: Wallet;
  batchId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  typeId: Scalars['Int'];
};

export type TransactionCategoryPreviewData = {
  __typename?: 'TransactionCategoryPreviewData';
  existingTransactions: Array<GlSyncTransactionPreview>;
  flaggedZeroOwnershipPositions: Array<SyncPositionInfo>;
  missingPositions: Array<SyncPositionInfo>;
  newTransactions: Array<GlSyncTransactionPreview>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<TransactionEdge>;
  pageInfo: PageInfo;
  totalCapitalIn: Wallet;
  totalCapitalOut: Wallet;
};

export type TransactionDetailObjectType = PermissionCheckedNode & {
  __typename?: 'TransactionDetailObjectType';
  amount: Wallet;
  amountRecalled?: Maybe<Scalars['Float']>;
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags?: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  instance?: Maybe<TransactionObjectType>;
  instanceId: Scalars['Int'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserObjectType>;
  sortOrder: Scalars['Int'];
  transactionType?: Maybe<TransactionTypeObjectType>;
  typeId: Scalars['Int'];
};

export type TransactionDetailObjectTypeConnection = {
  __typename?: 'TransactionDetailObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<TransactionDetailObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type TransactionDetailObjectTypeEdge = {
  __typename?: 'TransactionDetailObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: TransactionDetailObjectType;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: TransactionObjectType;
};

/** An enumeration. */
export enum TransactionFrequency {
  Actual = 'actual',
  Annually = 'annually',
  Monthly = 'monthly',
  Quarterly = 'quarterly'
}

export type TransactionInstanceBatchObjectType = CapitalCallBatchObjectType | DistributionBatchObjectType | ManagementFeeBatchObjectType | NavBatchObjectType | NetIncomeBatchObjectType | TransactionBatchObjectType | UnitValueBatchObjectType;

export type TransactionObjectType = PermissionCheckedNode & {
  __typename?: 'TransactionObjectType';
  allAmounts: Wallet;
  amountReceived?: Maybe<Wallet>;
  batch?: Maybe<TransactionBatchObjectType>;
  capitalCall?: Maybe<CapitalCallObjectType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<TransactionDetailObjectTypeConnection>;
  /** The ID of the object */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  paymentDate: Scalars['Date'];
  paymentMethod?: Maybe<Scalars['String']>;
  position?: Maybe<PositionObjectType>;
  positionDelta?: Maybe<PositionDeltaObjectType>;
  pricePerUnit: Wallet;
  rebalancingTransactions?: Maybe<Array<Maybe<TransactionObjectType>>>;
  redemptionDetails?: Maybe<TransactionRedemptionDetailsObjectType>;
  totalRedeemAmount: Wallet;
};


export type TransactionObjectTypeDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TransactionObjectTypeConnection = {
  __typename?: 'TransactionObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<TransactionObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type TransactionObjectTypeEdge = {
  __typename?: 'TransactionObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: TransactionObjectType;
};

/** An enumeration. */
export enum TransactionObjectTypeSortEnum {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  PaymentDateAsc = 'PAYMENT_DATE_ASC',
  PaymentDateDesc = 'PAYMENT_DATE_DESC'
}

export type TransactionRedemptionDetailsObjectType = {
  __typename?: 'TransactionRedemptionDetailsObjectType';
  ownershipUnit: OwnershipUnitObjectType;
  positionBalance: Wallet;
  redemptionDetailList: Array<RedemptionDetail>;
  unitValue?: Maybe<UnitValueObjectType>;
};

export type TransactionTotalsObjectTypeBase = {
  accountValue?: Maybe<Wallet>;
  amountCommitted?: Maybe<Wallet>;
  amountRecallable?: Maybe<Wallet>;
  capitalAccountBalance?: Maybe<Wallet>;
  contributionsExcessCommitment?: Maybe<Wallet>;
  dpi?: Maybe<Scalars['Float']>;
  estimatedTotalReturn?: Maybe<Wallet>;
  hasRecallable?: Maybe<Scalars['Int']>;
  investedEquityBalance?: Maybe<Wallet>;
  lifetimeAmountCalled?: Maybe<Wallet>;
  lifetimeAmountCalledAgainstCommitment?: Maybe<Wallet>;
  lifetimeAmountDistributed?: Maybe<Wallet>;
  lifetimeAmountPassedThrough?: Maybe<Wallet>;
  lifetimeAmountRecalled?: Maybe<Wallet>;
  lifetimeAmountReceived?: Maybe<Wallet>;
  lifetimeAmountReturned?: Maybe<Wallet>;
  lifetimeCapitalContributed?: Maybe<Wallet>;
  lifetimeCapitalContributedAgainstCommitment?: Maybe<Wallet>;
  lifetimeCapitalOut?: Maybe<Wallet>;
  lifetimeCapitalRollover?: Maybe<Wallet>;
  lifetimeTotalAmountCalled?: Maybe<Wallet>;
  lifetimeTotalAmountPassedThrough?: Maybe<Wallet>;
  lifetimeTotalCapitalOut?: Maybe<Wallet>;
  lifetimeUncalledContributions?: Maybe<Wallet>;
  lifetimeUncalledContributionsAgainstCommitment?: Maybe<Wallet>;
  lifetimeWithheld?: Maybe<Wallet>;
  navBalance?: Maybe<Wallet>;
  remainingRecallable?: Maybe<Wallet>;
  rvpi?: Maybe<Scalars['Float']>;
  totalOutstandingCapitalCalled?: Maybe<Wallet>;
  tvpi?: Maybe<Scalars['Float']>;
  unfundedCommitment?: Maybe<Wallet>;
  unitCount?: Maybe<Scalars['Float']>;
  ytdAmountCalled?: Maybe<Wallet>;
  ytdAmountDistributed?: Maybe<Wallet>;
  ytdAmountPassedThrough?: Maybe<Wallet>;
  ytdAmountReceived?: Maybe<Wallet>;
  ytdAmountReturned?: Maybe<Wallet>;
  ytdCapitalContributed?: Maybe<Wallet>;
  ytdCapitalOut?: Maybe<Wallet>;
  ytdCapitalRollover?: Maybe<Wallet>;
  ytdWithheld?: Maybe<Wallet>;
};

/**
 * This is a slimmed down version of TransactionTotalsObjectType. It's used to return minimal information since a
 * portfolio overview might only want a small amount of data. This allows us to use simpler methods and the result
 * is a simpler and faster result.
 */
export type TransactionTotalsSummaryObjectType = {
  __typename?: 'TransactionTotalsSummaryObjectType';
  accountValue?: Maybe<Wallet>;
  amountCommitted?: Maybe<Wallet>;
  capitalAccountBalance?: Maybe<Wallet>;
  lifetimeAmountDistributed?: Maybe<Wallet>;
  lifetimeCapitalContributed?: Maybe<Wallet>;
  lifetimeTotalAmountCalled?: Maybe<Wallet>;
  navBalance?: Maybe<Wallet>;
};

/** An enumeration. */
export enum TransactionTypeEffect {
  Negative = 'negative',
  Neutral = 'neutral',
  Positive = 'positive'
}

export type TransactionTypeObjectType = PermissionCheckedNode & {
  __typename?: 'TransactionTypeObjectType';
  affectsCommitment?: Maybe<Scalars['Boolean']>;
  affectsOwnership?: Maybe<Scalars['Boolean']>;
  excludeFromMetrics: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  isNegative: Scalars['Boolean'];
  isPositive: Scalars['Boolean'];
  isSubType: Scalars['Boolean'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type TransferGlBatchPreviewResult = {
  __typename?: 'TransferGlBatchPreviewResult';
  glBatchDate: Scalars['Date'];
  glBatchDescription: Scalars['String'];
  glBatchId: Scalars['Int'];
  positions: Array<TransferPositionSummary>;
};

export type TransferPositionSummary = {
  __typename?: 'TransferPositionSummary';
  accountName: Scalars['String'];
  positionId: Scalars['Int'];
  transactionTypes: Array<TransferTransactionTypeSummary>;
};

export type TransferTransactionTypeSummary = {
  __typename?: 'TransferTransactionTypeSummary';
  amount: Scalars['Decimal'];
  type: Scalars['String'];
};

export type TrustInvestorDataObjectType = CommonCompanyInvestorDataInterface & {
  __typename?: 'TrustInvestorDataObjectType';
  beneficiaries?: Maybe<Array<Maybe<CounterpartyObjectType>>>;
  dateOfOrganization?: Maybe<Scalars['Date']>;
  domicileAddress?: Maybe<AddressObjectType>;
  isUsPerson?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<AddressObjectType>;
  name: Scalars['String'];
  signatories?: Maybe<Array<Maybe<CounterpartyObjectType>>>;
  taxId?: Maybe<Scalars['String']>;
};

export type TwoFaRecoveryCodeInfoObjectType = {
  __typename?: 'TwoFaRecoveryCodeInfoObjectType';
  /** List of active recovery codes */
  activeTwoFaRecoveryCodes: Array<Scalars['String']>;
  /** download url for two fa recovery codes file */
  twoFaRecoveryCodesDownloadPath: Scalars['String'];
};

/** An enumeration. */
export enum TwoFaType {
  Email = 'email',
  Sms = 'sms'
}

export type TwrPeriodMetricDetailsObjectType = TwrPeriodMetricPortalObjectType | TwrPeriodMetricStaffObjectType;

export type TwrPeriodMetricObjectType = PermissionCheckedNode & {
  __typename?: 'TwrPeriodMetricObjectType';
  grossAppreciation?: Maybe<Scalars['Float']>;
  grossAppreciationOverride?: Maybe<Scalars['Float']>;
  grossIncome?: Maybe<Scalars['Float']>;
  grossIncomeOverride?: Maybe<Scalars['Float']>;
  /** The ID of the object */
  id: Scalars['ID'];
  netAppreciation?: Maybe<Scalars['Float']>;
  netAppreciationOverride?: Maybe<Scalars['Float']>;
  netIncome?: Maybe<Scalars['Float']>;
  netIncomeOverride?: Maybe<Scalars['Float']>;
  objectId: Scalars['Int'];
  objectType: Scalars['Int'];
  twrPeriod: Scalars['Period'];
  unclassifiedIncome?: Maybe<Scalars['Float']>;
  weightedAverageEquity: Wallet;
  weightedAverageEquityOverride: Wallet;
};

export type TwrPeriodMetricPortalObjectType = {
  __typename?: 'TwrPeriodMetricPortalObjectType';
  grossAppreciation?: Maybe<Scalars['Float']>;
  grossIncome?: Maybe<Scalars['Float']>;
  isGrossAppreciationOverridden?: Maybe<Scalars['Boolean']>;
  isGrossIncomeOverridden?: Maybe<Scalars['Boolean']>;
  isNetAppreciationOverridden?: Maybe<Scalars['Boolean']>;
  isNetIncomeOverridden?: Maybe<Scalars['Boolean']>;
  netAppreciation?: Maybe<Scalars['Float']>;
  netIncome?: Maybe<Scalars['Float']>;
  period: Scalars['Period'];
  totalGross?: Maybe<Scalars['Float']>;
  totalGrossNet?: Maybe<Scalars['Float']>;
  totalNet?: Maybe<Scalars['Float']>;
  unclassifiedIncome?: Maybe<Scalars['Float']>;
};

export type TwrPeriodMetricStaffObjectType = {
  __typename?: 'TwrPeriodMetricStaffObjectType';
  isWithinTwrDateRange: Scalars['Boolean'];
  metric: TwrPeriodMetricObjectType;
};

export type TwrPeriodMetricsTableObjectType = {
  __typename?: 'TwrPeriodMetricsTableObjectType';
  headers: Array<Scalars['String']>;
  id: Scalars['ID'];
  metrics: Array<TwrPeriodMetricDetailsObjectType>;
};

export type TwrSummaryMetricsHeaderObjectType = {
  __typename?: 'TwrSummaryMetricsHeaderObjectType';
  metricsColumnNames: Array<Scalars['String']>;
  periodColumnName: Scalars['String'];
};

export type TwrSummaryMetricsObjectType = {
  __typename?: 'TwrSummaryMetricsObjectType';
  header: TwrSummaryMetricsHeaderObjectType;
  id: Scalars['ID'];
  rows: Array<TwrSummaryMetricsRowObjectType>;
};

export type TwrSummaryMetricsRowObjectType = {
  __typename?: 'TwrSummaryMetricsRowObjectType';
  metrics: Array<Scalars['String']>;
  period: Scalars['String'];
};

export type TypeFilterInput = {
  quantifier?: InputMaybe<SetFilterQuantifier>;
  selected?: InputMaybe<Array<ContactTypeFilterEnum>>;
};

export type TypeFilterObjectType = {
  __typename?: 'TypeFilterObjectType';
  quantifier?: Maybe<SetFilterQuantifier>;
  selected?: Maybe<Array<ContactTypeFilterEnum>>;
};

export type UnitValueBatchObjectType = PermissionCheckedNode & {
  __typename?: 'UnitValueBatchObjectType';
  description: Scalars['String'];
  effectiveDate?: Maybe<Scalars['Date']>;
  entity?: Maybe<AccountObjectType>;
  expirationDate?: Maybe<Scalars['Date']>;
  /** The ID of the object */
  id: Scalars['ID'];
  instances?: Maybe<UnitValueObjectTypeConnection>;
  noun?: Maybe<Scalars['String']>;
  viewRoute?: Maybe<Scalars['String']>;
};


export type UnitValueBatchObjectTypeInstancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UnitValueMetricObjectType = PermissionCheckedNode & {
  __typename?: 'UnitValueMetricObjectType';
  displayToInvestor: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  ownershipUnit: OwnershipUnitObjectType;
  usedForCalculation: Scalars['Boolean'];
};

export type UnitValueMetricObjectTypeConnection = {
  __typename?: 'UnitValueMetricObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<UnitValueMetricObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type UnitValueMetricObjectTypeEdge = {
  __typename?: 'UnitValueMetricObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: UnitValueMetricObjectType;
};

export type UnitValueObjectType = PermissionCheckedNode & {
  __typename?: 'UnitValueObjectType';
  calculatedPricePerUnit: Wallet;
  /** The ID of the object */
  id: Scalars['ID'];
  pricePerUnit: Wallet;
  totalValue: Wallet;
  unitValueBatch?: Maybe<UnitValueBatchObjectType>;
  unitValueMetric: UnitValueMetricObjectType;
};

export type UnitValueObjectTypeConnection = {
  __typename?: 'UnitValueObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<UnitValueObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type UnitValueObjectTypeEdge = {
  __typename?: 'UnitValueObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: UnitValueObjectType;
};

export type UpdateCommunicationPrefsRequest = {
  __typename?: 'UpdateCommunicationPrefsRequest';
  account: AccountObjectType;
  accountContactMap: ChangeRequestAccountContactMap;
  changeRequester: UserObjectType;
  contactUnderReview: ContactObjectType;
};

export type UpdateCommunicationPrefsRequestRemoveContactRequestAddContactRequestUpdatePositionPaymentPrefRequest = AddContactRequest | RemoveContactRequest | UpdateCommunicationPrefsRequest | UpdatePositionPaymentPrefRequest;

export type UpdateContactBioInput = {
  assigneeId?: InputMaybe<Scalars['String']>;
  dataAccessRoleId?: InputMaybe<Scalars['String']>;
  emailDomains?: InputMaybe<Array<ContactEmailDomainAcceptanceInput>>;
  employments?: InputMaybe<Array<ContactEmploymentInput>>;
  excludePortalInvitation?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  preqinUrl?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<ObjectRefInput>;
  relation?: InputMaybe<Array<ContactRelationInput>>;
  salutationName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateContactDetailsInput = {
  accessGroupIds?: InputMaybe<Array<Scalars['String']>>;
  addresses?: InputMaybe<Array<ContactAddressInput>>;
  emails?: InputMaybe<Array<ContactEmailAddressInput>>;
  faxes?: InputMaybe<Array<ContactPhoneInput>>;
  id: Scalars['ID'];
  phones?: InputMaybe<Array<ContactPhoneInput>>;
};

export type UpdateEntityGlSyncSettingsResponse = {
  __typename?: 'UpdateEntityGLSyncSettingsResponse';
  success: Scalars['Boolean'];
};

export type UpdateMappingSettingInput = {
  arenaId: Scalars['GlobalID'];
  entityIds?: InputMaybe<Array<Scalars['Int']>>;
  mappingGroupId: Scalars['GlobalID'];
  mappingId: Scalars['GlobalID'];
  mappingName?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentPrefForAccountsInput = {
  /** Account IDs to copy over the payment preference to. */
  accountIds: Array<Scalars['ID']>;
  /** Bypass check for accepted payment methods in order to update the position's payment preference */
  bypassCheckForAcceptedPaymentMethods?: InputMaybe<Scalars['Boolean']>;
  /** payment preference ID to update the account's  */
  paymentPrefId: Scalars['ID'];
};

export type UpdatePaymentPrefForAccountsOutput = {
  __typename?: 'UpdatePaymentPrefForAccountsOutput';
  /** Updated positions across all accounts */
  changedPositions: Array<PositionObjectType>;
};

export type UpdatePaymentPrefForAccountsResponse = MutationErrorResponse | UpdatePaymentPrefForAccountsOutput;

export type UpdatePaymentPrefForPositionInput = {
  /** Payment Preference ID to update the position for */
  paymentPrefId: Scalars['ID'];
  /** Position ID to update the payment preference for */
  positionId: Scalars['ID'];
};

export type UpdatePaymentPrefForPositionOutput = {
  __typename?: 'UpdatePaymentPrefForPositionOutput';
  /** Updated position, nullable if position not updated. */
  position?: Maybe<PositionObjectType>;
};

export type UpdatePaymentPrefForPositionResponse = MutationErrorResponse | UpdatePaymentPrefForPositionOutput;

export type UpdatePaymentPrefForPositionsOfPaymentPrefInput = {
  /** original payment preference ID currently attached to the positions */
  sourcePaymentPrefId: Scalars['ID'];
  /** target payment preference ID to associate positions with after mutation is complete */
  targetPaymentPrefId: Scalars['ID'];
};

export type UpdatePaymentPrefForPositionsOfPaymentPrefOutput = {
  __typename?: 'UpdatePaymentPrefForPositionsOfPaymentPrefOutput';
  /** Updated positions */
  positions: Array<PositionObjectType>;
};

/**
 * Mutation that updates the association for a list of positions that match the source payment preference ID
 * to have the target payment preference id.
 * All positions will be associated with the passed in payment preference ID.
 *
 * Currently supported for staff users only.
 */
export type UpdatePaymentPrefForPositionsOfPaymentPrefResponse = MutationErrorResponse | UpdatePaymentPrefForPositionsOfPaymentPrefOutput;

export type UpdatePaymentPrefForPositionsWithoutPaymentPrefInput = {
  /** account ID such that the positions without payment pref will be updated */
  accountId: Scalars['ID'];
  /** payment preference ID to associate positions with after mutation is complete */
  paymentPrefId: Scalars['ID'];
};

export type UpdatePaymentPrefForPositionsWithoutPaymentPrefOutput = {
  __typename?: 'UpdatePaymentPrefForPositionsWithoutPaymentPrefOutput';
  /** Updated positions */
  positions: Array<PositionObjectType>;
};

/**
 * Mutation that updates the association for a list of positions that match the source payment preference ID
 * to have the target payment preference id.
 * All positions will be associated with the passed in payment preference ID.
 *
 * Currently supported for staff users only.
 */
export type UpdatePaymentPrefForPositionsWithoutPaymentPrefResponse = MutationErrorResponse | UpdatePaymentPrefForPositionsWithoutPaymentPrefOutput;

export type UpdatePositionAllocationForPublicationInput = {
  publicationId: Scalars['ID'];
};

export type UpdatePositionPaymentPrefRequest = {
  __typename?: 'UpdatePositionPaymentPrefRequest';
  changeRequester: UserObjectType;
  impactedPositions: AssociatePositionsWithSourcePaymentPrefObjectTypeAssociateWithPositionObjectTypeAssociatePositionsOfAccountObjectTypeAssociatePositionsWithoutPaymentPrefObjectType;
  targetPaymentPref: PaymentPrefObjectType;
};

export type UpdateSectionProgressInput = {
  contactUuid?: InputMaybe<Scalars['UUID']>;
  sectionId: Scalars['StrawberryObjectId'];
  status: LpResponseSectionStatus;
  subscriptionId: Scalars['ID'];
};

export type UpdatedContactIdsObjectType = {
  __typename?: 'UpdatedContactIdsObjectType';
  ids: Array<Scalars['String']>;
};

export type UpdatedContactIdsOrError = MutationErrorResponse | UpdatedContactIdsObjectType;

export type UpdatedContactOrError = ContactObjectType | MutationErrorResponse;

export type UpsertCustomSectionSettingsInput = {
  arenaId: Scalars['Int'];
  internalDescription?: InputMaybe<Scalars['String']>;
  sectionId?: InputMaybe<Scalars['StrawberryObjectId']>;
  title: Scalars['String'];
};

export type UpsertEditorTemplateInput = {
  htmlBody: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JSONString']>;
  name: Scalars['String'];
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  objectType: Scalars['Int'];
  otherTemplateTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  templateType: Scalars['String'];
};

export type UserAddressObjectType = AddressObjectTypeBase & {
  __typename?: 'UserAddressObjectType';
  addressType?: Maybe<AddressType>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<ObjectRefObjectType>;
  countryId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  owner?: Maybe<UserObjectType>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  state?: Maybe<ObjectRefObjectType>;
  stateId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
};

export type UserAnnouncementNotificationDto = {
  __typename?: 'UserAnnouncementNotificationDto';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  header: Scalars['String'];
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type UserArenaPermissionsObjectType = {
  __typename?: 'UserArenaPermissionsObjectType';
  canAccessAdminSettingsPage: Scalars['Boolean'];
  canAccessAuditLog: Scalars['Boolean'];
  canAccessBasicConfigurations: Scalars['Boolean'];
  canAccessConfigurationSettings: Scalars['Boolean'];
  canAccessDynamicSubscriptionSettingsTab: Scalars['Boolean'];
  canAccessIntegrationSettingsTab: Scalars['Boolean'];
  canAccessInvestmentAccountSettings: Scalars['Boolean'];
  canAccessLegacySubscriptionSettingsTab: Scalars['Boolean'];
  canAccessPortalSettingsTab: Scalars['Boolean'];
  canAccessProspectSubscription: Scalars['Boolean'];
  canAccessStaffAccessTab: Scalars['Boolean'];
  canAccessSubscriptionSettingsTab: Scalars['Boolean'];
  canAssignAdministratorPermissionsToStaffUsers: Scalars['Boolean'];
  canConfigCrmNotificationLists: Scalars['Boolean'];
  canConfigDistributionsAndCapitalTransactions: Scalars['Boolean'];
  canConfigDocumentCategories: Scalars['Boolean'];
  canConfigESignatures: Scalars['Boolean'];
  canConfigEmailDistributionLists: Scalars['Boolean'];
  canConfigEntitySubtypes: Scalars['Boolean'];
  canConfigIntegrations: Scalars['Boolean'];
  canConfigManagementFees: Scalars['Boolean'];
  canConfigMappings: Scalars['Boolean'];
  canConfigMetricSettings: Scalars['Boolean'];
  canConfigNetIncomeAndNavComponents: Scalars['Boolean'];
  canConfigOrganizationSettings: Scalars['Boolean'];
  canConfigOwnershipUnitsAndPercentages: Scalars['Boolean'];
  canConfigPortalOfferingSettings: Scalars['Boolean'];
  canConfigReportingTemplate: Scalars['Boolean'];
  canConfigStatementNotices: Scalars['Boolean'];
  canConfigStationeryLibrary: Scalars['Boolean'];
  canConfigWatermarkTemplates: Scalars['Boolean'];
  canManageAgreements: Scalars['Boolean'];
  canManageAuthentication: Scalars['Boolean'];
  canManageContact: Scalars['Boolean'];
  canManageDataFields: Scalars['Boolean'];
  canManageSelf: Scalars['Boolean'];
  canManageStaffAccess: Scalars['Boolean'];
  canManageUserRoles: Scalars['Boolean'];
  canViewContact: Scalars['Boolean'];
  canViewEntity: Scalars['Boolean'];
  canViewOffering: Scalars['Boolean'];
};


export type UserArenaPermissionsObjectTypeCanManageContactArgs = {
  globalContactId?: InputMaybe<Scalars['String']>;
};


export type UserArenaPermissionsObjectTypeCanViewContactArgs = {
  globalContactId?: InputMaybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: UserObjectType;
};

export type UserNotificationFrequencyObjectType = PermissionCheckedNode & {
  __typename?: 'UserNotificationFrequencyObjectType';
  frequency: Frequency;
  /** The ID of the object */
  id: Scalars['ID'];
  notificationCampaign: NotificationCampaignObjectType;
};

/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectType = PermissionCheckedNode & {
  __typename?: 'UserObjectType';
  addresses: Array<UserAddressObjectType>;
  arena?: Maybe<ArenaObjectType>;
  arenaId?: Maybe<Scalars['Int']>;
  /** Returns the list of two step auth methods a user has access to. */
  availableTwoStepAuthMethods: Array<TwoFaType>;
  /** determines whether the user has access to edit payment preferences forall the positions of the provided account */
  canEditPaymentPrefsForAccountPermission: Scalars['Boolean'];
  /** determines whether the user can view payment preferences permission */
  canRevealPaymentPrefPermission: Scalars['Boolean'];
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObjectType>;
  contactFullName?: Maybe<Scalars['String']>;
  /** The user's preferred currencies */
  currencies?: Maybe<Array<CurrencyObjectType>>;
  /** Returns the list of two step auth methods a user has enabled. */
  currentTwoFaMethods: Array<TwoFaType>;
  /** The user's preferred date format */
  dateFormat?: Maybe<Scalars['String']>;
  defaultCurrency?: Maybe<Scalars['Int']>;
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  globalActivated: Scalars['Boolean'];
  groups?: Maybe<GroupConnection>;
  hasAcceptedTouMobileIos?: Maybe<Scalars['Boolean']>;
  /** True if has linked activated global user, otherwise false. */
  hasLinkedGlobalUser: Scalars['Boolean'];
  /** If the user belongs to any arena which requires accreditation and investor preferences. */
  hasLpAccreditationAndPreferencesRequiredArenas: Scalars['Boolean'];
  hasUnlinkedLocalUsers: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** If the user belongs to an arena which requires onboarding and the user has yet to complete the onboarding. */
  isOnboardingRequired?: Maybe<Scalars['Boolean']>;
  /** True if this user is a staff user and an admin */
  isStaffAdminUser: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** The contact associated with the user's linked arena, if any. */
  linkedArenaContact?: Maybe<ContactObjectType>;
  linkedArenas: Array<ArenaObjectType>;
  middleName?: Maybe<Scalars['String']>;
  /** List of recently linked arena IDs since user's last login. Only applicable to global users. */
  newLinkedArenaIds: Array<Scalars['ID']>;
  newsletterSubscribed: Scalars['Boolean'];
  newsletterSubscriptionType?: Maybe<NewsletterSubscriptionTypes>;
  notificationCampaignFrequencies: Array<UserNotificationFrequencyObjectType>;
  phones: Array<UserPhoneObjectType>;
  /** Determines whether a user requires a 2FA upgrade. Currently only applicable to global users. */
  requiresTwoFaUpgrade: Scalars['Boolean'];
  roleNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<RoleObjectTypeConnection>;
  salutationName?: Maybe<Scalars['String']>;
  sessionLogs?: Maybe<SessionLogConnection>;
  status?: Maybe<Scalars['String']>;
  /** The user's preferred time format */
  timeFormat?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** 2FA email address. Nullable if 2FA is disabled or phone 2FA is used instead */
  twoFaEmailAddress?: Maybe<Scalars['String']>;
  twoFaEnabled: Scalars['Boolean'];
  /** 2FA phone number. Nullable if 2FA is disabled or email 2FA is used instead */
  twoFaPhoneNumber?: Maybe<Scalars['String']>;
  /** List of active recovery codes for a user and recovery code file download url. */
  twoFaRecoveryCodeInfo?: Maybe<TwoFaRecoveryCodeInfoObjectType>;
  userPermissions?: Maybe<UserArenaPermissionsObjectType>;
};


/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectTypeCanEditPaymentPrefsForAccountPermissionArgs = {
  accountId: Scalars['ID'];
};


/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectTypeCanRevealPaymentPrefPermissionArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
};


/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<GroupObjectTypeSortEnum>>>;
};


/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectTypeIsOnboardingRequiredArgs = {
  arenaDomain: Scalars['String'];
};


/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectTypeRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectTypeSessionLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<SessionLogObjectTypeSortEnum>>>;
};


/** Corresponds to the UserObjectType in the main subgraph. */
export type UserObjectTypeTwoFaRecoveryCodeInfoArgs = {
  password?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum UserObjectTypeSortEnum {
  ArenaIdAsc = 'ARENA_ID_ASC',
  ArenaIdDesc = 'ARENA_ID_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  DefaultCurrencyAsc = 'DEFAULT_CURRENCY_ASC',
  DefaultCurrencyDesc = 'DEFAULT_CURRENCY_DESC',
  EmailAddressAsc = 'EMAIL_ADDRESS_ASC',
  EmailAddressDesc = 'EMAIL_ADDRESS_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  GlobalActivatedAsc = 'GLOBAL_ACTIVATED_ASC',
  GlobalActivatedDesc = 'GLOBAL_ACTIVATED_DESC',
  JobTitleAsc = 'JOB_TITLE_ASC',
  JobTitleDesc = 'JOB_TITLE_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  MiddleNameAsc = 'MIDDLE_NAME_ASC',
  MiddleNameDesc = 'MIDDLE_NAME_DESC',
  NewsletterSubscribedAsc = 'NEWSLETTER_SUBSCRIBED_ASC',
  NewsletterSubscribedDesc = 'NEWSLETTER_SUBSCRIBED_DESC',
  NewsletterSubscriptionTypeAsc = 'NEWSLETTER_SUBSCRIPTION_TYPE_ASC',
  NewsletterSubscriptionTypeDesc = 'NEWSLETTER_SUBSCRIPTION_TYPE_DESC',
  SalutationNameAsc = 'SALUTATION_NAME_ASC',
  SalutationNameDesc = 'SALUTATION_NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export type UserPhoneObjectType = PhoneObjectTypeBase & {
  __typename?: 'UserPhoneObjectType';
  id: Scalars['ID'];
  owner?: Maybe<UserObjectType>;
  phoneNumber: Scalars['String'];
  phoneType?: Maybe<PhoneType>;
};

export type ValidateRoutingNumberInput = {
  routingNumber: Scalars['String'];
  routingNumberType: PaymentsRoutingNumberType;
};

export enum ValueTypeEnum {
  Boolean = 'boolean',
  Date = 'date',
  Float = 'float',
  Int = 'int',
  String = 'string'
}

/** An enumeration. */
export enum VerificationStatus {
  Error = 'error',
  Unverified = 'unverified',
  Verified = 'verified'
}

export type VerifiedSignatories = {
  __typename?: 'VerifiedSignatories';
  verifiedEmailAddresses: Array<Scalars['String']>;
};

export type VerifySignatoriesInput = {
  emailAddresses: Array<Scalars['String']>;
};

export type VerifySignatoriesResponse = MutationErrorResponse | VerifiedSignatories;

export type VoidBulkCountersignInput = {
  bulkCountersignId?: InputMaybe<Scalars['Int']>;
};

export type VoidBulkCountersignResponse = AsyncJobObjectType | MutationErrorResponse;

export type Wallet = {
  __typename?: 'Wallet';
  monies: Array<Scalars['Money']>;
};

export type WalletGl = {
  __typename?: 'WalletGl';
  conversion: ConversionTotalGl;
  monies: Array<Scalars['Money']>;
};

export type WatchlistKeyIndividualObjectType = {
  __typename?: 'WatchlistKeyIndividualObjectType';
  alertId: Scalars['String'];
  emailAddress: Scalars['String'];
  name: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
};

export type WatchlistKeyInstitutionObjectType = {
  __typename?: 'WatchlistKeyInstitutionObjectType';
  alertId: Scalars['String'];
  name: Scalars['String'];
  uuid: Scalars['String'];
};

export type WatchlistScreeningDetailObjectType = PermissionCheckedNode & {
  __typename?: 'WatchlistScreeningDetailObjectType';
  arena?: Maybe<ArenaObjectType>;
  arenaId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  externalReferenceKey?: Maybe<Scalars['String']>;
  /** Bitmask that contains the object status.  See definition in class Flags above. */
  flags: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  info?: Maybe<Scalars['JSONString']>;
  investorDiligence?: Maybe<InvestorDiligenceObjectType>;
  investorDiligenceId: Scalars['Int'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  latestRecurringCheckDate?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  objectId?: Maybe<Scalars['Int']>;
  objectType?: Maybe<Scalars['Int']>;
  owner?: Maybe<UserObjectType>;
  status: WatchlistScreeningStatus;
  statusChangeDate?: Maybe<Scalars['DateTime']>;
  watchlistScreeningLogs?: Maybe<WatchlistScreeningLogObjectTypeConnection>;
};


export type WatchlistScreeningDetailObjectTypeWatchlistScreeningLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type WatchlistScreeningDetailObjectTypeConnection = {
  __typename?: 'WatchlistScreeningDetailObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<WatchlistScreeningDetailObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type WatchlistScreeningDetailObjectTypeEdge = {
  __typename?: 'WatchlistScreeningDetailObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: WatchlistScreeningDetailObjectType;
};

export type WatchlistScreeningLogObjectType = PermissionCheckedNode & {
  __typename?: 'WatchlistScreeningLogObjectType';
  checkEndDate?: Maybe<Scalars['DateTime']>;
  checkStartDate?: Maybe<Scalars['DateTime']>;
  externalReferenceKey?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  objectId?: Maybe<Scalars['Int']>;
  objectType?: Maybe<Scalars['Int']>;
  serviceResults?: Maybe<Scalars['JSONString']>;
};

export type WatchlistScreeningLogObjectTypeConnection = {
  __typename?: 'WatchlistScreeningLogObjectTypeConnection';
  count?: Maybe<Scalars['Int']>;
  edges: Array<WatchlistScreeningLogObjectTypeEdge>;
  pageInfo: PageInfo;
};

export type WatchlistScreeningLogObjectTypeEdge = {
  __typename?: 'WatchlistScreeningLogObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: WatchlistScreeningLogObjectType;
};

/** An enumeration. */
export enum WatchlistScreeningStatus {
  FailedThirdPartyCheck = 'failed_third_party_check',
  PassedThirdPartyCheck = 'passed_third_party_check',
  PendingThirdPartyCheck = 'pending_third_party_check'
}

export type WaterfallObjectType = PermissionCheckedNode & {
  __typename?: 'WaterfallObjectType';
  allocationChoice: AllocationChoice;
  entity?: Maybe<AccountObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  lastError?: Maybe<Scalars['String']>;
  transactionFrequency: TransactionFrequency;
};

export type WaterfallReturnAccountData = {
  __typename?: 'WaterfallReturnAccountData';
  /** Label for the waterfall account */
  label: Scalars['String'];
  /** Value of return(usually a %) for the waterfall account */
  value: Scalars['String'];
};

export type WaterfallReturnDataInfo = {
  __typename?: 'WaterfallReturnDataInfo';
  /** List of applicable waterfall accounts */
  accounts: Array<WaterfallReturnAccountData>;
  /** Effective date of the waterfall accounts */
  asOfDate?: Maybe<Scalars['Date']>;
};

export type WaterfallStatsItemObjectType = {
  __typename?: 'WaterfallStatsItemObjectType';
  grossDistributionAmount: Wallet;
  label?: Maybe<Scalars['String']>;
  tiers: Array<WaterfallStatsTierObjectType>;
};

export type WaterfallStatsObjectType = {
  __typename?: 'WaterfallStatsObjectType';
  errorReason?: Maybe<Scalars['String']>;
  items: Array<WaterfallStatsItemObjectType>;
};

export type WaterfallStatsTierObjectType = {
  __typename?: 'WaterfallStatsTierObjectType';
  amount: Wallet;
  label: Scalars['String'];
};

export type WireBankObjectType = {
  __typename?: 'WireBankObjectType';
  abaNumber?: Maybe<Scalars['String']>;
  address?: Maybe<SubscriptionAddressObjectType>;
  intlRoutingCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type WireExtraFieldInput = {
  field0?: InputMaybe<Scalars['String']>;
  field1?: InputMaybe<Scalars['String']>;
  field2?: InputMaybe<Scalars['String']>;
  field3?: InputMaybe<Scalars['String']>;
};

export type WireExtraFieldObjectType = {
  __typename?: 'WireExtraFieldObjectType';
  field0?: Maybe<Scalars['String']>;
  field1?: Maybe<Scalars['String']>;
  field2?: Maybe<Scalars['String']>;
  field3?: Maybe<Scalars['String']>;
};

export type WireFundingInstructionsDto = {
  __typename?: 'WireFundingInstructionsDto';
  accountAddress?: Maybe<AddressDto>;
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  amount: Scalars['Money'];
  bankAba: Scalars['String'];
  bankAddress: AddressDto;
  wireObiField: Scalars['String'];
};

export type WirePaymentPrefObjectType = {
  __typename?: 'WirePaymentPrefObjectType';
  bank?: Maybe<WireBankObjectType>;
  bankInfoRedacted?: Maybe<Scalars['String']>;
  beneficiaryAccountNumber?: Maybe<Scalars['String']>;
  beneficiaryAddress?: Maybe<SubscriptionAddressObjectType>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryType?: Maybe<Scalars['String']>;
  /** May be None if the payment pref is in draft state via subscriptions workflows. In this case, they may return a CommonPaymentPrefObjectType with the ID set to None. */
  id?: Maybe<Scalars['ID']>;
  intermediaryBank?: Maybe<WireBankObjectType>;
  naturalPersonDateOfBirth?: Maybe<Scalars['Date']>;
  naturalPersonEmailAddress?: Maybe<Scalars['String']>;
  naturalPersonFirstName?: Maybe<Scalars['String']>;
  naturalPersonLastName?: Maybe<Scalars['String']>;
  naturalPersonMiddleName?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentPrefId?: Maybe<Scalars['Int']>;
  positions?: Maybe<Array<PositionObjectType>>;
  referenceToBeneficiary?: Maybe<Scalars['String']>;
  wireBbi?: Maybe<WireExtraFieldObjectType>;
  wireObi?: Maybe<WireExtraFieldObjectType>;
};

export enum Join__Graph {
  BillingBackend = 'BILLING_BACKEND',
  ChangeManagement = 'CHANGE_MANAGEMENT',
  Compliance = 'COMPLIANCE',
  DashboardHubBe = 'DASHBOARD_HUB_BE',
  Gl = 'GL',
  MainSubgraph = 'MAIN_SUBGRAPH',
  Notifications = 'NOTIFICATIONS',
  Payments = 'PAYMENTS',
  Subscriptions = 'SUBSCRIPTIONS'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export const federatedNamedOperations = {
  Query: {
    GetCountries: 'GetCountries',
    GetProfilesForCase: 'GetProfilesForCase',
    GetComplianceCasesByObjectId: 'GetComplianceCasesByObjectId',
    GetDocumentDetails: 'GetDocumentDetails'
  },
  Mutation: {
    createDocuments: 'createDocuments',
    complianceGeneratePresignedUrls: 'complianceGeneratePresignedUrls'
  },
  Fragment: {
    ProfileDetails: 'ProfileDetails',
    ProfileRelationshipDetails: 'ProfileRelationshipDetails'
  }
}