import React from "react";
import { Button, Flex, HelpIndicator, Text } from "@jsq/ds/core";
import { ProfileActionOverviewItemProps } from "../types";
import { buttonStyle, buttonWrapperDiv, profileActionOverviewItem } from "./style_vanilla.css";

type ButtonWrapperProps = {
  children: React.ReactNode;
  tooltip?: string;
  id?: string;
};

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  children,
  tooltip,
  id,
}: ButtonWrapperProps) => {
  return tooltip ? (
    <HelpIndicator content={tooltip} id={id}>
      <div className={buttonWrapperDiv}>{children}</div>
    </HelpIndicator>
  ) : (
    <>{children}</>
  );
};

export const ProfileActionOverviewItem: React.FC<ProfileActionOverviewItemProps> = ({
  title,
  subTitle,
  buttonType,
  buttonEnabled,
  buttonTooltip,
  onClick,
}: ProfileActionOverviewItemProps) => {
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      flexWrap={"wrap"}
      className={profileActionOverviewItem}
    >
      <Flex flexDirection="column">
        <Text variant="h4">{title}</Text>
        <Text variant="secondary-md">{subTitle}</Text>
      </Flex>
      <Flex>
        <ButtonWrapper tooltip={buttonTooltip} id="help_indicator">
          <Button
            disabled={!buttonEnabled}
            className={buttonStyle}
            variant={buttonType === "start" ? "primary" : "neutral"}
            onClick={onClick}
          >
            {buttonType === "start" ? "Start" : "Edit"}
          </Button>
        </ButtonWrapper>
      </Flex>
    </Flex>
  );
};
