import React from "react";
import { Flex, Text } from "@jsq/ds/core";
import { ComplianceObjectType } from "../../graphql/supergraphOperations";
import { sharedHeaderStyle } from "../common/style_vanilla.css";
import { ProfileActionOverviewItem } from "./OverviewItem";
import { description } from "./style_vanilla.css";
import { ProfileActionOverviewProps } from "./types";

export const ProfileActionOverview: React.FC<ProfileActionOverviewProps> = ({
  profileName,
  profileType,
  detailsCompleted,
  documentsCompleted,
  ownersCompleted,
  controllersCompleted,
  onDetailsClick,
  onDocumentsClick,
  onOwnersClick,
  onControllersClick,
}: ProfileActionOverviewProps) => {
  return (
    <Flex flexDirection="column">
      <Text variant="h3" className={sharedHeaderStyle}>
        {profileName}
      </Text>
      {profileType === ComplianceObjectType.Institution && (
        <Text variant="regular-md" className={description}>
          Additional information about {profileName} and its subsidiaries is required. Entering
          this information accurately is a regulatory requirement.
        </Text>
      )}
      <Flex gap="20px" flexDirection="column">
        <Flex flexDirection="column">
          <ProfileActionOverviewItem
            title="Details"
            subTitle={`Basic information about this ${profileType === ComplianceObjectType.Institution ? "entity" : "individual"}`}
            buttonType={detailsCompleted ? "edit" : "start"}
            buttonEnabled
            onClick={onDetailsClick}
          />
          <ProfileActionOverviewItem
            title="Documents"
            subTitle={
              profileType === ComplianceObjectType.Institution
                ? "Formation documents and others based on entity details"
                : "Identification documents"
            }
            buttonType={documentsCompleted ? "edit" : "start"}
            buttonEnabled={profileType === ComplianceObjectType.Individual || detailsCompleted}
            buttonTooltip={
              profileType === ComplianceObjectType.Individual || detailsCompleted
                ? undefined
                : "Documents cannot be uploaded without first completing the Details section for this entity."
            }
            onClick={onDocumentsClick}
          />
          {profileType === ComplianceObjectType.Institution && (
            <>
              <ProfileActionOverviewItem
                title="Owners"
                subTitle="Add profiles for individuals or entities with significant equity interests"
                buttonType={ownersCompleted ? "edit" : "start"}
                buttonEnabled
                onClick={onOwnersClick}
              />
              <ProfileActionOverviewItem
                title="Controllers"
                subTitle="Add profiles for individuals with significant responsibility for managing the business"
                buttonType={controllersCompleted ? "edit" : "start"}
                buttonEnabled
                onClick={onControllersClick}
              />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
